import { Typography, Button, Box, Tooltip } from "@mui/material";
import theme from "lib/theme";
import { DrawerProps } from "types/pickup";
import { PendingActions } from "@mui/icons-material";
import { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  address,
  assets,
  CancelConfirmation,
  contact,
  PickupAssetsDetails,
  pickupDate,
  PickupDetailsRow,
  PickupDetailsRows,
  PickupDetailsTitle,
  shouldDisableEditPickup,
} from "./shared";
import { navActions } from "components/AppLayout/Nav";
import useCurrentUserPermission from "hooks/useCurrentUserPermission";

export function PendingConfirmationDrawer(props: DrawerProps) {
  const { pickup } = props;
  const [showCancel, setShowCancel] = useState(false);
  const { hasPermission } = useCurrentUserPermission();

  const disabled = useMemo(() => {
    return shouldDisableEditPickup(pickup) || !hasPermission("pickups");
  }, [pickup, hasPermission]);

  return (
    <Box mb={5} mx={2}>
      <Box
        sx={{
          py: 3,
          px: 4,
          background: "#FAFAFA",
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
        }}
      >
        <PendingActions />
        <Typography variant="h6" fontWeight="bold">
          Your pickup date still needs to be confirmed!
        </Typography>
        <Typography>
          Our logistics team is currently reviewing your pickup request. We’ll get back to you soon!
        </Typography>

        <Box mt={2}>
          <PickupDetailsTitle />

          <PickupDetailsRows>
            <PickupDetailsRow
              items={[
                {
                  title: "PICKUP DATE",
                  list: pickupDate(pickup),
                },
                {
                  title: "CONTACT",
                  list: contact(pickup),
                },
                {
                  title: "ADDRESS",
                  list: address(pickup),
                },
              ]}
            />
            <PickupAssetsDetails items={assets(pickup)} />
          </PickupDetailsRows>
        </Box>
      </Box>

      <Box
        mt={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          gap: 3,
        }}
      >
        <Tooltip
          title={
            disabled
              ? "We're excited to pickup your items soon! Please contact us if you need to update your pickup any further."
              : ""
          }
        >
          <span>
            <Button
              size="small"
              variant="contained"
              component={RouterLink}
              to={`/pickups/${pickup.uuid}`}
              disabled={disabled}
            >
              Edit pickup
            </Button>
          </span>
        </Tooltip>
        <Button
          size="small"
          variant="contained"
          onClick={() => setShowCancel(true)}
          disabled={shouldDisableEditPickup(pickup) || showCancel || !hasPermission("pickups")}
        >
          cancel pickup
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            navActions.openContactConcierge();
          }}
        >
          Need to reschedule? Contact us
        </Button>
      </Box>

      {showCancel && (
        <Box mt={3}>
          <CancelConfirmation close={() => setShowCancel(false)} pickup={pickup} />
        </Box>
      )}
    </Box>
  );
}
