import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import { useLocation, useSearchParams } from "react-router-dom";
import OrderDetails from "./OrderDetails";
import StartOrder from "./StartOrder";
import { useCallback } from "react";
import PlaceOrder from "./PlaceOrder";
import theme from "lib/theme";
import { Formik } from "formik";
import * as yup from "yup";
import useAssetRetrieval from "hooks/assetRetrievals/useAssetRetrieval";
import useAnalytics from "hooks/analytics/useAnalytics";

const validations = yup.object({
  employeeFirstName: yup.string().required("required"),
  employeeLastName: yup.string().required("required"),
  employeeEmail: yup.string().required("required"),
  employeeAddress: yup.string().required("required"),
  employeeAddressCity: yup.string().required("required"),
  employeeAddressState: yup.string().required("required"),
  employeeAddressZipCode: yup.string().required("required"),
  employeeAddressCountry: yup.string().required("required"),
  manufacturer: yup.string(),
  model: yup.string(),
  serialNumber: yup.string(),
  insuranceOption: yup.string().required("required"),
  locationId: yup.string().when(["isCustomAddress", "virtualPickup"], {
    is: (isCustomAddress: boolean, virtualPickup: boolean) => {
      return !isCustomAddress && !virtualPickup;
    },
    then: yup.string().required("Please select a location"),
  }),
  returnRecipientFirstName: yup.string().when(["isCustomAddress", "virtualPickup"], {
    is: (isCustomAddress: boolean, virtualPickup: boolean) => {
      return isCustomAddress && !virtualPickup;
    },
    then: yup.string().required("Please enter a first name"),
  }),
  returnRecipientLastName: yup.string().when(["isCustomAddress", "virtualPickup"], {
    is: (isCustomAddress: boolean, virtualPickup: boolean) => {
      return isCustomAddress && !virtualPickup;
    },
    then: yup.string().required("Please enter a last name"),
  }),
  returnRecipientAddress: yup.string().when(["isCustomAddress", "virtualPickup"], {
    is: (isCustomAddress: boolean, virtualPickup: boolean) => {
      return isCustomAddress && !virtualPickup;
    },
    then: yup.string().required("Please enter an address"),
  }),
  returnRecipientAddressCity: yup.string().when(["isCustomAddress", "virtualPickup"], {
    is: (isCustomAddress: boolean, virtualPickup: boolean) => {
      return isCustomAddress && !virtualPickup;
    },
    then: yup.string().required("Please enter a city"),
  }),
  returnRecipientAddressState: yup.string().when(["isCustomAddress", "virtualPickup"], {
    is: (isCustomAddress: boolean, virtualPickup: boolean) => {
      return isCustomAddress && !virtualPickup;
    },
    then: yup.string().required("Please enter a state"),
  }),
  returnRecipientAddressZipCode: yup.string().when(["isCustomAddress", "virtualPickup"], {
    is: (isCustomAddress: boolean, virtualPickup: boolean) => {
      return isCustomAddress && !virtualPickup;
    },
    then: yup.string().required("Please enter a zip code"),
  }),
});

export default function NewAssetRetrieval() {
  const { assetRetrieval, create, loading } = useAssetRetrieval();
  let location = useLocation();
  let [, uuid, path] = location.pathname.split("/").filter(Boolean);
  let [searchParams, setSearchParams] = useSearchParams();
  const { createAnalyticsEvent } = useAnalytics();

  let step = parseInt(searchParams.get("step") ?? "0");

  let goToNextStep = useCallback(() => {
    setSearchParams({ step: String(step + 1) });
    createAnalyticsEvent(`asset_retrieval_form_step_${step + 1}`);
  }, [setSearchParams, step]);

  return (
    <div>
      <Box>
        <Breadcrumbs
          data={[
            { label: "Dashboard", to: "/" },
            { label: "Retrievals", to: "/retrievals/send-boxes" },
            { label: "Individual Order", to: "/retrievals/send-boxes/new" },
          ]}
        ></Breadcrumbs>
      </Box>

      <Box mt={4}>
        <Typography variant="h4" fontWeight="bold">
          Place an Order
        </Typography>
        <Typography variant="body1">Send a box to an employee to retrieve their asset. </Typography>

        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Stepper
            alternativeLabel
            activeStep={step}
            sx={{
              width: "100%",
              maxWidth: "900px",
              mt: 5,
              ".MuiStepLabel-root": {
                [theme.breakpoints.down("sm")]: {
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 0.5,
                },
              },
            }}
          >
            <Step
              onClick={() => setSearchParams({ step: "0" })}
              sx={{
                cursor: "pointer",
              }}
            >
              <StepLabel>Order Details</StepLabel>
            </Step>
            <Step
              onClick={() => setSearchParams({ step: "1" })}
              sx={{
                cursor: "pointer",
              }}
            >
              <StepLabel>Return Address</StepLabel>
            </Step>
            <Step>
              <StepLabel>Review & Pay</StepLabel>
            </Step>
          </Stepper>
        </Box>
      </Box>
      {!loading && (
        <Formik
          validateOnMount
          initialValues={assetRetrieval}
          validationSchema={validations}
          onSubmit={(values, { setFieldValue }) => {
            create(values).then(({ data: { orderNumber } }) => {
              setFieldValue("orderNumber", orderNumber);
            });
          }}
        >
          <Box sx={{ mt: 4 }}>
            {step === 0 ? (
              <StartOrder goToNextStep={goToNextStep} />
            ) : step === 1 ? (
              <OrderDetails goToNextStep={goToNextStep} />
            ) : step === 2 ? (
              <PlaceOrder goToNextStep={goToNextStep} />
            ) : null}
          </Box>
        </Formik>
      )}
    </div>
  );
}
