import { Box, Alert, AlertTitle } from "@mui/material";
import theme from "lib/theme";

export default function Error() {
  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: theme.palette.divider,
        background: "white",
        minHeight: "500px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Something went wrong
      </Alert>
    </Box>
  );
}
