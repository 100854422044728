import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "services/api.service";
import useAnalytics from "hooks/analytics/useAnalytics";

interface oauthProps {
  name: string;
}

export default function useCreateOauthApplication() {
  const navigate = useNavigate();
  const { createAnalyticsEvent } = useAnalytics();

  const create = useCallback((oauthApplication: oauthProps) => {
    createAnalyticsEvent("created_oauth_application");
    api.post("/oauth_applications", { oauthApplication }).then(() => {
      navigate("/api");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    create,
  };
}
