import { Close } from "@mui/icons-material";
import { Box, IconButton, Modal as MUIModal } from "@mui/material";

interface ModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  width?: string | number;
  height?: string | number;
  onClose?: () => void;
  hideCloseButton?: boolean;
  style?: React.CSSProperties;
}

export function Modal({
  open,
  setOpen,
  children,
  onClose,
  width = "742px",
  height,
  style,
  hideCloseButton,
}: React.PropsWithChildren<ModalProps>) {
  function handleClose() {
    setOpen(false);
    onClose?.();
  }

  return (
    <MUIModal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width,
          height,
          bgcolor: "background.paper",
          boxShadow: 24,
          pt: 4.5,
          px: 8,
          pb: 6,
        }}
        style={style}
      >
        {!hideCloseButton && (
          <IconButton onClick={handleClose} sx={{ position: "absolute", top: "4px", right: "4px" }}>
            <Close />
          </IconButton>
        )}

        {children}
      </Box>
    </MUIModal>
  );
}
