import { Box, Button, FormControl, Link, Typography } from "@mui/material";
import { InputBase, InputLabel } from "components/common/Input";
import { Formik } from "formik";
import useAuth from "hooks/useAuth";
import theme from "lib/theme";
import LoginCoverImg from "images/login-cover.png";
import * as yup from "yup";
import { useAlert } from "hooks/alerts/useAlert";

export default function CreateResetPassword() {
  const { createResetPassword } = useAuth();
  const { alertInfo, alertError } = useAlert();

  return (
    <Box sx={{ paddingBottom: "100px" }}>
      <img
        src={LoginCoverImg}
        alt="login cover"
        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
      ></img>

      <Typography
        variant="h4"
        sx={{ textAlign: "center", mt: 6, fontWeight: "bold", color: theme.palette.text.primary }}
      >
        Reset Password
      </Typography>

      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          color: theme.palette.text.secondary,
          mt: 3,
        }}
      >
        Don't worry, we’ll email you instructions to reset your password. If you don’t have access to your email
        anymore, please contact us at{" "}
        <Link sx={{ my: 2 }} href={`mailto:support@revivn.com`}>
          support@revivn.com
        </Link>
      </Typography>
      <Box sx={{ maxWidth: "660px", mx: "auto" }}>
        <Box mt={4}>
          <Formik
            initialValues={{ email: "" }}
            onSubmit={async (values, { setFieldError, setErrors }) => {
              setErrors({});
              try {
                await createResetPassword(values)
                  .then(() => {
                    alertInfo("An email has been sent - please check your inbox.", {
                      vertical: "bottom",
                      horizontal: "right",
                    });
                  })
                  .catch(
                    ({
                      response: {
                        data: { errors },
                      },
                    }) => {
                      if (errors.email) setFieldError("email", "User with this email doesn't exist");
                    }
                  );
              } finally {
              }
            }}
            validationSchema={yup.object({
              email: yup.string().email("Invalid Email").required("Required"),
            })}
          >
            {({ getFieldProps, errors, handleSubmit, isSubmitting, touched, isValid }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="column">
                  <FormControl variant="standard" sx={{ mt: 2 }}>
                    <InputLabel shrink htmlFor="password">
                      Email
                    </InputLabel>
                    <InputBase
                      type="email"
                      {...getFieldProps("email")}
                      id="email"
                      helperText={touched.email && errors.email}
                      error={touched.email && !!errors.email}
                    />
                  </FormControl>

                  <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    disabled={isSubmitting || !isValid}
                    sx={{ textTransform: "uppercase", mt: 4, alignSelf: "start" }}
                  >
                    Reset Password
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}
