import { Box, Typography, Button } from "@mui/material";
import { Computer } from "@mui/icons-material";
import { Modal } from "components/common/Modal";
import useUpdateAsset from "hooks/assets/useUpdateAsset";
import { useAlert } from "hooks/alerts/useAlert";

interface NotFoundAssetModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  refetch: () => void;
  uuid?: string;
}

export function NotFoundAssetModal({ open, setOpen, refetch, uuid }: NotFoundAssetModalProps) {
  const { notFoundAsset } = useUpdateAsset({ refetch });
  const { alertError, alertSuccess } = useAlert();
  const handleClick = () => {
    if (uuid) {
      notFoundAsset(uuid)
        .then(() => {
          alertSuccess("Successfully marked the asset as can't release.");
          setOpen(false);
        })
        .catch(({ response: { data } }) => {
          if (data.errors) {
            alertError(`There was an error: ${data.errors}`);
          }
        });
    }
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Box>
        <Box sx={{ textAlign: "center" }}>
          <Computer color="primary" sx={{ fontSize: "60px" }} />
          <Typography mt={1} variant="h5" component="h2" fontWeight={800}>
            Can’t release this asset?
          </Typography>
          <Typography mt={0.5} color="text.secondary">
            Are you having issues releasing this asset? Whatever the case may be, Revivn can take apart the laptop
            instead.
          </Typography>
          <Typography mt={0.5} color="text.secondary">
            Let us know if you would like us to proceed with parting this asset! (However, please note that laptops that
            are taken apart are not eligible for earnings.)
          </Typography>

          <Box mt={3} sx={{ display: "flex", gap: 1, alignItems: "center", justifyContent: "center" }}>
            <Button
              variant="outlined"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={handleClick}>
              yes, mark this asset as can’t be released
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
