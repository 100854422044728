import { useEffect } from "react";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import { Attachment, CloseOutlined } from "@mui/icons-material";
import FileDropSection from "components/FileDropSection";
import theme from "lib/theme";
import useDirectUpload from "hooks/useDirectUpload";
import React from "react";
import { AssetRetrievalReminderConfig } from "types/assetRetrieverReminderConfig";

interface UploadClientLogoProps {
  fileUploaded: (key: string) => void;
  assetRetrievalReminderConfig?: AssetRetrievalReminderConfig;
  deleteAssetReminderConfiguration: () => void;
}

export default function UploadClientLogo({
  fileUploaded,
  assetRetrievalReminderConfig,
  deleteAssetReminderConfiguration,
}: UploadClientLogoProps) {
  const { blob, upload, loading, reset } = useDirectUpload({ keyPrefix: "/clients/logos/" });

  useEffect(() => {
    if (blob) fileUploaded(blob.signed_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blob]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            border: "1px solid",
            borderColor: theme.palette.divider,
            background: "white",
            display: "flex",
            minHeight: "195px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {assetRetrievalReminderConfig?.companyLogoUrl || blob ? (
            <Tooltip
              title={
                <>
                  <img src={assetRetrievalReminderConfig?.companyLogoUrl} alt="" loading="lazy" width={280} />
                </>
              }
            >
              <Box
                component="a"
                href={assetRetrievalReminderConfig?.companyLogoUrl}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  border: `1px solid ${theme.palette.primary.main}`,
                  borderRadius: "4px",
                  p: 1,
                  color: theme.palette.primary.main,
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  fontFamily: "Catamaran",
                  textDecoration: "none",
                  position: "relative",
                  width: "50%",
                }}
              >
                <Attachment />
                {assetRetrievalReminderConfig?.companyLogoFilename || blob?.filename}
                <Box
                  component="button"
                  sx={{
                    height: "24px",
                    width: "24px",
                    background: theme.palette.primary.main,
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: 0,
                    borderRadius: "100%",
                    position: "absolute",
                    top: "-4px",
                    right: "-8px",
                    cursor: "pointer",
                  }}
                  onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    e.preventDefault();
                    reset();
                    deleteAssetReminderConfiguration();
                  }}
                >
                  <CloseOutlined />
                </Box>
              </Box>
            </Tooltip>
          ) : (
            <FileDropSection
              acceptHint={"jpg, jpeg, png"}
              accept={{
                "image/png": ["png"],
                "image/jpeg": ["jpg", "jpeg"],
              }}
              documents={undefined}
              onUpload={([file]) => {
                upload(file);
              }}
            />
          )}
        </>
      )}
    </>
  );
}
