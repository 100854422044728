import { useState } from "react";
import { Box, Button, FormControl, Typography } from "@mui/material";
import { InputBase, InputLabel } from "components/common/Input";
import { Formik } from "formik";
import useAuth from "hooks/useAuth";
import theme from "lib/theme";
import LoginCoverImg from "images/login-cover.png";
import * as yup from "yup";

export default function FindOrganization() {
  const [organizationNotFound, setOrganizationNotFound] = useState(false);
  const { findOrganization } = useAuth();

  return (
    <Box sx={{ paddingBottom: "100px" }}>
      <img
        src={LoginCoverImg}
        alt="login cover"
        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
      ></img>

      <Typography
        variant="h4"
        sx={{ textAlign: "center", mt: 6, fontWeight: "bold", color: theme.palette.text.primary }}
      >
        Login to Revivn
      </Typography>

      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          color: theme.palette.text.secondary,
          mt: 3,
        }}
      >
        Manage your hardware lifecycle and repurpose your unused tech for a greater cause all in one place{" "}
      </Typography>
      <Box sx={{ maxWidth: "660px", mx: "auto" }}>
        <Box mt={4}>
          <Formik
            validateOnMount
            initialValues={{ email: "" }}
            onSubmit={async (values) => {
              setOrganizationNotFound(false);
              try {
                await findOrganization(values.email);
              } catch {
                setOrganizationNotFound(true);
              }
            }}
            validationSchema={yup.object({
              email: yup.string().email("Must be a valid email").required("Required"),
            })}
          >
            {({ getFieldProps, errors, handleSubmit, isSubmitting, touched, isValid }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="column">
                  <FormControl variant="standard" sx={{ mt: 2 }}>
                    <InputLabel shrink htmlFor="password">
                      Email
                    </InputLabel>
                    <InputBase
                      type="email"
                      {...getFieldProps("email")}
                      id="email"
                      helperText={touched.email && errors.email}
                      error={touched.email && !!errors.email}
                    />
                  </FormControl>

                  <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    disabled={isSubmitting || !isValid}
                    sx={{ textTransform: "uppercase", mt: 4, alignSelf: "start" }}
                  >
                    Submit
                  </Button>

                  {organizationNotFound && (
                    <Typography paddingTop={2} color="red" textAlign="center">
                      Sorry, it looks like this email isn’t associated with any account in our platform. Please double
                      check your email is entered in correctly or contact support@revivn.com for any issues.
                    </Typography>
                  )}
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}
