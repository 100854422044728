import { Typography, Button, Box, Tooltip } from "@mui/material";
import theme from "lib/theme";
import { DrawerProps } from "types/pickup";
import { CalendarToday } from "@mui/icons-material";
import { useMemo, useState } from "react";
import {
  address,
  assets,
  CancelConfirmation,
  contact,
  orderNumber,
  PickupAgentDetails,
  PickupAssetsDetails,
  pickupDate,
  PickupDetailsRow,
  PickupDetailsRows,
  PickupDetailsTitle,
  shouldDisableEditPickup,
} from "./shared";
import { Link as RouterLink } from "react-router-dom";
import { navActions } from "components/AppLayout/Nav";
import useCurrentUserPermission from "hooks/useCurrentUserPermission";

export function ScheduledDrawer(props: DrawerProps) {
  const { pickup } = props;
  const { hasPermission } = useCurrentUserPermission();

  const [showCancel, setShowCancel] = useState(false);

  const disabled = useMemo(() => {
    return shouldDisableEditPickup(pickup) || !hasPermission("pickups");
  }, [pickup, hasPermission]);

  return (
    <Box mb={5} mx={2}>
      <Box
        sx={{
          py: 3,
          px: 4,
          background: "#FAFAFA",
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
        }}
      >
        <CalendarToday />
        <Typography variant="h6" fontWeight="bold">
          Your pickup is scheduled!
        </Typography>
        <Typography>
          Your pickup was successfully scheduled! Please ensure the pickup details are accurate prior to the date.
          You’ll be able to edit the details up until 72 hours prior to the pickup date.
        </Typography>

        <Box mt={2}>
          <PickupDetailsTitle />

          <PickupDetailsRows>
            <PickupDetailsRow
              columnCount={4}
              items={[
                {
                  title: "PICKUP ID",
                  list: orderNumber(pickup),
                },
                {
                  title: "PICKUP DATE",
                  list: pickupDate(pickup),
                },
                {
                  title: "CONTACT",
                  list: contact(pickup),
                },
                {
                  title: "ADDRESS",
                  list: address(pickup),
                },
              ]}
            />
            <PickupDetailsRow
              columnCount={4}
              items={[<PickupAssetsDetails items={assets(pickup)} />, <PickupAgentDetails pickup={pickup} />]}
            />
          </PickupDetailsRows>
        </Box>
      </Box>

      <Box
        mt={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          gap: 3,
        }}
      >
        <Tooltip
          title={
            disabled
              ? "We're excited to pickup your items soon! Please contact us if you need to update your pickup any further."
              : ""
          }
        >
          <span>
            <Button
              size="small"
              variant="contained"
              component={RouterLink}
              to={`/pickups/${pickup.uuid}`}
              disabled={disabled}
            >
              Edit pickup
            </Button>
          </span>
        </Tooltip>
        <Button
          size="small"
          variant="contained"
          onClick={() => setShowCancel(true)}
          disabled={shouldDisableEditPickup(pickup) || showCancel || !hasPermission("pickups")}
        >
          cancel pickup
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            navActions.openContactConcierge();
          }}
        >
          Need to reschedule? Contact us
        </Button>
      </Box>

      {showCancel && (
        <Box mt={3}>
          <CancelConfirmation close={() => setShowCancel(false)} pickup={pickup} />
        </Box>
      )}
    </Box>
  );
}
