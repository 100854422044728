import { useCallback, useMemo, useState } from "react";
import { Blob, DirectUpload, DirectUploadDelegate } from "activestorage";

type Props = {
  keyPrefix: string;
  service?: "local" | "amazon" | "amazon_public";
};

export default function useDirectUpload({ keyPrefix, service = "amazon" }: Props) {
  const default_service = process.env.REACT_APP_USE_PUBLIC_S3_SERVICE;
  const [blob, setBlob] = useState<Blob>();
  const [loading, setLoading] = useState(false);

  const delegate: DirectUploadDelegate = useMemo(
    () => ({
      directUploadWillCreateBlobWithXHR: (xhr: XMLHttpRequest) => {
        xhr.setRequestHeader("Key-Prefix", keyPrefix);
        xhr.setRequestHeader("Service", default_service || service);
      },
    }),
    [keyPrefix]
  );

  const reset = useCallback(() => {
    setBlob(undefined);
  }, [setBlob]);

  const upload = useCallback((file: File) => {
    setLoading(true);
    const directUpload = new DirectUpload(file, "/api/direct_uploads", delegate);
    directUpload.create((error: Error, blob: Blob) => {
      if (setBlob) {
        setBlob(blob);
        setLoading(false);
      }
    });
    return directUpload;
  }, []);

  const uploadAsync = useCallback((file: File) => {
    return new Promise<Blob>((resolve, reject) => {
      const directUpload = new DirectUpload(file, "/api/direct_uploads", delegate);
      directUpload.create((error: Error, blob: Blob) => {
        if (error) {
          reject(error);
        } else {
          setBlob(blob);
          resolve(blob);
        }
      });
    });
  }, []);
  return {
    blob,
    upload,
    reset,
    loading,
    uploadAsync,
  };
}
