import { SetupIntent } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import { api } from "services/api.service";
import { mutate } from "swr";

export const usePaymentMethod = () => {
  const navigate = useNavigate();

  const createPaymentMethod = (setupIntent: SetupIntent, callback: () => void) => {
    api.post("/payment_methods", { paymentMethod: setupIntent.payment_method }).then(() => {
      mutate("/payment_methods");
      callback();
    });
  };
  return {
    createPaymentMethod,
  };
};
