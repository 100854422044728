export const chartColors = [
  "#264653",
  "#287271",
  "#2a9d8f",
  "#e9c46a",
  "#efb366",
  "#f4a261",
  "#ee8959",
  "#e76f51",
  "#e97c61",
  "#eb886f",
  "#780000",
  "#9d0910",
  "#c1121f",
  "#df817a",
  "#fdf0d5",
  "#7f908f",
  "#003049",
  "#669bbc",
  "#74a4c2",
  "#ee6055",
  "#a79a75",
  "#60d394",
  "#85e58c",
  "#aaf683",
  "#d5e880",
  "#ffd97d",
  "#ffba81",
  "#ff9b85",
  "#ffa490",
];
