import { Box, Button, Link, Typography } from "@mui/material";
import theme from "lib/theme";
import UploadAssets from "./UploadAssets";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAssetUpload from "hooks/useAssetUpload";

function ConnectAssets() {
  const navigate = useNavigate();
  const [connected, setIsConnected] = useState(false);
  const { key, setKey, create, submitting } = useAssetUpload();

  if (connected) {
    return (
      <div>
        <Box mt={6}>
          <Typography variant="h4" fontWeight="bold">
            Asset data was added
          </Typography>
          <Typography variant="body1" mt={1}>
            Check out a summary of your assets on the home dashboard or navigate to the assets tab to view more details.
          </Typography>
        </Box>
      </div>
    );
  }

  return (
    <div>
      <Box mt={6}>
        <Typography variant="h4" fontWeight="bold">
          Add assets
        </Typography>
        <Typography variant="body1" mt={1}>
          Connect your retired and/or live asset data following the template linked here. Or connect your live asset
          data using one of the following integrations.
        </Typography>
      </Box>

      <Box sx={{ p: 4, background: "white", borderRadius: 1, border: `1px solid ${theme.palette.divider}` }}>
        <Box>
          <Box
            sx={{
              color: "text.secondary",
              px: "10px",
              py: "4px",
              background: "#AAE8FF",
              borderRadius: "4px",
              display: "inline-flex",
            }}
          >
            OPTION 1
          </Box>

          <Box mt={2}>
            <Typography variant="h6" fontWeight="600">
              Option 1: Connect with one of the following softwares
            </Typography>
            <Typography mt={0.5} color="text.secondary">
              If you have an account with one of the following softwares, sign in and connect
            </Typography>

            <Box mt={3} sx={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(220px, 1fr))", gap: 2 }}>
              {[
                require(`./images/logo1.png`),
                require(`./images/logo2.png`),
                require(`./images/logo3.png`),
                require(`./images/logo4.png`),
                require(`./images/logo5.png`),
              ].map((image) => {
                return (
                  <Box
                    component="button"
                    onClick={() => {
                      setIsConnected(true);
                    }}
                    sx={[
                      {
                        cursor: "pointer",
                        height: "74px",
                        background: "white",
                        borderRadius: 1,
                        border: `1px solid ${theme.palette.divider}`,
                        display: "grid",
                        placeItems: "center",
                        ":hover": {
                          border: `1px solid ${theme.palette.primary.main}`,
                        },
                      },
                    ]}
                  >
                    <img alt="" src={image}></img>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>

        <Box mt={5}>
          <Box
            sx={{
              color: "text.secondary",
              px: "10px",
              py: "4px",
              background: "#AAE8FF",
              borderRadius: "4px",
              display: "inline-flex",
            }}
          >
            OPTION 2
          </Box>

          <Box mt={2}>
            <Typography variant="h6" fontWeight="600">
              Option 2: Upload spreadsheet of assets
            </Typography>
            <Typography mt={0.5} color="text.secondary">
              Manually upload your asset data
            </Typography>
          </Box>

          <Box mt={3}>
            <UploadAssets fileUploaded={setKey} />
          </Box>
        </Box>
      </Box>

      <Box mt={4} sx={{ display: "flex", flexDirection: "column", alignItems: "start", gap: 2 }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="outlined"
            onClick={() => {
              navigate("/user-setup/add-locations");
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            disabled={(!key && !connected) || submitting}
            onClick={() => {
              create();
              // navigate("/user-setup/connect-assets");
            }}
          >
            Next
          </Button>
        </Box>
        <Link
          component="button"
          underline="hover"
          variant="button"
          onClick={() => {
            // navigate("/user-setup/connect-assets");
          }}
        >
          Skip
        </Link>
      </Box>
    </div>
  );
}

export default ConnectAssets;
