import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { api } from "services/api.service";
import { NewUser, User } from "types/user";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAlert } from "hooks/alerts/useAlert";
import useOnboarding from "hooks/companySettings/useOnboarding";

export default function useUsers() {
  const navigate = useNavigate();
  const params = useParams();
  const { alertSuccess, alertError } = useAlert();
  const { fetchOnboarding } = useOnboarding();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [searchParams] = useSearchParams();
  const [users, setUsers] = useState<User[]>([]);
  const [user, setUser] = useState<User>({
    firstName: "",
    lastName: "",
    email: "",
    uuid: "",
    phoneNumber: "",
    roles: [{ uuid: "", name: "" }],
  });
  const [meta, setMeta] = useState<{ totalPages: number; count: number }>({ totalPages: 0, count: 0 });

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    if (params.uuid) {
      api
        .get(`/users/${params.uuid}`)
        .then((res) => {
          setUser(res.data.user);
        })
        .catch(() => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      api
        .get<{ users: User[]; meta: { totalPages: number; count: number } }>(`/users?${searchParams.toString()}`)
        .then((res) => {
          setUsers(res.data.users);
          setMeta(res.data.meta);
        })
        .catch(() => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [params, searchParams]);

  interface UserProps {
    email: string;
    firstName: string;
    lastName: string;
    roles?: string;
  }

  const createUser = useCallback((user: UserProps) => {
    const args = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      roles: [user?.roles],
    };
    api
      .post("/users", { user: args })
      .then(() => {
        alertSuccess("User Successfully Invited");
        navigate("/company-settings/users");
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          var errorMessage = "";

          for (const [key, value] of Object.entries(errors)) {
            errorMessage = errorMessage.concat(`${key}: ${value}  `);
          }

          alertError(`There was an error: ${errorMessage}`);
        }
      )
      .finally(() => {
        fetchOnboarding && fetchOnboarding();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUser = useCallback((user: UserProps) => {
    const args = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      roles: [user?.roles],
    };
    api
      .put(`/users/${params.uuid}`, { user: args })
      .then(() => {
        alertSuccess("User Successfully Updated");
        navigate("/company-settings/users");
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          var errorMessage = "";

          for (const [key, value] of Object.entries(errors)) {
            errorMessage = errorMessage.concat(`${key}: ${value}  `);
          }

          alertError(`There was an error: ${errorMessage}`);
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteUser = (uuid: string) => {
    api
      .delete(`/users/${uuid}`)
      .then(() => {
        navigate("/company-settings/users");
      })
      .finally(() => {
        fetchOnboarding && fetchOnboarding();
      });
  };

  return {
    isLoading,
    isError,
    users,
    meta,
    createUser,
    updateUser,
    user,
    deleteUser,
  };
}
