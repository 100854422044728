import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { api } from "services/api.service";
import { useAlert } from "hooks/alerts/useAlert";

export default function useAssetRetrievalExport() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const { alertError } = useAlert();

  const exportAssetRetrievals = () => {
    setIsLoading(true);
    const args = searchParams.toString();
    api
      .get(`/asset_retrieval_exports.csv?${args.toString()}`)
      .then(({ data }) => {
        const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          const date = new Date();
          const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
          link.setAttribute("href", url);
          link.setAttribute("download", `assets_retrievals(${formattedDate}).csv`);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch(() => {
        alertError("Failed to export asset retrievals, please try again.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    exportAssetRetrievals,
  };
}
