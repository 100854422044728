import { Box, Button, debounce, FormControl, IconButton, MenuItem, TablePagination, Typography } from "@mui/material";
import theme from "lib/theme";
import React, { useCallback, useEffect, useState } from "react";
import { AppSelect, InputBase } from "components/common/Input";
import { ReactComponent as PdfIcon } from "./imgs/pdf.svg";
import { ReactComponent as FolderIcon } from "./imgs/folder.svg";
import useDocuments from "hooks/documents/useDocuments";
import { formatDate } from "utils/helpers";
import useSearchParamsOperations from "hooks/useSearchParamsOperations";
import usePickups from "hooks/pickups/usePickups";
import { useSearchParams } from "react-router-dom";
import { Download } from "@mui/icons-material";
import useDonations from "hooks/donations/useDonations";
import PlanFeatureWrapper from "components/PlanFeatureWrapper";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { Document } from "types/document";
import { MenuProps } from "constants/filterMenuProps";
import IndividualCodBanner from "./IndividualCodBanner";

function Documents() {
  const { documents, meta, downloadDocument } = useDocuments();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const { replace, replaceInArrayFieldUsingPrefix, deleteFieldInArrayUsingPrefix } = useSearchParamsOperations();

  const [searchParams] = useSearchParams();
  const { pickups, errorMessage, isLoading, incrementCurrentPage, hasNextPage } = usePickups(true);

  const { donations } = useDonations();

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasNextPage,
    onLoadMore: incrementCurrentPage,
    disabled: !!errorMessage,
    rootMargin: "0px 0px 400px 0px",
  });

  const [pickupIds, setPickupIds] = useState(
    searchParams
      .getAll("filter[]")
      .find((p) => p.includes("pickup_id,is_any_of"))
      ?.split("pickup_id,is_any_of,")[1]
      ?.split(",") || []
  );
  const [donationIds, setDonationIds] = useState(
    searchParams
      .getAll("filter[]")
      .find((d) => d.includes("donation_id,is_any_of"))
      ?.split("donation_id,is_any_of,")[1]
      ?.split(",") || []
  );

  const handleSearchChange = useCallback(
    debounce((replaceParamsFn: (f: string, v: string) => void, search: string) => {
      replaceParamsFn("q", search);
    }, 200),
    []
  );

  useEffect(() => {
    replace("page[number]", (pageNumber + 1).toString());
  }, [pageNumber, replace]);

  useEffect(() => {
    replace("page[size]", pageSize.toString());
  }, [pageSize, replace]);

  useEffect(() => {
    if (pickupIds && pickupIds.length > 0) {
      replaceInArrayFieldUsingPrefix("filter[]", "pickup_id,is_any_of", `pickup_id,is_any_of,${pickupIds.join(",")}`);
    } else {
      deleteFieldInArrayUsingPrefix("filter[]", "pickup_id,is");
    }
    if (donationIds && donationIds.length > 0) {
      replaceInArrayFieldUsingPrefix(
        "filter[]",
        "donation_id,is_any_of",
        `donation_id,is_any_of,${donationIds.join(",")}`
      );
    } else {
      deleteFieldInArrayUsingPrefix("filter[]", "donation_id,is");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupIds, donationIds]);

  const cleanFilters = useCallback(() => {
    setPickupIds([]);
    setDonationIds([]);
  }, []);

  return (
    <div>
      <Typography variant="h4" fontWeight="bold">
        Documents
      </Typography>
      <PlanFeatureWrapper
        feature="individual_cod"
        enabledElement={<></>}
        disabledElement={<IndividualCodBanner />}
        grandfatheredElement={<></>}
      />
      <Box
        sx={{
          mt: 3,
          p: 4,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography variant="h6" fontWeight={600}>
          Search documents by pickup
        </Typography>
        <Typography>
          Quickly access all the files related to a pickup date or location when you use the filter bar
        </Typography>

        <Box mt={3}>
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr auto", maxWidth: "550px", width: "100%" }}>
            <InputBase
              placeholder="Search"
              sx={{ ".MuiInputBase-input": { borderTopRightRadius: 0, borderBottomRightRadius: 0 } }}
              onChange={(e) => handleSearchChange(replace, e.target.value)}
            ></InputBase>
          </Box>
        </Box>

        <Box mt={4}>
          <Box
            display="grid"
            gridTemplateColumns="auto 1fr 1fr 1fr 1fr 1fr auto"
            columnGap="1em"
            marginY={2}
            alignItems="center"
          >
            <Typography width="100%">Quick Filters:</Typography>
            <FormControl variant="standard" size="small" sx={{ width: "100%" }}>
              <AppSelect
                multiple
                MenuProps={MenuProps}
                value={pickupIds}
                onChange={(e) => setPickupIds(e.target.value as string[])}
                renderValue={(values) =>
                  values.length === 0
                    ? "Pickup Date"
                    : values.length === 1
                    ? formatDate(pickups.find(({ id }) => id.toString() === values[0].toString())?.scheduledAt || "")
                    : `${values.length} pickups selected`
                }
                displayEmpty
              >
                {pickups
                  .filter(({ scheduledAt }) => scheduledAt)
                  .map((pickup) => (
                    <MenuItem key={pickup.id} value={pickup.id} ref={sentryRef}>
                      {formatDate(pickup.scheduledAt)} - {pickup.orderNumber}
                    </MenuItem>
                  ))}
              </AppSelect>
            </FormControl>
            <FormControl variant="standard" size="small" sx={{ width: "100%" }}>
              <AppSelect
                multiple
                value={donationIds}
                onChange={(e) => setDonationIds(e.target.value as string[])}
                renderValue={(values) =>
                  values.length === 0
                    ? "Donation Date"
                    : values.length === 1
                    ? formatDate(donations.find(({ id }) => id.toString() === values[0].toString())?.createdAt || "")
                    : `${values.length} donations selected`
                }
                displayEmpty
              >
                {donations
                  .filter(({ createdAt }) => createdAt)
                  .map((donation) => (
                    <MenuItem key={donation.id} value={donation.id}>
                      {formatDate(donation.createdAt)} - {donation.donationRequest.benefitingOrganization.name}
                    </MenuItem>
                  ))}
              </AppSelect>
            </FormControl>
            <Button variant={"outlined"} onClick={cleanFilters}>
              Clear All
            </Button>
          </Box>

          <Box mt={4} sx={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(260px, 1fr))", gap: 5 }}>
            {documents && documents.length === 0 && <Typography textAlign="center">No Documents found!</Typography>}
            {documents &&
              documents.map((document: Document) => {
                return (
                  <FileCard
                    key={document.uuid}
                    downloadDocument={downloadDocument}
                    uuid={document.uuid}
                    name={document.name}
                    type={document.type}
                    fileType={document.fileType}
                    orderNumber={document.pickup && document.pickup.orderNumber}
                    icon={document.fileType === "zip" ? <FolderIcon /> : <PdfIcon />}
                  />
                );
              })}
          </Box>
        </Box>
      </Box>

      <Box mt={8} sx={{ display: "flex", justifyContent: "end" }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={meta.count || 1}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={(_, page) => {
            setPageNumber(page);
          }}
          onRowsPerPageChange={(e) => {
            setPageSize(parseInt(e.target.value));
          }}
        />
      </Box>
    </div>
  );
}

interface FileCardProps {
  name: string;
  orderNumber: number;
  uuid: string;
  icon: React.ReactNode;
  type: string;
  downloadDocument: (uuid: string, name: string, type: string, fileType: string) => void;
  fileType: string;
}

function FileCard({ uuid, name, orderNumber, icon, type, downloadDocument, fileType }: FileCardProps) {
  return (
    <Box
      sx={{
        background: "white",
        borderRadius: 1,
        border: `1px solid ${theme.palette.divider}`,
        p: 2,

        display: "flex",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Box sx={{ width: "64px", height: "64px", "> *": { width: "100%", height: "100%" } }}>{icon}</Box>
      <Box display="flex" justifyContent="space-between" flex={1}>
        <Box>
          <Typography variant="body2" fontWeight={600}>
            {name}
          </Typography>
          <Typography variant="body2" fontWeight={600}>
            {orderNumber}
          </Typography>
        </Box>
        <Box>
          {" "}
          <IconButton
            onClick={() => {
              downloadDocument(uuid, name, type, fileType);
            }}
          >
            <Download color="primary" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default Documents;
