import useUpdateLocation from "hooks/locations/useUpdateLocation";
import useLocation from "hooks/locations/useLocation";
import { useParams } from "react-router-dom";
import LocationForm from "./Form";
import { useLocation as useRouterLocation } from "react-router-dom";

export default function EditLocation() {
  const { uuid } = useParams();
  const { location, isLoading } = useLocation({ uuid });

  let { state } = useRouterLocation();
  let originalPath = (state as any)?.originalPath || `/company-settings/locations`;

  const { updateLocation } = useUpdateLocation(originalPath);

  return (
    <>
      {!isLoading && location && (
        <LocationForm location={location} onSubmit={updateLocation} originalPath={originalPath} />
      )}
    </>
  );
}
