import { Box, Step, StepLabel, Stepper } from "@mui/material";

interface UserSetupStepsProps {
  activeStep?: number;
  steps: { label: string; onClick?(): void }[];
}

function UserSetupSteps({ activeStep, steps = [] }: UserSetupStepsProps) {
  return (
    <Box sx={{ display: "grid", placeItems: "center" }}>
      <Stepper
        activeStep={activeStep}
        sx={{
          width: "100%",
          maxWidth: "900px",
          ".MuiStepLabel-root": { flexDirection: "column", gap: 2 },
        }}
      >
        {steps.map((step) => {
          return (
            <Step
              key={step.label}
              onClick={step.onClick}
              sx={[
                { textAlign: "center" },
                step.onClick ? { ".MuiStepLabel-root": { cursor: "pointer !important" } } : null,
              ]}
            >
              <StepLabel>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

export default UserSetupSteps;
