import { useAlert } from "hooks/alerts/useAlert";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { api } from "services/api.service";
import { AssetRetrieval, AssetRetrievalBulkOrderDetails } from "types/assetRetrieval";

export default function useAssetRetrievals() {
  const [assetRetrievals, setAssetRetrievals] = useState<AssetRetrieval[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const { alertError, alertSuccess } = useAlert();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);

  const getAssetRetrievals = useCallback(() => {
    api.get(`/asset_retrievals?${searchParams.toString()}`).then(({ data }) => {
      setAssetRetrievals(data.assetRetrievals);
      setLoading(false);
      setTotalPages(data.meta.totalPages);
      setCount(data.meta.count);
    });
  }, [searchParams]);

  useEffect(() => {
    getAssetRetrievals();
  }, [getAssetRetrievals, searchParams]);

  const toggleRemindersMute = useCallback(
    (asset_retrieval_uuid: any) => {
      return api
        .put(`asset_retrievals/${asset_retrieval_uuid}/mute_reminders`)
        .then(() => {
          alertSuccess("Successfully saved your preference.");
          getAssetRetrievals();
        })
        .catch(() => {
          alertError("Something went wrong, please try again.");
        });
    },
    [alertError, alertSuccess, getAssetRetrievals]
  );

  const createAssetRetrievals = useCallback((bulkOrderDetails: AssetRetrievalBulkOrderDetails) => {
    return api
      .post("/asset_retrievals", { bulkOrderDetails })
      .then(() => {
        alertSuccess("Successfully created asset retrieval.");
        getAssetRetrievals();
      })
      .catch(() => {
        alertError("Something went wrong, please try again.");
      });
  }, []);

  return {
    assetRetrievals,
    loading,
    toggleRemindersMute,
    createAssetRetrievals,
    totalPages,
    count,
    searchParams,
    setSearchParams,
  };
}
