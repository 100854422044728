import { useState } from "react";
import { api } from "services/api.service";

export default function usePickupReports() {
  const [initialReportData, setInitialReportData] = useState<any>("");
  const [enrolledData, setEnrolledData] = useState<any>("");
  const [, setLoadingEnrolledData] = useState<Boolean>(false);
  const [, setLoadingInitialReportData] = useState<Boolean>(false);
  const [downloadInitialReport, setDownloadInitialReport] = useState<Boolean>(false);
  const [downloadEnrolled, setDownloadEnrolled] = useState<Boolean>(false);

  const getInitialReportData = (pickupUuid: String) => {
    setLoadingInitialReportData(true);
    api
      .get(`/pickup_reports_initial_report.csv?pickup_uuid=${pickupUuid}`)
      .then(({ data }) => {
        setInitialReportData(data);
      })
      .then(() => {
        setLoadingInitialReportData(false);
        setDownloadInitialReport(true);
      });
  };

  const getEnrolledData = (pickupUuid: String) => {
    setLoadingEnrolledData(true);
    api
      .get(`/pickup_reports_enrolled.csv?pickup_uuid=${pickupUuid}`)
      .then(({ data }) => {
        setEnrolledData(data);
      })
      .then(() => {
        setLoadingEnrolledData(false);
        setDownloadEnrolled(true);
      });
  };

  return {
    getInitialReportData,
    downloadInitialReport,
    setDownloadInitialReport,
    initialReportData,
    getEnrolledData,
    downloadEnrolled,
    setDownloadEnrolled,
    enrolledData,
  };
}
