import { useEffect, useState } from "react";
import { api } from "services/api.service";

export default function useReports() {
  const [isLoading, setIsLoading] = useState(false);
  const [reports, setReports] = useState<any>();

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      api.get(`/reports`).then((res) => {
        setReports(res.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    reports,
  };
}
