import { ArrowForward } from "@mui/icons-material";
import { Box, Link, Typography } from "@mui/material";
import { useState } from "react";
import PickupEstimatedItems from "./PickupEstimatedItems";
import PickupImages from "./PickupImages";
import theme from "lib/theme";
import { useAlert } from "hooks/alerts/useAlert";

interface PickupReasonProps {
  goToNextStep?: () => void;
}

export default function PickupItemsDetails({ goToNextStep }: PickupReasonProps) {
  const [imagesCompleted, setImagesCompleted] = useState(false);
  const [itemsCompleted, setItemsCompleted] = useState(false);
  const { alertError } = useAlert();

  return (
    <div>
      <Typography variant="h6" fontSize="18px" fontWeight="bold">
        Pickup Details *
      </Typography>
      <Typography mt={0.5} variant="body2">
        Fill out either (or both) of the sections below to the best of your ability to ensure we allocate the right
        resources to your pickup!
      </Typography>
      <Box
        sx={{
          p: 4,
          my: "16px",
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
          position: "relative",
          overflow: "hidden",
          [theme.breakpoints.down("md")]: {
            p: 2,
          },
        }}
      >
        <PickupEstimatedItems setItemsCompleted={setItemsCompleted} />
      </Box>
      <Box
        sx={{
          p: 4,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
          position: "relative",
          overflow: "hidden",
          [theme.breakpoints.down("md")]: {
            p: 2,
          },
        }}
      >
        <PickupImages setImagesCompleted={setImagesCompleted} />
      </Box>

      <Link
        component="button"
        sx={{
          paddingTop: "20px",
          fontWeight: 600,
          textDecoration: "none",
          fontSize: "16px",
          fontFamily: "Catamaran",
          display: "flex",
          alignItems: "center",
          gap: 1,
          ":hover": {
            textDecoration: "underline",
          },
        }}
        onClick={() => {
          if (imagesCompleted || itemsCompleted) {
            goToNextStep?.();
          } else {
            alertError(
              "We need more details on your pickup request in order to review it and allocate enough resources! Please fill out the pickup details section to the best of your ability."
            );
          }
        }}
      >
        That’s everything for now, let’s move on <ArrowForward />
      </Link>
    </div>
  );
}
