import {
  Box,
  Alert,
  AlertTitle,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import usePickups from "hooks/pickups/usePickups";
import theme from "lib/theme";
import PickUpRow from "./PickupRow";

export default function QuickStatsPickUpTable() {
  let { pickups, errorMessage, isLoading } = usePickups();

  // TODO: limit from backend
  if (pickups) {
    pickups = pickups.slice(0, 4);
  }

  if (errorMessage) {
    return (
      <Box
        sx={{
          background: "white",
          minHeight: "230px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Something went wrong
        </Alert>
      </Box>
    );
  }

  if (isLoading || !pickups) {
    return (
      <Box
        sx={{
          background: "white",
          minHeight: "230px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <TableContainer
      sx={{
        minHeight: "230px",
        ".MuiTableCell-root:first-of-type": { paddingLeft: 0 },
        ".MuiTableCell-root:last-child": { paddingRight: 0 },
      }}
      component={Box}
    >
      <Table
        sx={{ minWidth: "500px", ".MuiTableRow-root:last-child td, .MuiTableRow-root:last-child th": { border: 0 } }}
        size="small"
      >
        <TableHead sx={{ ".MuiTableCell-root": { color: theme.palette.text.disabled, borderBottom: 0 } }}>
          <TableRow>
            <TableCell>PICKUP</TableCell>
            <TableCell>ADDRESS</TableCell>
            <TableCell>STATUS</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pickups?.map((pickup) => (
            <PickUpRow key={pickup.id} pickup={pickup} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
