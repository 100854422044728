import { Box, CircularProgress } from "@mui/material";
import theme from "lib/theme";

export default function Loading() {
  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: theme.palette.divider,
        background: "white",
        minHeight: "500px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
