import { Box, Typography, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import usePickup from "hooks/pickups/usePickup";
import FileDropSection from "components/FileDropSection";
import { AddAPhoto } from "@mui/icons-material";
import useDirectUpload from "hooks/useDirectUpload";
import theme from "lib/theme";

interface PickupImagesProps {
  setImagesCompleted: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PickupImages({ setImagesCompleted }: PickupImagesProps) {
  const { pickup, createImage, deleteImage } = usePickup();
  const { upload, blob, loading } = useDirectUpload({ keyPrefix: "/pickup/pickup_images/", service: "amazon_public" });

  useEffect(() => {
    blob && createImage(blob?.signed_id);
  }, [blob]);

  useEffect(() => {
    if ((pickup && pickup.pickupImages.length > 0) || blob) {
      setImagesCompleted(true);
    } else {
      setImagesCompleted(false);
    }
  }, [createImage, pickup, deleteImage]);

  return (
    <Box>
      <Typography variant="h6" fontSize="18px" fontWeight="bold">
        4. Upload pictures, or attach a file of the items you will be repurposing (the more information the better)
      </Typography>
      <Typography mt={0.5} variant="body2">
        Pictures and item information help us prepare the crew with the right staff and trucks for seamless processing
      </Typography>

      <Box mt={3}>
        {loading ? (
          <Box
            sx={{
              border: "1px solid",
              borderColor: theme.palette.divider,
              background: "white",
              display: "flex",
              minHeight: "195px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <FileDropSection
            icon={<AddAPhoto color="primary" sx={{ fontSize: "32px" }} />}
            acceptHint={"jpg, jpeg, png, csv, xls, xlsx"}
            accept={{
              "image/png": [".png", ".jpeg", ".jpg"],
              "image/jpeg": [".png", ".jpeg", ".jpg"],
              "files/csv": [".csv", ".xls", ".xlsx"],
            }}
            documents={
              pickup && pickup.pickupImages.length > 0
                ? pickup.pickupImages.map((image) => ({
                    name: image.filename,
                    url: image.url,
                    onDelete() {
                      deleteImage(image.id);
                    },
                  }))
                : undefined
            }
            onUpload={async ([file]) => {
              upload(file);
            }}
            loading={loading}
          />
        )}
      </Box>
    </Box>
  );
}
