import { useEffect, useState } from "react";
import { api } from "services/api.service";

interface HomeInfoProps {
  enrolledAssetCount: number;
}

export default function useHome() {
  const [isLoading, setIsLoading] = useState(false);
  const [homeInfo, setHomeInfo] = useState<HomeInfoProps>();

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      api.get(`/home`).then((res) => {
        setHomeInfo(res.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    homeInfo,
  };
}
