import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { api } from "services/api.service";
import TokenService from "services/token.service";
import useAnalytics from "./analytics/useAnalytics";
import { useAlert } from "./alerts/useAlert";
import Cookies from "js-cookie";

type AuthContextValue = {
  loggedIn: Boolean;
  setLoggedIn: (loggedIn: Boolean) => void;
};

export const AuthContext = createContext<AuthContextValue>({
  loggedIn: false,
  setLoggedIn: () => {},
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [loggedIn, setLoggedIn] = useState<Boolean>(Boolean(TokenService.get()));
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { createAnalyticsEvent } = useAnalytics();

  const value = useMemo(
    () => ({
      loggedIn,
      setLoggedIn,
    }),
    [loggedIn]
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    if (queryParams.has("email")) {
      const email = queryParams.get("email");
      queryParams.delete("email");

      api.get("/organizations/find", { params: { email: email } }).then(({ data: { organizationUrl } }) => {
        const newUrl = `${organizationUrl}${pathname}${queryParams.toString()}`;
        window.location.href = newUrl;
      });
    }
    if (queryParams.has("support_token")) {
      const params = Object.fromEntries(queryParams.entries());

      api
        .post("/activate_support", { ...params })
        .then(({ data: { jwt } }) => {
          TokenService.set(jwt);
          setLoggedIn(true);
          createAnalyticsEvent("support_log_in");
        })
        .catch((response) => {
          TokenService.remove();
          setLoggedIn(false);
        });
    }

    if (!pathname.includes("accept-invitation")) {
      if (search.includes("jwt")) {
        TokenService.set(search.replace("?jwt=", ""));
        setLoggedIn(true);
        createAnalyticsEvent("sso_logged_in");
      }

      if (loggedIn && pathname.includes("login")) {
        const redirectPath = Cookies.get("location");
        Cookies.remove("location");
        navigate(redirectPath ?? "/");
      } else if (
        !loggedIn &&
        !pathname.includes("reset-password") &&
        !pathname.includes("create-reset-password") &&
        !pathname.includes("find-organization") &&
        !pathname.includes("no-backend-connection")
      ) {
        if (!pathname.includes("login")) {
          const pathAndSearch = `${pathname}${queryParams.toString()}`;
          Cookies.set("location", pathAndSearch);
        }
        navigate("/login");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default function useAuth() {
  const { loggedIn, setLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const { alertInfo } = useAlert();
  const { search } = useLocation();
  const [searchParams] = useSearchParams(search);
  const { createAnalyticsEvent } = useAnalytics();

  const login = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      const {
        data: {
          session: { jwt: token },
        },
      } = await api.post("/sessions", { email, password });
      TokenService.set(token);
      setLoggedIn(true);
      createAnalyticsEvent("logged_in");
    },
    [setLoggedIn]
  );

  const createResetPassword = useCallback(async ({ email }: { email: string }) => {
    await api.post("/reset_passwords", { resetPassword: { email } });
  }, []);

  const resetPassword = useCallback(
    async ({ passwordConfirmation, password }: { passwordConfirmation: string; password: string }) => {
      await api
        .put(`/reset_passwords/${searchParams.get("reset_password_token")}`, {
          resetPassword: { passwordConfirmation, password },
        })
        .then(() => {
          alertInfo("You've successfully reset your password!", {
            vertical: "bottom",
            horizontal: "right",
          });
          setTimeout(() => {
            navigate("/login");
          }, 1000);
        });
    },
    []
  );

  const findOrganization = useCallback(
    (email: string) =>
      api.get("/organizations/find", { params: { email } }).then(({ data: { organizationUrl } }) => {
        window.location.href = organizationUrl;
      }),
    []
  );

  return {
    login,
    loggedIn,
    createResetPassword,
    resetPassword,
    findOrganization,
  };
}
