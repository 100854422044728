export const DTRFeature = {
  title: "Simplify remote retrievals",
  header: "",
  features: [
    { title: "Place bulk orders", description: ["Save time and place multiple retrieval orders in bulk."] },
    {
      title: "Return direct to Revivn",
      description: ["Know an asset is end of life? Send it back direct to\nRevivn and we’ll waive the cost!"],
    },
  ],
  buttonText: "Try it out",
  buttonLink: "/retrievals/send-boxes?page[number]=1&page[size]=10",
  cookieName: "direct to revivn feature dismissed",
  salesRequest: "direct_to_revivn",
  featureFlagName: "direct_to_revivn_launch",
};

export const newBoomerangFeature = {
  title: "Remote Retrievals Made Easy",
  header: "",
  features: [
    {
      title: "Effortlessly and securely retrieve remote assets - place an order and we’ll handle the rest.",
      description: [
        "Real-time tracking for every order",
        "Streamline retrievals with bulk orders",
        "Send assets to any company location or send directly to Revivn HQ and we'll waive the fees",
      ],
    },
  ],
  buttonText: "Place an Order",
  buttonLink: "/retrievals/send-boxes?page[number]=1&page[size]=10",
  cookieName: "new boomerang feature dismissed",
  salesRequest: "boomerang new",
  featureFlagName: "",
};
