import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { InputBase, InputLabel, RequiredSymbol } from "components/common/Input";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import theme from "lib/theme";
import { useEffect, useMemo, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useLocations from "hooks/locations/useLocations";
import { formatLocations } from "utils/helpers";
import { useFormikContext } from "formik";
import useCurrentUser from "hooks/users/useCurrentUser";
import { AssetRetrievalBulkOrderDetails } from "types/assetRetrieval";
import useFeatureFlag from "../../../hooks/useFeatureFlag";
import ReturnAddressForm from "./ReturnAddressForm";
import { AssetRetrieval } from "hooks/assetRetrievals/useAssetRetrieval";
import { set } from "date-fns";

interface ReturnDetailsProps {
  setCustomReturnLabel: (value: boolean) => void;
  customReturnLabel: boolean;
}
interface AutocompleteOption {
  label: string;
  id: string | number | undefined;
}

export default function ReturnAddressRadioGroup({ setCustomReturnLabel, customReturnLabel }: ReturnDetailsProps) {
  const [currentLocation, setCurrentLocation] = useState<AutocompleteOption>({ id: "", label: "" });
  const [locationOptions, setLocationOptions] = useState<AutocompleteOption[]>([{ id: "", label: "" }]);
  const [formLocation, setFormLocation] = useState<any>({});
  const { enabled: enabledMarketingLaunch } = useFeatureFlag("asset_retrievals_marketing_launch");
  const { locations } = useLocations();
  const { currentUser } = useCurrentUser();
  const { getFieldProps, values, errors, touched, setValues, setTouched, setFieldValue } = useFormikContext<
    AssetRetrievalBulkOrderDetails | AssetRetrieval
  >();

  useMemo(() => {
    const options = formatLocations(locations, true);
    setLocationOptions(options);
  }, [locations]);

  const [selectedReturnMethod, setSelectedReturnMethod] = useState("location");

  useMemo(() => {
    const newLocation = locationOptions?.find(({ id }: any) => id === values.locationId);
    setCurrentLocation(newLocation || { id: "", label: "" });
  }, [values.locationId, locations]);

  useEffect(() => {
    const location = locations.filter((loc) => loc.id === currentLocation.id)[0];
    setFormLocation(location);

    if (location && location.locationContacts) {
      const lastContact =
        location.locationContacts.length > 0
          ? location.locationContacts[location.locationContacts.length - 1]
          : undefined;
      if (!lastContact?.firstName) {
        setFieldValue("returnRecipientFirstName", currentUser.firstName);
        setFieldValue("returnRecipientLastName", currentUser.lastName);
      } else {
        setFieldValue("returnRecipientFirstName", lastContact.firstName);
        setFieldValue("returnRecipientLastName", lastContact.lastName);
      }
    }
  }, [values.locationId, currentLocation, formLocation]);

  useEffect(() => {
    if (values.virtualPickup || values.locationId) {
      setValues((prevValues) => ({
        ...prevValues,
        returnRecipientName: "",
        returnRecipientFirstName: "",
        returnRecipientLastName: "",
        returnRecipientCompany: "",
        returnRecipientAddress: "",
        returnRecipientAddressTwo: "",
        returnRecipientAddressCity: "",
        returnRecipientAddressState: "",
        returnRecipientAddressZipCode: "",
        returnRecipientAddressCountry: "",
      }));

      setTouched({});
    }
  }, [values.virtualPickup, values.locationId]);

  const returnMethodValues = useMemo(
    () => ({
      location: {
        virtualPickup: false,
        iscCustomAddress: false,
      },
      virtualPickup: {
        returnRecipientFirstName: "",
        returnRecipientLastName: "",
        locationId: "",
        virtualPickup: true,
        iscCustomAddress: false,
      },
      customReturnLocation: {
        returnRecipientFirstName: "",
        returnRecipientLastName: "",
        virtualPickup: false,
        locationId: "",
        isCustomAddress: true,
      },
    }),
    [currentUser]
  );

  const onChangeReturnMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValues = returnMethodValues[event.target.value as keyof typeof returnMethodValues];
    if (newValues) {
      setValues({
        ...values,
        ...newValues,
      });
    }

    if (event.target.value === "customReturnLocation") {
      setCustomReturnLabel(true);
      setCurrentLocation({ id: "", label: "" });
    } else {
      setCustomReturnLabel(false);
    }
  };

  return (
    <>
      {enabledMarketingLaunch && (
        <>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="location"
              name="radio-buttons-group"
              sx={{ gap: 2, mt: 2 }}
              onChange={(event) => {
                setSelectedReturnMethod(event.target.value);
                onChangeReturnMethod(event);
              }}
            >
              <FormControlLabel
                value="location"
                control={<Radio sx={{ pt: 0 }} />}
                label={
                  <>
                    <Typography fontWeight={600} fontSize="16px">
                      Office Location
                    </Typography>
                    <Typography variant="body2">Send your retrieved laptop to one of your saved locations.</Typography>
                  </>
                }
              />
              {selectedReturnMethod === "location" && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1, pl: 4 }}>
                  <Autocomplete
                    forcePopupIcon
                    options={locationOptions}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option: any, value: any) => {
                      return option.id === value.id;
                    }}
                    onChange={(_event, newValue: any) => {
                      setCustomReturnLabel(false);
                      setValues({ ...values, locationId: newValue.id, isCustomAddress: false });
                    }}
                    renderInput={(params) => {
                      return (
                        <FormControl variant="standard" sx={{ width: "100%" }}>
                          <InputBase
                            ref={params.InputProps.ref}
                            inputProps={params.inputProps}
                            placeholder="Select Address"
                            error={touched.locationId && !!errors.locationId}
                            helperText={touched.locationId && errors.locationId}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" sx={{ mr: 1 }}>
                                  <ArrowDropDownIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      );
                    }}
                    value={locationOptions?.find(({ id }: any) => id === currentLocation.id)}
                  />
                  {formLocation && (
                    <Box mt={1}>
                      <Box sx={{ display: "grid", gridTemplateColumns: "2fr 2fr", gap: 2, mb: 1 }}>
                        <FormControl variant="standard">
                          <InputLabel shrink htmlFor="city">
                            First Name
                          </InputLabel>
                          <InputBase
                            type="text"
                            {...getFieldProps("returnRecipientFirstName")}
                            error={touched.returnRecipientFirstName && !!errors.returnRecipientFirstName}
                            helperText={touched.returnRecipientFirstName && errors.returnRecipientFirstName}
                          />
                        </FormControl>

                        <FormControl variant="standard">
                          <InputLabel shrink htmlFor="state">
                            Last Name
                          </InputLabel>
                          <InputBase
                            type="text"
                            {...getFieldProps("returnRecipientLastName")}
                            error={touched.returnRecipientLastName && !!errors.returnRecipientLastName}
                            helperText={touched.returnRecipientLastName && errors.returnRecipientLastName}
                          />
                        </FormControl>
                      </Box>
                      <Alert severity="info">
                        <AlertTitle sx={{ fontWeight: 700 }}>
                          Please check this address and contact are correct.
                        </AlertTitle>

                        <Box>
                          <Typography>
                            {values.returnRecipientFirstName} {values.returnRecipientLastName}
                          </Typography>
                          <Typography>{formLocation.address}</Typography>
                          <Typography>{formLocation.address2}</Typography>
                          <Typography>{formLocation.city}</Typography>
                          <Typography>{formLocation.zipcode}</Typography>
                          <Typography>{formLocation.country}</Typography>
                        </Box>
                      </Alert>
                    </Box>
                  )}
                </Box>
              )}

              <FormControlLabel
                value="virtualPickup"
                control={<Radio sx={{ pt: 0 }} />}
                label={
                  <Box display="flex" flexDirection="column">
                    <Typography fontWeight={600} fontSize="16px">
                      Direct to Revivn
                      <Chip
                        size="small"
                        label="NEW"
                        sx={{
                          backgroundColor: theme.palette.lightBlue.main,
                          color: theme.palette.primary.main,
                          ml: 1,
                          fontWeight: 700,
                        }}
                      />
                    </Typography>
                    <Typography variant="body2">
                      Send your end of life asset directly to Revivn and we'll waive all shipping and processing fees.
                    </Typography>
                  </Box>
                }
              />

              <FormControlLabel
                value="customReturnLocation"
                control={<Radio />}
                label={
                  <Typography fontWeight={600} fontSize="16px">
                    Custom Return Address
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
          {selectedReturnMethod === "customReturnLocation" && <ReturnAddressForm />}
        </>
      )}
      {!enabledMarketingLaunch && (
        <>
          <>
            <FormControlLabel
              control={
                <Checkbox
                  {...getFieldProps("virtualPickup")}
                  onClick={() => {
                    setCustomReturnLabel(false);
                  }}
                />
              }
              label="Return to Revivn"
            />
            <Box mb={2}>
              <Typography>
                Send this box directly back to Revivn to be processed and we'll waive all associated fees!
              </Typography>
            </Box>
          </>

          {!values.virtualPickup && (
            <Box>
              <Box>
                <Typography>
                  Or select which one of your company locations you'd like this box to be returned to
                </Typography>

                <Box mt={2}>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Autocomplete
                      forcePopupIcon
                      options={locationOptions}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option: any, value: any) => {
                        return option.id === value.id;
                      }}
                      onChange={(_event, newValue: any) => {
                        setCustomReturnLabel(false);
                        setValues({ ...values, locationId: newValue.id, isCustomAddress: false });
                      }}
                      renderInput={(params) => {
                        return (
                          <FormControl variant="standard" sx={{ width: "100%" }}>
                            <InputLabel shrink {...params.InputLabelProps}>
                              Choose an office location {!customReturnLabel && <RequiredSymbol />}
                            </InputLabel>
                            <InputBase
                              ref={params.InputProps.ref}
                              inputProps={params.inputProps}
                              placeholder="Select Address"
                              error={touched.locationId && !!errors.locationId}
                              helperText={touched.locationId && errors.locationId}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" sx={{ mr: 1 }}>
                                    <ArrowDropDownIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        );
                      }}
                      value={locationOptions?.find(({ id }: any) => id === currentLocation.id)}
                    />
                  </Box>

                  {formLocation && (
                    <Box mt={2}>
                      <Alert severity="info">
                        <AlertTitle sx={{ fontWeight: 700 }}>
                          Please check the address is correct otherwise click on customize return label to input a
                          different address
                        </AlertTitle>

                        <Box>
                          <Typography>
                            {currentUser.firstName} {currentUser.lastName}
                          </Typography>
                          <Typography>{formLocation.address}</Typography>
                          <Typography>{formLocation.addressTwo}</Typography>
                          <Typography>{formLocation.city}</Typography>
                          <Typography>{formLocation.zipcode}</Typography>
                          <Typography>{formLocation.country}</Typography>
                        </Box>
                      </Alert>
                    </Box>
                  )}
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="right" mt={2}>
                  <Button
                    variant={customReturnLabel ? "outlined" : "contained"}
                    onClick={() => {
                      setCurrentLocation({ label: "None", id: "" });
                      setCustomReturnLabel(true);
                      setValues({ ...values, isCustomAddress: true, locationId: "" });
                    }}
                  >
                    customize return label
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
          {customReturnLabel && !values.virtualPickup && (
            <Box
              sx={{
                mt: 6,
                py: 3,
                px: 4,
                background: "white",
                borderRadius: 1,
                border: `1px solid ${theme.palette.divider}`,
                [theme.breakpoints.down("lg")]: { px: 2, py: 2 },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                <ArrowForwardIcon />
                Return Label
              </Box>
              <Typography>Add the details of the return label.</Typography>

              <ReturnAddressForm />
            </Box>
          )}
        </>
      )}
    </>
  );
}
