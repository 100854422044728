import { Box, Tab, Tabs } from "@mui/material";
import RouterBreadcrumbs from "components/common/RouterBreadcrumbs";
import useAnalytics from "hooks/analytics/useAnalytics";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

function DonationsAndEarnings() {
  let location = useLocation();
  let navigate = useNavigate();
  const { createAnalyticsEvent } = useAnalytics();
  const [eventTracked, setEventTracked] = useState(false);

  let [, tabValue] = location.pathname.split("/").filter(Boolean);

  useEffect(() => {
    if (eventTracked) return;
    setEventTracked(true);
    createAnalyticsEvent("donations_and_earnings_page_visit");
  }, [eventTracked]);

  return (
    <div>
      <Box>
        <RouterBreadcrumbs
          routes={[
            { path: "/", breadcrumb: "Dashboard" },
            { path: "/donations-and-earnings", breadcrumb: "Donations & Earnings" },
            { path: "/donations-and-earnings/donations", breadcrumb: "Donations" },
            { path: "/donations-and-earnings/donations/:details", breadcrumb: "Tech Kids Unlimited" },
            { path: "/donations-and-earnings/earnings", breadcrumb: "Earnings" },
          ]}
        />
      </Box>

      <Box mt={4}>
        <Tabs
          value={tabValue}
          onChange={(e, value) => {
            navigate(`/donations-and-earnings/${value}`);
          }}
        >
          <Tab label="Donations" value="donations" />
          <Tab label="Earnings" value="earnings" />
        </Tabs>

        <Box sx={{ padding: "24px 0" }}>
          <Outlet />
        </Box>
      </Box>
    </div>
  );
}

export default DonationsAndEarnings;
