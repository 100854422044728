import { Box, Typography } from "@mui/material";
import useEarnings from "hooks/earnings/useEarnings";

import SavingsIcon from "@mui/icons-material/Savings";
import { formatNumberToCurrency } from "utils/helpers";

export default function EarningsCard() {
  const { earningsBalance } = useEarnings();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "left",
        justifyContent: "left",
        px: 1,
      }}
    >
      <Box>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "left", justifyContent: "flexStart" }}>
          <SavingsIcon fontSize="large" htmlColor="#ffffff" sx={{ mr: 1 }} />
          <Typography variant="h6" fontWeight="bold" color="white">
            Your current earnings balance: {formatNumberToCurrency(earningsBalance)}
          </Typography>
        </Box>
        {earningsBalance > 0 ? (
          <Typography fontSize={"15px"} color="white" sx={{ pt: 1 }}>
            Yay, you’ve got earnings to donate! Select an organization below and help them reach their goal.
          </Typography>
        ) : (
          <Typography fontSize={"15px"} color="white" sx={{ pt: 1 }}>
            Don’t worry - you don’t have any earnings currently but you can schedule a pickup to start increasing your
            balance!
          </Typography>
        )}
      </Box>
    </Box>
  );
}
