import { AddCircleOutline } from "@mui/icons-material";
import { Autocomplete, Box, Button, Divider, FormControl, Link, MenuItem, Typography } from "@mui/material";
import { AppSelect, InputBase, InputLabel, RequiredSymbol } from "components/common/Input";
import theme from "lib/theme";
import React, { useState } from "react";
import AssetCart from "./AssetCart";
import useLocations from "hooks/locations/useLocations";
import { Link as RouterLink } from "react-router-dom";

interface OrderDetailsProps {
  goToNextStep: () => void;
}

interface AutocompleteOption {
  label: string;
  id: string;
  address: string;
  zipcode: string;
}

function OrderDetails({ goToNextStep }: OrderDetailsProps) {
  const { locations } = useLocations();

  // NOTE: this code is similar to PickupLocation auto complete code
  // we should abstract this to a common component if this logic is needed here
  const locationOptions = () => {
    let formattedLocations: AutocompleteOption[] = [{ label: "None", id: "", address: "", zipcode: "" }];
    if (locations) {
      locations.forEach((location) =>
        formattedLocations.push({
          label: location.name,
          id: location.uuid,
          address: location.address,
          zipcode: location.zipcode,
        })
      );
    }
    return formattedLocations;
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "2fr 1fr",
        gap: 4,
        alignItems: "start",
        [theme.breakpoints.down("md")]: { gridTemplateColumns: "1fr" },
      }}
    >
      <Box
        sx={{
          py: 3,
          px: 4,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
          [theme.breakpoints.down("lg")]: { px: 2, py: 2 },
        }}
      >
        <ItemSection />

        <Divider sx={{ my: 3 }} />

        <EmployeeInfo />

        <Divider sx={{ my: 3 }} />

        <EmployeeAddress />

        <Divider sx={{ my: 3 }} />

        <Box>
          <Typography variant="h5" fontWeight="bold">
            4. Recipient details
          </Typography>
          <Box sx={{ mt: 1, ml: 3 }}>
            <Typography>
              This is where your employee will ship the laptop. We'll send you tracking information when the laptop
              return shipment is in transit.
            </Typography>

            <Box mt={4}>
              <Box mt={3} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Autocomplete
                  options={locationOptions()}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                  onChange={(_event, newValue: any) => {}}
                  renderInput={(params) => {
                    return (
                      <FormControl variant="standard" sx={{ width: "100%" }}>
                        <InputLabel shrink {...params.InputLabelProps}>
                          Choose an office location <RequiredSymbol />
                        </InputLabel>
                        <InputBase
                          ref={params.InputProps.ref}
                          inputProps={params.inputProps}
                          placeholder="Select Address"
                        />
                      </FormControl>
                    );
                  }}
                  // value={currentLocation.address === "" ? { ...currentLocation, label: "" } : currentLocation}
                />
              </Box>

              <Link
                component={RouterLink}
                to={"/company-settings/locations"}
                sx={{
                  mt: 2,
                  display: "inline-flex",
                  alignItems: "center",
                  gap: 1,
                  fontWeight: 600,
                  textDecoration: "none",
                  ":hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                <AddCircleOutline sx={{ width: "20px", height: "20px" }} />
                Add a new location
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>

      <AssetCart onContinue={goToNextStep} />
    </Box>
  );
}

function ItemSection() {
  let [isEdit, setIsEdit] = useState(false);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 2fr",
        gap: 1,
        [theme.breakpoints.down("sm")]: { gridTemplateColumns: "1fr" },
      }}
    >
      <Typography variant="h5" fontWeight="bold">
        1. Item
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "start", gap: 2 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box sx={{ width: "60px", height: "60px" }}>
              <img src={require("assets/images/monitor.png")} style={{ width: "100%", height: "100%" }} alt=""></img>
            </Box>
            <Box>
              <Typography fontWeight="bold">Asset return - QR code</Typography>
              <Typography variant="body2" mt={0.5}>
                Insurance: none
              </Typography>
              <Typography mt={0.25} variant="body2">
                Quantity: 1
              </Typography>
            </Box>
          </Box>

          {isEdit ? (
            <Box mt={2}>
              <FormControl variant="standard" sx={{ width: "100%", maxWidth: "400px" }}>
                <InputLabel shrink htmlFor="insurance">
                  Select insurance for this item
                </InputLabel>
                <AppSelect id="insurance" defaultValue="none" fullWidth>
                  <MenuItem value="none">No Insurance</MenuItem>
                  <MenuItem value="Up to $1,000 (+$10)">Up to $1,000 (+$10)</MenuItem>
                  <MenuItem value="Up to $2,000 (+$20)">Up to $2,000 (+$20)</MenuItem>
                  <MenuItem value="Up to $3,000 (+$30)">Up to $3,000 (+$30)</MenuItem>
                  <MenuItem value="Up to $4,000 (+$40)">Up to $4,000 (+$40)</MenuItem>
                  <MenuItem value="Up to $5,000 (+$50)">Up to $5,000 (+$50)</MenuItem>
                </AppSelect>
              </FormControl>
            </Box>
          ) : null}
        </Box>
        <Button size="small" variant="contained" onClick={() => setIsEdit((f) => !f)}>
          {isEdit ? "save" : "edit"}
        </Button>
      </Box>
    </Box>
  );
}

function EmployeeInfo() {
  let [isEdit, setIsEdit] = useState(false);

  return (
    <Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 2fr",
          gap: 1,
          [theme.breakpoints.down("sm")]: { gridTemplateColumns: "1fr" },
        }}
      >
        <Typography variant="h5" fontWeight="bold">
          2. Employee info
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "start" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            {!isEdit && (
              <>
                <Typography>Abby Gaines</Typography>
                <Typography>againes@airbnb.com</Typography>
              </>
            )}
          </Box>
          <Button size="small" variant="contained" onClick={() => setIsEdit((f) => !f)}>
            {isEdit ? "save" : "edit"}
          </Button>
        </Box>
      </Box>

      {isEdit && (
        <Box mt={2} sx={{ display: "grid", gridTemplateColumns: "2fr 3fr", gap: 4 }}>
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="employee-name">
              Employee name <RequiredSymbol />
            </InputLabel>
            <InputBase type="text" id="employee-name" placeholder="Name" />
          </FormControl>
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="employee-email">
              Employee email <RequiredSymbol />
            </InputLabel>
            <InputBase type="email" id="employee-email" placeholder="Email" />
          </FormControl>
        </Box>
      )}
    </Box>
  );
}

function EmployeeAddress() {
  let [isEdit, setIsEdit] = useState(false);

  return (
    <Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 2fr",
          gap: 1,
          [theme.breakpoints.down("sm")]: { gridTemplateColumns: "1fr" },
        }}
      >
        <Typography variant="h5" fontWeight="bold">
          3. Employee address
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "start" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            {!isEdit && (
              <>
                <Typography>48737 Cheshire Road, Suite 2A</Typography>
                <Typography>Laguna Niguel, CA, 11234</Typography>
                <Typography>United States </Typography>
                <Typography>(111) 222-3333</Typography>
              </>
            )}
          </Box>
          <Button size="small" variant="contained" onClick={() => setIsEdit((f) => !f)}>
            {isEdit ? "save" : "edit"}
          </Button>
        </Box>
      </Box>

      {isEdit && (
        <Box mt={2} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="address-line-1">
              Employee address line 1 <RequiredSymbol />
            </InputLabel>
            <InputBase type="text" id="address-line-1" />
          </FormControl>

          <FormControl variant="standard">
            <InputLabel shrink htmlFor="address-line-2">
              Employee address line 2
            </InputLabel>
            <InputBase type="text" id="address-line-2" />
          </FormControl>

          <Box sx={{ display: "grid", gridTemplateColumns: "2fr 1fr 2fr", gap: 2 }}>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="address-line-1">
                City <RequiredSymbol />
              </InputLabel>
              <InputBase type="text" id="address-line-1" />
            </FormControl>

            <FormControl variant="standard">
              <InputLabel shrink htmlFor="address-line-1">
                State <RequiredSymbol />
              </InputLabel>
              <InputBase type="text" id="address-line-1" />
            </FormControl>

            <FormControl variant="standard">
              <InputLabel shrink htmlFor="address-line-1">
                Zip Code <RequiredSymbol />
              </InputLabel>
              <InputBase type="text" id="address-line-1" />
            </FormControl>
          </Box>

          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2, mr: 6 }}>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="country">
                Country <RequiredSymbol />
              </InputLabel>
              <AppSelect id="country">
                <MenuItem value="United States">United States</MenuItem>
                <MenuItem value="Egypt">Egypt</MenuItem>
              </AppSelect>
            </FormControl>

            <FormControl variant="standard">
              <InputLabel shrink htmlFor="phone-number">
                Phone Number <RequiredSymbol />
              </InputLabel>
              <InputBase type="text" id="phone-number" />
            </FormControl>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default OrderDetails;
