import { Box, Typography } from "@mui/material";
import theme from "lib/theme";
import { VpnKey, Settings, Webhook, Description } from "@mui/icons-material";

export default function PaywallFeatures() {
  return (
    <Box display="flex" justifyContent="center" flexDirection="column" gap={1} my="40px" mx="30px">
      <Box display="flex" justifyContent="center" flexDirection="row" gap={3}>
        <Box
          sx={{
            borderRadius: "50%",
            border: `2px solid ${theme.palette.primary.main}`,
            width: "42px",
            height: "42px",
            display: "grid",
            placeItems: "center",
            flexShrink: 0,
          }}
        >
          <VpnKey sx={{ color: theme.palette.primary.main }} />
        </Box>
        <Box display="grid" justifyItems="start">
          <Typography fontWeight="700" fontSize="14" align="left">
            Single Sign On
          </Typography>
          <Typography fontWeight="400" fontSize="14" align="left">
            Connect your Identity Provider, such as Okta, to allow your users to login to Revivn with Single Sign On.
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" flexDirection="row" gap={3}>
        <Box
          sx={{
            borderRadius: "50%",
            border: `2px solid ${theme.palette.primary.main}`,
            width: "42px",
            height: "42px",
            display: "grid",
            placeItems: "center",
            flexShrink: 0,
          }}
        >
          <Settings style={{ color: theme.palette.primary.main }} />
        </Box>
        <Box display="grid" justifyItems="start">
          <Typography fontWeight="700" fontSize="14" align="left">
            API
          </Typography>
          <Typography fontWeight="400" fontSize="14" align="left">
            Automate your hardware retirement processes by leveraging our API.
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" flexDirection="row" gap={3}>
        <Box
          sx={{
            borderRadius: "50%",
            border: `2px solid ${theme.palette.primary.main}`,
            width: "42px",
            height: "42px",
            display: "grid",
            placeItems: "center",
            flexShrink: 0,
          }}
        >
          <Webhook style={{ color: theme.palette.primary.main }} />
        </Box>
        <Box display="grid" justifyItems="start">
          <Typography fontWeight="700" fontSize="14" align="left">
            Webhooks
          </Typography>
          <Typography fontWeight="400" fontSize="14" align="left">
            Subscribe to key Revivn events and receive real time dynamic data into your system of choice.
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" flexDirection="row" gap={3}>
        <Box
          sx={{
            borderRadius: "50%",
            border: `2px solid ${theme.palette.primary.main}`,
            width: "42px",
            height: "42px",
            display: "grid",
            placeItems: "center",
            flexShrink: 0,
          }}
        >
          <Description style={{ color: theme.palette.primary.main }} />
        </Box>
        <Box display="grid" justifyItems="start">
          <Typography fontWeight="700" fontSize="14" align="left">
            Individual Certificates of Destruction
          </Typography>
          <Typography fontWeight="400" fontSize="14" align="left">
            Receive individual certificates of destruction to streamline your audits.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
