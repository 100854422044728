import { Box, Button, Checkbox, FormControl, FormControlLabel, Typography } from "@mui/material";
import { InputBase, InputLabel } from "components/common/Input";
import theme from "lib/theme";
import { Link as RouterLink } from "react-router-dom";

function ACHInformationForm() {
  return (
    <div>
      <Typography variant="h4" fontWeight="bold">
        Payment information
      </Typography>
      <Typography mt={0.5}>
        We collect your ACH info for payment relating to earnings and allow you to save a credit card in your account if
        you choose to purchase additional features within the app (but don’t worry, we won’t charge you without
        notifying you first)!
      </Typography>

      <Box
        sx={{
          mt: 3,
          p: 4,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography variant="h6" fontWeight="700">
          Enter your ACH/Wire information
        </Typography>
        <Typography mt={0.5} color="text.secondary">
          Enter the details of your financial institution.
        </Typography>

        <Box mt={3} sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink htmlFor="company-name">
              Company name
            </InputLabel>
            <InputBase id="company-name" />
          </FormControl>

          <FormControl variant="standard" fullWidth>
            <InputLabel shrink htmlFor="location-address">
              Address Line 1
            </InputLabel>
            <InputBase id="location-address" placeholder="Street address or P.O. Box" />
          </FormControl>

          <FormControl variant="standard" fullWidth>
            <InputLabel shrink htmlFor="location-address2">
              Address Line 2
            </InputLabel>
            <InputBase id="location-address2" placeholder="Apt, suite, unit, building, floor, etc." />
          </FormControl>

          <Box sx={{ display: "grid", gridTemplateColumns: "2fr 1fr 1fr", gap: 3 }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="location-city">
                City
              </InputLabel>
              <InputBase id="location-city" placeholder="City" />
            </FormControl>

            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="location-state">
                State
              </InputLabel>
              <InputBase id="location-state" placeholder="State" />
            </FormControl>

            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="location-zipcode">
                Zipcode
              </InputLabel>
              <InputBase id="location-zipcode" placeholder="Zipcode" />
            </FormControl>
          </Box>

          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="bank-name">
                Bank Name
              </InputLabel>
              <InputBase id="bank-name" />
            </FormControl>

            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="bank-phone">
                Bank Phone #:
              </InputLabel>
              <InputBase id="bank-phone" />
            </FormControl>
          </Box>

          <FormControl variant="standard" fullWidth>
            <InputLabel shrink htmlFor="bank-address">
              Bank Address
            </InputLabel>
            <InputBase id="bank-address" placeholder="Street address, city, state, zip code" />
          </FormControl>

          <Typography fontWeight="bold">
            Provide the corresponding information for one of the following (ACH, Wire, or International Wire):
          </Typography>

          <Box
            sx={{
              py: 2.5,
              px: 3.5,
              background: "white",
              borderRadius: 1,
              border: `1px solid ${theme.palette.divider}`,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography fontWeight="bold">ACH</Typography>
            <Box sx={{ display: "flex", gap: 3 }}>
              <FormControlLabel control={<Checkbox />} label="DDA (Checking)" />
              <FormControlLabel control={<Checkbox />} label="TDA (Savings)" />
            </Box>
            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink htmlFor="bank-routing">
                  Bank Routing #
                </InputLabel>
                <InputBase id="bank-routing" />
              </FormControl>

              <FormControl variant="standard" fullWidth>
                <InputLabel shrink htmlFor="bank-account">
                  Account #
                </InputLabel>
                <InputBase id="bank-account" />
              </FormControl>
            </Box>
          </Box>

          <Box
            sx={{
              py: 2.5,
              px: 3.5,
              background: "white",
              borderRadius: 1,
              border: `1px solid ${theme.palette.divider}`,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography fontWeight="bold">Domestic Wire</Typography>

            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink htmlFor="bank-aba">
                  Bank ABA #
                </InputLabel>
                <InputBase id="bank-aba" />
              </FormControl>

              <FormControl variant="standard" fullWidth>
                <InputLabel shrink htmlFor="bank-aba-account">
                  Account #
                </InputLabel>
                <InputBase id="bank-aba-account" />
              </FormControl>
            </Box>
          </Box>

          <Box
            sx={{
              py: 2.5,
              px: 3.5,
              background: "white",
              borderRadius: 1,
              border: `1px solid ${theme.palette.divider}`,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography fontWeight="bold">International Wire</Typography>

            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink htmlFor="bic-or-swift-code">
                  BIC or SWIFT Code
                </InputLabel>
                <InputBase id="bic-or-swift-code" />
              </FormControl>

              <FormControl variant="standard" fullWidth>
                <InputLabel shrink htmlFor="international-account">
                  Account #
                </InputLabel>
                <InputBase id="international-account" />
              </FormControl>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mt={3} sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
        <Button variant="outlined" component={RouterLink} to="/company-settings/payment-info">
          Back
        </Button>
        <Button variant="contained">Save</Button>
      </Box>
    </div>
  );
}

export default ACHInformationForm;
