import { OpenInNew } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import FileDownload from "@mui/icons-material/FileDownload";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  debounce,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AnnouncementBanner from "components/common/AnnouncementBanner";
import Breadcrumbs from "components/common/Breadcrumbs";
import { InputBase } from "components/common/Input";
import { TableLoadingSkeleton } from "components/common/Skeletons";
import useAnalytics from "hooks/analytics/useAnalytics";
import useAssetRetrievalExport from "hooks/assetRetrievals/useAssetRetrievalExport";
import useAssetRetrievalRemindersConfiguration from "hooks/assetRetrievals/useAssetRetrievalRemindersConfiguration";
import useAssetRetrievals from "hooks/assetRetrievals/useAssetRetrievals";
import useFeatureFlag from "hooks/useFeatureFlag";
import theme from "lib/theme";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { AssetRetrieval } from "types/assetRetrieval";
import { formatDate } from "utils/helpers";
import ReminderConfigurationModal from "./ReminderConfigurationModal";

const debouncedInputHandler = debounce((handle: (value: string) => void, value: string) => {
  handle(value);
}, 200);

export default function SendBoxes() {
  const { assetRetrievals, loading, toggleRemindersMute, totalPages, searchParams, setSearchParams } =
    useAssetRetrievals();
  const {
    assetRetrievalReminderConfig,
    createAssetReminderConfiguration,
    updateAssetReminderConfiguration,
    deleteAssetReminderConfiguration,
    setKey,
  } = useAssetRetrievalRemindersConfiguration();
  const [openReminder, setOpenReminder] = useState(false);
  const [search, setSearch] = useState<string>(searchParams.get("q") || "");
  const { createAnalyticsEvent } = useAnalytics();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const navigate = useNavigate();
  const [page, setPage] = useState(Number(searchParams.get("page[number]") ?? 1));
  const [pageSize, setPageSize] = useState(Number(searchParams.get("page[size]") ?? 10));
  const { isLoading, exportAssetRetrievals } = useAssetRetrievalExport();
  const { enabled: boomerangLaunchEnabled } = useFeatureFlag("asset_retrievals_marketing_launch");

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event: any, orderType: string) => {
    createAnalyticsEvent(`asset_retrieval_order_started_${orderType}`);
    setAnchorEl(null);
    navigate(`/retrievals/send-boxes/${orderType}`);
  };

  const handleStartOrder = (event: React.MouseEvent<HTMLButtonElement>) => {
    createAnalyticsEvent("asset_retrieval_order_started");
    setAnchorEl(event.currentTarget);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const getChipStyle = (state: string) => {
    const styleEnum: { [key: string]: { color: any; label: string } } = {
      order_placed: { color: "placed", label: "Order Placed" },
      order_accepted: { color: "accepted", label: "Order Accepted" },
      package_out_to_employee: { color: "outToDestination1", label: "En Route to Employee" },
      package_received_by_employee: { color: "outToDestination1", label: "Delivered to Employee" },
      package_out_to_final_destination: { color: "outToDestination2", label: "En Route to Destination" },
      package_delivered: { color: "outToDestination2", label: "Delivered to Destination" },
      order_completed: { color: "complete", label: "Complete" },
      order_cancelled: { color: "canceled", label: "Canceled" },
    };

    return { ...styleEnum[state] };
  };

  function ToggleRemindersButton({ assetRetrieval }: { assetRetrieval: AssetRetrieval }) {
    const [muted, setMuted] = useState(!!assetRetrieval.remindersMutedAt);
    const [reminderLoading, setReminderLoading] = useState(false);

    const mutedTooltip = "You can reactivate reminders associated only to this order.";
    const unmutedTooltip = "If you'd like us to stop sending reminders associated only to this order, deactivate them.";

    const toggle = async () => {
      setReminderLoading(true);
      await toggleRemindersMute(assetRetrieval.uuid)
        .then(() => {
          setMuted(!muted);
        })
        .finally(() => {
          setReminderLoading(false);
        });
    };

    return (
      <Tooltip title={assetRetrieval.remindersMutedAt ? mutedTooltip : unmutedTooltip}>
        <IconButton
          disabled={reminderLoading}
          onClick={toggle}
          color={assetRetrieval.remindersMutedAt ? "default" : "primary"}
          size="small"
        >
          {reminderLoading ? <CircularProgress /> : muted ? <NotificationsOffIcon /> : <NotificationsActiveIcon />}
        </IconButton>
      </Tooltip>
    );
  }

  const tableHeaders = [
    { label: "Order No", width: "100" },
    { label: "Date", width: "150" },
    { label: "Status", width: "200" },
    { label: "Employee", width: "200" },
    { label: "Employee Location", width: "250" },
    { label: "Return Location", width: "200" },
    { label: "Reminders Sent", width: "150" },
    { label: "Insurance", width: "150" },
    { label: "Serial Number", width: "200" },
    { label: "Manufacturer", width: "200" },
    { label: "Model", width: "250" },
    { label: "Reminder Status", width: "200" },
  ];

  const stickyCellStyle = {
    backgroundColor: "background.paper",
    position: "sticky",
    left: "0",
    zIndex: 1,
    filter: `drop-shadow(0px 4px 4px ${theme.palette.divider})`,
  };

  useEffect(() => {
    if (searchParams.get("q") !== search) {
      debouncedInputHandler((value: string) => {
        value === "" ? searchParams.delete("q") : searchParams.set("q", value);
        setSearchParams(searchParams, { replace: true });
      }, search);
    }
  }, [search]);

  useEffect(() => {
    setSearchParams((prevParams: URLSearchParams) => {
      prevParams.set("page[number]", page.toString());
      prevParams.set("page[size]", pageSize.toString());
      return new URLSearchParams(prevParams);
    });
  }, [page, pageSize, setSearchParams]);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 4,
        }}
      >
        <Breadcrumbs
          data={[
            { label: "Dashboard", to: "/" },
            { label: "Retrievals", to: "/retrievals/send-boxes" },
          ]}
        ></Breadcrumbs>

        <>
          <Button variant="contained" disableElevation onClick={handleStartOrder} endIcon={<ArrowDropDownIcon />}>
            Place Order
          </Button>
          <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={openMenu} onClose={handleClose}>
            <MenuItem onClick={(event) => handleMenuClick(event, "new")}>Individual Order</MenuItem>
            <MenuItem onClick={(event) => handleMenuClick(event, "bulk-new")}>Bulk Order</MenuItem>
          </Menu>
        </>
      </Box>
      <div>
        <Typography variant="h4" fontWeight="bold">
          Retrievals
        </Typography>
        <Typography>Manage your remote retrievals - place orders, customize notifications, and track boxes.</Typography>

        {boomerangLaunchEnabled && (
          <Box sx={{ mt: 5 }}>
            <AnnouncementBanner
              title="Introducing Bulk Orders"
              description="Simplify your workflow by placing multiple retrieval requests in a single bulk order."
            />
          </Box>
        )}

        <Box
          sx={{
            mt: 5,
            px: 5,
            py: 3,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography variant="h6">Past & Current Orders</Typography>
          <Typography variant="body1">
            View the statuses of past and current orders and send additional reminders to employees.
          </Typography>
          <Box
            sx={{
              mt: 4,
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            <Box display="flex">
              <InputBase
                placeholder="Search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                sx={{
                  "&:hover, &:focus": { zIndex: 2 },
                }}
              ></InputBase>
              <Box ml={1}>
                <Button
                  variant="outlined"
                  startIcon={isLoading ? <CircularProgress size={24} /> : <FileDownload />}
                  onClick={exportAssetRetrievals}
                  disabled={isLoading}
                >
                  export
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                gap: 2,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setOpenReminder(true)}
                startIcon={<EditNotificationsIcon />}
              >
                reminders
              </Button>
            </Box>
          </Box>

          <TableContainer
            sx={{
              mt: 5,
              borderRadius: 1,
              border: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Table
              sx={{
                ".MuiTableRow-root:last-child td, .MuiTableRow-root:last-child th": { border: 0 },
                tableLayout: "fixed",
              }}
            >
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header, index) => (
                    <TableCell
                      sx={{
                        ...((index === 0 && stickyCellStyle) || {}),
                        [`&.${tableCellClasses.head}`]: {
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                        },
                        [`&.${tableCellClasses.body}`]: {
                          fontSize: 14,
                        },
                      }}
                      width={header.width}
                    >
                      {header.label.toUpperCase()}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableLoadingSkeleton cellSpan={10} cellHeight={41} rowNumber={4} />
                ) : (
                  assetRetrievals.map((assetRetrieval) => {
                    return (
                      <TableRow>
                        <TableCell key={assetRetrieval.uuid} sx={{ ...stickyCellStyle }}>
                          {assetRetrieval.orderNumber}
                        </TableCell>
                        <TableCell>
                          <Typography noWrap>{formatDate(assetRetrieval.createdAt)}</Typography>
                        </TableCell>
                        <TableCell>
                          <Chip {...getChipStyle(assetRetrieval.state)} />
                        </TableCell>
                        <TableCell>
                          {(assetRetrieval.employeeFirstName || assetRetrieval.employeeLastName) && (
                            <>
                              {assetRetrieval.employeeFirstName} {assetRetrieval.employeeLastName}
                              <br />
                            </>
                          )}

                          {assetRetrieval.employeeEmail}
                        </TableCell>
                        <TableCell>
                          {assetRetrieval.employeeAddress}
                          <br />
                          {assetRetrieval.employeeAddressTwo && (
                            <>
                              {assetRetrieval.employeeAddressTwo}
                              <br />
                            </>
                          )}
                          {assetRetrieval.employeeAddressCity}, {assetRetrieval.employeeAddressState}{" "}
                          {assetRetrieval.employeeAddressZipCode}
                          <br />
                          {assetRetrieval.employeeAddressCountry}
                          {assetRetrieval.outboundAssetRetrievalShipment?.trackingNumber && (
                            <>
                              <br />
                              <Link
                                href={assetRetrieval.outboundAssetRetrievalShipment?.trackingUrl}
                                target="blank"
                                noWrap
                              >
                                {assetRetrieval.outboundAssetRetrievalShipment?.trackingNumber}{" "}
                                <OpenInNew fontSize="inherit" />
                              </Link>
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          {assetRetrieval.virtualPickup ? (
                            <>Direct to Revivn</>
                          ) : (
                            <>
                              {assetRetrieval.location ? (
                                <>
                                  {assetRetrieval.location.name}
                                  <br />
                                  {assetRetrieval.location.address}
                                  <br />
                                  {assetRetrieval.location.address2 && (
                                    <>
                                      {assetRetrieval.location.address2}
                                      <br />
                                    </>
                                  )}
                                  {assetRetrieval.location.city}, {assetRetrieval.location.state}{" "}
                                  {assetRetrieval.location.zipcode}
                                  <br />
                                  {assetRetrieval.location?.country}
                                </>
                              ) : (
                                <>
                                  {assetRetrieval.returnRecipientAddress && (
                                    <>
                                      {assetRetrieval.returnRecipientName ||
                                        `${assetRetrieval.returnRecipientFirstName} ${assetRetrieval.returnRecipientLastName}`}{" "}
                                      {assetRetrieval.returnRecipientCompany}
                                      <br />
                                      {assetRetrieval.returnRecipientAddressCity},{" "}
                                      {assetRetrieval.returnRecipientAddressState}{" "}
                                      {assetRetrieval.returnRecipientAddressZipCode}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                          {assetRetrieval.inboundAssetRetrievalShipment?.trackingNumber && (
                            <>
                              <br />
                              <Link
                                href={assetRetrieval.inboundAssetRetrievalShipment?.trackingUrl}
                                target="blank"
                                noWrap
                              >
                                {assetRetrieval.inboundAssetRetrievalShipment?.trackingNumber}{" "}
                                <OpenInNew fontSize="inherit" />
                              </Link>
                            </>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {assetRetrievalReminderConfig && (
                            <Typography variant="inherit" noWrap>
                              {assetRetrieval.followupEscalationEmailSentAt
                                ? "Escalation Sent"
                                : `${assetRetrieval.assetRetrievalRemindersCount || 0} / ${
                                    assetRetrievalReminderConfig.followupEscalationCount
                                  }`}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          {assetRetrieval.insuranceOption === 0 || assetRetrieval.insuranceOption === null ? (
                            <Typography variant="inherit" noWrap>
                              No Insurance
                            </Typography>
                          ) : (
                            <>
                              <Typography variant="inherit" noWrap>
                                ${assetRetrieval.insuranceOption}
                              </Typography>
                              <Typography variant="inherit" noWrap>
                                (Up to ${assetRetrieval.insuranceOption * 100})
                              </Typography>
                            </>
                          )}
                        </TableCell>
                        <TableCell>{assetRetrieval.serialNumber ?? "-"}</TableCell>
                        <TableCell>{assetRetrieval.manufacturer ?? "-"}</TableCell>
                        <TableCell>{assetRetrieval.model ?? "-"}</TableCell>
                        <TableCell align="center">
                          {["order_cancelled", "order_completed"].includes(assetRetrieval.state) ? (
                            <>-</>
                          ) : (
                            <ToggleRemindersButton assetRetrieval={assetRetrieval} />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" sx={{ float: "center", gap: 4, alignItems: "baseline", justifyContent: "space-between" }}>
            <Pagination
              siblingCount={3}
              count={totalPages}
              size="large"
              color="primary"
              sx={{ marginTop: 2 }}
              page={page}
              onChange={handlePageChange}
            />
            <TextField
              select
              label="Items per page"
              size="small"
              variant="outlined"
              onChange={(e) => {
                setPageSize(parseInt(e.target.value));
                setPage(1);
              }}
              value={pageSize}
              sx={{ minWidth: "8em" }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </TextField>
          </Box>
        </Box>
      </div>
      <ReminderConfigurationModal
        openReminder={openReminder}
        setOpenReminder={setOpenReminder}
        assetRetrievalReminderConfig={assetRetrievalReminderConfig}
        createAssetReminderConfiguration={createAssetReminderConfiguration}
        updateAssetReminderConfiguration={updateAssetReminderConfiguration}
        deleteAssetReminderConfiguration={deleteAssetReminderConfiguration}
        setKey={setKey}
      />
    </div>
  );
}
