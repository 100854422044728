import { InfoOutlined } from "@mui/icons-material";
import { Box, FormControl, MenuItem, Typography, useMediaQuery } from "@mui/material";
import { AppSelect, InputLabel } from "components/common/Input";
import theme from "lib/theme";
import React from "react";
import AssetCard from "./AssetCard";
import AssetCart from "./AssetCart";

interface StartOrderProps {
  goToNextStep: () => void;
}

function StartOrder({ goToNextStep }: StartOrderProps) {
  let isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "2fr 1fr",
        gap: 4,
        alignItems: "start",
        [theme.breakpoints.down("md")]: { gridTemplateColumns: "1fr" },
      }}
    >
      <Box
        sx={{
          py: 3,
          px: 4,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
          [theme.breakpoints.down("lg")]: { px: 2, py: 2 },
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          What’s being sent back
        </Typography>

        <Box sx={{ mt: 1 }}>
          <AssetCard />
        </Box>

        <Typography mt={3} variant="h6" fontWeight="bold">
          Insurance
        </Typography>

        <Box
          sx={{
            mt: 1,
            p: 4,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            [theme.breakpoints.down("md")]: { p: 2 },
          }}
        >
          <FormControl variant="standard" sx={{ width: "100%", maxWidth: "400px" }}>
            <InputLabel shrink htmlFor="insurance">
              Select insurance for this item
            </InputLabel>
            <AppSelect id="insurance" defaultValue="none">
              <MenuItem value="none">No Insurance</MenuItem>
              <MenuItem value="Up to $1,000 (+$10)">Up to $1,000 (+$10)</MenuItem>
            </AppSelect>
          </FormControl>
        </Box>

        <Typography mt={3} variant="h6" fontWeight="bold">
          How it works
        </Typography>

        <Box
          sx={{
            mt: 1,
            py: 1.5,
            px: 4,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            gap: 1.5,
            [theme.breakpoints.down("sm")]: { py: 2, px: 2, gridTemplateColumns: "1fr", gap: 0 },
          }}
        >
          {!isMobile && (
            <Box>
              <InfoOutlined color="primary" />
            </Box>
          )}
          <Box
            sx={{
              ul: { fontSize: "14px", color: theme.palette.text.secondary, mt: 0.5, pl: 3 },
            }}
          >
            <Typography fontWeight="bold">What’s the process?</Typography>
            <ul>
              <li>A QR code is generated based on the details you provide us and emailed to your employee.</li>
              <li>Employee brings their laptop and accessories to the nearest FedEx location with the QR code.</li>
              <li>QR code is scanned by the FedEx location and laptop is packaged for the employee.</li>
              <li>FedEx location ships out the packaged laptop box to the return address you specified,</li>
            </ul>

            <Typography fontSize="14px" fontWeight="bold" mt={2}>
              What we include:
            </Typography>
            <ul>
              <li>
                Email communications are sent to your employee, including reminders if they delay returning their laptop
              </li>
              <li>Tracking updates sent to your company email address</li>
            </ul>

            <Typography fontSize="14px" mt={2}>
              <strong>Where we ship:</strong>{" "}
              <Typography component="span" fontSize="14px" color="text.secondary">
                we support laptop returns to / from all US states and Puerto Rico
              </Typography>
            </Typography>

            <Typography fontSize="14px" mt={2}>
              <strong>How long shipping takes:</strong>{" "}
              <Typography component="span" fontSize="14px" color="text.secondary">
                Shipping generally takes 1-3 business days from the moment it’s dropped off at the FedEx location.
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>

      <AssetCart onContinue={goToNextStep} />
    </Box>
  );
}

export default StartOrder;
