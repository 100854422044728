import { AddCircleOutline, CheckCircleOutline } from "@mui/icons-material";
import { Box, Divider, Link, Typography } from "@mui/material";
import { useState } from "react";
import theme from "lib/theme";
import AssetCart from "./AssetCart";
import { VisaCard } from "./VisaCard";
import { Link as RouterLink } from "react-router-dom";
import EarningSlider from "components/common/EarningSlider";
import { Modal } from "components/common/Modal";

interface PlaceOrderProps {
  goToNextStep: () => void;
}

function PlaceOrder({ goToNextStep }: PlaceOrderProps) {
  let [open, setOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "2fr 1fr",
          gap: 4,
          alignItems: "start",
          [theme.breakpoints.down("md")]: { gridTemplateColumns: "1fr" },
        }}
      >
        <Box
          sx={{
            py: 3,
            px: 4,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
            [theme.breakpoints.down("lg")]: { px: 2, py: 2 },
          }}
        >
          <Typography variant="h5" fontWeight="bold">
            Payments & Discounts
          </Typography>

          <Box mt={2}>
            <Typography variant="h6">Option 1: Use Credit Card</Typography>
            <Typography color="text.secondary">Use the card you’ve got on file with us or add a new one.</Typography>

            <Box mt={2}>
              <Box sx={{ maxWidth: "550px" }}>
                <VisaCard />
              </Box>
            </Box>

            <Link
              component={RouterLink}
              to={"/company-settings/locations"}
              sx={{
                mt: 1,
                ml: 2,
                display: "inline-flex",
                alignItems: "center",
                gap: 1,
                fontWeight: 600,
                textDecoration: "none",
                ":hover": {
                  textDecoration: "underline",
                },
              }}
            >
              <AddCircleOutline sx={{ width: "20px", height: "20px" }} />
              Add new credit card
            </Link>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box mt={2}>
            <Typography variant="h6">Option 2: Use your earnings</Typography>
            <Typography color="text.secondary">
              Use your earnings so far to pay for this order by using the slider to adjust the amount.{" "}
            </Typography>

            <Box sx={{ maxWidth: "550px" }}>
              <EarningSlider />
            </Box>
          </Box>
        </Box>

        <AssetCart
          onContinue={() => {
            setOpen(true);
          }}
          continueLabel="place order"
        />
      </Box>

      <Modal open={open} setOpen={setOpen}>
        <Box sx={{ px: 3 }}>
          <CheckCircleOutline color="primary" sx={{ fontSize: "25px" }} />

          <Typography variant="h5" fontWeight="bold">
            Your order has been placed!
          </Typography>
          <Typography mt={1.5} fontSize="18px">
            Your order # is{" "}
            <Typography component="span" color="primary">
              83849
            </Typography>
          </Typography>

          <Typography mt={0.5} color="text.secondary">
            You will receive an email confirmation with your order details and status soon. Once the shipping materials
            are sent, you’ll receive another email confirmation with the tracking information attached. If you have any
            questions, reach out to us!
          </Typography>
        </Box>
      </Modal>
    </>
  );
}

export default PlaceOrder;
