import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function useSearchParamsOperations() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [internalSearchParams, setInternalSearchParams] = useState(searchParams);

  const replace = useCallback(
    (field: string, value: string) => {
      setInternalSearchParams((oldSearchParams) => {
        const newSearchParams = new URLSearchParams(oldSearchParams);
        newSearchParams.delete(field);
        newSearchParams.append(field, value);
        return newSearchParams;
      });
    },
    [setInternalSearchParams]
  );

  const deleteFieldInArrayUsingPrefix = useCallback(
    (field: string, prefix: string) => {
      setInternalSearchParams((oldSearchParams) => {
        const newSearchParams = new URLSearchParams(oldSearchParams);
        const fieldArrayValues = newSearchParams.getAll(field);
        newSearchParams.delete(field);
        fieldArrayValues
          .filter((v) => !v.includes(prefix))
          .forEach((v) => {
            newSearchParams.append(field, v);
          });
        return newSearchParams;
      });
    },
    [setInternalSearchParams]
  );

  const replaceInArrayField = useCallback(
    (field: string, oldValue: string, newValue: string) => {
      setInternalSearchParams((oldSearchParams) => {
        const newSearchParams = new URLSearchParams(oldSearchParams);
        const fieldArrayValues = newSearchParams.getAll(field);
        newSearchParams.delete(field);
        fieldArrayValues
          .filter((v) => v !== oldValue)
          .forEach((v) => {
            newSearchParams.append(field, v);
          });
        newSearchParams.append(field, newValue);
        return newSearchParams;
      });
    },
    [setInternalSearchParams]
  );

  const replaceInArrayFieldUsingPrefix = useCallback(
    (field: string, oldValuePrefix: string, newValue: string) => {
      setInternalSearchParams((oldSearchParams) => {
        const newSearchParams = new URLSearchParams(oldSearchParams);
        const fieldArrayValues = newSearchParams.getAll(field);
        if (fieldArrayValues.find((v) => v.includes(oldValuePrefix)) === newValue) return newSearchParams;
        newSearchParams.delete(field);
        fieldArrayValues
          .filter((v) => !v.includes(oldValuePrefix))
          .forEach((v) => {
            newSearchParams.append(field, v);
          });
        newSearchParams.append(field, newValue);
        return newSearchParams;
      });
    },
    [setInternalSearchParams]
  );

  useEffect(() => {
    setSearchParams(internalSearchParams, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalSearchParams]);

  return {
    replace,
    deleteFieldInArrayUsingPrefix,
    replaceInArrayField,
    replaceInArrayFieldUsingPrefix,
    searchParams,
  };
}
