import { Box, Typography, Skeleton, Button, Tooltip } from "@mui/material";
import theme from "lib/theme";
import { Link as RouterLink } from "react-router-dom";
import { formatNumberToCurrency } from "utils/helpers";
import useCurrentUserPermission, { CurrentUserContext } from "hooks/useCurrentUserPermission";

export default function QuickInsights(homeInfo: any) {
  const { hasPermission } = useCurrentUserPermission();

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gap: 2,
        [theme.breakpoints.down("md")]: {
          gridTemplateColumns: "1fr 1fr",
        },
        [theme.breakpoints.down("sm")]: {
          gridTemplateColumns: "1fr",
        },
      }}
    >
      <Box
        sx={{
          p: 3,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          [theme.breakpoints.down("md")]: {
            p: 2,
          },
        }}
      >
        <Typography fontWeight="600" fontSize="32px">
          {!homeInfo.homeInfo && <Skeleton variant="text" height={50} width={50} />}
          {homeInfo.homeInfo && homeInfo.homeInfo.pickupsCount}
        </Typography>
        <Typography fontWeight="600" fontSize="14px">
          Pickups in progress
        </Typography>
        <Tooltip
          title={
            hasPermission("pickups")
              ? ""
              : "You do not have permissions for this action, please contact your administrator for permission."
          }
        >
          <span>
            <Button
              size="small"
              variant="contained"
              sx={{ mt: "20px" }}
              component={RouterLink}
              to="/pickups/schedule-pickup"
              disabled={!hasPermission("pickups")}
            >
              Schedule a pickup
            </Button>
          </span>
        </Tooltip>
      </Box>
      <Box
        sx={{
          p: 3,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          [theme.breakpoints.down("md")]: {
            p: 2,
          },
        }}
      >
        <Typography fontWeight="600" fontSize="32px">
          {!homeInfo.homeInfo && <Skeleton variant="text" height={50} width={50} />}
          {homeInfo.homeInfo && homeInfo.homeInfo.assetsCount}
        </Typography>
        <Typography fontWeight="600" fontSize="14px">
          Total retired assets
        </Typography>
        <Button
          size="small"
          variant="contained"
          sx={{ mt: "20px" }}
          component={RouterLink}
          to="/assets?page%5Bnumber%5D=1&page%5Bsize%5D=25&filter%5B%5D=state%2Cis_any_of%2Cretired"
        >
          manage your assets
        </Button>
      </Box>

      <Box
        sx={{
          p: 3,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          [theme.breakpoints.down("md")]: {
            p: 2,
          },
        }}
      >
        <Typography fontWeight="600" fontSize="32px">
          {!homeInfo.homeInfo && <Skeleton variant="text" height={50} width={50} />}
          {homeInfo.homeInfo && formatNumberToCurrency(homeInfo.homeInfo.donationsAmount)}
        </Typography>
        <Typography fontWeight="600" fontSize="14px">
          Total donated this year
        </Typography>
        <Button
          size="small"
          variant="contained"
          sx={{ mt: "20px" }}
          component={RouterLink}
          to="/donations-and-earnings/donations"
        >
          donate your earnings
        </Button>
      </Box>
    </Box>
  );
}
