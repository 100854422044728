import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "services/api.service";
import { Asset } from "types/asset";

export default function useCreateAsset() {
  const navigate = useNavigate();

  const create = useCallback((asset: Asset) => {
    api.post("/assets", { asset }).then(() => {
      navigate("/assets");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    create,
  };
}
