import { useEffect, useState } from "react";
import { api } from "services/api.service";

export default function useEarningsReport() {
  const [isLoading, setIsLoading] = useState(false);
  const [earningsReport, setEarningsReport] = useState([]);

  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);
    api
      .get("/earnings/report")
      .then((res) => {
        setEarningsReport(res.data.report);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return {
    earningsReport,
    isLoading,
  };
}
