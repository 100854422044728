import { Box, Button, CircularProgress, FormControl, MenuItem, Tooltip, Typography } from "@mui/material";
import { AppSelect, InputBase, InputLabel } from "components/common/Input";
import theme from "lib/theme";
import useOrganization from "hooks/organizations/useOrganization";
import { useFormik } from "formik";
import useCurrentUserPermission from "hooks/useCurrentUserPermission";

function Overview() {
  const { organization, updateOrganization } = useOrganization();
  const { hasPermission } = useCurrentUserPermission();
  const formik = useFormik({
    initialValues: organization,
    enableReinitialize: true,
    onSubmit: (values) => values && updateOrganization(values),
  });
  if (!organization) {
    return (
      <Box
        sx={{
          border: "1px solid",
          borderColor: theme.palette.divider,
          background: "white",
          minHeight: "500px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <Typography variant="h4" fontWeight="bold">
        Company Information
      </Typography>

      <Box
        sx={{
          mt: 3,
          px: 5,
          py: 3,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography variant="h5" fontWeight="bold">
          Basic Information
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mt: 4, display: "grid", gridTemplateColumns: `1fr 1fr`, gap: 3 }}>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="company-name">
                Company Name
              </InputLabel>
              <InputBase type="text" id="company-name" placeholder="Name" {...formik.getFieldProps("name")} />
            </FormControl>

            <FormControl variant="standard">
              <InputLabel shrink htmlFor="industry">
                Industry
              </InputLabel>
              <InputBase
                type="text"
                id="industry"
                placeholder="ex. retail, manufacturing, technology"
                {...formik.getFieldProps("industry")}
              />
            </FormControl>

            <FormControl variant="standard">
              <InputLabel shrink htmlFor="companySize">
                End Users
              </InputLabel>
              <InputBase
                type="number"
                id="companySize"
                placeholder="Estimated # of Users"
                {...formik.getFieldProps("companySize")}
                value={formik.values.companySize ?? ""}
                onChange={(e) => {
                  e.preventDefault();
                  const { value } = e.target;
                  if (+value < 0) {
                    return;
                  }
                  formik.setFieldValue("companySize", value);
                }}
              />
            </FormControl>

            <FormControl variant="standard">
              <InputLabel shrink htmlFor="refresh-cycle">
                Refresh Cycle
              </InputLabel>
              <AppSelect id="refresh-cycle" placeholder="Select cycle" {...formik.getFieldProps("refreshCycle")}>
                <MenuItem value="five_years">5-year cycle</MenuItem>
                <MenuItem value="four_years">4-year cycle</MenuItem>
                <MenuItem value="three_years">3-year cycle</MenuItem>
                <MenuItem value="two_years">2-year cycle</MenuItem>
                <MenuItem value="one_year">1-year cycle</MenuItem>
              </AppSelect>
            </FormControl>
          </Box>

          <Box textAlign="right" mt={3}>
            <Tooltip
              title={
                hasPermission("companyInfo")
                  ? ""
                  : "You do not have permissions for this action, please contact your administrator for permission."
              }
            >
              <span>
                {formik.dirty ? (
                  <Button variant="outlined" type="submit" disabled={!hasPermission("companyInfo")}>
                    Save Updates
                  </Button>
                ) : null}
              </span>
            </Tooltip>
          </Box>
        </form>
      </Box>

      {/* {(organization.accountRep || organization.salesRep) && (
        <Box
          sx={{
            mt: 3,
            px: 5,
            py: 3,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
            maxWidth: "440px",
          }}
        >
          <Typography variant="h5" fontWeight="bold" color="primary">
            Your Revivn Representative
          </Typography>

          <Box mt={4} sx={{ display: "grid", gridTemplateColumns: "auto 1fr", gap: 4 }}>
            <img style={{ width: "113px", height: "64px" }} src={LogoIcon} alt=""></img>
            <Box>
              <Typography variant="h5" color="text.secondary" fontWeight="bold">
                {`${(organization.accountRep || organization.salesRep)?.firstName} ${
                  (organization.accountRep || organization.salesRep)?.lastName
                }`}
              </Typography>
              <Typography variant="h6" color="text.secondary" fontWeight="400">
                {(organization.accountRep || organization.salesRep)?.email}
              </Typography>

              <Button
                variant="contained"
                sx={{ my: 2 }}
                href={`mailto:${(organization.accountRep || organization.salesRep)?.email}`}
              >
                contact
              </Button>
            </Box>
          </Box>
        </Box>
      )} */}
    </div>
  );
}

export default Overview;
