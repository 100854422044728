import { Skeleton, TableCell, TableRow } from "@mui/material";

interface LoadingSkeletonProps {
  cellSpan: number;
  rowNumber?: number;
  cellHeight?: number | string;
}

export const TableLoadingSkeleton = ({ rowNumber = 10, cellHeight, cellSpan }: LoadingSkeletonProps) => {
  return (
    <>
      {Array.from({ length: rowNumber }).map((_, index) => (
        <TableRow key={index}>
          <TableCell colSpan={cellSpan}>
            <Skeleton variant="text" height={cellHeight} />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
