import { OpenInNew } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControlLabel,
  styled,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  useTheme,
  FormControl,
} from "@mui/material";
import PaywallModal from "components/PaywallModal";
import PlanFeatureWrapper from "components/PlanFeatureWrapper";
import ApiTable from "./ApiTable";
import { useEffect, useState } from "react";
import useFeatureFlag from "hooks/useFeatureFlag";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 29,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.primaryPro,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const ToggleButtonStyled = styled(ToggleButton)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

function ApiCredentials() {
  const { enabled } = useFeatureFlag("api_sandbox");
  let [isChecked, setChecked] = useState(!!localStorage.getItem("sandbox"));
  const theme = useTheme();

  useEffect(() => {
    if (isChecked === !!localStorage.getItem("sandbox")) return;
    if (isChecked) {
      localStorage.setItem("sandbox", "true");
    } else {
      localStorage.removeItem("sandbox");
    }
    window.location.reload();
  }, [isChecked]);

  const toggleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    console.log("newAlignment", newAlignment, event.target);
    if (newAlignment !== null) {
      setChecked(newAlignment === "sandbox");
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" mt={3}>
        <Box>
          <Typography variant="h5" fontSize={32} fontWeight="bold">
            API Credentials
          </Typography>

          <Typography variant="body1" mt={1}>
            Manage your API Credentials
          </Typography>
        </Box>
      </Box>
      <Box
        mt={3}
        sx={{
          p: 4,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
          minHeight: "750px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {enabled && (
            <Box display="flex" gap={2}>
              <PlanFeatureWrapper
                feature="api"
                enabledElement={
                  <ToggleButtonGroup color="primary" size="small" onChange={toggleChange} exclusive>
                    <ToggleButtonStyled selected={!isChecked} value="production">
                      Production
                    </ToggleButtonStyled>
                    <ToggleButtonStyled selected={isChecked} value="sandbox">
                      Sandbox
                    </ToggleButtonStyled>
                  </ToggleButtonGroup>
                }
                disabledElement={
                  <>
                    <PaywallModal
                      feature={"apiTable"}
                      trigger={(props) => (
                        <FormControlLabel
                          control={<AntSwitch sx={{ marginRight: 2 }} checked={false} color="primaryPro" {...props} />}
                          label="Sandbox/Production"
                        />
                      )}
                    />
                  </>
                }
                grandfatheredElement={
                  <FormControlLabel
                    control={
                      <AntSwitch
                        sx={{ marginRight: 2 }}
                        checked={isChecked}
                        onChange={(_, v) => setChecked(v)}
                        color="primaryPro"
                      />
                    }
                    label="Sandbox/Production"
                  />
                }
              />
            </Box>
          )}
          {/* Placeholder div to keep the same styling */}
          <div></div>

          <Box sx={{ display: "flex", gap: 2 }}>
            {/* <Button
              variant="outlined"
              startIcon={<OpenInNew />}
              href={process.env.REACT_APP_API_GETTING_STARTED_URL || "#"}
            >
              Getting Started
            </Button> */}
            <Button
              target="_blank"
              rel="noreferrer"
              variant="outlined"
              startIcon={<OpenInNew />}
              href={process.env.REACT_APP_API_DOCUMENTS_URL || "#"}
            >
              API Documentation
            </Button>
          </Box>
        </Box>
        <Box mt={4}>
          <ApiTable />
        </Box>
      </Box>
    </>
  );
}

export default ApiCredentials;
