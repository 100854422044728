import useDirectUpload from "hooks/useDirectUpload";
import { useEffect, useState } from "react";
import { api } from "services/api.service";
import useSWRMutation from "swr/mutation";
import CSVFileValidator, { RowError } from "csv-file-validator";
import { AssetRetrievalBulkLog } from "types/assetRetrievalBulkLog";
import { useAlert } from "hooks/alerts/useAlert";
import { Blob } from "activestorage";

const headersArray = [
  { name: "Manufacturer", inputName: "Manufacturer", required: true },
  { name: "Model", inputName: "Model", required: true },
  { name: "Serial No", inputName: "Serial No", required: true },
  {
    name: "Insurance(0, 1000, 2000, 3000, 4000, 5000)",
    inputName: "Insurance(0, 1000, 2000, 3000, 4000, 5000)",
    required: true,
    validate: (value: any) => ["0", "1000", "2000", "3000", "4000", "5000"].includes(value),
    validateError: () => {
      return "Invalid insurance. Please select one of the available options (0, 1000, 2000, 3000, 4000, 5000) ";
    },
  },
  { name: "First Name (To)", inputName: "First Name (To)", required: true },
  { name: "Last Name (To)", inputName: "Last Name (To)", required: true },
  { name: "Phone Number", inputName: "Phone Number", optional: true },
  {
    name: "Employee Email",
    inputName: "Employee Email",
    required: true,
    validate: (value: any) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(value);
    },
  },
  { name: "Employee Address Line 1", inputName: "Employee Address Line 1", required: true },
  { name: "Employee Address Line 2", inputName: "Employee Address Line 2", required: false },
  { name: "City", inputName: "City", required: true },
  { name: "State", inputName: "State", required: true },
  {
    name: "Zip Code",
    inputName: "Zip Code",
    required: true,
    validate: (value: any) => {
      const zipCodeRegex = /^\d{5}(-\d{4})?$/;
      return zipCodeRegex.test(String(value));
    },
    validateError: (headerName: string, rowNumber: number, columnNumber: number) => {
      return `Invalid ${headerName} at row ${rowNumber}, column ${columnNumber}. Please enter a 5-digit or 9-digit US ZIP code.. Note: When exporting a CSV, a leading 0 might be removed from the ZIP code. To fix this, format the ZIP code column as 'Text' in your spreadsheet software before exporting`;
    },
  },
  { name: "Country", inputName: "Country", required: true },
];

const config = {
  headers: headersArray,
  isHeaderNameOptional: false,
  isColumnIndexAlphabetic: false,
};

const pollValidationLogCount = async (blob: Blob, rowCount: number) => {
  const polling = new Promise<Blob>((resolve, reject) => {
    const interval = setInterval(() => {
      api.get(`/asset_retrieval_bulk_logs/log_count?file_signed_id=${blob.signed_id}`).then((response) => {
        if (response.data.count === rowCount) {
          clearInterval(interval);
          resolve(blob);
        }
      });
    }, 1000);
  });
  const timeout = new Promise((_, reject) => {
    setTimeout(() => reject(new Error("We had an issue validating addresses, please reupload the file")), 1000000);
  });

  return Promise.race([polling, timeout]);
};

export default function useAssetRetrievalBulkValidation() {
  const { blob, uploadAsync, reset } = useDirectUpload({ keyPrefix: "/assets_retrievals/file/" });
  const [clientValidationErrors, setClientValidationErrors] = useState<RowError[]>([]);
  const [serverValidationErrors, setServerValidationErrors] = useState<AssetRetrievalBulkLog[]>([]);
  const { alertError } = useAlert();
  useEffect(() => {
    setClientValidationErrors([]);
    setServerValidationErrors([]);
  }, [blob]);

  const { trigger: validate, isMutating } = useSWRMutation(
    "csv bulk validation",
    async (key, { arg: { file } }: { arg: { file: File } }): Promise<{ blob: Blob; rowCount: number }> => {
      try {
        const csvData = await CSVFileValidator(file, config);

        if (csvData.inValidData.length > 0) {
          setClientValidationErrors(csvData.inValidData);
          throw new Error("Invalid CSV data, please refer to the error logs.");
        }
        const rowCount = csvData.data.length;
        const blob = await uploadAsync(file);

        await api.post("/asset_retrieval_bulk_uploads/validate", {
          assetRetrievalBulkUpload: { file_signed_id: blob.signed_id },
        });
        await pollValidationLogCount(blob, rowCount);

        const response = await api.get(`/asset_retrieval_bulk_logs/validation_errors?file_signed_id=${blob.signed_id}`);

        setServerValidationErrors(response.data.erroredLogs);
        return { blob: blob, rowCount: rowCount };
      } catch (error: any) {
        alertError(error.message.toString());
        throw error;
      }
    }
  );
  return {
    isMutating,
    validate,
    serverValidationErrors,
    clientValidationErrors,
    blob,
    reset,
    setClientValidationErrors,
  };
}
