import { Box, Typography } from "@mui/material";
import { chartColors } from "components/common/charts/chartsUtils";
import useReports from "hooks/reports/useReports";
import theme from "lib/theme";
import { Doughnut, Bar } from "react-chartjs-2";

export default function StandardReports() {
  const { reports } = useReports();

  return (
    <>
      <Typography variant="h4" fontWeight="bold">
        Reporting
      </Typography>
      <Typography mt={1}>Check out some of the stats we pulled together just for you! </Typography>
      <Box sx={{ mt: 3, display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}>
        <Box
          sx={{
            px: 3,
            py: 2,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,

            display: "grid",
            gridTemplateRows: "auto 1fr",
            gap: 1,
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Retired Assets by Manufacturer
          </Typography>

          <Box sx={{ height: "270px" }}>
            <Doughnut
              data={{
                labels: reports && reports.retiredAssetsInfo.manufacturers.map(({ manufacturer }: any) => manufacturer),
                datasets: [
                  {
                    data: reports && reports.retiredAssetsInfo.manufacturers.map(({ count }: any) => count),
                    backgroundColor: chartColors,
                    hoverBackgroundColor: chartColors,
                    borderWidth: 4,
                    borderColor: "white",
                    hoverBorderColor: "white",
                    borderRadius: 33,
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                cutout: "85%",
                layout: {},
                plugins: {
                  legend: {
                    position: "bottom",
                  },
                },
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            px: 3,
            py: 2,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,

            display: "grid",
            gridTemplateRows: "auto 1fr",
            gap: 1,
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Retired Assets by Type
          </Typography>

          <Box sx={{ height: "270px" }}>
            <Bar
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  x: {
                    grid: { display: false },
                  },
                  y: {
                    grid: { display: false },
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
              data={{
                labels: reports && reports.retiredAssetsInfo.deviceTypes.map(({ deviceType }: any) => deviceType),
                datasets: [
                  {
                    label: "Dataset 2",
                    data: reports && reports.retiredAssetsInfo.deviceTypes.map(({ count }: any) => count),
                    borderColor: "#0089BD",
                    borderRadius: Number.MAX_VALUE,
                    backgroundColor: "#0089BD",
                    barPercentage: 0.3,
                  },
                ],
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
