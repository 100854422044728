import { useState } from "react";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import theme from "lib/theme";
import { Box, Button } from "@mui/material";
import { useStripePaymentMethod } from "hooks/paymentMethods/useStripePaymentMethod";

const SetupForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<StripeError>();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return null;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.toString()}&paymentCallback=true`,
      },
    });

    if (error) {
      setErrorMessage(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Box mt={2} mb={2} display="flex" justifyContent="flex-end">
        <Button type="submit" variant="text" disabled={!stripe} sx={{ mx: 2 }}>
          Cancel
        </Button>
        <Button type="submit" variant="contained" disabled={!stripe}>
          Save
        </Button>
      </Box>
      {errorMessage && <div>{errorMessage.message}</div>}
    </form>
  );
};

export default function NewPaymentMethod() {
  const { stripePromise, stripeIntentSecret } = useStripePaymentMethod();
  if (!stripeIntentSecret) return null;

  return (
    <Box
      mt={3}
      position="relative"
      paddingTop={5}
      paddingX={5}
      paddingBottom={2}
      sx={{
        background: "white",
        borderRadius: 1,
        border: `1px solid ${theme.palette.divider}`,
        minHeight: "105px",
      }}
    >
      <Elements stripe={stripePromise} options={{ clientSecret: stripeIntentSecret }}>
        <SetupForm />
      </Elements>
    </Box>
  );
}
