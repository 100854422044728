import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "services/api.service";

export default function useCreatePickup() {
  const navigate = useNavigate();

  type CreatePickupProps = {
    locationId: string;
  };

  const createPickup = useCallback(
    ({ locationId }: CreatePickupProps) =>
      api
        .post("/pickups", {
          pickup: {
            locationId,
          },
        })
        .then((res: any) => {
          navigate(`/pickups/${res.data.uuid}`, { replace: true });
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    createPickup,
  };
}
