import { Box, Button, Typography } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid-pro";

type SelectAllBunnerProps = {
  selectedAssetIds: GridSelectionModel;
  selectAllFlag?: boolean;
  meta: {
    count: number;
  };
  setSelectAllFlag?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedAssetIds: React.Dispatch<React.SetStateAction<GridSelectionModel>>;
  setHeaderSelectAll: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function SelectAllAssetsBanner({
  setSelectedAssetIds,
  setHeaderSelectAll,
  selectedAssetIds,
  setSelectAllFlag,
  selectAllFlag,
  meta,
}: SelectAllBunnerProps) {
  const handleDeselect = () => {
    setSelectAllFlag?.(!selectAllFlag);
    setSelectedAssetIds([]);
    setHeaderSelectAll(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        px: 1,
        backgroundColor: "#e3f2fd",
      }}
    >
      {selectAllFlag ? (
        <>
          <Typography>{`You have selected all ${meta.count} assets.`}</Typography>
          <Button variant="text" onClick={handleDeselect}>
            clear selection
          </Button>
        </>
      ) : (
        <>
          <Typography>{`You have selected ${selectedAssetIds.length} assets on this page.`}</Typography>
          <Button variant="text" onClick={() => setSelectAllFlag?.(!selectAllFlag)}>
            {`select all ${meta.count} assets`}
          </Button>
        </>
      )}
    </Box>
  );
}
