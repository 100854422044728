import { Box, Typography, Autocomplete } from "@mui/material";
import { InputBase } from "components/common/Input";
import usePickup from "hooks/pickups/usePickup";
import useUpdatePickup from "hooks/pickups/useUpdatePickup";
import useUsers from "hooks/users/useUsers";
import { useEffect, useState } from "react";

export default function PickupOtherContacts() {
  const { pickup } = usePickup();
  const { updatePickup } = useUpdatePickup();
  const { users } = useUsers();
  let [emails, setEmails] = useState<string[]>([]);
  let [userEmails, setUserEmails] = useState<string[]>([]);

  useEffect(() => {
    const contacts = pickup?.pickupContacts.filter((contact) => contact.pointOfContactType !== "main");
    if (!contacts) return;
    const contactEmails = contacts.map((contact) => {
      return contact.email;
    });
    setEmails(contactEmails);
  }, [pickup]);

  useEffect(() => {
    const contactEmails = users?.map((contact) => {
      return contact.email;
    });
    if (!contactEmails) return;

    setUserEmails(contactEmails);
  }, [users]);

  return (
    <Box>
      <Typography variant="h6" fontSize="18px" fontWeight="bold">
        6. Choose the contacts you’d like us to send updates to for this pickup
      </Typography>
      <Typography mt={0.5} variant="body2">
        (Optional) Enter the emails of people you’d like to keep in the loop about this pickup - we’ll send them
        updates, reports, and anything else that may be important. Don’t worry, we’ll include you as well on the email
        communications!
      </Typography>
      <Box mt={3}>
        <Typography fontSize="14px" fontWeight="600">
          Enter email addresses
        </Typography>
        <Autocomplete
          multiple
          freeSolo
          value={emails}
          options={userEmails}
          renderInput={(params) => <InputBase {...params} placeholder="name@company.com..." />}
          onChange={(event: any, newValue: any) => {
            setEmails(newValue);
            const payload = newValue.map((email: string) => {
              return { pointOfContactType: "notifications", email: email };
            });
            updatePickup({ contacts: payload });
          }}
        />
      </Box>
    </Box>
  );
}
