import { Box, Button, Link } from "@mui/material";
import { LocationsList } from "pages/CompanySettings/Locations/List";
import { useNavigate } from "react-router-dom";

function AddLocations() {
  let navigate = useNavigate();

  return (
    <div>
      <LocationsList />

      <Box mt={4}>
        {/* <LocationsSection
          onAddLocation={() => {
            navigate("/user-setup/add-locations/create");
          }}
        /> */}
      </Box>

      <Box mt={4} sx={{ display: "flex", flexDirection: "column", alignItems: "start", gap: 2 }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="outlined"
            onClick={() => {
              navigate("/user-setup/company-info");
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              navigate("/user-setup/connect-assets");
            }}
          >
            Next
          </Button>
        </Box>
        <Link
          component="button"
          underline="hover"
          variant="button"
          onClick={() => {
            navigate("/user-setup/connect-assets");
          }}
        >
          Skip
        </Link>
      </Box>
    </div>
  );
}

export default AddLocations;
