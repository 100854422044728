import { Box, Alert, List, ListItemText, Typography } from "@mui/material";
import { AssetRetrievalBulkLog } from "types/assetRetrievalBulkLog";

interface AddressValidationErrorsProps {
  clientValidationErrors?: { rowIndex?: number; columnIndex?: number; message: string }[];
  serverValidationErrors?: AssetRetrievalBulkLog[];
}

export default function AddressValidationErrors({
  clientValidationErrors,
  serverValidationErrors,
}: AddressValidationErrorsProps) {
  return (
    <Box
      sx={{
        my: 2,
        border: "1px solid #ef9a9a",
        borderRadius: "4px",
        p: 1,
        height: (clientValidationErrors?.length || serverValidationErrors?.length) === 3 ? "auto" : "300px",
        overflow: "auto",
      }}
    >
      {clientValidationErrors && clientValidationErrors.length > 0 && (
        <>
          <Alert severity="error">
            We detected errors in your uploaded file. If necessary, consult with the employee for the correct address
            and update the file before uploading again or remove the incorrect lines from the CSV, and process another
            order later.
          </Alert>
          <List>
            {clientValidationErrors.map((error, index) => (
              <ListItemText key={index}>- {error.message}</ListItemText>
            ))}
          </List>
        </>
      )}

      {serverValidationErrors && serverValidationErrors.length > 0 && (
        <>
          <Alert severity="error">
            We detected errors in your uploaded file. If necessary, consult with the employee for the correct address
            and update the file before uploading again or remove the incorrect lines from the CSV, and process another
            order later.
          </Alert>
          <List sx={{ listStyleType: "disc", px: "40px" }}>
            {serverValidationErrors.map((error, index) => {
              let validation = JSON.parse(error.validationErrors);
              return validation.map((validation: any, index: number) => {
                return (
                  <ListItemText key={index} sx={{ display: "list-item" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="body2" fontWeight={600}>
                        csv row: {error.rowNumber}{" "}
                      </Typography>
                      <Typography variant="body2" color={"black"}>
                        - error message: {validation.message}
                      </Typography>
                    </Box>
                  </ListItemText>
                );
              });
            })}
          </List>
        </>
      )}
    </Box>
  );
}
