import { Computer } from "@mui/icons-material";
import { Box, Typography, Button } from "@mui/material";
import { Modal } from "components/common/Modal";

interface LockedDeviceModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  refetch: () => void;
  markPickupUnenrolled: any;
  uuid: string;
}

export function LockedDeviceModal({ open, setOpen, uuid, markPickupUnenrolled }: LockedDeviceModalProps) {
  const handleClick = () => {
    markPickupUnenrolled(uuid);
    setOpen(false);
  };
  return (
    <Modal open={open} setOpen={setOpen}>
      <Box>
        <Box sx={{ textAlign: "center" }}>
          <Computer color="primary" sx={{ fontSize: "60px" }} />
          <Typography mt={1} variant="h5" component="h2" fontWeight={800}>
            Are you sure you want to mark all the assets in this pickup as unlocked?
          </Typography>
          <Typography mt={0.5} color="text.secondary">
            Revivn will verify each device by turning them on and confirming they have been unlocked.
          </Typography>

          <Box mt={3} sx={{ display: "flex", gap: 1, alignItems: "center", justifyContent: "center" }}>
            <Button variant="outlined" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={() => handleClick()}>
              proceed
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
