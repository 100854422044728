import { useAlert } from "hooks/alerts/useAlert";
import { useEffect, useState } from "react";
import { api } from "services/api.service";
import { AssetRetrievalReminderConfig } from "types/assetRetrieverReminderConfig";

export default function useAssetRetrievalRemindersConfiguration() {
  const [assetRetrievalReminderConfig, setAssetRetrievalReminderConfig] = useState<AssetRetrievalReminderConfig>();
  const [key, setKey] = useState<string | null>(null);
  const { alertError, alertSuccess } = useAlert();

  useEffect(() => {
    fetchAssetReminderConfiguration();
  }, []);

  const fetchAssetReminderConfiguration = () => {
    api.get("/asset_retrieval_reminder_configuration").then(({ data }) => {
      setAssetRetrievalReminderConfig(data);
    });
  };

  const createAssetReminderConfiguration = (assetRetrievalReminderConfig: AssetRetrievalReminderConfig) => {
    const { followupCadence, followupEscalationCount, followupEscalationEmail } = assetRetrievalReminderConfig;
    return api
      .post("/asset_retrieval_reminder_configuration", {
        assetRetrievalReminderConfiguration: {
          followupCadence,
          followupEscalationCount,
          followupEscalationEmail,
          company_logo: key,
        },
      })
      .then(({ data }) => {
        setAssetRetrievalReminderConfig(data);
        alertSuccess("Configuration successfully saved.");
      })
      .catch(() => {
        alertError("Something went wrong, please try again.");
      });
  };

  const updateAssetReminderConfiguration = (assetRetrievalReminderConfig: AssetRetrievalReminderConfig) => {
    const { followupCadence, followupEscalationCount, followupEscalationEmail } = assetRetrievalReminderConfig;
    return api
      .put("/asset_retrieval_reminder_configuration", {
        assetRetrievalReminderConfiguration: {
          followupCadence,
          followupEscalationCount,
          followupEscalationEmail,
          company_logo: key,
        },
      })
      .then(({ data }) => {
        setAssetRetrievalReminderConfig(data);
        alertSuccess("Configuration successfully updated.");
      })
      .catch(() => {
        alertError("Something went wrong, please try again.");
      });
  };

  const deleteAssetReminderConfiguration = () => {
    api
      .delete(`/asset_retrieval_reminder_configuration/company_logo`)
      .then(() => {
        fetchAssetReminderConfiguration();
      })
      .catch(() => {
        alertError("Something went wrong, please try again.");
      });
  };

  return {
    assetRetrievalReminderConfig,
    createAssetReminderConfiguration,
    updateAssetReminderConfiguration,
    deleteAssetReminderConfiguration,
    key,
    setKey,
  };
}
