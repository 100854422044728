import { Box, FormControl, MenuItem } from "@mui/material";
import { AppSelect, InputBase, InputLabel, RequiredSymbol } from "components/common/Input";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import theme from "lib/theme";
import { useCallback, useEffect, useState } from "react";
import AssetCart from "./AssetCart";
import { useFormikContext } from "formik";
import { AssetRetrieval } from "hooks/assetRetrievals/useAssetRetrieval";
import { useValidateAddress } from "hooks/useValidateAddress";
import { useAlert } from "hooks/alerts/useAlert";
import ReturnAddressRadioGroup from "./ReturnAddressRadioGroup";

interface OrderDetailsProps {
  goToNextStep: () => void;
}

function OrderDetails({ goToNextStep }: OrderDetailsProps) {
  const { validateAssetRetrievalAddress, validateAssetRetrievalReturnAddress } = useValidateAddress();
  const { alertError } = useAlert();
  const [isValidating, setIsValidation] = useState(false);
  const [customReturnLabel, setCustomReturnLabel] = useState(false);

  const { getFieldProps, values, errors, touched, setFieldError, setValues } = useFormikContext<AssetRetrieval>();

  const validateAddressAndGoToNextStep = useCallback(() => {
    setIsValidation(true);
    validateAssetRetrievalAddress(values)
      .then(() => {
        if (customReturnLabel) {
          validateAssetRetrievalReturnAddress(values)
            .then(() => {
              goToNextStep();
            })
            .catch((error) => {
              setFieldError("returnRecipientAddress", "Invalid Address");
              setFieldError("returnRecipientAddressTwo", "Invalid Address");
              setFieldError("returnRecipientAddressCity", "Invalid Address");
              setFieldError("returnRecipientAddressState", "Invalid Address");
              setFieldError("returnRecipientAddressZipCode", "Invalid Address");
              alertError(error.response.data.errors[0].message);
            })
            .finally(() => {
              setIsValidation(false);
            });
        } else {
          goToNextStep();
        }
      })
      .catch((error) => {
        setFieldError("employeeAddress", "Invalid Address");
        setFieldError("employeeAddressTwo", "Invalid Address");
        setFieldError("employeeAddressCity", "Invalid Address");
        setFieldError("employeeAddressState", "Invalid Address");
        setFieldError("employeeAddressZipCode", "Invalid Address");
        alertError(error.response.data.errors[0].message);
      })
      .finally(() => {
        setIsValidation(false);
      });
  }, [values]);

  useEffect(() => {
    if (values.virtualPickup || values.locationId) {
      setValues((prevValues) => ({
        ...prevValues,
        returnRecipientName: "",
        returnRecipientFirstName: "",
        returnRecipientLastName: "",
        returnRecipientCompany: "",
        returnRecipientAddress: "",
        returnRecipientAddressTwo: "",
        returnRecipientAddressCity: "",
        returnRecipientAddressState: "",
        returnRecipientAddressZipCode: "",
        returnRecipientAddressCountry: "",
      }));
    }
  }, [values.virtualPickup, values.locationId]);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "2fr 1fr",
        gap: 4,
        alignItems: "start",
        [theme.breakpoints.down("md")]: { gridTemplateColumns: "1fr" },
      }}
    >
      <div>
        <Box
          sx={{
            py: 3,
            px: 4,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
            [theme.breakpoints.down("lg")]: { px: 2, py: 2 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            <ArrowForwardIcon />
            Where the box is being sent
          </Box>
          <Box mt={2} sx={{ display: "grid", gridTemplateColumns: "2fr 2fr", gap: 4 }}>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="employee-first-name">
                First Name <RequiredSymbol />
              </InputLabel>
              <InputBase
                type="text"
                {...getFieldProps("employeeFirstName")}
                error={touched.employeeFirstName && !!errors.employeeFirstName}
                helperText={touched.employeeFirstName && errors.employeeFirstName}
              />
            </FormControl>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="employee-last-name">
                Last Name <RequiredSymbol />
              </InputLabel>
              <InputBase
                type="text"
                {...getFieldProps("employeeLastName")}
                error={touched.employeeLastName && !!errors.employeeLastName}
                helperText={touched.employeeLastName && errors.employeeLastName}
              />
            </FormControl>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="employee-phone-number">
                Phone Number
              </InputLabel>
              <InputBase
                type="phone"
                {...getFieldProps("employeePhone")}
                error={touched.employeePhone && !!errors.employeePhone}
                helperText={touched.employeePhone && errors.employeePhone}
              />
            </FormControl>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="employee-email">
                Email <RequiredSymbol />
              </InputLabel>
              <InputBase
                type="email"
                {...getFieldProps("employeeEmail")}
                error={touched.employeeEmail && !!errors.employeeEmail}
                helperText={touched.employeeEmail && errors.employeeEmail}
              />
            </FormControl>
          </Box>

          <Box mt={2} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="address-line-1">
                Employee address line 1 <RequiredSymbol />
              </InputLabel>
              <InputBase
                type="text"
                {...getFieldProps("employeeAddress")}
                error={touched.employeeAddress && !!errors.employeeAddress}
                helperText={touched.employeeAddress && errors.employeeAddress}
              />
            </FormControl>

            <FormControl variant="standard">
              <InputLabel shrink htmlFor="address-line-2">
                Employee address line 2
              </InputLabel>
              <InputBase type="text" {...getFieldProps("employeeAddressTwo")} />
            </FormControl>

            <Box sx={{ display: "grid", gridTemplateColumns: "2fr 2fr", gap: 2 }}>
              <FormControl variant="standard">
                <InputLabel shrink htmlFor="city">
                  City <RequiredSymbol />
                </InputLabel>
                <InputBase
                  type="text"
                  {...getFieldProps("employeeAddressCity")}
                  error={touched.employeeAddressCity && !!errors.employeeAddressCity}
                  helperText={touched.employeeAddressCity && errors.employeeAddressCity}
                />
              </FormControl>

              <FormControl variant="standard">
                <InputLabel shrink htmlFor="state">
                  State <RequiredSymbol />
                </InputLabel>
                <InputBase
                  type="text"
                  {...getFieldProps("employeeAddressState")}
                  error={touched.employeeAddressState && !!errors.employeeAddressState}
                  helperText={touched.employeeAddressState && errors.employeeAddressState}
                />
              </FormControl>

              <FormControl variant="standard">
                <InputLabel shrink htmlFor="zip-code">
                  Zip Code <RequiredSymbol />
                </InputLabel>
                <InputBase
                  type="text"
                  {...getFieldProps("employeeAddressZipCode")}
                  error={touched.employeeAddressZipCode && !!errors.employeeAddressZipCode}
                  helperText={touched.employeeAddressZipCode && errors.employeeAddressZipCode}
                />
              </FormControl>

              <FormControl variant="standard">
                <InputLabel shrink htmlFor="country">
                  Country <RequiredSymbol />
                </InputLabel>
                <AppSelect {...getFieldProps("employeeAddressCountry")}>
                  <MenuItem value="United States">United States</MenuItem>
                </AppSelect>
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 6,
            py: 3,
            px: 4,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
            [theme.breakpoints.down("lg")]: { px: 2, py: 2 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            <ArrowForwardIcon />
            Where the box is being returned to
          </Box>
          <ReturnAddressRadioGroup setCustomReturnLabel={setCustomReturnLabel} customReturnLabel={customReturnLabel} />
        </Box>
      </div>
      <AssetCart
        onContinue={validateAddressAndGoToNextStep}
        disableContinue={
          Boolean(
            errors.employeeFirstName ||
              errors.employeeLastName ||
              errors.employeePhone ||
              errors.employeeEmail ||
              errors.employeeAddress ||
              errors.employeeAddressCity ||
              errors.employeeAddressState ||
              errors.employeeAddressZipCode ||
              errors.locationId ||
              isValidating
          ) ||
          Boolean(
            errors.returnRecipientFirstName ||
              errors.returnRecipientLastName ||
              errors.returnRecipientAddress ||
              errors.returnRecipientAddressCity ||
              errors.returnRecipientAddressState ||
              errors.returnRecipientAddressZipCode ||
              errors.returnRecipientAddressCountry
          ) ||
          Boolean(errors.locationId || isValidating)
        }
      />
    </Box>
  );
}

export default OrderDetails;
