import { Box, Button, FormControl, MenuItem, Typography } from "@mui/material";
import { AppSelect, InputBase, InputLabel } from "components/common/Input";
import SettingsIcon from "@mui/icons-material/Settings";
import { Modal } from "components/common/Modal";
import { useFormik } from "formik";
import { AssetRetrievalReminderConfig } from "types/assetRetrieverReminderConfig";
import UploadClientLogo from "./UploadClientLogo";
import React from "react";
import useAnalytics from "hooks/analytics/useAnalytics";

interface ReminderConfigurationModalProps {
  openReminder: boolean;
  setOpenReminder: React.Dispatch<React.SetStateAction<boolean>>;
  assetRetrievalReminderConfig: AssetRetrievalReminderConfig | undefined;
  createAssetReminderConfiguration: (assetRetrievalReminderConfig: AssetRetrievalReminderConfig) => Promise<void>;
  updateAssetReminderConfiguration: (assetRetrievalReminderConfig: AssetRetrievalReminderConfig) => Promise<void>;
  deleteAssetReminderConfiguration: () => void;
  setKey: (key: string) => void;
}

export default function ReminderConfigurationModal({
  openReminder,
  setOpenReminder,
  assetRetrievalReminderConfig,
  createAssetReminderConfiguration,
  updateAssetReminderConfiguration,
  deleteAssetReminderConfiguration,
  setKey,
}: ReminderConfigurationModalProps) {
  const { createAnalyticsEvent } = useAnalytics();
  const { getFieldProps, isSubmitting, setSubmitting, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: assetRetrievalReminderConfig ?? {
      followupCadence: undefined as unknown as number,
      followupEscalationCount: undefined as unknown as number,
      followupEscalationEmail: "",
    },
    onSubmit: (values) => {
      assetRetrievalReminderConfig
        ? updateAssetReminderConfiguration(values).finally(() => {
            setOpenReminder(false);
            setSubmitting(false);
          })
        : createAssetReminderConfiguration(values).finally(() => {
            createAnalyticsEvent("asset_retrieval_configuration_created");
            setOpenReminder(false);
            setSubmitting(false);
          });
    },
  });

  return (
    <Modal open={openReminder} setOpen={setOpenReminder}>
      <Box sx={{ px: 3, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <SettingsIcon
          color="primary"
          sx={{
            fontSize: "50px",
            mb: 3,
          }}
        />
        <Typography variant="h4" fontWeight="bold" mb={3}>
          Configure Reminders
        </Typography>
        <form onSubmit={handleSubmit}>
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink htmlFor="employee-name" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              Send follow-up every:
            </InputLabel>
            <AppSelect {...getFieldProps("followupCadence")}>
              <MenuItem value={7}>1 week</MenuItem>
              <MenuItem value={14}>2 week</MenuItem>
              <MenuItem value={21}>3 week</MenuItem>
              <MenuItem value={28}>4 week</MenuItem>
            </AppSelect>
          </FormControl>

          <Box sx={{ display: "flex", gap: 2, alignItems: "center", mt: 4, mb: 4 }}>
            <div>After</div>
            <InputBase
              type="text"
              sx={{ width: "124px" }}
              placeholder="# of follow-ups"
              {...getFieldProps("followupEscalationCount")}
            />
            <div>send an escalation email to</div>
            <InputBase
              type="email"
              sx={{ width: "164px" }}
              placeholder="email address"
              {...getFieldProps("followupEscalationEmail")}
            />
          </Box>

          <FormControl variant="standard" fullWidth>
            <InputLabel shrink htmlFor="employee-name" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              Add your company logo to the reminders:
            </InputLabel>
            <UploadClientLogo
              fileUploaded={setKey}
              assetRetrievalReminderConfig={assetRetrievalReminderConfig}
              deleteAssetReminderConfiguration={deleteAssetReminderConfiguration}
            />
          </FormControl>

          <Box sx={{ display: "flex", gap: 2, justifyContent: "end", mt: 2, width: "100%" }}>
            <Button type="submit" variant="contained" color="primary" disableElevation disabled={isSubmitting}>
              save
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
}
