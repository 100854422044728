import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Breadcrumbs from "components/common/Breadcrumbs";
import UploadAssets from "pages/UserSetup/UploadAssets";
import useAssetUpload from "hooks/useAssetUpload";
import theme from "lib/theme";
import { Download as DownloadIcon } from "@mui/icons-material";

export default function UploadCSV() {
  const { key, setKey, create, submitting } = useAssetUpload();

  return (
    <div>
      <Box>
        <Breadcrumbs
          data={[{ label: "Dashboard", to: "/" }, { label: "Assets", to: "/assets" }, { label: "Upload CSV" }]}
        ></Breadcrumbs>
      </Box>

      <Box mt={4} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Box mt={3}>
          <Button startIcon={<ArrowBack />} component={Link} to="/assets">
            back to assets
          </Button>
          <Typography sx={{ mt: 4.5 }} variant="h4" fontWeight="bold">
            Add Multiple Assets
          </Typography>
        </Box>

        <img src={require("assets/images/monitor.png")} alt=""></img>
      </Box>

      <Box sx={{ p: 4, mt: 4, background: "white", borderRadius: 1, border: `1px solid ${theme.palette.divider}` }}>
        <a
          href={process.env.PUBLIC_URL + "/asset_upload_template.csv"}
          download
          style={{ textDecoration: "none", color: "#202020" }}
        >
          <Button variant="contained" component="label" startIcon={<DownloadIcon />}>
            Download CSV Template
          </Button>
        </a>
        <Box mt={4}>
          <UploadAssets fileUploaded={setKey} />
        </Box>
      </Box>

      <Box textAlign="right" mt={3}>
        <Button variant="contained" onClick={create} disabled={!key || submitting}>
          add assets
        </Button>
      </Box>
    </div>
  );
}
