import { useAlert } from "hooks/alerts/useAlert";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { api } from "services/api.service";
import { Pickup } from "types/pickup";

export default function usePickups(skipFiltering: boolean = false) {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [pickups, setPickups] = useState<Pickup[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const { alertSuccess } = useAlert();

  const params = useMemo(() => {
    const _params = new URLSearchParams({ "page[number]": currentPage.toString() });
    if (skipFiltering) return _params;
    searchParams.forEach((value, key) => {
      _params.append(key, value);
    });
    return _params;
  }, [currentPage, searchParams]);

  const getPickups = () => {
    if (!hasNextPage) return;
    setIsLoading(true);
    api
      .get(`/pickups?${params.toString()}`)
      .then((response) => {
        setHasNextPage(response.data.pickups.length > 0);
        setPickups((oldPickups) => [...oldPickups, ...response.data.pickups]);
        setIsLoading(false);
      })
      .catch(function (error) {
        setErrorMessage(error);
      });
  };

  const incrementCurrentPage = () => {
    setCurrentPage((oldCurrentPage) => oldCurrentPage + 1);
  };

  useEffect(() => {
    getPickups();
  }, [params.toString(), hasNextPage]);

  useEffect(() => {
    if (skipFiltering) return;
    setCurrentPage(1);
    setPickups([]);
    setHasNextPage(true);
  }, [searchParams]);

  const deletePickup = (pickupUuid: string) =>
    api.delete(`/pickups/${pickupUuid}`).then(() => {
      const newPickups = pickups?.filter((pickup) => pickup.uuid !== pickupUuid);
      setPickups(newPickups);
    });

  const markPickupUnenrolled = (pickupUuid: string) => {
    api.put(`/pickups/${pickupUuid}/mark_unenrolled`).then(({ data }) => {
      alertSuccess("Thank you! We will verify your devices as soon as possible.");
      api
        .get(`/pickups`)
        .then((response) => {
          setPickups(response.data.pickups);
          setIsLoading(false);
        })
        .catch(function (error) {
          setErrorMessage(error);
        });
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps

  return {
    pickups,
    getPickups,
    deletePickup,
    isLoading,
    errorMessage,
    hasNextPage,
    markPickupUnenrolled,
    incrementCurrentPage,
  };
}
