import { ArrowBack, LocationOn, Mail } from "@mui/icons-material";
import { Box, Button, LinearProgress, Slider, Tooltip, Typography } from "@mui/material";
import theme from "lib/theme";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { formatNumberToCurrency } from "utils/helpers";
import useDonationRequest from "hooks/donations/useDonationRequest";
import Loading from "../../components/common/Loading";
import Error from "../../components/common/Error";
import useDonations from "hooks/donations/useDonations";
import LanguageIcon from "@mui/icons-material/Language";
import useEarnings from "hooks/earnings/useEarnings";
import useCurrentUserPermission from "hooks/useCurrentUserPermission";
import Confetti from "react-confetti";
import EmptyUseEarningsToDonate from "./EmptyUseEarningsToDonate";

function DonationRequestDetails() {
  const [progress, setProgress] = useState(0);
  const { donationRequest, isError, isLoading, totalDonated, getDonationRequest } = useDonationRequest();
  const { createDonation, isDonationPlaced } = useDonations();
  const { earningsBalance } = useEarnings();
  const [goalRemaining, setGoalRemaining] = useState(0);
  const [donationValue, setDonationValue] = useState(0);
  const { hasPermission } = useCurrentUserPermission();
  const isMounted = useRef(false);

  useEffect(() => {
    if (earningsBalance < 0) {
      setDonationValue(0);
    } else if (donationRequest && donationRequest.benefitingOrganization) {
      setProgress((totalDonated / donationRequest.totalRequestedAmountInDollars) * 100);
      setGoalRemaining(donationRequest.totalRequestedAmountInDollars - totalDonated);
      setDonationValue(earningsBalance > goalRemaining ? goalRemaining : earningsBalance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donationRequest, createDonation, isDonationPlaced, earningsBalance, goalRemaining]);

  useEffect(() => {
    if (isMounted.current) {
      getDonationRequest();
    } else {
      isMounted.current = true;
    }
  }, [isDonationPlaced, createDonation]);

  if (isError) {
    return <Error />;
  }

  if (isLoading || !donationRequest) {
    return <Loading />;
  }

  return (
    <>
      <Box
        sx={{ mt: 2, background: "white", borderRadius: 1, border: `1px solid ${theme.palette.divider}`, px: 6, py: 4 }}
      >
        <Box>
          <Button
            size="small"
            variant="text"
            startIcon={<ArrowBack />}
            component={Link}
            to={"/donations-and-earnings/donations"}
          >
            back to donations feed
          </Button>
        </Box>

        {/* thanks box */}
        {isDonationPlaced && (
          <Box textAlign="center" pt={4} pb={4} sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
            <Typography variant="h3" color="primary" fontWeight="bold">
              Thank You For Your Donation!
            </Typography>

            <Typography mt={4}>
              Your donation is greatly appreciated! We can't do it without you, and we value your willingness to be a
              helping hand. Revivn is committed to empowering the communities around us by providing them tech access
              and your contributions go a long way in enabling us to do that!
            </Typography>
          </Box>
        )}

        <Typography mt={4} variant={"h4"} fontWeight="bold">
          {donationRequest.benefitingOrganization.name}
        </Typography>

        <Box mt={4} sx={{ display: "grid", gridTemplateColumns: `auto 1fr`, gap: 6 }}>
          <img
            src={donationRequest.heroUrl}
            alt=""
            style={{
              width: 500,
              height: 362,
              //  objectFit: "cover",
              //   objectPosition: "center"
            }}
          />

          <Box>
            <Typography variant={"body2"} fontWeight="bold">
              {`${formatNumberToCurrency(totalDonated)} donated out of ${formatNumberToCurrency(
                donationRequest.totalRequestedAmountInDollars
              )} goal - Help them reach their goal!`}
            </Typography>

            <Box mt={2}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>

            <Box sx={{ mt: 4 }}>
              <Typography variant="body1" fontWeight="bold" color="primary">
                Contact
              </Typography>
              <Box sx={{ display: "grid", gridTemplateColumns: "auto 1fr", gap: 2, mt: 1 }}>
                <Typography variant={"body1"} color="primary">
                  <Mail />
                </Typography>
                <Box>
                  <Typography variant={"body1"}>{donationRequest.mainContactEmail}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: "grid", gridTemplateColumns: "auto 1fr", gap: 2, mt: 1 }}>
                <Typography variant={"body1"} color="primary">
                  <LanguageIcon />
                </Typography>
                <Box>
                  <Typography variant={"body1"}>{donationRequest.benefitingOrganization.websiteUrl}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: "grid", gridTemplateColumns: "auto 1fr", gap: 2, mt: 1 }}>
                <Typography variant={"body1"} color="primary">
                  <LocationOn />
                </Typography>
                <Box>
                  <Typography variant={"body1"}>{donationRequest.benefitingOrganization.name}</Typography>
                  <Typography variant={"body1"}>{donationRequest.address}</Typography>
                  <Typography variant={"body1"}>{donationRequest.address2}</Typography>
                  <Typography variant={"body1"}>{`${donationRequest.zipcode}, ${donationRequest.city}`}</Typography>
                  <Typography variant={"body1"}>{donationRequest.country}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box mt={5}>
          <Typography color="primary" fontWeight="bold">
            {donationRequest.goalName}
          </Typography>
          <Typography mb={6}>
            {`${donationRequest.goalDescription}
             Learn more at
            ${donationRequest.benefitingOrganization.websiteUrl}.`}
          </Typography>
        </Box>
      </Box>

      {!isDonationPlaced && earningsBalance > 0 ? (
        <Box
          sx={{
            mt: 4,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
            px: 6,
            py: 5,
          }}
        >
          <Box sx={{ maxWidth: "600px", width: "100%", mx: "auto" }}>
            <Typography variant={"h4"} fontWeight="bold">
              Use Earnings
            </Typography>
            <Typography mt={1} color="text.secondary">
              Use your earnings to help this organization reach their goal by using the slider to adjust the amount.
            </Typography>
            <Typography color="primary" fontWeight="bold" mt={1}>
              Your earnings: {formatNumberToCurrency(earningsBalance)}
            </Typography>
            <Box mt={8} sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
              <Slider
                valueLabelDisplay="on"
                size="small"
                value={donationValue}
                marks
                step={earningsBalance < 25 ? 1 : 25}
                onChange={(e, v) => !Array.isArray(v) && setDonationValue(v)}
                min={0}
                max={earningsBalance > goalRemaining ? goalRemaining : earningsBalance}
                aria-label="Small"
                valueLabelFormat={(v) => formatNumberToCurrency(v)}
                sx={{
                  ".MuiSlider-mark ": {
                    opacity: "0",
                  },
                  ".MuiSlider-mark": {
                    opacity: "0",
                  },
                }}
              />
            </Box>
            <Box display="flex" justifyContent={"end"} mt={4}>
              <Typography
                variant="body2"
                fontWeight="600"
                color="text.secondary"
                sx={{ position: "relative", top: "-12px" }}
              >
                Remaining donation request goal: ${goalRemaining}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" mt={4}>
              <Tooltip
                title={
                  hasPermission("donations")
                    ? ""
                    : "You do not have permissions for this action, please contact your administrator for permission."
                }
              >
                <span>
                  <Button
                    disabled={!hasPermission("donations") || donationValue === 0}
                    variant="contained"
                    onClick={() => {
                      createDonation(donationValue, donationRequest?.id);
                      window.scrollTo(0, 0);
                    }}
                  >
                    place donation
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      ) : null}
      {(!isDonationPlaced && !earningsBalance) || earningsBalance < 0 ? <EmptyUseEarningsToDonate /> : null}

      {isDonationPlaced && (
        <Confetti
          width={window.innerWidth || 200}
          height={window.innerHeight || 200}
          colors={["#0089BD", "#F9A62B", "#005577", "#1AB5F017", "#41F98B21", "#ffc166"]}
          tweenDuration={17000}
          numberOfPieces={600}
          recycle={false}
        />
      )}
    </>
  );
}

export default DonationRequestDetails;
