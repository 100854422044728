import { useAlert } from "hooks/alerts/useAlert";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { api, fetcher } from "services/api.service";
import useSWR from "swr";

export default function useEarnings(billing = false) {
  const { alertSuccess, alertError } = useAlert();
  const [searchParams] = useSearchParams();
  const {
    data,
    error: isError,
    isLoading,
  } = useSWR(`/earnings?${billing ? "is_billing=true&" : ""}${searchParams.toString()}`, fetcher);
  const earningsTransactions = data?.transactions || [];
  const earningsBalance = (data?.earningsBalance || 0) / 100.0;
  const meta = data?.meta || { totalPages: 0, count: 0 };

  const requestWithdrawal = useCallback(() => {
    api
      .post("/earnings/withdraw")
      .then(() => {
        alertSuccess("We have received your transfer request");
      })
      .catch(() => {
        alertError("Something went wrong, please contact us.");
      });
  }, []);

  return {
    earningsBalance,
    earningsTransactions,
    requestWithdrawal,
    meta,
    isLoading,
    isError,
  };
}
