import { OrganizationConfigurationContext } from "hooks/useOrganizationConfiguration";
import { ReactElement, useContext } from "react";
import { Feature, OrganizationFeature } from "types/plan";

export default function PlanFeatureWrapper({
  feature,
  enabledElement,
  disabledElement,
  grandfatheredElement,
}: {
  feature: Feature;
  enabledElement: ReactElement;
  disabledElement: ReactElement;
  grandfatheredElement: ReactElement;
}): ReactElement {
  const { planFeatures } = useContext(OrganizationConfigurationContext);

  if (!planFeatures.find((organizationFeature: OrganizationFeature) => organizationFeature.name === feature))
    return disabledElement;

  if (
    planFeatures.find(
      (organizationFeature: OrganizationFeature) =>
        organizationFeature.name === feature && organizationFeature.grandfathered
    )
  )
    return grandfatheredElement;

  return enabledElement;
}
