import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "services/api.service";
import { PickupContact } from "types/pickupContact";

export default function useContacts() {
  const params = useParams();
  const [contacts, setContacts] = useState<PickupContact[]>();

  useEffect(() => {
    getContacts();
  }, [params.uuid]);

  const getContacts = () => {
    api.get("/contacts").then(({ data }) => {
      setContacts(data.contacts);
    });
  };

  const createContact = (contact: PickupContact) => {
    return api.post("/contacts", {
      contact,
    });
  };

  return {
    contacts,
    createContact,
  };
}
