import { ArrowForward } from "@mui/icons-material";
import { Box, Typography, FormControl } from "@mui/material";
import { useFormikContext } from "formik";
import theme from "lib/theme";
import { useEffect, useState } from "react";
import { InputBase, InputLabel } from "components/common/Input";
import { Location } from "types/location";

export default function LocationContact() {
  const { values, setFieldValue } = useFormikContext<Location>();
  const currentContact = (values.locationContacts && values.locationContacts[values.locationContacts.length - 1]) ?? {
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
  };
  const [email, setEmail] = useState(currentContact.email);
  const [firstName, setFirstName] = useState(currentContact.firstName);
  const [lastName, setLastName] = useState(currentContact.lastName);
  const [phoneNumber, setPhoneNumber] = useState(currentContact.phoneNumber);

  useEffect(() => {
    setFieldValue("locationContacts", [{ email, firstName, lastName, phoneNumber }]);
  }, [email, firstName, lastName, phoneNumber]);

  return (
    <Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        <ArrowForward sx={{ mt: "4px", flexShrink: 0 }} />
        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" fontWeight={"bold"}>
            Point of contact for the building
          </Typography>
          <Typography>
            Revivn may need someone to contact for additional details or logistics. You may designate a different
            contact for a pickup upon scheduling.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          mt: 3,
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 2,
          [theme.breakpoints.down("sm")]: { gridTemplateColumns: "1fr" },
        }}
      >
        <FormControl variant="standard">
          <InputLabel shrink htmlFor="first-name">
            First name
          </InputLabel>
          <InputBase type="text" id="first-name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
        </FormControl>
        <FormControl variant="standard">
          <InputLabel shrink htmlFor="last-name">
            Last name
          </InputLabel>
          <InputBase type="text" id="last-name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </FormControl>
        <FormControl variant="standard">
          <InputLabel shrink htmlFor="phone">
            Phone
          </InputLabel>
          <InputBase type="text" id="phone" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
        </FormControl>
        <FormControl variant="standard">
          <InputLabel shrink htmlFor="email">
            Email
          </InputLabel>
          <InputBase type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </FormControl>
      </Box>
    </Box>
  );
}
