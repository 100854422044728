import { useCallback, useEffect, useState } from "react";
import { api } from "services/api.service";
import { Donation } from "types/donation";

export default function useDonations() {
  const [donations, setDonations] = useState<Donation[]>([]);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isDonationPlaced, setIsDonationPlaced] = useState(false);

  useEffect(() => {
    setError(false);
    setLoading(false);
  }, [donations]);

  useEffect(() => {
    setLoading(true);
    api
      .get<{ donations: Donation[] }>(`/donations`)
      .then((res) => setDonations(res.data.donations))
      .then(() => setLoading(false))
      .catch(() => setError(true));
  }, []);

  const createDonation = useCallback((donationAmount: number, donationRequestId?: number) => {
    api
      .post(`donations`, {
        donation: { totalDollarsAmount: donationAmount, donationRequestId: donationRequestId },
      })
      .then(() => {
        setIsDonationPlaced(true);
      })
      .catch((err) => {
        setError(!isError);
      });
  }, []);

  return {
    donations,
    isError,
    isLoading,
    createDonation,
    isDonationPlaced,
  };
}
