import { useAlert } from "hooks/alerts/useAlert";
import { useCallback } from "react";
import { api } from "services/api.service";

export default function useTestWebhookSubscription() {
  const { alertSuccess } = useAlert();

  const testWebhookSubscription = useCallback((uuid: string) => {
    api.post(`/webhook_subscriptions/${uuid}/send_test`).then(() => {
      alertSuccess("Test webhook sent.");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    testWebhookSubscription,
  };
}
