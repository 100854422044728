import { Box, Typography, Button, FormControl } from "@mui/material";
import { InputBase } from "components/common/Input";
import { useFormik } from "formik";
import useSaml, { api } from "hooks/saml/useSaml";
import theme from "lib/theme";
import { useNavigate, useParams } from "react-router-dom";
import { SamlSetting } from "types/samlSetting";
import { init } from "filestack-js";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { AttachFile } from "@mui/icons-material";

const samlSetting: SamlSetting = {
  idpSsoServiceUrl: "",
};

export function ConfigureProvider() {
  const navigate = useNavigate();
  const params = useParams();
  const { createSamlSettings } = useSaml();
  const [certName, setCertName] = useState("");
  const [fileUrl, setFileUrl] = useState("");

  const handleFileUploadFinish = (result: any) => {
    setCertName(result.filename);
    setFileUrl(result.key);
  };

  const handleFile = (e: any) => {
    const filename = uuidv4();
    const options = {
      accept: [".pem", ".cert"],
      maxFiles: 1,
      fromSources: ["local_file_system"],
      storeTo: {
        path: `/saml_settings/${filename}.pem`,
        container: process.env.REACT_APP_S3_BUCKET,
        region: process.env.REACT_APP_S3_REGION,
      },
      onFileUploadFinished: handleFileUploadFinish,
    };

    const client = init(process.env.REACT_APP_FILESTACK_API_KEY || "");

    const setCredentials = () => {
      api.post("/api/v1/filestack_credential").then((resp) => {
        client.setSecurity(resp.data);
      });
    };
    setCredentials();

    client.picker(options).open();
  };

  const formik = useFormik({
    initialValues: samlSetting,
    onSubmit: (values: any) => {
      if (fileUrl[0]) {
        values["samlCertificateUrl"] = `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${fileUrl}`;
      }
      values["provider"] = params.provider;

      createSamlSettings(values);
    },
  });

  const showSave = () => {
    if (formik.values.idpSsoServiceUrl && fileUrl[0]) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      <Box
        mt={4}
        sx={{
          px: 5,
          py: 3,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography variant="h6" fontWeight="600">
          {`Configure SAML authentication for ${params.provider}`}
        </Typography>
        <Typography>
          Add the information below to enable single sign on for the Revivn platform with your provider.
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <Box mt={4}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
              <FormControl
                variant="standard"
                sx={{ display: "grid", gridTemplateColumns: "290px 1fr", gap: 4, alignItems: "end" }}
              >
                <label htmlFor="sso-url">
                  <Typography fontWeight="600">SAML SSO URL</Typography>
                  <Typography>Enter your SAML 2.0 Endpoint Login URL</Typography>
                </label>
                <InputBase
                  name="idpSsoServiceUrl"
                  value={formik.values.idpSsoServiceUrl}
                  onChange={formik.handleChange}
                />
              </FormControl>

              <FormControl
                variant="standard"
                sx={{ display: "grid", gridTemplateColumns: "290px 1fr", gap: 4, alignItems: "end" }}
              >
                <label htmlFor="public-certificate">
                  <Typography fontWeight="600">Public Certificate</Typography>
                  <Typography>Attach your Public Certificate here (.pem or .cert files accepted)</Typography>
                </label>
                <Box mt={3}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={(e) => {
                      handleFile(e);
                    }}
                  >
                    <AttachFile color="primary" />
                    {`${certName ? "Edit" : "Attach Your Certificate Here"}`}
                  </Button>
                  {certName && <Box>{`${certName} is attached`}</Box>}
                </Box>
              </FormControl>
            </Box>
            <Box sx={{ mt: 5, display: "flex", gap: 2 }}>
              <Button size="large" variant="outlined" onClick={() => navigate(-1)}>
                Back
              </Button>
              <Button size="large" variant="contained" type="submit" disabled={showSave()}>
                Save
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </div>
  );
}
