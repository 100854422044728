import { Box } from "@mui/material";
import { chartColors } from "components/common/charts/chartsUtils";
import { Doughnut } from "react-chartjs-2";

export default function RetiredAssetDoughnutChart(homeInfo: any) {
  return (
    <Box sx={{ minHeight: "230px", height: "100%" }}>
      <Doughnut
        data={{
          labels:
            homeInfo.homeInfo &&
            homeInfo.homeInfo.homeInfo &&
            homeInfo.homeInfo.homeInfo.retiredAssetsInfo &&
            homeInfo.homeInfo.homeInfo.retiredAssetsInfo.manufacturers.map(({ manufacturer }: any) => manufacturer),
          datasets: [
            {
              data:
                homeInfo.homeInfo &&
                homeInfo.homeInfo.homeInfo &&
                homeInfo.homeInfo.homeInfo.retiredAssetsInfo &&
                homeInfo.homeInfo.homeInfo.retiredAssetsInfo.manufacturers.map(({ count }: any) => count),
              backgroundColor: chartColors,
              hoverBackgroundColor: chartColors,
              borderWidth: 4,
              borderColor: "white",
              hoverBorderColor: "white",
              borderRadius: 33,
            },
          ],
        }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          cutout: "85%",
          layout: {},
          plugins: {
            legend: {
              position: "bottom",
            },
          },
        }}
      />
    </Box>
  );
}
