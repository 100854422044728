import { Box, Button, Link, Typography } from "@mui/material";
import theme from "lib/theme";
import { OnHoldDrawerProps } from "types/pickup";
import { ArrowForward, CopyAll, Lock, PauseCircle } from "@mui/icons-material";
import {
  address,
  contact,
  orderNumber,
  pickupDate,
  PickupDetailsRow,
  PickupDetailsRows,
  PickupDetailsTitle,
} from "./shared";
import usePickupReports from "hooks/pickups/usePickupReports";
import { navActions } from "components/AppLayout/Nav";
import { useState } from "react";
import { LockedDeviceModal } from "./LockedDeviceModal";
import useAnalytics from "hooks/analytics/useAnalytics";
import useAssets from "hooks/assets/useAssets";

export function OnHoldDrawer(props: OnHoldDrawerProps) {
  const { pickup, markPickupUnenrolled } = props;
  const { getEnrolledData, enrolledData, setDownloadEnrolled, downloadEnrolled } = usePickupReports();
  let [modalOpen, setModalOpen] = useState(false);
  const { createAnalyticsEvent } = useAnalytics();
  const { getAllSerialsNumbers } = useAssets();

  if (downloadEnrolled) {
    setDownloadEnrolled(false);
    const blob = new Blob([enrolledData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `pickup_${pickup.uuid}_enrolled.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      createAnalyticsEvent("on_hold_report_downloaded");
    }
  }
  function moreItem({ number, text }: { number: number; text: string }) {
    return (
      <Box sx={{ display: "flex", gap: 1, alignItems: "baseline" }}>
        <Box>
          <Box sx={{ fontSize: "14px" }}>{number}.</Box>
        </Box>
        <Typography variant="body1">{text}</Typography>
      </Box>
    );
  }

  return (
    <Box mb={5} mx={2}>
      <Box
        sx={{
          py: 3,
          px: 4,
          background: "#FAFAFA",
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
        }}
      >
        <PauseCircle />
        <Typography variant="h6" fontWeight="bold">
          Your pickup has been placed on hold!{" "}
        </Typography>
        <Typography>
          We found assets that need to released before we can finish processing them and provide you with your reports
          and certificate of destruction.
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "0.2fr 2.8fr",
            gap: 2,
            background: "white",
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            mt: 3,
            py: 3,
            px: 3,
          }}
        >
          <Lock color="secondary" sx={{ fontSize: "45px" }} />
          <Box>
            <Typography fontSize="18px" fontWeight="bold">
              Enrolled Assets
            </Typography>
            <Typography mt={0.5}>
              Download your list of assets that are on hold below and we’ll continue processing them as soon as they’re
              released.
            </Typography>
            <Typography mt={0.5}>
              In{" "}
              <Link href="https://business.apple.com/" target="_blank" rel="noreferrer" color="inherit">
                Apple Business Manager (ABM)
              </Link>
              , you can release an asset (such as a Mac or iOS device) by following these steps:
            </Typography>
            <Box sx={{ pl: 2, display: "flex", flexDirection: "column", gap: 0.5, pt: 1 }}>
              {moreItem({
                number: 1,
                text: "Log in to your ABM account.",
              })}
              {moreItem({
                number: 2,
                text: "Go to the Devices section of ABM.",
              })}
              {moreItem({
                number: 3,
                text: "Copy all of your enrolled serials to your clipboard by clicking the button below.",
              })}
            </Box>
            <Button
              size="small"
              variant="outlined"
              sx={{ my: 2, ml: 1, mr: "16px" }}
              onClick={() => getAllSerialsNumbers(undefined, true)}
              startIcon={<CopyAll />}
              color="secondary"
            >
              copy serials to clipboard
            </Button>
            <Box sx={{ pl: 2, display: "flex", flexDirection: "column", gap: 0.5 }}>
              {moreItem({
                number: 4,
                text: "Paste the serials into the search bar (in bulk) in ABM.",
              })}
              {moreItem({
                number: 5,
                text: "Click Release Devices.",
              })}
              {moreItem({
                number: 6,
                text:
                  "Let us know these devices have been released by following the button below to the assets table and" +
                  "marking them as released - we’ll verify on our end!",
              })}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                size="small"
                variant="contained"
                sx={{ mt: 2, marginLeft: "10px" }}
                onClick={() => {
                  setModalOpen(true);
                }}
                endIcon={<ArrowForward />}
                color="secondary"
              >
                mark devices as released
              </Button>
            </Box>
          </Box>
        </Box>

        <LockedDeviceModal
          open={modalOpen}
          setOpen={setModalOpen}
          uuid={pickup.uuid}
          markPickupUnenrolled={markPickupUnenrolled}
          refetch={() => {}}
        />

        <Box mt={3}>
          <PickupDetailsTitle />

          <PickupDetailsRows>
            <PickupDetailsRow
              columnCount={4}
              items={
                pickup.virtualPickup
                  ? [
                      {
                        title: "PICKUP ID",
                        list: orderNumber(pickup),
                      },
                      {
                        title: "ASSETS RETRIEVED",
                        list: [
                          pickup.inventoryCount ? (
                            pickup.inventoryCount > 0 ? (
                              <Link
                                href={`/assets?page[number]=1&page[size]=25&filter[]=state%2Cis_any_of%2Cretired&filter[]=pickup_id%2Cis_any_of%2C${pickup.id}`}
                              >
                                {pickup.inventoryCount}
                              </Link>
                            ) : (
                              "0"
                            )
                          ) : (
                            "0"
                          ),
                        ],
                      },
                      {
                        title: "Enrolled Assets",
                        list: [
                          pickup.enrolledCount ? (
                            pickup.enrolledCount > 0 ? (
                              <Link
                                href={`/assets?page[number]=1&page[size]=25&filter[]=state%2Cis_any_of%2Cretired&filter[]=pickup_id%2Cis_any_of%2C${pickup.id}&filter[]=status%2Cis%2CEnrolled`}
                              >
                                {pickup.enrolledCount}
                              </Link>
                            ) : (
                              "0"
                            )
                          ) : (
                            "0"
                          ),
                        ],
                      },
                    ]
                  : [
                      {
                        title: "PICKUP ID",
                        list: orderNumber(pickup),
                      },
                      {
                        title: "PICKUP DATE",
                        list: pickupDate(pickup),
                      },
                      {
                        title: "CONTACT",
                        list: contact(pickup),
                      },
                      {
                        title: "ADDRESS",
                        list: address(pickup),
                      },
                    ]
              }
            />
          </PickupDetailsRows>
        </Box>
      </Box>

      <Box
        mt={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          gap: 3,
        }}
      >
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            navActions.openContactConcierge();
          }}
        >
          questions? contact us
        </Button>
      </Box>
    </Box>
  );
}
