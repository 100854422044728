import { Box, FormControl, MenuItem } from "@mui/material";
import { AppSelect, InputBase, InputLabel, RequiredSymbol } from "components/common/Input";
import { AssetRetrievalBulkOrderDetails } from "types/assetRetrieval";
import { useFormikContext } from "formik";

export default function ReturnAddressForm() {
  const { getFieldProps, errors, touched } = useFormikContext<AssetRetrievalBulkOrderDetails>();

  return (
    <Box>
      <Box mt={2} sx={{ display: "flex", flexDirection: "column", gap: 2, alignContent: "center" }}>
        <Box sx={{ display: "grid", gridTemplateColumns: "2fr 2fr", gap: 2 }}>
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="city">
              First Name <RequiredSymbol />
            </InputLabel>
            <InputBase
              type="text"
              {...getFieldProps("returnRecipientFirstName")}
              error={touched.returnRecipientFirstName && !!errors.returnRecipientFirstName}
              helperText={touched.returnRecipientFirstName && errors.returnRecipientFirstName}
            />
          </FormControl>

          <FormControl variant="standard">
            <InputLabel shrink htmlFor="state">
              Last Name <RequiredSymbol />
            </InputLabel>
            <InputBase
              type="text"
              {...getFieldProps("returnRecipientLastName")}
              error={touched.returnRecipientLastName && !!errors.returnRecipientLastName}
              helperText={touched.returnRecipientLastName && errors.returnRecipientLastName}
            />
          </FormControl>
        </Box>
        <FormControl variant="standard">
          <InputLabel shrink htmlFor="address-line-1">
            Company Name
          </InputLabel>
          <InputBase
            type="text"
            {...getFieldProps("returnRecipientCompany")}
            error={touched.returnRecipientCompany && !!errors.returnRecipientCompany}
            helperText={touched.returnRecipientCompany && errors.returnRecipientCompany}
          />
        </FormControl>
        <FormControl variant="standard">
          <InputLabel shrink htmlFor="address-line-1">
            Return address line 1 <RequiredSymbol />
          </InputLabel>
          <InputBase
            type="text"
            {...getFieldProps("returnRecipientAddress")}
            error={touched.returnRecipientAddress && !!errors.returnRecipientAddress}
            helperText={touched.returnRecipientAddress && errors.returnRecipientAddress}
          />
        </FormControl>

        <FormControl variant="standard">
          <InputLabel shrink htmlFor="address-line-2">
            Return address line 2
          </InputLabel>
          <InputBase type="text" {...getFieldProps("returnRecipientAddressTwo")} />
        </FormControl>

        <Box sx={{ display: "grid", gridTemplateColumns: "2fr 2fr", gap: 2 }}>
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="city">
              City <RequiredSymbol />
            </InputLabel>
            <InputBase
              type="text"
              {...getFieldProps("returnRecipientAddressCity")}
              error={touched.returnRecipientAddressCity && !!errors.returnRecipientAddressCity}
              helperText={touched.returnRecipientAddressCity && errors.returnRecipientAddressCity}
            />
          </FormControl>

          <FormControl variant="standard">
            <InputLabel shrink htmlFor="state">
              State <RequiredSymbol />
            </InputLabel>
            <InputBase
              type="text"
              {...getFieldProps("returnRecipientAddressState")}
              error={touched.returnRecipientAddressState && !!errors.returnRecipientAddressState}
              helperText={touched.returnRecipientAddressState && errors.returnRecipientAddressState}
            />
          </FormControl>

          <FormControl variant="standard">
            <InputLabel shrink htmlFor="zip-code">
              Zip Code <RequiredSymbol />
            </InputLabel>
            <InputBase
              type="text"
              {...getFieldProps("returnRecipientAddressZipCode")}
              error={touched.returnRecipientAddressZipCode && !!errors.returnRecipientAddressZipCode}
              helperText={touched.returnRecipientAddressZipCode && errors.returnRecipientAddressZipCode}
            />
          </FormControl>

          <FormControl variant="standard">
            <InputLabel shrink htmlFor="country">
              Country <RequiredSymbol />
            </InputLabel>
            <AppSelect {...getFieldProps("returnRecipientAddressCountry")}>
              <MenuItem value="United States">United States</MenuItem>
            </AppSelect>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
}
