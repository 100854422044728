import { useFormik } from "formik";
import React from "react";
import ACHInformationForm from "./ACHInformationForm";

function ACHInformation() {
  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {},
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <ACHInformationForm />
    </form>
  );
}

export default ACHInformation;
