import { Box, Menu as MUIMenu, MenuItem } from "@mui/material";
import React, { useId, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

interface Action {
  label: React.ReactNode;
  onClick?: () => void;
  to?: string;
  href?: string;
  props?: { [key: string]: any };
}

interface MenuProps {
  button: (props: any) => React.ReactNode;
  actions: Action[];
}

export function Menu({ button, actions }: MenuProps) {
  const id = useId();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      {button({
        "aria-controls": open ? "basic-menu" : undefined,
        "aria-haspopup": "true",
        "aria-expanded": open ? "true" : undefined,
        "aria-describedby": id,
        onClick: handleClick,
      })}

      <MUIMenu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {actions.map((action, i) => {
          let props = action.props ?? {};

          if (action.to) {
            props = {
              component: RouterLink,
              to: action.to,
              ...props,
            };
          }

          if (action.href) {
            props = {
              component: "a",
              href: action.href,
              ...props,
            };
          }

          return (
            <MenuItem
              {...props}
              key={i}
              onClick={() => {
                action.onClick?.();
                handleClose();
              }}
            >
              {action.label}
            </MenuItem>
          );
        })}
      </MUIMenu>
    </Box>
  );
}
