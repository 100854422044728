import { useCallback } from "react";
import { api } from "services/api.service";

export default function useCancelPickup() {
  const cancelPickup = useCallback(
    (cancellationReason: string, pickupUuid: string) =>
      api.put(`/pickups/${pickupUuid}/cancel_pickup`, { cancellationReason }),
    []
  );

  return {
    cancelPickup,
  };
}
