import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { createContext, Dispatch, SetStateAction, useCallback, useContext, useState } from "react";

type AsyncHandler = (() => Promise<void>) | (() => void);

type ConfirmationContextValue = {
  showDialog: Dispatch<SetStateAction<boolean>>;
  setTitle: Dispatch<SetStateAction<string>>;
  setContent: Dispatch<SetStateAction<string>>;
  setHandler: Dispatch<SetStateAction<AsyncHandler>>;
};

const ConfirmationContext = createContext<ConfirmationContextValue>({
  showDialog: () => {},
  setHandler: () => {},
  setContent: () => {},
  setTitle: () => {},
});

export const ConfirmationProvider = ({ children }: { children: React.ReactNode }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [handler, setHandler] = useState<AsyncHandler>(() => {});
  const [title, setTitle] = useState("Confirmation");
  const [content, setContent] = useState("Are you sure?");

  const handleConfirm = useCallback(async () => {
    await handler();
    setShowConfirmation(false);
  }, [handler]);

  return (
    <ConfirmationContext.Provider value={{ showDialog: setShowConfirmation, setHandler, setContent, setTitle }}>
      {children}
      <Dialog open={showConfirmation} data-testid="confirmation-dialog" fullWidth>
        <DialogTitle data-testid="confirmation-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText data-testid="confirmation-dialog-message">{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmation(false)} data-testid="confirmation-dialog-cancel-button">
            Cancel
          </Button>
          <Button onClick={handleConfirm} data-testid="confirmation-dialog-confirm-button" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmationContext.Provider>
  );
};

export const useConfirmation = () => {
  const { showDialog, setHandler, setTitle, setContent } = useContext(ConfirmationContext);
  const confirm = useCallback(
    ({ handler, message, title }: { handler: AsyncHandler; message?: string; title?: string }) => {
      setHandler(() => handler);
      if (message) setContent(message);
      if (title) setTitle(title);

      showDialog(true);
    },
    [setHandler, showDialog]
  );

  return {
    confirm,
  };
};
