import { InfoOutlined } from "@mui/icons-material";
import { Box, Typography, useMediaQuery } from "@mui/material";
import theme from "lib/theme";

export default function HowItWorks() {
  let isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {" "}
      <Typography mt={3} variant="h6" fontWeight="bold">
        How it works
      </Typography>
      <Box
        sx={{
          mt: 1,
          py: 1.5,
          px: 4,
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          gap: 1.5,
          [theme.breakpoints.down("sm")]: { py: 2, px: 2, gridTemplateColumns: "1fr", gap: 0 },
        }}
      >
        {!isMobile && (
          <Box>
            <InfoOutlined color="primary" />
          </Box>
        )}
        <Box
          sx={{
            ul: { fontSize: "14px", color: theme.palette.text.secondary, mt: 0.5, pl: 3 },
          }}
        >
          <Typography fontWeight="bold">What's Included?</Typography>
          <ul>
            <li>
              A padded laptop box shipped to your employee, a shipping label (no printer required!), easy instructions,
              and tape
            </li>
            <li>Email communications with your employee, including reminders if they delay returning their laptop</li>
            <li>Tracking updates sent to your company email address</li>
          </ul>

          <Box>
            <Typography fontWeight="bold" component="span">
              What fits in the box:
            </Typography>{" "}
            a laptop (up to 16”) and an accessory or two
          </Box>

          <Box>
            <Typography fontWeight="bold" component="span">
              Where we ship:
            </Typography>{" "}
            we support laptop returns to / from all US states
          </Box>

          <Box>
            <Typography fontWeight="bold" component="span">
              How long shipping takes:
            </Typography>{" "}
            Shipping generally takes 3-5 business days.
          </Box>
        </Box>
      </Box>
    </>
  );
}
