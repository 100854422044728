import { Box, FormControl, Link, Typography, useMediaQuery } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import useLocations from "hooks/locations/useLocations";
import useUpdatePickup from "hooks/pickups/useUpdatePickup";
import { useCallback, useEffect, useMemo, useState } from "react";
import usePickup from "hooks/pickups/usePickup";
import { useLocation, Link as RouterLink, useNavigate } from "react-router-dom";
import { InputBase, InputLabel } from "components/common/Input";
import theme from "lib/theme";
import { formatLocations } from "utils/helpers";
import useCreatePickup from "hooks/pickups/useCreatePickup";

interface AutocompleteOption {
  label: string;
  id: string;
  address: string;
  zipcode: string;
}

interface PickupLocationProps {
  goToNextStep: () => void;
}

const EMPTY_LOCATION_OPTION: AutocompleteOption = { label: "", id: "", address: "", zipcode: "" };

export default function PickupLocation({ goToNextStep }: PickupLocationProps) {
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const routeLocation = useLocation();
  const { locations, isLoading: isLocationsLoading } = useLocations();
  const { updatePickup } = useUpdatePickup();
  const { createPickup } = useCreatePickup();
  const navigate = useNavigate();
  const { pickup, isLoading: isPickupLoading, refetch: refetchPickup } = usePickup();

  const locationOptions: Array<any> = useMemo(() => {
    if (isLocationsLoading) {
      return [];
    }
    return formatLocations(locations);
  }, [isLocationsLoading, locations]);

  const [currentLocation, setCurrentLocation] = useState(EMPTY_LOCATION_OPTION);
  const defaultLocationOption = useCallback(
    (currentLocationUuid?: string): AutocompleteOption => {
      if (isLocationsLoading) {
        return EMPTY_LOCATION_OPTION;
      }

      const foundOption = locationOptions?.find((opt: AutocompleteOption) => opt.id === currentLocationUuid);
      return foundOption || EMPTY_LOCATION_OPTION;
    },
    [isLocationsLoading, locationOptions]
  );

  useEffect(() => {
    if (isLocationsLoading || isPickupLoading) {
      return;
    }

    if (routeLocation.state?.newLocationUuid) {
      onChangeLocation(undefined, defaultLocationOption(routeLocation.state?.newLocationUuid));
      navigate(routeLocation.pathname, { replace: true });
    }

    setCurrentLocation(defaultLocationOption(pickup?.location?.uuid));
  }, [
    isLocationsLoading,
    isPickupLoading,
    routeLocation.state?.newLocationUuid,
    createPickup,
    refetchPickup,
    defaultLocationOption,
    pickup?.location?.uuid,
  ]);

  const onChangeLocation = useCallback(
    (_event: any, newValue: AutocompleteOption) => {
      if (newValue) {
        if (!pickup) {
          createPickup({ locationId: newValue.id });
        } else {
          updatePickup({ locationId: newValue.id });
        }
        refetchPickup();

        setCurrentLocation(newValue);
        goToNextStep();
      }
    },
    [pickup, createPickup, updatePickup, goToNextStep, refetchPickup]
  );

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
      <Box sx={{ flex: "1" }}>
        <Typography variant="h6" fontSize="18px" fontWeight="bold">
          1. Where should we pick up your items?
        </Typography>

        <Box mt={3} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Autocomplete
            options={locationOptions}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
            onChange={onChangeLocation}
            renderInput={(params) => {
              return (
                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <InputLabel shrink {...params.InputLabelProps}>
                    Location
                  </InputLabel>
                  <InputBase ref={params.InputProps.ref} inputProps={params.inputProps} placeholder="Select Location" />
                </FormControl>
              );
            }}
            value={currentLocation}
          />
        </Box>

        <Link
          component={RouterLink}
          to={"/company-settings/locations/new"}
          state={{ originalPath: routeLocation.pathname }}
          sx={{
            fontSize: "16px",
            fontFamily: "Catamaran",
            cursor: "pointer",
            mt: 2,
            display: "inline-flex",
            alignItems: "center",
            gap: 1,
            fontWeight: 600,
            textDecoration: "none",
            ":hover": {
              textDecoration: "underline",
            },
          }}
        >
          <AddCircleOutline sx={{ width: "20px", height: "20px" }} />
          Add a new location
        </Link>
      </Box>

      {!isTablet && (
        <Box sx={{ flexShrink: 0, height: "170px" }}>
          <iframe
            title="map"
            style={{ width: "100%", height: "100%", border: "none" }}
            src={
              currentLocation.address === ""
                ? `https://maps.google.com/maps?ie=UTF8&iwloc=&output=embed`
                : `https://maps.google.com/maps?q=${currentLocation.address}${currentLocation.zipcode}&ie=UTF8&iwloc=&output=embed`
            }
            allowFullScreen
            scrolling="no"
          ></iframe>
        </Box>
      )}
    </Box>
  );
}
