import { Box, Typography, Button } from "@mui/material";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { Modal } from "components/common/Modal";
import useOrganization from "hooks/organizations/useOrganization";

interface ServiceTermsModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  submit: () => void;
}

export function ServiceTermsModal({ open, setOpen, submit }: ServiceTermsModalProps) {
  const { updateTermsOfServiceTimestamp } = useOrganization();

  const handleClick = () => {
    updateTermsOfServiceTimestamp();
    setOpen(false);
    submit();
  };
  return (
    <Modal open={open} setOpen={setOpen}>
      <Box>
        <Box sx={{ textAlign: "center" }}>
          <StickyNote2Icon color="primary" sx={{ fontSize: "60px" }} />
          <Typography mt={1} variant="h5" component="h2" fontWeight={800}>
            Terms of Service
          </Typography>

          <Typography mt={1} color="text.secondary">
            Thank you for trying out our new offering! Please remember that this new service falls under our existing
            terms. However, we want to point out that we are not responsible for any shipping or retrieval issues. As a
            reminder: you do have the option to select shipping insurance, your employees enter their shipping
            information and we assist with management of the process and shipping the box to them. We can't ensure that
            your employees are packing the computers or sending the boxes back so we can't be responsible for lost or
            damaged items.
          </Typography>

          <Box mt={3} sx={{ display: "flex", gap: 1, alignItems: "center", justifyContent: "center" }}>
            <Button
              variant="outlined"
              onClick={() => {
                setOpen(false);
              }}
            >
              back
            </Button>
            <Button variant="contained" onClick={handleClick}>
              I agree
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
