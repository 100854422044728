import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Skeleton,
} from "@mui/material";
import theme from "lib/theme";
import { Link as RouterLink } from "react-router-dom";
import { Webhook, Delete, Cancel } from "@mui/icons-material";
import PlanFeatureWrapper from "components/PlanFeatureWrapper";
import PaywallModal from "components/PaywallModal";
import useWebhookSubscriptions from "hooks/api/useWebhookSubscriptions";
import { WebhookSubscription } from "types/webhookSubscription";
import { formatDate } from "utils/helpers";
import { useState } from "react";
import { Modal } from "components/common/Modal";
import useDeleteWebhookSubscription from "hooks/api/useDeleteWebhookSubscription";
import useTestWebhookSubscription from "hooks/api/useTestWebhookSubscription";
import slackLogo from "images/slack_icon.png";
import ApiIcon from "@mui/icons-material/Api";

function WebhooksTable() {
  const { webhookSubscriptions, isLoading, setReload } = useWebhookSubscriptions();
  return (
    <div>
      {isLoading ? (
        <>
          <Skeleton variant="text" height={75} />
          <Skeleton variant="text" height={75} />
          <Skeleton variant="text" height={75} />
          <Skeleton variant="text" height={75} />
          <Skeleton variant="text" height={75} />
        </>
      ) : webhookSubscriptions && webhookSubscriptions.length > 0 ? (
        <ApiDataTable webhookSubscriptions={webhookSubscriptions} setReload={setReload} />
      ) : (
        <EmptyTable />
      )}
    </div>
  );
}

function CreateAppButton() {
  return (
    <PlanFeatureWrapper
      feature="api"
      enabledElement={
        <Button component={RouterLink} variant="contained" size="large" to="/api/webhooks/new" disableElevation>
          Create Webhook Subscription
        </Button>
      }
      disabledElement={
        <>
          <PaywallModal
            feature={"webhooks"}
            trigger={(props) => (
              <Button variant="contained" size="large" {...props} disableElevation>
                Create Webhook Subscription
              </Button>
            )}
          />
        </>
      }
      grandfatheredElement={
        <Button component={RouterLink} variant="contained" size="large" to="/api/webhooks/new" disableElevation>
          Create Webhook Subscription
        </Button>
      }
    />
  );
}

function EmptyTable() {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height={400}>
      <Box maxWidth={390} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <Webhook sx={{ fontSize: 70, opacity: 0.25 }} />
        <Typography variant="h5" textAlign="center">
          You don’t have any webhook subscriptions yet!
        </Typography>
        <Typography mt={2} mb={3} textAlign="center">
          Subscribe to key events and automate workflows with Revivn's Webhooks
        </Typography>
        <CreateAppButton />
      </Box>
    </Box>
  );
}

function ApiDataTable({
  webhookSubscriptions,
  setReload,
}: {
  webhookSubscriptions: WebhookSubscription[];
  setReload: any;
}) {
  return (
    <>
      <TableContainer
        sx={{
          mt: 2,
        }}
      >
        <Table sx={{ ".MuiTableRow-root:last-child td, .MuiTableRow-root:last-child th": { border: 0 } }}>
          <TableHead sx={{ ".MuiTableCell-root": { color: theme.palette.text.disabled, borderBottom: 0 } }}>
            <TableRow>
              <TableCell>Channel</TableCell>
              <TableCell>Event</TableCell>
              <TableCell>Request Type</TableCell>
              <TableCell>URL</TableCell>
              <TableCell>Custom Headers</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Created</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {webhookSubscriptions?.map((webhookSubscription) => {
              return (
                <ApiTableRow
                  key={webhookSubscription.uuid}
                  webhookSubscription={webhookSubscription}
                  setReload={setReload}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateAppButton />
    </>
  );
}

function ApiTableRow({ webhookSubscription, setReload }: { webhookSubscription: WebhookSubscription; setReload: any }) {
  let [isOpen, setIsOpen] = useState(false);
  const { deleteWebhookSubscription } = useDeleteWebhookSubscription();
  const { testWebhookSubscription } = useTestWebhookSubscription();
  return (
    <TableRow>
      <TableCell>
        <>
          {webhookSubscription.channel === "rest_api" && (
            <ApiIcon color={"primary"} sx={{ width: "20px", height: "20px", ml: "10px" }} />
          )}
          {webhookSubscription.channel === "slack" && (
            <img src={slackLogo} alt=" Slack" style={{ width: "18px", height: "18px", marginLeft: "10px" }} />
          )}
        </>
      </TableCell>
      <TableCell>{webhookSubscription.eventName}</TableCell>
      <TableCell>{webhookSubscription.requestType.toUpperCase()}</TableCell>
      <TableCell>{webhookSubscription.endpoint}</TableCell>
      <TableCell>{JSON.stringify(webhookSubscription.customHeaders)}</TableCell>
      <TableCell>{`${webhookSubscription.createdBy?.firstName || ""} ${
        webhookSubscription.createdBy?.lastName || ""
      }`}</TableCell>
      <TableCell>{formatDate(webhookSubscription.createdAt)}</TableCell>
      <TableCell>
        <Button
          sx={{ width: "125px", margin: "2px" }}
          variant="contained"
          startIcon={<Webhook />}
          onClick={(e) => {
            testWebhookSubscription(webhookSubscription.uuid);
          }}
        >
          Send Test
        </Button>
        <Button
          sx={{ width: "125px", margin: "2px" }}
          variant="outlined"
          startIcon={<Delete />}
          onClick={(e) => {
            setIsOpen(true);
          }}
        >
          Delete
        </Button>
      </TableCell>

      <Modal open={isOpen} setOpen={setIsOpen}>
        <Box sx={{ textAlign: "center" }}>
          <Cancel color="primary" sx={{ fontSize: "60px" }} />

          <Typography fontWeight="bold" fontSize="24px" mt={1}>
            Delete Webhook Subscription
          </Typography>

          <Typography mt={0.5} fontSize="20px">
            Are you sure you want to delete this webhook subscription? Your endpoint will no longer receive requests for
            this event.
          </Typography>

          <Box mt={5} sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Button
              variant="contained"
              onClick={() => {
                deleteWebhookSubscription(webhookSubscription.uuid, setReload);
              }}
            >
              Yes, delete
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              no, don’t Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </TableRow>
  );
}

export default WebhooksTable;
