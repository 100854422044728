import useCreateLocation from "hooks/locations/useCreateLocation";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import { Location } from "types/location";
import LocationForm from "./Form";

export const location: Location = {
  name: "",
  uuid: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zipcode: "",
  country: "",
  locationContacts: [],
};

export default function NewLocation() {
  let { state } = useLocation();
  let formLocation = (state as any)?.location ?? location;

  let { current: originalPath } = useRef((state as any)?.originalPath);

  const { createLocation } = useCreateLocation(originalPath);

  return <LocationForm location={formLocation} onSubmit={createLocation} originalPath={originalPath} />;
}
