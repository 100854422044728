import { SelectChangeEvent, Box, Typography, FormControl, MenuItem, Radio, ListItemText } from "@mui/material";
import { InputBase } from "components/common/Input";
import usePickup from "hooks/pickups/usePickup";
import useUpdatePickup from "hooks/pickups/useUpdatePickup";
import { useEffect, useState } from "react";
import { AppSelect } from "../../../components/common/Input";

interface PickupReasonProps {
  goToNextStep: () => void;
}

export default function PickupReason({ goToNextStep }: PickupReasonProps) {
  let [value, setValue] = useState<string>("");
  let [open, setOpen] = useState(false);
  let [otherValue, setOtherValue] = useState("");
  const { pickup } = usePickup();
  const { updatePickup } = useUpdatePickup();

  function handleChange(event: SelectChangeEvent) {
    let value = event.target.value;
    if (value !== "other") {
      setOtherValue("");
      updatePickup({ reason: value });
      goToNextStep();
    }
    setValue(value);
  }

  const options = ["Standard device retiring/refresh", "Office closure"];

  useEffect(() => {
    if (pickup) {
      if (options.includes(pickup.reason)) {
        setValue(pickup.reason);
      } else if (!pickup.reason) {
        setValue("");
      } else {
        setValue("other");
        setOtherValue(pickup.reason);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickup]);

  return (
    <Box>
      <Typography variant="h6" fontSize="18px" fontWeight="bold">
        2. Why are you requesting a pickup?
      </Typography>

      <FormControl variant="standard" sx={{ mt: 3, width: "100%", maxWidth: "450px" }}>
        <AppSelect
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          value={value}
          onChange={handleChange}
          renderValue={(selected) => {
            if (selected === "other") {
              let result = "Other";
              if (otherValue) {
                result += `: ${otherValue}`;
              }
              return result;
            }
            return selected;
          }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={(e) => {
            let targetEl = e.currentTarget as HTMLDivElement;
            let isOtherSelected = targetEl.dataset.value === "other";
            if (isOtherSelected) {
              setTimeout(() => {
                (targetEl.querySelector("input[type='text']") as HTMLInputElement)?.focus();
              });
            } else {
              // close the popover
              setOpen(false);
              if (value === "other" && pickup && otherValue !== pickup.reason) {
                updatePickup({ reason: otherValue });
                goToNextStep();
              }
            }
          }}
        >
          {options.map((item) => {
            let isSelected = item === value;
            return (
              <MenuItem key={item} value={item}>
                <Radio size="medium" checked={isSelected} />
                <ListItemText primary={item} />
              </MenuItem>
            );
          })}
          <MenuItem key={"other"} value={"other"} data-value="other" onKeyDown={(e) => e.stopPropagation()}>
            <Radio size="medium" checked={value === "other"} />
            Other:
            <InputBase
              value={otherValue}
              style={{ marginLeft: "8px" }}
              onChange={(e: any) => setOtherValue(e.target.value)}
            ></InputBase>
          </MenuItem>
        </AppSelect>
      </FormControl>
    </Box>
  );
}
