import { useContext, useEffect, useState } from "react";
import { Box, Button, FormControl, Typography, Link, Skeleton } from "@mui/material";
import { InputBase, InputLabel } from "components/common/Input";
import { Formik } from "formik";
import useAuth from "hooks/useAuth";
import theme from "lib/theme";
import LoginCoverImg from "images/login-cover.png";
import { VpnKey } from "@mui/icons-material";
import * as yup from "yup";
import { SecondaryButton } from "components/common/Button";
import useOrganization from "hooks/organizations/useOrganization";
import useSaml from "hooks/saml/useSaml";
import { Link as RouterLink } from "react-router-dom";
import useAnalytics from "hooks/analytics/useAnalytics";
import { OrganizationConfigurationContext } from "hooks/useOrganizationConfiguration";

export default function Login() {
  const [invalidEmailOrPassword, setInvalidEmailOrPassword] = useState(false);
  const { login } = useAuth();
  const { loadingConfig, errorMessage } = useOrganization();
  const organizationConfiguration = useContext(OrganizationConfigurationContext);
  const { samlLogin } = useSaml();
  const { createAnalyticsEvent } = useAnalytics();
  const [eventTracked, setEventTracked] = useState(false);

  const handleSso = () => {
    return samlLogin();
  };

  useEffect(() => {
    if (eventTracked) return;
    setEventTracked(true);
    createAnalyticsEvent("login_page_visit");
  }, [eventTracked]);

  return (
    <Box sx={{ paddingBottom: "100px" }}>
      <img
        src={LoginCoverImg}
        alt="login cover"
        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
      ></img>

      {!errorMessage && (
        <Typography
          variant="h4"
          sx={{ textAlign: "center", mt: 6, fontWeight: "bold", color: theme.palette.text.primary }}
        >
          Login to Revivn
        </Typography>
      )}

      {errorMessage && (
        <Box sx={{ textAlign: "center" }} mt={3}>
          {errorMessage.includes("deactivated") ? (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", mt: 6, fontWeight: "bold", color: theme.palette.text.primary }}
            >
              Your account has been deactivated. Please contact{" "}
              <Link sx={{ my: 2 }} href={`mailto:concierge@revivn.com`}>
                concierge@revivn.com
              </Link>{" "}
              to reactivate your account.
            </Typography>
          ) : (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", mt: 6, fontWeight: "bold", color: theme.palette.text.primary }}
            >
              {errorMessage}
            </Typography>
          )}
        </Box>
      )}

      {!errorMessage && (
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            color: theme.palette.text.secondary,
            mt: 3,
          }}
        >
          Manage your hardware lifecycle and repurpose your unused tech for a greater cause all in one place{" "}
        </Typography>
      )}

      {loadingConfig && (
        <Box sx={{ textAlign: "center" }} mt={3}>
          {" "}
          <Skeleton variant="text" height={75} />
          <Skeleton variant="text" height={75} />
        </Box>
      )}

      {organizationConfiguration?.ssoActive && (
        <Box sx={{ textAlign: "center" }} mt={3}>
          <SecondaryButton
            variant="outlined"
            size="large"
            startIcon={<VpnKey />}
            onClick={() => {
              handleSso();
            }}
          >
            {`Login with ${organizationConfiguration?.ssoProvider}`}
          </SecondaryButton>
          <Box sx={{ marginTop: 6 }}>
            Having Trouble? Reach out to us at{" "}
            <Link sx={{ my: 2 }} href={`mailto:support@revivn.com`}>
              support@revivn.com
            </Link>
          </Box>
        </Box>
      )}

      {!errorMessage && !organizationConfiguration?.ssoActive && (
        <Box sx={{ maxWidth: "660px", mx: "auto" }}>
          <Box mt={4}>
            <Formik
              initialValues={{ email: "", password: "" }}
              onSubmit={async (values) => {
                setInvalidEmailOrPassword(false);
                try {
                  return await login(values);
                } catch {
                  setInvalidEmailOrPassword(true);
                }
              }}
              validationSchema={yup.object({
                email: yup.string().email("Invalid Email").required("Required"),
                password: yup.string().required("required"),
              })}
            >
              {({ getFieldProps, errors, handleSubmit, isSubmitting, touched, isValid }) => (
                <form onSubmit={handleSubmit}>
                  <Box display="flex" flexDirection="column">
                    <FormControl variant="standard">
                      <InputLabel shrink htmlFor="email">
                        Email
                      </InputLabel>
                      <InputBase
                        type="email"
                        {...getFieldProps("email")}
                        id="email"
                        helperText={touched.email && errors.email}
                        error={touched.email && !!errors.email}
                      />
                    </FormControl>

                    <FormControl variant="standard" sx={{ mt: 2 }}>
                      <InputLabel shrink htmlFor="password">
                        Password
                      </InputLabel>
                      <InputBase
                        type="password"
                        {...getFieldProps("password")}
                        id="password"
                        helperText={touched.password && errors.password}
                        error={touched.password && !!errors.password}
                      />
                    </FormControl>

                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      disabled={isSubmitting || !isValid}
                      sx={{ textTransform: "uppercase", mt: 4, alignSelf: "start" }}
                    >
                      Login
                    </Button>

                    {invalidEmailOrPassword && (
                      <Typography paddingTop={2} color="red">
                        Your email and/or password is incorrect.
                      </Typography>
                    )}
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
          <Link sx={{ marginTop: 2, position: "absolute" }} component={RouterLink} to="/create-reset-password">
            Forgot Password?
          </Link>
          <Box sx={{ marginTop: 8, position: "absolute" }}>
            Having Trouble? Reach out to us at{" "}
            <Link sx={{ my: 2 }} href={`mailto:support@revivn.com`}>
              support@revivn.com
            </Link>
          </Box>
        </Box>
      )}
    </Box>
  );
}
