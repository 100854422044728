import { Box, Typography, styled, Switch, useTheme } from "@mui/material";
import WebhooksTable from "./WebhooksTable";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 29,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.primaryPro,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

function Webhooks() {
  let [isChecked, setChecked] = useState(false);
  const { pathname } = useLocation();
  const theme = useTheme();

  return (
    <>
      <Box display="flex" alignItems="center" mt={3}>
        <Box>
          <Typography variant="h5" fontSize={32} fontWeight="bold">
            Webhooks
          </Typography>

          <Typography variant="body1" mt={1}>
            Manage your webhooks
          </Typography>
        </Box>
      </Box>
      <Box
        mt={3}
        sx={{
          p: 4,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
          minHeight: "750px",
        }}
      >
        <Box mt={4}>
          <WebhooksTable />
        </Box>
      </Box>
    </>
  );
}

export default Webhooks;
