import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import useEarnings from "hooks/earnings/useEarnings";
import useSearchParamsOperations from "hooks/useSearchParamsOperations";
import theme from "lib/theme";
import { useEffect, useState } from "react";
import { formatNumberToCurrency, formatDate } from "utils/helpers";

function ActivityTable() {
  const { earningsBalance, earningsTransactions, meta, requestWithdrawal } = useEarnings(true);
  const [pageNumber, setPageNumber] = useState(0);
  const { replace } = useSearchParamsOperations();
  useEffect(() => {
    replace("page[number]", (pageNumber + 1).toString());
  }, [pageNumber, replace]);

  useEffect(() => {
    replace("page[size]", "10");
  }, [replace]);

  return (
    <div>
      <TableContainer
        sx={{
          mt: 2,
        }}
      >
        <Table sx={{ ".MuiTableRow-root:last-child td, .MuiTableRow-root:last-child th": { border: 0 } }}>
          <TableHead sx={{ ".MuiTableCell-root": { color: theme.palette.text.disabled, borderBottom: 0 } }}>
            <TableRow>
              <TableCell>DATE POSTED</TableCell>
              <TableCell>DESCRIPTION</TableCell>
              <TableCell>AMOUNT</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {earningsTransactions &&
              earningsTransactions.map((transaction: any) => {
                return (
                  <>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body2">{formatDate(transaction.publishedAt)}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{transaction.description}</Typography>
                      </TableCell>
                      <TableCell>
                        {transaction.type == "::EarningsCredit" && (
                          <Typography variant="body2" color="success.main">
                            +{formatNumberToCurrency(transaction.amountInCents / 100.0)}
                          </Typography>
                        )}
                        {transaction.type == "::EarningsDebit" && (
                          <Typography variant="body2" color="error.main">
                            -{formatNumberToCurrency(transaction.amountInCents / 100.0)}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={meta.count || 1}
        rowsPerPage={10}
        page={pageNumber}
        onPageChange={(_, page) => {
          setPageNumber(page);
        }}
      />
    </div>
  );
}

export default ActivityTable;
