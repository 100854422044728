import axios from "axios";
import useOnboarding from "hooks/companySettings/useOnboarding";
import subdomain from "lib/subdomain";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TokenService from "services/token.service";
import { objectToSnake } from "ts-case-convert";

export const HOSTNAME = process.env.REACT_APP_API_BASE_URL || "";

export const api = axios.create({
  baseURL: `${HOSTNAME}`,
  withCredentials: true,
});

api.interceptors.request.use(
  (config: any) => {
    if (TokenService.get()) config.headers.Authorization = `Bearer ${TokenService.get()}`;

    if (config.data) {
      config.data = objectToSnake(config.data);
    }

    const organizationSubdomain = subdomain();
    if (organizationSubdomain) {
      config.headers.common["organization-subdomain"] = subdomain();
    }

    return config;
  },
  (error: Error) => {
    return Promise.reject(error);
  }
);

interface SamlSetting {
  provider?: string;
}

export default function useSaml() {
  const { fetchOnboarding } = useOnboarding();
  const navigate = useNavigate();
  const [samlSetting, setSamlSetting] = useState<SamlSetting>();
  const [isLoading, setIsLoading] = useState(false);

  const samlLogin = () => {
    api.post("/saml/v1/init").then((response: any) => {
      window.location.href = response.data.redirect_url;
    });
  };

  const createSamlSettings = (args: any) => {
    api
      .post("/api/v1/saml_settings", { saml_setting: args })
      .then(() => {
        navigate("/company-settings/authentication");
      })
      .finally(() => {
        fetchOnboarding && fetchOnboarding();
      });
  };

  const getSamlSetting = () => {
    api.get("/api/v1/saml_settings").then((response: any) => {
      setSamlSetting(response.data.saml_setting);
      setIsLoading(false);
    });
  };

  const deactivateSaml = () => {
    api
      .delete("/api/v1/saml_settings")
      .then(() => {
        getSamlSetting();
      })
      .finally(() => {
        fetchOnboarding && fetchOnboarding();
      });
  };

  useEffect(() => {
    if (!samlSetting && TokenService.get() && !window.location.href.includes("login")) {
      setIsLoading(true);
      getSamlSetting();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    samlLogin,
    createSamlSettings,
    samlSetting,
    isLoading,
    deactivateSaml,
  };
}
