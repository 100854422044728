import { Alert, AlertTitle, Box, Button, Typography } from "@mui/material";
import theme from "lib/theme";
import HowItWorks from "./HowItWorks";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddressValidationErrors from "./AddressValidationErrors";
import UploadBulkOrder from "./UploadBulkOrder";
import useAssetRetrievalBulkUpload from "hooks/assetRetrievals/useAssetRetrievalBulkUpload";
import useAssetRetrievalBulkValidation from "hooks/assetRetrievals/useAssetRetrievalBulkValidation";
import { useFormikContext } from "formik";
import { AssetRetrievalBulkOrderDetails } from "types/assetRetrieval";
import BulkAssetCart from "./BulkAssetCart";

interface BulkOrderDetailsProps {
  goToNextStep: () => void;
}

export default function BulkOrderDetails({ goToNextStep }: BulkOrderDetailsProps) {
  const { key, setKey, create, submitting } = useAssetRetrievalBulkUpload();
  const {
    validate,
    serverValidationErrors,
    clientValidationErrors,
    blob,
    reset,
    isMutating,
    setClientValidationErrors,
  } = useAssetRetrievalBulkValidation();
  const { setFieldValue, getFieldProps, values } = useFormikContext<AssetRetrievalBulkOrderDetails>();

  const uploadAndValidate = (file: File) => {
    validate({ file }).then(({ blob: { signed_id }, rowCount }) => {
      setKey(signed_id);
      setFieldValue("totalRows", rowCount);
      setFieldValue("fileSignedId", signed_id);
    });
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "2fr 1fr",
        gap: 4,
        alignItems: "start",
        [theme.breakpoints.down("md")]: { gridTemplateColumns: "1fr" },
      }}
    >
      <Box
        sx={{
          py: 3,
          px: 4,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
          [theme.breakpoints.down("lg")]: { px: 2, py: 2 },
        }}
      >
        <Box display="flex" flexDirection="column" gap="10px">
          <Typography variant="body1"> 1. Download and fill out CSV</Typography>
          <Typography variant="body2">
            Download template and fill out all relevant laptop and employee information.{" "}
          </Typography>
          <a
            href={process.env.PUBLIC_URL + "/asset_retrieval_bulk_template.csv"}
            download
            style={{ textDecoration: "none", color: "#202020" }}
          >
            <Button startIcon={<FileDownloadIcon />} variant="outlined">
              download csv template
            </Button>
          </a>
          <Alert severity="info" variant="outlined" color="primary" sx={{ borderColor: theme.palette.grey[300] }}>
            <AlertTitle>Confirm that each row contains:</AlertTitle>
            <ul style={{ marginTop: "0px", marginBottom: "0px", paddingLeft: "15px" }}>
              <li>Serial number for each laptop</li>
              <li>Insurance amount. Accepted options:</li>
              <ul>
                <li>0 - No insurance</li>
                <li>1,000 - (Up to $1,000, +$10/box)</li>
                <li>2,000 - (Up to $2,000, +$20/box)</li>
                <li>3,000 - (Up to $3,000, +$30/box)</li>
                <li>4,000 - (Up to $4,000, +$40/box)</li>
                <li>5,000 - (Up to $5,000, +$50/box)</li>
              </ul>
            </ul>
          </Alert>
          <Typography variant="body1">2. Upload CSV</Typography>
          <Typography variant="body2">Upload your completed CSV here to calculate the total cost.</Typography>

          <Box>
            <UploadBulkOrder
              uploadAndValidate={uploadAndValidate}
              blob={blob}
              reset={reset}
              isLoading={isMutating}
              setClientValidationErrors={setClientValidationErrors}
              anyErrors={serverValidationErrors.length > 0 || clientValidationErrors.length > 0}
            />
          </Box>
          {serverValidationErrors.length > 0 ||
            (clientValidationErrors.length > 0 && (
              <AddressValidationErrors
                serverValidationErrors={serverValidationErrors}
                clientValidationErrors={clientValidationErrors}
              />
            ))}
          {serverValidationErrors.length > 0 && (
            <AddressValidationErrors serverValidationErrors={serverValidationErrors} />
          )}
        </Box>
        <HowItWorks />
      </Box>
      <BulkAssetCart
        onContinue={goToNextStep}
        disableContinue={!values.fileSignedId || serverValidationErrors.length < 0 || clientValidationErrors.length < 0}
      />
    </Box>
  );
}
