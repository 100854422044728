import { useCallback } from "react";
import { api } from "services/api.service";

export default function useSupport() {
  const contactConcierge = useCallback(
    (payload: { phone: string; email: string; comments: string; blobId?: string }) =>
      api.post("/support/contact_concierge", { contactConcierge: payload }),
    []
  );

  return {
    contactConcierge,
  };
}
