import { Typography, Button, Box } from "@mui/material";
import theme from "lib/theme";
import { MissingInfoDrawerProps } from "types/pickup";
import { Cancel, ErrorOutline } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { useState } from "react";
import {
  address,
  contact,
  pickupDate,
  PickupDetailsRow,
  PickupDetailsRows,
  PickupDetailsTitle,
  assets,
  shouldDisableEditPickup,
} from "./shared";
import { Modal } from "components/common/Modal";
import useCurrentUserPermission from "hooks/useCurrentUserPermission";

export function MissingInfoDrawer(props: MissingInfoDrawerProps) {
  let [isOpen, setIsOpen] = useState(false);
  const { hasPermission } = useCurrentUserPermission();

  const { pickup, deletePickup } = props;

  return (
    <Box mb={5} mx={2}>
      <Box
        sx={{
          py: 3,
          px: 4,
          background: "#FAFAFA",
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
        }}
      >
        <ErrorOutline />
        <Typography variant="h6" fontWeight="bold">
          Your pickup is missing info!
        </Typography>
        <Typography>
          We noticed you started a pickup, but we need some more details. Complete your pickup below to secure a date
          and timeslot!
        </Typography>

        <Box mt={2}>
          <PickupDetailsTitle />

          <PickupDetailsRows>
            <PickupDetailsRow
              items={[
                {
                  title: "PICKUP DATE",
                  list: pickupDate(pickup),
                },
                {
                  title: "CONTACT",
                  list: contact(pickup),
                },
                {
                  title: "ADDRESS",
                  list: address(pickup),
                },
              ]}
            />
            <PickupDetailsRow
              items={[
                {
                  title: "ASSETS",
                  list: assets(pickup),
                },
              ]}
            />
          </PickupDetailsRows>
        </Box>
      </Box>

      <Box
        mt={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          gap: 3,
        }}
      >
        <Button
          size="small"
          variant="contained"
          component={RouterLink}
          to={`/pickups/${pickup.uuid}`}
          disabled={shouldDisableEditPickup(pickup) || !hasPermission("pickups")}
        >
          Edit pickup
        </Button>
        <Button
          disabled={!hasPermission("pickups")}
          size="small"
          variant="contained"
          onClick={(e) => {
            setIsOpen(true);
          }}
        >
          delete pickup
        </Button>
      </Box>

      <Modal open={isOpen} setOpen={setIsOpen}>
        <Box sx={{ textAlign: "center" }}>
          <Cancel color="primary" sx={{ fontSize: "60px" }} />

          <Typography fontWeight="bold" fontSize="24px" mt={1}>
            Delete Pickup
          </Typography>

          <Typography mt={0.5} fontSize="20px">
            Are you sure you want to delete this pickup? You can always start a new one by clicking on the button at the
            top!
          </Typography>

          <Box mt={5} sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Button
              variant="contained"
              onClick={() => {
                deletePickup(pickup.uuid);
              }}
            >
              Yes, delete
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              no, don’t Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
