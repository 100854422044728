import { Box, Button, Slide } from "@mui/material";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import ReferOrganization from "./ReferOrganization";
import React from "react";
import { useState } from "react";

function ReferAnOrganization() {
  let [showForm, setShowForm] = useState(false);

  return (
    <Box sx={{ position: "fixed", right: "32px", bottom: 0, width: "100%", maxWidth: "390px" }}>
      <Slide direction="up" in={showForm} mountOnEnter unmountOnExit>
        <Box sx={{ position: "absolute", bottom: 0, right: 0 }}>
          <ClickAwayListener
            onClickAway={() => {
              setShowForm(false);
            }}
          >
            <Box>
              <ReferOrganization />
            </Box>
          </ClickAwayListener>
        </Box>
      </Slide>

      <Slide direction="up" in={!showForm} mountOnEnter unmountOnExit>
        <Box sx={{ position: "absolute", bottom: 0, right: 0 }}>
          <Button
            size="large"
            variant="contained"
            sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
            onClick={() => setShowForm(true)}
          >
            refer an organization
          </Button>
        </Box>
      </Slide>
    </Box>
  );
}

export default ReferAnOrganization;
