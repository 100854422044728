import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "services/api.service";

export default function useAssetUpload() {
  const navigate = useNavigate();
  const [key, setKey] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const create = useCallback(() => {
    setSubmitting(true);
    api
      .post("/asset_uploads", { assetUpload: { file: key } })
      .then(() => {
        navigate("/assets");
      })
      .finally(() => {
        setSubmitting(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return {
    setKey,
    key,
    submitting,
    create,
  };
}
