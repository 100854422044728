import { Box, Divider, useMediaQuery } from "@mui/material";
import theme from "lib/theme";

function AssetCard() {
  let isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        px: 4,
        py: 7,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
        display: "grid",
        gap: 3,
        gridTemplateColumns: "auto 1fr",
        [theme.breakpoints.down("md")]: { px: 2, py: 2 },
        [theme.breakpoints.down("sm")]: { gridTemplateColumns: "1fr", gap: 0 },
      }}
    >
      <Box sx={{ width: "66px", height: "66px", [theme.breakpoints.down("sm")]: { display: "none" } }}>
        <img src={require("assets/images/monitor.png")} style={{ width: "100%", height: "100%" }} alt=""></img>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 6,
          [theme.breakpoints.down("sm")]: { gridTemplateColumns: "1fr", gap: 0 },
        }}
      >
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>Asset Type</Box>
            <Box fontWeight={600}>Laptop</Box>
          </Box>
          <Divider sx={{ my: 3 }} />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>Model</Box>
            <Box fontWeight={600}>MacBook Pro</Box>
          </Box>
          <Divider sx={{ my: 3 }} />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>Serial Number</Box>
            <Box fontWeight={600}>CWEX1H2837ZD</Box>
          </Box>
          {isMobile && <Divider sx={{ my: 3 }} />}
        </Box>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>Manufacturer</Box>
            <Box fontWeight={600}>Apple</Box>
          </Box>
          <Divider sx={{ my: 3 }} />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>Vendor</Box>
            <Box fontWeight={600}>Best Buy</Box>
          </Box>
          <Divider sx={{ my: 3 }} />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>Asset ID</Box>
            <Box fontWeight={600}>101934777</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AssetCard;
