import { Box, Button, Link, Typography } from "@mui/material";
import theme from "lib/theme";
import { DrawerProps } from "types/pickup";
import { Cached } from "@mui/icons-material";
import { ReactComponent as ShippingIcon } from "assets/icons/ShippingBlack.svg";
import {
  address,
  contact,
  orderNumber,
  pickupDate,
  PickupDetailsRow,
  PickupDetailsRows,
  PickupDetailsTitle,
} from "./shared";
import { navActions } from "components/AppLayout/Nav";

export function ProcessingDrawer(props: DrawerProps) {
  const { pickup } = props;
  return (
    <Box mb={5} mx={2}>
      <Box
        sx={{
          py: 3,
          px: 4,
          background: "#FAFAFA",
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
        }}
      >
        {pickup?.virtualPickup ? <ShippingIcon /> : <Cached />}
        <Typography variant="h6" fontWeight="bold">
          {pickup?.virtualPickup ? "Your direct to Revivn laptops were received" : "Your pickup is processing!"}
        </Typography>
        <Typography>
          {pickup?.virtualPickup
            ? "This pickup is ongoing! When devices are sent from employees directly back to Revivn, they will be aggregated under this open-ended collection."
            : "Good news! We just picked up your assets and they're on their way to one of our processing facilities. We will\n" +
              "          publish an initial report of what we collected from you in the near future."}
        </Typography>

        <Box mt={2}>
          <PickupDetailsTitle />

          <PickupDetailsRows>
            <PickupDetailsRow
              columnCount={4}
              items={
                pickup.virtualPickup
                  ? [
                      {
                        title: "PICKUP ID",
                        list: orderNumber(pickup),
                      },
                      {
                        title: "OPEN ORDERS",
                        list: [pickup.assetRetrievalsToDate || 0],
                      },
                      {
                        title: "ASSETS RETRIEVED",
                        list: [
                          pickup.inventoryCount ? (
                            pickup.inventoryCount > 0 ? (
                              <Link
                                href={`/assets?page[number]=1&page[size]=25&filter[]=state%2Cis_any_of%2Cretired&filter[]=pickup_id%2Cis_any_of%2C${pickup.id}`}
                              >
                                {pickup.inventoryCount}
                              </Link>
                            ) : (
                              "0"
                            )
                          ) : (
                            "0"
                          ),
                        ],
                      },
                      {
                        title: "Enrolled Assets",
                        list: [
                          pickup.enrolledCount ? (
                            pickup.enrolledCount > 0 ? (
                              <Link
                                href={`/assets?page[number]=1&page[size]=25&filter[]=state%2Cis_any_of%2Cretired&filter[]=pickup_id%2Cis_any_of%2C${pickup.id}&filter[]=status%2Cis%2CEnrolled`}
                              >
                                {pickup.enrolledCount}
                              </Link>
                            ) : (
                              "0"
                            )
                          ) : (
                            "0"
                          ),
                        ],
                      },
                    ]
                  : [
                      {
                        title: "PICKUP ID",
                        list: orderNumber(pickup),
                      },
                      {
                        title: "PICKUP DATE",
                        list: pickupDate(pickup),
                      },
                      {
                        title: "CONTACT",
                        list: contact(pickup),
                      },
                      {
                        title: "ADDRESS",
                        list: address(pickup),
                      },
                    ]
              }
            />
          </PickupDetailsRows>
        </Box>
      </Box>

      <Box
        mt={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          gap: 3,
        }}
      >
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            navActions.openContactConcierge();
          }}
        >
          questions? contact us
        </Button>
      </Box>
    </Box>
  );
}
