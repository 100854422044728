import { useEffect, useState } from "react";
import { api } from "services/api.service";
import { WebhookSubscription } from "types/webhookSubscription";

export default function useWebhookSubscriptions() {
  const [fetchedWebhookSubscriptions, setFetchedWebhooksSubscriptions] = useState(false);
  const [webhookSubscriptions, setWebhookSubscriptions] = useState<WebhookSubscription[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const getWebhookSubscriptions = () => {
    setIsLoading(true);
    api.get(`/webhook_subscriptions`).then((response) => {
      setWebhookSubscriptions(response.data.webhookSubscriptions);
      setIsLoading(false);
      setReload(false);
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!fetchedWebhookSubscriptions || reload) {
      getWebhookSubscriptions();
      setFetchedWebhooksSubscriptions(true);
    }
  });

  return {
    webhookSubscriptions,
    isLoading,
    setReload,
  };
}
