import { TableRow, TableCell } from "@mui/material";
import { PickupStatus } from "pages/Pickups/PickupStatus";
import { Pickup } from "types/pickup";
import { formatDate } from "utils/helpers";

export default function PickUpRow({ pickup }: { pickup: Pickup }) {
  return (
    <TableRow>
      <TableCell>{formatDate(pickup.scheduledAt)}</TableCell>
      <TableCell>{pickup.location && pickup.location.address}</TableCell>
      <TableCell>
        <PickupStatus pickup={pickup} />
      </TableCell>
    </TableRow>
  );
}
