import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { api } from "services/api.service";
import { Pickup } from "types/pickup";
import { PickupEstimatedItem } from "types/pickupEstimatedItem";
import { PickupContact } from "types/pickupContact";
import { ActiveStorageAttachment } from "types/activeStorageAttachment";

export default function useUpdatePickup() {
  const params = useParams();

  interface UpdatePickupProps {
    locationId?: string;
    reason?: string;
    comments?: string;
    scheduledAt?: string;
    requestedTime?: string;
    estimatedItems?: PickupEstimatedItem[];
    images?: ActiveStorageAttachment[];
    contacts?: PickupContact[];
  }

  const DEFAULT_PICKUPS_ROUTE = "schedule-pickup";

  const updatePickup = useCallback(
    ({
      locationId,
      reason,
      scheduledAt,
      requestedTime,
      estimatedItems,
      images,
      contacts,
      comments,
    }: UpdatePickupProps) => {
      if (params.pickupUuid === DEFAULT_PICKUPS_ROUTE) {
        return;
      }
      api.put<Pickup>(`/pickups/${params.pickupUuid}`, {
        pickup: {
          locationId,
          reason,
          scheduledAt,
          requestedTime,
          estimatedItems,
          images,
          contacts,
          comments,
        },
      });
    },
    [params.pickupUuid]
  );

  return {
    updatePickup,
  };
}
