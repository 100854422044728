import { Box, Tab, Tabs } from "@mui/material";
import Breadcrumbs, { BreadcrumbItem } from "components/common/Breadcrumbs";
import useAnalytics from "hooks/analytics/useAnalytics";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

function ReportingAndDocuments() {
  let location = useLocation();
  let navigate = useNavigate();
  const { createAnalyticsEvent } = useAnalytics();
  const [eventTracked, setEventTracked] = useState(false);

  useEffect(() => {
    if (eventTracked) return;
    setEventTracked(true);
    createAnalyticsEvent("reporting_and_documents_page_visit");
  }, [eventTracked]);

  let [, ...pathSegments] = location.pathname.split("/").filter(Boolean);

  return (
    <div>
      <Box>
        <Breadcrumbs
          data={
            [
              { label: "Dashboard", to: "/" },
              { label: "Reporting & Documents", to: `/reporting-and-documents` },
              pathSegments[0] === "reporting"
                ? [
                    { label: "Reporting", to: `/reporting-and-documents/reporting` },
                    // {
                    //   label: { "standard-reports": "Standard Reports", "custom-reports": "Custom Reports" }[
                    //     pathSegments[1]
                    //   ],
                    //   to: pathSegments[2] ? `/reporting-and-documents/reporting/custom-reports` : undefined,
                    // },
                    // pathSegments[2] && {
                    //   label: { "create-report": "Create Custom Report" }[pathSegments[2]],
                    // },
                  ]
                : { label: "Documents" },
            ]
              .flatMap((a) => a)
              .filter(Boolean) as BreadcrumbItem[]
          }
        />
      </Box>

      <Box mt={4}>
        <Tabs
          value={pathSegments[0]}
          onChange={(e, value) => {
            navigate(`/reporting-and-documents/${value}`);
          }}
        >
          <Tab label="Reporting" value="reporting" />
          <Tab label="Documents" value="documents" />
        </Tabs>

        <Box sx={{ padding: "24px 0" }}>
          <Outlet />
        </Box>
      </Box>
    </div>
  );
}

export default ReportingAndDocuments;
