import { Typography, Button, Box, Alert, AlertTitle, Tooltip } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Link as RouterLink } from "react-router-dom";
import PickupTable from "./PickupTable";
import theme from "lib/theme";
import useCurrentUserPermission from "hooks/useCurrentUserPermission";
import { useEffect, useState } from "react";
import useAnalytics from "hooks/analytics/useAnalytics";
import usePickups from "hooks/pickups/usePickups";
import useLocations from "hooks/locations/useLocations";
import PickupFilters from "./PickupFilters";

function Pickups() {
  let showAlert = false;
  const { hasPermission } = useCurrentUserPermission();
  const { createAnalyticsEvent } = useAnalytics();
  const [eventTracked, setEventTracked] = useState(false);
  const {
    pickups,
    errorMessage,
    isLoading,
    getPickups,
    hasNextPage,
    deletePickup,
    markPickupUnenrolled,
    incrementCurrentPage,
  } = usePickups();
  const {
    pickups: filterPickups,
    incrementCurrentPage: filterIncrementCurrentPage,
    hasNextPage: filterHasNextPage,
    isLoading: filterIsLoading,
  } = usePickups(true);
  const { locations } = useLocations();

  useEffect(() => {
    if (eventTracked) return;
    setEventTracked(true);
    createAnalyticsEvent("pickups_page_visit");
  }, [eventTracked]);

  return (
    <div>
      <Box>
        <Breadcrumbs data={[{ label: "Dashboard", to: "/" }, { label: "Pickups" }]}></Breadcrumbs>
      </Box>

      {showAlert && (
        <Box sx={{ clear: "both", display: "flex" }}>
          <Box sx={{ my: 5, width: "100%", [theme.breakpoints.down("sm")]: { my: 3 } }}>
            <Alert
              sx={{
                ".MuiAlert-icon": {
                  [theme.breakpoints.down("sm")]: { display: "none" },
                },
              }}
              action={
                <Box display="flex" alignItems="center" gap={1}>
                  <Button
                    size="small"
                    sx={{
                      [theme.breakpoints.down("sm")]: { display: "none" },
                    }}
                  >
                    Add assets
                  </Button>
                  <IconButton color="primary" size="small" onClick={() => {}}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              }
              icon={null}
              severity="info"
            >
              <AlertTitle>Connect your Live Assets to leverage Revivn End of Life Intelligence</AlertTitle>
            </Alert>
          </Box>
        </Box>
      )}

      <Box
        sx={[
          { float: "right", [theme.breakpoints.down("sm")]: { float: "none" } },
          !showAlert && {
            position: "relative",
            top: "-24px",
            [theme.breakpoints.down("sm")]: { position: "static", mt: 2 },
          },
        ]}
      >
        <Tooltip
          title={
            hasPermission("pickups")
              ? ""
              : "You do not have permissions for this action, please contact your administrator for permission."
          }
        >
          <span>
            <Button
              component={RouterLink}
              variant="contained"
              size="large"
              to="/pickups/schedule-pickup"
              disabled={!hasPermission("pickups")}
            >
              Start a Pickup
            </Button>
          </span>
        </Tooltip>
      </Box>

      <Typography variant="h4" mt={{ xs: 3, sm: showAlert ? 0 : 3 }} fontWeight="bold">
        Pickups
      </Typography>

      <Typography variant="body1" mt={1}>
        Start a new pickup and repurpose your hardware below! It’s easier (and better) than sending it to landfills.
      </Typography>

      <PickupFilters
        filterPickups={filterPickups}
        pickups={pickups}
        locations={locations}
        errorMessage={errorMessage}
        isLoading={filterIsLoading}
        hasNextPage={filterHasNextPage}
        incrementCurrentPage={filterIncrementCurrentPage}
      />

      <PickupTable
        pickups={pickups}
        errorMessage={errorMessage}
        isLoading={isLoading}
        getPickups={getPickups}
        hasNextPage={hasNextPage}
        deletePickup={deletePickup}
        markPickupUnenrolled={markPickupUnenrolled}
        incrementCurrentPage={incrementCurrentPage}
      />
    </div>
  );
}

export default Pickups;
