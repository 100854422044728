import { useCallback } from "react";
import { api } from "services/api.service";
import { AssetRetrieval } from "./assetRetrievals/useAssetRetrieval";
import { AssetRetrievalBulkOrderDetails } from "types/assetRetrieval";

export function useValidateAddress() {
  const validateAssetRetrievalAddress = useCallback((assetRetrieval: AssetRetrieval) => {
    return api.post("addresses/validate", {
      addressLine1: assetRetrieval.employeeAddress,
      addressLine2: assetRetrieval.employeeAddressTwo,
      cityLocality: assetRetrieval.employeeAddressCity,
      stateProvince: assetRetrieval.employeeAddressState,
      postalCode: assetRetrieval.employeeAddressZipCode,
    });
  }, []);

  const validateAssetRetrievalReturnAddress = useCallback(
    (assetRetrieval: AssetRetrieval | AssetRetrievalBulkOrderDetails) => {
      return api.post("addresses/validate", {
        addressLine1: assetRetrieval.returnRecipientAddress,
        addressLine2: assetRetrieval.returnRecipientAddressTwo,
        cityLocality: assetRetrieval.returnRecipientAddressCity,
        stateProvince: assetRetrieval.returnRecipientAddressState,
        postalCode: assetRetrieval.returnRecipientAddressZipCode,
      });
    },
    []
  );

  return {
    validateAssetRetrievalAddress,
    validateAssetRetrievalReturnAddress,
  };
}
