import useOnboarding from "hooks/companySettings/useOnboarding";
import { useCallback } from "react";
import { api } from "services/api.service";
import { Location } from "types/location";

export default function useDeleteLocation(refetch: () => Promise<void>) {
  const { fetchOnboarding } = useOnboarding();

  const deleteLocation = useCallback(
    ({ uuid }: Location) =>
      api
        .delete<Location>(`/locations/${uuid}`)
        .then((_) => {
          refetch();
        })
        .finally(() => {
          fetchOnboarding && fetchOnboarding();
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    deleteLocation,
  };
}
