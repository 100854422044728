import { useState } from "react";
import { Box, Button, FormControl, Typography } from "@mui/material";
import theme from "lib/theme";
import Breadcrumbs from "components/common/Breadcrumbs";
import useCurrentUser from "hooks/users/useCurrentUser";
import { useFormik } from "formik";
import { InputBase, InputLabel } from "components/common/Input";
import UpdatePassword from "./resetPassword";

function Profile() {
  const [updatePasswordOpen, setUpdatedPasswordOpen] = useState(false);
  const { currentUser, updateCurrentUser, updateCurrentUserPassword } = useCurrentUser();

  const formik = useFormik({
    initialValues: currentUser,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateCurrentUser(values);
    },
  });

  return (
    <div>
      <Box>
        <Breadcrumbs data={[{ label: "Dashboard", to: "/" }, { label: "Profile" }]}></Breadcrumbs>
      </Box>

      <Typography variant="h4" mt={{ xs: 3 }} fontWeight="bold">
        User Information
      </Typography>

      <div>
        <Box
          sx={{
            mt: 3,
            p: 4,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box display="flex" gap={2} pb={5} borderBottom={`1px solid ${theme.palette.divider}`}>
            <Box
              sx={{
                p: 4,
                backgroundColor: "#DDF3FC",
                borderRadius: 45,
              }}
            >
              <Typography variant="h2" sx={{ color: "rgba(0,0,0,0.38)" }} fontWeight="bold">
                {`${currentUser.firstName[0]}${currentUser.lastName[0]}`}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="space-between">
              <Typography variant="h5" sx={{ color: "rgba(0,0,0,0.6)" }} fontWeight="bold">
                {currentUser.firstName} {currentUser.lastName}
              </Typography>
              <Typography variant="h5" sx={{ color: "rgba(0,0,0,0.6)" }} fontWeight="thin">
                {currentUser.email}
              </Typography>
              <Button
                variant="text"
                sx={{ justifyContent: "start", padding: 0 }}
                onClick={() => setUpdatedPasswordOpen(true)}
              >
                Change Password
              </Button>
            </Box>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mt: 4, display: "flex", flexDirection: "column", gap: 3 }}>
              <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink htmlFor="first-name">
                    First Name
                  </InputLabel>
                  <InputBase id="first-name" placeholder="First Name" {...formik.getFieldProps("firstName")} />
                </FormControl>

                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink htmlFor="last-name">
                    Last Name
                  </InputLabel>
                  <InputBase id="last-name" placeholder="Last Name" {...formik.getFieldProps("lastName")} />
                </FormControl>
              </Box>

              <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink htmlFor="email">
                    Email
                  </InputLabel>
                  <InputBase id="email" placeholder="Email" {...formik.getFieldProps("email")} />
                </FormControl>
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink htmlFor="phone">
                    Phone Number
                  </InputLabel>
                  <InputBase id="phone" placeholder="Phone Number" {...formik.getFieldProps("phoneNumber")} />
                </FormControl>
              </Box>
            </Box>
            <Box textAlign="right" mt={3}>
              {formik.dirty ? (
                <Button variant="outlined" type="submit">
                  Save Updates
                </Button>
              ) : null}
            </Box>
          </form>
        </Box>
      </div>
      <UpdatePassword
        open={updatePasswordOpen}
        setOpen={setUpdatedPasswordOpen}
        updatePassword={updateCurrentUserPassword}
      />
    </div>
  );
}

export default Profile;
