import { useCallback, useEffect, useState } from "react";
import { api } from "services/api.service";
import { Location } from "types/location";

export default function useLocations({ uuid }: { uuid: string | undefined }) {
  const [location, setLocation] = useState<Location>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const fetachLocation = useCallback(() => {
    setIsLoading(true);
    setIsError(false);
    api
      .get<Location>(`/locations/${uuid}`)
      .then((res) => {
        setLocation(res.data);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [uuid]);

  useEffect(() => {
    fetachLocation();
  }, []);

  return {
    location,
    isLoading,
    isError,
    fetachLocation,
  };
}
