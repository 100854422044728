import { useAlert } from "hooks/alerts/useAlert";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { api } from "services/api.service";
import { Asset } from "types/asset";

export default function useUpdateAsset({ refetch }: { refetch: () => void }) {
  const [, setSearchParams] = useSearchParams();
  const { alertSuccess } = useAlert();

  const update = useCallback((asset: Asset) => {
    api.put(`/assets/${asset.uuid}`, { asset }).then(() => {
      setSearchParams({});
      refetch();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pendingPickup = useCallback((uuid: string) => {
    api.put(`/assets/${uuid}/pending_pickup`).then(() => {
      refetch();
      alertSuccess("You've successfully updated the asset!", {
        vertical: "bottom",
        horizontal: "right",
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unlockAsset = useCallback((uuid: string) => {
    api.put(`/assets/${uuid}`, { enrolledStatus: "unlocked" }).then(() => {
      refetch();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reportDamage = useCallback((uuid: string, damageNotes: string) => {
    api.put(`/assets/${uuid}`, { damageNotes }).then(() => {
      refetch();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notFoundAsset = useCallback((uuid: string) => {
    return api.put(`/assets/${uuid}/asset_not_found`).then(() => {
      refetch();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    update,
    pendingPickup,
    unlockAsset,
    reportDamage,
    notFoundAsset,
  };
}
