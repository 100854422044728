import { Pickup } from "types/pickup";

interface TimeMap {
  [index: string]: string;
}

const timeConverter: TimeMap = {
  "9": "9:00 AM",
  "13": "1:00 PM",
};

export function mainContact(pickup?: Pickup) {
  return (
    pickup?.pickupContacts?.find((contact) => {
      return contact.pointOfContactType === "main";
    }) || { firstName: "", lastName: "", phoneNumber: "", email: "" }
  );
}

export function otherContacts(pickup?: Pickup) {
  return (
    pickup?.pickupContacts.filter((contact) => {
      return contact.pointOfContactType !== "main";
    }) || [{ email: "" }]
  );
}
