import { Box, Modal as MUIModal, IconButton, Button, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useState, useContext } from "react";
import PaywallFeatures from "./PaywallFeatures";
import PaywallBackground from "./PaywallBackground.png";
import useSubscriptions from "hooks/companySettings/useSubscriptions";
import { OrganizationConfigurationContext } from "hooks/useOrganizationConfiguration";
import useAnalytics from "hooks/analytics/useAnalytics";
import { useAlert } from "hooks/alerts/useAlert";
import { RequestSentConfirmationModal } from "components/RequestSentConfirmationModal";

export default function PaywallModal({
  trigger: Trigger,
  feature,
}: {
  trigger: React.FC<{ onClick: React.MouseEventHandler<HTMLButtonElement> }>;
  feature: string;
}) {
  const [open, setOpen] = useState(false);
  const { requestPlanUpgrade } = useSubscriptions();
  const organizationConfiguration = useContext(OrganizationConfigurationContext);
  const { createAnalyticsEvent } = useAnalytics();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { alertError } = useAlert();

  const handleContactSales = () => {
    if (organizationConfiguration?.organizationPlan?.plan) {
      requestPlanUpgrade(String(organizationConfiguration.organizationPlan.subscription.id), feature)
        .then(() => {
          setOpenConfirmation(true);
          setOpen(false);
        })
        .catch(() => {
          alertError("Something went wrong, please try again.");
        });
    }
    setOpen(false);
  };

  const handleTriggerClicked: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    createAnalyticsEvent(`triggered_paywall_modal-${feature}`);
    setOpen(true);
  };

  return (
    <>
      <Trigger onClick={handleTriggerClicked} />
      <MUIModal open={open} onClose={() => setOpen(false)} disableAutoFocus={true}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            height: "100%",
            maxWidth: "570px",
            maxHeight: "650px",
            bgcolor: "background.paper",
            boxShadow: 24,
            overflow: "scroll",
          }}
        >
          <IconButton
            onClick={() => setOpen(false)}
            sx={{ position: "absolute", top: "26px", right: "26px", color: "#FFFFFF" }}
          >
            <Close />
          </IconButton>

          <Box sx={{ textAlign: "center", alignContent: "center", m: "40px" }}>
            <Typography fontWeight="700" fontSize="26px">
              Unlock powerful new features
            </Typography>
            <Typography variant="body1">
              Talk to our team to activate features at no cost and make hardware end of life even simpler.
            </Typography>
            <Box
              sx={{
                backgroundImage: `url(${PaywallBackground})`,
                backgroundSize: "cover",
                pt: "5px",
              }}
            >
              <PaywallFeatures />
            </Box>

            <Box display="flex" justifyContent="center" gap={2}>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                no, go back
              </Button>
              <Button variant="contained" onClick={() => handleContactSales()}>
                contact us
              </Button>
            </Box>
          </Box>
        </Box>
      </MUIModal>
      <RequestSentConfirmationModal open={openConfirmation} setOpen={setOpenConfirmation} />
    </>
  );
}
