import { Button, ListItemIcon, Menu, MenuItem, MenuList, Tooltip, ListItemText } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { ContentCopy, ArrowDropDown, LockOpen } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

type AssetsDataGridActionsProps = {
  assetsTab: string;
  setSecurityLocksOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRetiredAssetOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAssetIds: GridRowId[];
  getAllSerialsNumbers: (asset_ids?: GridRowId[], selectAllFlag?: boolean) => Promise<void>;
  selectAllFlag?: boolean;
};

export default function AssetsDataGridActions({
  assetsTab,
  setSecurityLocksOpen,
  setRetiredAssetOpen,
  selectedAssetIds,
  getAllSerialsNumbers,
  selectAllFlag,
}: AssetsDataGridActionsProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { search } = useLocation();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const isEnrolled = search && search.includes("status%2Cis%2CEnrolled");

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopySerials = () => {
    getAllSerialsNumbers(selectedAssetIds, selectAllFlag).then(() => {
      handleClose();
    });
  };

  useEffect(() => {
    setAnchorEl(null);
  }, [selectedAssetIds]);

  return (
    <>
      <Tooltip title="Select assets to perform bulk actions">
        <span>
          <Button
            onClick={handleClick}
            variant="contained"
            disabled={!selectedAssetIds.length}
            endIcon={<ArrowDropDown />}
          >
            Actions
          </Button>
        </span>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "20ch",
          },
        }}
      >
        {assetsTab.includes("live") && (
          <MenuItem
            onClick={(e) => {
              setRetiredAssetOpen(true);
            }}
          >
            Retire Assets
          </MenuItem>
        )}

        {assetsTab.includes("retired") && (
          <MenuList>
            <MenuItem
              onClick={(e) => {
                setSecurityLocksOpen(true);
              }}
            >
              <ListItemIcon>
                <LockOpen fontSize="small" />
              </ListItemIcon>
              <ListItemText>Confirm released</ListItemText>
            </MenuItem>
            {isEnrolled && (
              <MenuItem onClick={handleCopySerials}>
                <ListItemIcon>
                  <ContentCopy fontSize="small" />
                </ListItemIcon>
                <ListItemText>Copy serials</ListItemText>
              </MenuItem>
            )}
          </MenuList>
        )}
      </Menu>
    </>
  );
}
