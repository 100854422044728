import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import { useLocation, useSearchParams } from "react-router-dom";
import OrderDetails from "./OrderDetails";
import StartOrder from "./StartOrder";
import { useCallback } from "react";
import PlaceOrder from "./Place Order";
import theme from "lib/theme";

function AssetsReturn() {
  let location = useLocation();
  let [, uuid, path] = location.pathname.split("/").filter(Boolean);
  let [searchParams, setSearchParams] = useSearchParams();

  let step = parseInt(searchParams.get("step") ?? "0");

  let goToNextStep = useCallback(() => {
    setSearchParams({ step: String(step + 1) }, { replace: true });
  }, [setSearchParams, step]);

  return (
    <div>
      <Box>
        <Breadcrumbs
          data={[
            { label: "Dashboard", to: "/" },
            { label: "Assets", to: "/assets" },
            { label: "Asset Details", to: `/assets/${uuid}/details` },
            { label: { retrieve: "Remote Asset Return" }[path] },
          ]}
        ></Breadcrumbs>
      </Box>

      <Box mt={4}>
        <Typography variant="h4" fontWeight="bold">
          {{ 0: "Start Order", 1: "Order Details" }[step]}
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Stepper
            activeStep={step}
            sx={{
              width: "100%",
              maxWidth: "900px",
              mt: 5,
              ".MuiStepLabel-root": {
                [theme.breakpoints.down("sm")]: {
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 0.5,
                },
              },
            }}
          >
            <Step>
              <StepLabel>Start Order</StepLabel>
            </Step>
            <Step>
              <StepLabel>Order Details</StepLabel>
            </Step>
            <Step>
              <StepLabel>Place Order</StepLabel>
            </Step>
          </Stepper>
        </Box>
      </Box>

      <Box sx={{ mt: 4 }}>
        {step === 0 ? (
          <StartOrder goToNextStep={goToNextStep} />
        ) : step === 1 ? (
          <OrderDetails goToNextStep={goToNextStep} />
        ) : step === 2 ? (
          <PlaceOrder goToNextStep={goToNextStep} />
        ) : null}
      </Box>
    </div>
  );
}

export default AssetsReturn;
