import { Box, Typography } from "@mui/material";
import theme from "lib/theme";
import { ReactComponent as EmptyEarningsIcon } from "./images/empty-earnings.svg";

export default function EmptyUseEarningsToDonate() {
  return (
    <Box
      sx={{
        mt: 4,
        background: "white",
        borderRadius: 1,
        border: `1px solid ${theme.palette.divider}`,
        px: 6,
        py: 5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          px: 1,
        }}
      >
        <Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 3 }}
          >
            <Typography fontSize="15px" textAlign="center">
              Looks like you don’t currently have earnings, but you can easily add some by scheduling a net positive
              pickup with Revivn! When you do have earnings, you’ll be able to adjust the amount of funds you’d like to
              donate to this specific request below and place your donation with a click of a button.
            </Typography>
            <Box
              sx={{
                width: "200px",
                height: "200px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <EmptyEarningsIcon style={{ width: "200px", height: "200px" }} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
