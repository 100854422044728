import { createContext, useCallback, useContext, useState } from "react";
import { api } from "services/api.service";

type Feature = { [F: string]: boolean };

type FeatureFlagContext = {
  features: Feature;
  addFeature: (name: string, enabled: boolean) => void;
};

const FeatureFlagContextValue = createContext<FeatureFlagContext>({
  features: {},
  addFeature: () => {},
});

export const FeatureFlagProvider = ({ children }: { children: React.ReactNode }) => {
  const [features, setFeatures] = useState<Feature>({});

  const addFeature = useCallback(
    (name: string, enabled: boolean) => {
      setFeatures((features) => ({ ...features, [name]: enabled }));
    },
    [setFeatures]
  );

  const value = {
    features,
    addFeature,
  };

  return <FeatureFlagContextValue.Provider value={value}>{children}</FeatureFlagContextValue.Provider>;
};

export default function (name: string) {
  const { features, addFeature } = useContext(FeatureFlagContextValue);
  const fetchFeature = useCallback(
    (name: string) => {
      if (!name) {
        return false;
      }
      api.get(`/feature_flags/${name}`).then(({ data: { enabled } }) => {
        addFeature(name, enabled);
      });
    },
    [addFeature]
  );

  const enabled = features[name];

  if (enabled === undefined) fetchFeature(name);

  return { enabled: enabled, loading: enabled === undefined };
}
