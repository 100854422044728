import { Box, Grow, LinearProgress, Typography } from "@mui/material";
import { Attachment, CloseOutlined, RemoveModeratorOutlined, VerifiedUserOutlined } from "@mui/icons-material";
import FileDropSection from "components/FileDropSection";
import { Blob } from "activestorage";
import { useMemo, useState } from "react";
import theme from "lib/theme";
import { useFormikContext } from "formik";
import { AssetRetrievalBulkOrderDetails } from "types/assetRetrieval";
import { RowError } from "csv-file-validator";
import Papa from "papaparse";

type ParsedRow = { [key: string]: string };

type UploadBulkOrderProps = {
  blob?: Blob;
  uploadAndValidate: (file: File) => void;
  reset: () => void;
  isLoading?: boolean;
  setClientValidationErrors: React.Dispatch<React.SetStateAction<RowError[]>>;
  anyErrors: boolean;
};

export default function UploadBulkOrder({
  uploadAndValidate,
  blob,
  reset,
  isLoading,
  setClientValidationErrors,
  anyErrors,
}: UploadBulkOrderProps) {
  const [file, setFile] = useState<File | null>(null);
  const { setFieldValue, values, setValues } = useFormikContext<AssetRetrievalBulkOrderDetails>();

  useMemo(() => {
    if (file) {
      const fileReader = new FileReader();

      fileReader.onloadend = (e: ProgressEvent<FileReader>) => {
        const content = e.target?.result as string;
        const results = Papa.parse<ParsedRow>(content, { header: true });

        const sum = results.data.reduce((acc, row) => {
          const insuranceValue = row["Insurance(0, 1000, 2000, 3000, 4000, 5000)"];
          const value = Number(insuranceValue);
          return !isNaN(value) ? acc + value : acc;
        }, 0);

        setFieldValue("insuranceOption", sum / 100);
      };

      fileReader.readAsText(file);
    }
  }, [file]);

  return blob || file ? (
    <Box pt={2}>
      <Typography color="text.secondary" fontWeight="700">
        Attached:
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", width: "fit-content" }}>
        <Box
          key={blob?.signed_id}
          component="a"
          href={blob?.signed_id}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: "4px",
            p: 1,
            pr: 3,
            color: theme.palette.primary.main,
            display: "flex",
            alignItems: "center",
            gap: "10px",
            fontFamily: "Catamaran",
            textDecoration: "none",
            position: "relative",
            width: "fit-content",
          }}
        >
          <Attachment />
          {blob?.filename || file?.name}

          <Box
            component="button"
            sx={{
              height: "24px",
              width: "24px",
              background: theme.palette.primary.main,
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: 0,
              borderRadius: "100%",
              position: "absolute",
              top: "-4px",
              right: "-8px",
              cursor: "pointer",
            }}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.preventDefault();
              reset();
              setFile(null);
              setValues({ ...values, totalRows: 0, insuranceOption: "0" });
              setClientValidationErrors([]);
            }}
          >
            <CloseOutlined />
          </Box>
          {anyErrors ? (
            <Grow
              in={!isLoading}
              style={{ transformOrigin: "0 0 0" }}
              {...(values.fileSignedId ? { timeout: 500 } : {})}
            >
              {<RemoveModeratorOutlined />}
            </Grow>
          ) : (
            <Grow
              in={!isLoading && values.fileSignedId ? true : false}
              style={{ transformOrigin: "0 0 0" }}
              {...(values.fileSignedId ? { timeout: 500 } : {})}
            >
              {<VerifiedUserOutlined />}
            </Grow>
          )}
        </Box>
        {isLoading && (
          <Box display="flex" flexDirection="column" alignItems="center">
            <LinearProgress sx={{ mt: 1, width: "100%" }} />
            <Typography variant="caption">Validating Addresses...</Typography>
          </Box>
        )}
      </Box>
    </Box>
  ) : (
    <>
      <FileDropSection
        acceptHint={"csv"}
        accept={{
          "text/csv": [],
        }}
        onUpload={(files) => {
          setFile(files[0]);
          uploadAndValidate(files[0]);
        }}
      />
    </>
  );
}
