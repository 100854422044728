import useUsers from "hooks/users/useUsers";
import UserForm from "./Form";

const newUser = {
  firstName: "",
  lastName: "",
  email: "",
  roles: [{ name: "" }],
};

export default function NewUser() {
  const { createUser } = useUsers();
  return <UserForm user={newUser} onSubmit={createUser} />;
}
