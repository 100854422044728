import { Box, Divider, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import theme from "lib/theme";
import AssetCartCheckout from "./AssetCartCheckout";
import { Modal } from "components/common/Modal";
import { useFormikContext } from "formik";
import useAssetRetrieval, { AssetRetrieval } from "hooks/assetRetrievals/useAssetRetrieval";
import { useNavigate } from "react-router-dom";
import useEarnings from "../../../hooks/earnings/useEarnings";
import { bulkOrderTotal } from "../../../utils/assetRetrieval";
import useFeatureFlag from "hooks/useFeatureFlag";
import NewPaymentMethod from "../../CompanySettings/PaymentInfo/newPaymentMethod";
import { usePaymentMethods } from "../../../hooks/paymentMethods/usePaymentMethods";
import { useLocation } from "react-router";
import { CheckCircleOutline } from "@mui/icons-material";
import { AssetRetrievalBulkOrderDetails } from "types/assetRetrieval";

interface PlaceOrderProps {
  goToNextStep: () => void;
}

function BulkPlaceOrder({ goToNextStep }: PlaceOrderProps) {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const { pricing } = useAssetRetrieval();
  const [open, setOpen] = useState(false);
  const [newCreditCardOpen, setNewCreditCardOpen] = useState(false);
  const { earningsBalance, isLoading: isLoadingEarnings } = useEarnings();
  const navigate = useNavigate();
  const { getFieldProps, submitForm, errors, touched, values, setFieldValue, setValues } =
    useFormikContext<AssetRetrievalBulkOrderDetails>();
  const total = bulkOrderTotal(values.totalRows, values.insuranceOption, pricing, values);
  const { paymentMethods } = usePaymentMethods();
  const stripePaymentCallback = query.get("paymentCallback") === "true";

  useEffect(() => {
    if (stripePaymentCallback) {
      setValues(
        localStorage.getItem("assetRetrieval")
          ? { ...JSON.parse(localStorage.getItem("assetRetrieval")!), paymentType: "payment" }
          : ({} as AssetRetrieval)
      );
    }
  }, []);
  return (
    <>
      {pricing && !isLoadingEarnings && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: !values.virtualPickup ? "1.5fr 1fr" : "1fr",
            gap: 4,
            alignItems: "start",
            [theme.breakpoints.down("md")]: { gridTemplateColumns: "1fr" },
          }}
        >
          {!values.virtualPickup && (
            <RadioGroup defaultValue="earnings">
              <Box
                sx={{
                  py: 3,
                  px: 4,
                  background: "white",
                  borderRadius: 1,
                  border: `1px solid ${theme.palette.divider}`,
                  [theme.breakpoints.down("lg")]: { px: 2, py: 2 },
                }}
              >
                <FormControlLabel
                  {...getFieldProps("paymentType")}
                  value="earnings"
                  control={<Radio disabled={total > earningsBalance} />}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Pay with Earnings{" "}
                      {total > earningsBalance && (
                        <Typography color="error">
                          Insufficient Funds. You can choose to pay using Stripe below.
                        </Typography>
                      )}
                    </Box>
                  }
                />
                <Box>
                  <Typography>
                    We’ll deduct this amount from your total earnings! You’ll see this transaction posted to your
                    earnings page shortly.
                  </Typography>
                  <Box mx={4}>
                    <Box mt={4} sx={{ display: "flex", justifyContent: "space-between", color: "text.secondary" }}>
                      <Box>Current Earnings Balance</Box>
                      <Box>${earningsBalance}</Box>
                    </Box>
                    <Box mt={1} sx={{ display: "flex", justifyContent: "space-between", color: "text.secondary" }}>
                      <Box>Order Total</Box>
                      <Box>-${total}</Box>
                    </Box>
                    <Divider />
                    <Box mt={1} sx={{ display: "flex", justifyContent: "space-between", color: "text.secondary" }}>
                      <Box>New Earnings Balance</Box>
                      <Box>${earningsBalance - total}</Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  mt: 4,
                  py: 3,
                  px: 4,
                  background: "white",
                  borderRadius: 1,
                  border: `1px solid ${theme.palette.divider}`,
                  [theme.breakpoints.down("lg")]: { px: 2, py: 2 },
                }}
              >
                <FormControlLabel
                  {...getFieldProps("paymentType")}
                  value="payment"
                  control={<Radio disabled={!paymentMethods || paymentMethods?.length === 0} />}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Pay with Monthly Invoice
                    </Box>
                  }
                />
                <Box>
                  <Typography>
                    We’ll send you an invoice via Stripe at the end of the month and you can pay it with a credit card.
                  </Typography>
                </Box>
                {paymentMethods?.length === 0 && !stripePaymentCallback && (
                  <Box mt={3} display="flex" justifyContent="space-between">
                    <Typography color="error">You need to add a credit card to use this option</Typography>
                    <Typography
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        localStorage.setItem("assetRetrieval", JSON.stringify(values));
                        setNewCreditCardOpen(true);
                      }}
                    >
                      Add a payment method
                    </Typography>
                  </Box>
                )}
              </Box>
            </RadioGroup>
          )}

          <AssetCartCheckout
            onContinue={(invoiced?: boolean) => {
              if (invoiced) {
                setFieldValue("paymentType", "quickbook_invoice");
              }
              submitForm().then(() => {
                localStorage.setItem("assetRetrieval", JSON.stringify(values));
                setOpen(true);
              });
            }}
            continueLabel="place order"
            total={total}
            paymentType={values.paymentType}
          />
        </Box>
      )}

      <Modal open={open} setOpen={setOpen} onClose={() => navigate("/retrievals/send-boxes")}>
        <Box sx={{ px: 3 }}>
          <CheckCircleOutline color="primary" sx={{ fontSize: "25px" }} />

          <Typography variant="h5" fontWeight="bold">
            Your order has been placed!
          </Typography>

          <Typography mt={0.5} color="text.secondary">
            You have successfully submitted {values.totalRows} order(s) for a total of ${total} dollars
            {values.paymentType === "earnings"
              ? " that will be deducted from your earnings balance."
              : " that will be charged to your card on file at the end of the month."}
          </Typography>
          <Typography color="text.secondary">
            You will be cc'd on the email correspondence and can check back here for tracking status updates!
          </Typography>
        </Box>
      </Modal>

      <Modal open={newCreditCardOpen} setOpen={setNewCreditCardOpen}>
        <Box sx={{ px: 3 }}>
          <NewPaymentMethod />
        </Box>
      </Modal>
    </>
  );
}

export default BulkPlaceOrder;
