import { CreditCard } from "@mui/icons-material";
import { Box, Radio, Typography } from "@mui/material";
import theme from "lib/theme";

export function VisaCard() {
  return (
    <Box
      sx={{
        p: 3,
        background: "white",
        borderRadius: 1,
        border: `1px solid ${theme.palette.divider}`,
        display: "grid",
        gridTemplateColumns: `auto 1fr`,
        alignItems: "center",
        gap: 1,
        cursor: "pointer",
        userSelect: "none",
        boxSizing: "border-box",
      }}
      role="button"
    >
      <Radio
        // @ts-ignore
        size="medium"
        name="radio-buttons"
        inputProps={{ "aria-label": "A" }}
      />

      <Typography variant="h6" fontWeight="bold" color="text.secondary" sx={{ display: "flex", alignItems: "center" }}>
        Use visa ending in 1673 <CreditCard sx={{ ml: 2, fontSize: "40px" }} />
      </Typography>
    </Box>
  );
}
