import { Box, Typography, FormControl, RadioGroup, FormControlLabel, Radio, Button } from "@mui/material";
import useSaml from "hooks/saml/useSaml";
import theme from "lib/theme";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

export function SelectProvider() {
  const navigate = useNavigate();
  const { samlSetting } = useSaml();
  const [provider, setProvider] = useState<string>("");

  const handleChange = (e: any) => {
    setProvider(e.target.value);
  };

  useEffect(() => {
    if (samlSetting?.provider === "okta") {
      setProvider("okta");
    } else if (samlSetting?.provider === "onelogin") {
      setProvider("onelogin");
    } else {
      setProvider("custom");
    }
  }, [samlSetting]);

  return (
    <div>
      <Box
        mt={4}
        sx={{
          px: 5,
          py: 3,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography variant="h6" fontWeight="600">
          SAML authentication
        </Typography>
        <Typography>Get set up with Okta, OneLogin, or your custom SAML 2.0 solution</Typography>

        <Box mt={4}>
          <Typography fontWeight="600">Choose your SAML provider</Typography>
          <Box mt={2}>
            <FormControl>
              <RadioGroup
                value={provider}
                onChange={(e) => {
                  handleChange(e);
                }}
              >
                <FormControlLabel value="okta" control={<Radio />} label="Okta" />
                <FormControlLabel value="onelogin" control={<Radio />} label="OneLogin" />
                <FormControlLabel value="custom" control={<Radio />} label="Custom SAML 2.0" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </Box>

      <Box sx={{ mt: 5, display: "flex", gap: 2 }}>
        <Button size="large" variant="outlined" onClick={() => navigate(-1)}>
          Back
        </Button>
        <Button
          size="large"
          component={RouterLink}
          variant="contained"
          to={`/company-settings/authentication/provider/${provider}`}
        >
          Next
        </Button>
      </Box>
    </div>
  );
}
