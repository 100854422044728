import { AddLocationAlt, LocationCity } from "@mui/icons-material";
import { Box, Divider, Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import theme from "lib/theme";
import useLocations from "hooks/locations/useLocations";
import useDeleteLocation from "hooks/locations/useDeleteLocation";
import useCurrentUserPermission from "hooks/useCurrentUserPermission";
import { useConfirmation } from "hooks/useConfirmation";
import { Location } from "types/location";

export default function NewLocation() {
  return <LocationsList />;
}

export function LocationsList() {
  const { locations, refetch } = useLocations();
  const { deleteLocation } = useDeleteLocation(refetch);
  const routeLocation = useLocation();
  const { hasPermission } = useCurrentUserPermission();
  const { confirm } = useConfirmation();

  const handleDelete = (location: Location) => {
    confirm({
      handler: () => deleteLocation(location),
      title: "Delete Location",
      message: `Are you sure you want to delete ${location.address} location?`,
    });
  };

  return (
    <Box mt={4} sx={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))", gap: 2 }}>
      {locations &&
        locations.map((location) => (
          <Box
            key={location.uuid}
            sx={{
              py: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "white",
              borderRadius: 1,
              border: `1px solid ${theme.palette.divider}`,
              minHeight: "150px",
            }}
          >
            <Box sx={{ display: "flex", flexFlow: "column", alignItems: "center", padding: "0 10px" }}>
              <Typography
                variant="body2"
                fontWeight="600"
                textTransform="uppercase"
                sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "center" }}
              >
                <LocationCity />
                {location.name}

                <Box> {location.address}</Box>
              </Typography>

              {hasPermission("locations") && (
                <Stack mt={2} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                  <Link
                    sx={{ textDecoration: "none" }}
                    component={RouterLink}
                    to={`/company-settings/locations/${location.uuid}/edit`}
                    state={{ originalPath: routeLocation.pathname }}
                  >
                    Edit
                  </Link>
                  <Link sx={{ textDecoration: "none" }} component={"button"} onClick={() => handleDelete(location)}>
                    Delete
                  </Link>
                </Stack>
              )}
            </Box>
          </Box>
        ))}
      {hasPermission("locations") && (
        <Box
          component={"button"}
          sx={{
            py: 4,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
            borderRadius: 1,
            border: `1px dashed ${theme.palette.divider}`,
            minHeight: "150px",
            ":hover": {
              borderColor: theme.palette.primary.main,
              background: "#DDF3FC",
            },
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", justifyContent: "center", textDecoration: "none" }}
            component={RouterLink}
            to={"/company-settings/locations/new"}
            state={{ originalPath: routeLocation.pathname }}
          >
            <Typography
              variant="body2"
              fontWeight="600"
              sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "center" }}
              color="primary"
            >
              <AddLocationAlt />
              Add new location
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
