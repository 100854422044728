import { Box, Chip, Collapse, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import theme from "lib/theme";
import { formatNumberToCurrency } from "utils/helpers";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import useSWR from "swr";
import { fetcher } from "services/api.service";
interface TransactionDetails {
  boxCount: number;
  serviceFee: number;
  miscellaneousFees: number;
  shipping: number;
  discountPercentage: number;
  insuranceCost: [{ value: number }];
  total: number;
}
export default function ActivityRow({ transaction }: any) {
  const [open, setOpen] = useState(false);
  const { data, isLoading } = useSWR<TransactionDetails>(
    open ? `/earnings/virtual_pickup_transaction_details/${transaction.uuid}` : null,
    fetcher
  );

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography variant="body2">{transaction.description}</Typography>
        </TableCell>
        <TableCell>
          {transaction.type == "::EarningsCredit" && (
            <Typography variant="body2" color="success.main">
              +{formatNumberToCurrency(transaction.amountInCents / 100.0)}
            </Typography>
          )}
          {transaction.type == "::EarningsDebit" && !transaction.waived && (
            <Typography variant="body2" color="error.main">
              -{formatNumberToCurrency(transaction.amountInCents / 100.0)}
            </Typography>
          )}
          {transaction.type == "::EarningsDebit" && transaction.waived && (
            <Typography variant="body2" color="secondary.main">
              -{formatNumberToCurrency(transaction.displayAmountInCents / 100.0)}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          {transaction.type == "::EarningsDebit" && transaction.waived && (
            <Chip
              style={{ color: "white", fontWeight: 600, marginLeft: "10px", fontSize: "12px" }}
              color="secondary"
              label="FEES WAIVED"
            />
          )}
        </TableCell>
        <TableCell>
          {transaction.description.includes("Remote Retrieval") ? (
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : null}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: open ? undefined : 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {isLoading && <Box>Loading...</Box>}
            {data && (
              <Box sx={{ my: 1, backgroundColor: theme.palette.grey[50] }}>
                <Box
                  sx={{
                    display: "flex",
                    padding: "12px 36px",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "16px",
                    alignSelf: "stretch",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      alignSelf: "stretch",
                    }}
                  >
                    <Typography variant="body2" color="textSecondary">
                      Processing Fees ($10 x {data.boxCount} {data.boxCount > 1 ? "boxes" : "box"})
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      ${data.miscellaneousFees}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      alignSelf: "stretch",
                    }}
                  >
                    <Typography variant="body2" color="textSecondary">
                      Shipping Fee ($70.00 x {data.boxCount} {data.boxCount > 1 ? "boxes" : "box"})
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      ${data.shipping}
                    </Typography>
                  </Box>
                  {data.insuranceCost.length > 0 &&
                    data.insuranceCost.map((insurance, index) => {
                      const [insuranceValue, insuranceCount] = Object.entries(insurance)[0];
                      return (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            alignSelf: "stretch",
                          }}
                        >
                          <Typography variant="body2" color="textSecondary">
                            Up to ${insuranceValue} Insurance (${Number(insuranceValue) / 100} x {insuranceCount}
                            {insuranceCount > 1 ? " boxes" : " box"})
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            ${(Number(insuranceValue) / 100) * insuranceCount}
                          </Typography>
                        </Box>
                      );
                    })}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      alignSelf: "stretch",
                      paddingBottom: data.discountPercentage === 0 ? "12px" : null,
                      borderBottom: data.discountPercentage === 0 ? `1px solid ${theme.palette.divider}` : null,
                    }}
                  >
                    <Typography variant="body2" color="textSecondary">
                      Data Wipe & Audit ($10 x {data.boxCount} {data.boxCount > 1 ? "boxes" : "box"})
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      ${10 * data.boxCount}
                    </Typography>
                  </Box>
                  {data.discountPercentage > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        alignSelf: "stretch",
                        paddingBottom: "12px",
                        borderBottom: `1px solid ${theme.palette.divider}`,
                      }}
                    >
                      <Typography variant="body2" color={theme.palette.primaryPro.main}>
                        Discount ({Number(data.discountPercentage)}%)
                      </Typography>
                      <Typography variant="body2" color={theme.palette.primaryPro.main}>
                        ${data.total - data.total * data.discountPercentage}
                      </Typography>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      alignSelf: "stretch",
                    }}
                  >
                    <Typography fontWeight="600" fontSize="14px">
                      TOTAL
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      ${data.total}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      alignSelf: "stretch",
                    }}
                  >
                    <Typography fontWeight="600" color={theme.palette.primaryPro.main}>
                      Fees waived for Direct to Revivn
                    </Typography>
                    <Typography fontWeight="600" color={theme.palette.primaryPro.main}>
                      ${data.total}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
