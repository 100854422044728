import { Box, Button, Typography, Divider } from "@mui/material";
import { InputBase } from "components/common/Input";
import theme from "lib/theme";

interface AssetCartProps {
  onContinue: () => void;
  continueLabel?: string;
  disableContinue?: boolean;
}

function AssetCart({ onContinue, continueLabel = "Continue", disableContinue }: AssetCartProps) {
  return (
    <Box
      sx={{
        py: 3,
        px: 4,
        background: "white",
        borderRadius: 1,
        border: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.down("lg")]: { px: 2, py: 2 },
      }}
    >
      <Typography fontWeight="bold" variant="h5">
        Your cart
      </Typography>
      <Typography mt={2} fontWeight="bold" fontSize={"18px"}>
        Asset return - QR code
      </Typography>
      <Box
        sx={{ my: 2, display: "flex", justifyContent: "space-between", alignItems: "center", color: "text.secondary" }}
      >
        <label htmlFor="assets-quantity">Quantity:</label>
        <InputBase type="number" id="assets-quantity" sx={{ width: "80px" }} defaultValue="1" />
      </Box>
      <Divider />
      <Box sx={{ py: 2, display: "flex", flexDirection: "column", gap: 0.5 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", color: "text.secondary" }}>
          <Box>Subtotal</Box>
          <Box>TBD</Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", color: "text.secondary" }}>
          <Box>Tax</Box>
          <Box>TBD</Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", color: "text.secondary" }}>
          <Box>Insurance</Box>
          <Box>$0</Box>
        </Box>
      </Box>
      <Divider />
      <Box mt={2} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography fontSize={"20px"} fontWeight="bold" color="primary">
          Total
        </Typography>
        <Typography fontSize={"20px"} fontWeight="bold" color="primary">
          TBD
        </Typography>
      </Box>
      <Box mt={5}>
        <Button fullWidth variant="contained" onClick={onContinue} disabled={disableContinue}>
          {continueLabel}
        </Button>
      </Box>

      <Typography mt={4} color="text.secondary">
        *This is an estimated calculation of the shipping rate and local tax that we’ll need to collect. Your credit
        card or account won’t be charged until we get the actual costs of this transaction.
      </Typography>
    </Box>
  );
}

export default AssetCart;
