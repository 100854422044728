import { useCallback, useEffect, useState } from "react";
import { Typography, Button, Box, MenuItem, FormControl } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import useSearchParamsOperations from "hooks/useSearchParamsOperations";
import { AppSelect } from "components/common/Input";
import { formatDate } from "utils/helpers";
import { Pickup } from "types/pickup";
import { Location } from "types/location";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { MenuProps } from "constants/filterMenuProps";

interface PickupFiltersProps {
  filterPickups: Pickup[];
  pickups: Pickup[];
  locations: Location[];
  isLoading: boolean;
  hasNextPage: boolean;
  errorMessage: null;
  incrementCurrentPage: () => void;
}
export default function PickupFilters({
  filterPickups,
  pickups,
  locations,
  isLoading,
  hasNextPage,
  errorMessage,
  incrementCurrentPage,
}: PickupFiltersProps) {
  const { deleteFieldInArrayUsingPrefix, replaceInArrayFieldUsingPrefix } = useSearchParamsOperations();
  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: incrementCurrentPage,
    disabled: !!errorMessage,
    rootMargin: "0px 0px 400px 0px",
  });

  const [searchParams] = useSearchParams();

  const [pickupIds, setPickupIds] = useState(
    searchParams
      .getAll("filter[]")
      .find((p) => p.includes("id,is_any_of"))
      ?.split("id,is_any_of,")[1]
      ?.split(",") || []
  );
  const [locationId, setLocationId] = useState(
    searchParams
      .getAll("filter[]")
      .find((p) => p.includes("location_id,is"))
      ?.split("location_id,is,")[1] || ""
  );

  const cleanFilters = useCallback(() => {
    setPickupIds([]);
    setLocationId("");
  }, []);

  useEffect(() => {
    if (pickupIds && pickupIds.length > 0) {
      replaceInArrayFieldUsingPrefix("filter[]", "id,is_any_of", `id,is_any_of,${pickupIds.join(",")}`);
    } else {
      deleteFieldInArrayUsingPrefix("filter[]", "id,is_any_of");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupIds]);

  useEffect(() => {
    if (locationId && locationId.length > 0) {
      replaceInArrayFieldUsingPrefix("filter[]", "location_id,is", `location_id,is,${locationId}`);
    } else {
      deleteFieldInArrayUsingPrefix("filter[]", "location_id,is");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  return (
    <Box
      display="grid"
      gridTemplateColumns="auto 1fr 1fr 1fr 1fr 1fr auto"
      columnGap="1em"
      marginY={2}
      alignItems="center"
    >
      <Typography width="100%">Quick Filters:</Typography>
      <FormControl variant="standard" size="small" sx={{ width: "100%" }}>
        <AppSelect
          multiple
          MenuProps={MenuProps}
          value={pickupIds}
          onChange={(e) => setPickupIds(e.target.value as string[])}
          renderValue={(values) =>
            values.length === 0
              ? "Pickup Date"
              : values.length === 1
              ? formatDate(pickups.find(({ id }) => id.toString() === values[0].toString())?.scheduledAt || "")
              : `${values.length} pickups selected`
          }
          displayEmpty
        >
          {filterPickups
            .filter(({ scheduledAt }) => scheduledAt)
            .map((pickup) => (
              <MenuItem key={pickup.id} value={pickup.id} ref={sentryRef}>
                {formatDate(pickup.scheduledAt)}- {pickup.orderNumber}
              </MenuItem>
            ))}
        </AppSelect>
      </FormControl>
      <FormControl variant="standard" sx={{ width: "100%" }}>
        <AppSelect value={locationId} onChange={(e) => setLocationId(e.target.value.toString())} displayEmpty>
          <MenuItem value="">Location</MenuItem>
          {locations.map((location) => (
            <MenuItem key={location.id} value={location.id}>
              {location.name}
            </MenuItem>
          ))}
        </AppSelect>
      </FormControl>

      <Button variant={"outlined"} onClick={cleanFilters}>
        Clear All
      </Button>
    </Box>
  );
}
