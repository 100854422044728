import { useEffect, useState } from "react";
import { api } from "services/api.service";

export default function useCompletedPickup(pickupUuid: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [completedPickupInfo, setCompletedPickupInfo] = useState({
    devicesRepurposed: "",
    devicesRecycled: "",
    lbsRecycled: "",
    earnings: 0,
    waivedFees: 0,
    trpUrl: "",
    codUrl: "",
    impactReportUrl: "",
    earningsCredit: "",
  });

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    api
      .get(`/pickups/${pickupUuid}/completed_pickup_info`)
      .then((res) => {
        setCompletedPickupInfo(res.data);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    completedPickupInfo,
    isLoading,
    isError,
  };
}
