import { api, fetcher } from "services/api.service";
import useSWR from "swr";

export const usePaymentMethods = () => {
  const { data, isLoading, mutate } = useSWR("/payment_methods", fetcher);
  const paymentMethods = data?.paymentMethods || [];

  const deletePaymentMethod = (paymentMethod: any) => {
    api.delete(`/payment_methods/${paymentMethod.uuid}`).then(() => {
      mutate();
    });
  };

  const setDefaultPaymentMethod = (paymentMethod: any) => {
    api.put(`/payment_methods/${paymentMethod.uuid}`, { active: true }).then(() => {
      mutate();
    });
  };

  return {
    paymentMethods,
    deletePaymentMethod,
    setDefaultPaymentMethod,
    isLoading,
  };
};
