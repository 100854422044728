import { Outlet, useLocation } from "react-router-dom";
import UserSetupSteps from "./UserSetupSteps";

function UserSetup() {
  let location = useLocation();

  let [, stepPath] = location.pathname.split("/").filter(Boolean);

  let activeStep = { "company-info": 1, "add-locations": 2, "connect-assets": 3 }[stepPath];

  return (
    <div>
      <UserSetupSteps
        activeStep={activeStep}
        steps={[
          { label: "Account setup" },
          { label: "Company info" },
          { label: "Add locations" },
          { label: "Connect assets" },
        ]}
      />

      <Outlet />
    </div>
  );
}

export default UserSetup;
