import { Button } from "@mui/material";
import FileDownload from "@mui/icons-material/FileDownload";
import useAssetExport from "hooks/assets/useAssetExport";

export default function AssetsExportGridItem() {
  const { exportAssets, exportedAssets, downloadAssetsExport, setDownloadAssetsExport } = useAssetExport();

  if (downloadAssetsExport) {
    setDownloadAssetsExport(false);
    const blob = new Blob([exportedAssets], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "assets.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  return (
    <Button
      startIcon={<FileDownload />}
      size="small"
      onClick={() => {
        exportAssets();
      }}
    >
      Export
    </Button>
  );
}
