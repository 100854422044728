import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import { ReactComponent as CarIcon } from "./images/car.svg";
import PickupLocation from "./PickupLocation";
import { Link as RouterLink } from "react-router-dom";
import usePickup from "hooks/pickups/usePickup";
import PickupReason from "./PickupReason";
import PickupEstimatedItems from "./PickupEstimatedItems";
import PickupImages from "./PickupImages";
import PickupMainContact from "./PickupMainContact";
import PickupOtherContacts from "./PickupOtherContacts";
import PickupDate from "./PickupDate";
import { useState, useEffect, useCallback } from "react";
import StepCard from "./StepCard";
import useAnalytics from "hooks/analytics/useAnalytics";
import PickupItemsDetails from "./PickupItemsDetails";
import theme from "../../../lib/theme";

function SchedulePickup() {
  const { pickup } = usePickup();
  const { createAnalyticsEvent } = useAnalytics();
  const [reminderVisible, setReminderVisible] = useState(true);
  const [eventTracked, setEventTracked] = useState(false);

  let [activeStep, setActiveStep] = useState(0);

  // @ts-ignore
  window.setActiveStep = setActiveStep;

  let advanceActiveStep = useCallback(
    (step: number) => {
      if (step > activeStep) {
        setActiveStep(step);
      }
    },
    [activeStep]
  );

  useEffect(() => {
    if (eventTracked) return;
    setEventTracked(true);
    createAnalyticsEvent("start_schedule_pickup");
  }, [eventTracked]);

  useEffect(() => {
    if (!pickup) {
      return advanceActiveStep(1);
    }

    if (pickup.pickupContacts.length > 0) {
      return advanceActiveStep(6);
    }

    if (pickup.pickupImages.length > 0) {
      return advanceActiveStep(5);
    }

    if (pickup.pickupEstimatedItems.length > 0) {
      return advanceActiveStep(4);
    }

    if (pickup.reason) {
      return advanceActiveStep(3);
    }

    if (pickup?.location) {
      return advanceActiveStep(2);
    }

    advanceActiveStep(1);
  }, [pickup, advanceActiveStep]);

  return (
    <div>
      <Box>
        <Breadcrumbs
          data={[{ label: "Dashboard", to: "/" }, { label: "Pickups", to: "/pickups" }, { label: "Schedule a pickup" }]}
        ></Breadcrumbs>
      </Box>

      <Stack spacing={{ md: 5, xs: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <CarIcon style={{ width: "270px" }} />
          </Box>

          <Box>
            <Typography variant="h4" mt={{ md: 3, xs: 0 }} fontWeight="bold">
              Schedule a pickup
            </Typography>
            <Typography mt={1}>
              Revivn aims to repurpose as much hardware as possible by turning it into items that people can still use,
              keeping pressure off the landfills and saving the energy that comes with having to dismantle and
              remanufacture products. A sustainable pickup is net positive for the earth - it contains more items that
              can be repurposed vs pure waste. Laptops and computers tend to be highly repurposable items and help fuel
              Revivn's mission.
            </Typography>
          </Box>
        </Box>

        {reminderVisible && (
          <Alert
            severity="info"
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              ".MuiAlert-icon": { alignItems: "center", color: theme.palette.primary.contrastText },
            }}
            onClose={() => {
              setReminderVisible(false);
            }}
          >
            <strong>Reminder:</strong> Before scheduling a pickup to have our team come on-site, please follow your
            internal processes to remove all locks and deprovision all devices that are no longer in use and ready to be
            collected. Releasing devices from Apple Business Manager or other Device Enrollment Programs prior to a
            pickup with Revivn will ensure the quickest processing times and help you avoid your final report from being
            put on hold.
          </Alert>
        )}

        <StepCard step={1} activeStep={activeStep}>
          <PickupLocation goToNextStep={() => advanceActiveStep(2)} />
        </StepCard>

        <StepCard step={2} activeStep={activeStep}>
          <PickupReason goToNextStep={() => advanceActiveStep(3)} />
        </StepCard>

        <StepCard step={3} activeStep={activeStep}>
          <PickupItemsDetails goToNextStep={activeStep <= 3 || 4 ? () => advanceActiveStep(5) : undefined} />
        </StepCard>

        <StepCard step={5} activeStep={activeStep}>
          <PickupMainContact goToNextStep={() => advanceActiveStep(6)} />
        </StepCard>

        <StepCard step={6} activeStep={activeStep}>
          <PickupOtherContacts />
        </StepCard>

        <StepCard
          step={7}
          activeStep={activeStep}
          disable={activeStep < 6 || pickup?.state === "pending_confirmation" || pickup?.state === "scheduled"}
        >
          <PickupDate />
        </StepCard>

        {pickup && (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button component={RouterLink} size="large" variant="outlined" to={`/pickups`}>
              Back
            </Button>
            {pickup.state === "missing_info" ? (
              <Button component={RouterLink} size="large" variant="contained" to={`/pickups/${pickup.uuid}/summary`}>
                Next
              </Button>
            ) : (
              <Button component={RouterLink} size="large" variant="contained" to={`/pickups`}>
                Save
              </Button>
            )}
          </Box>
        )}
      </Stack>
    </div>
  );
}

export default function SchedulePickupWrapper() {
  return <SchedulePickup />;
}
