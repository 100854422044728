import { Close } from "@mui/icons-material";
import { Box, Modal as MUIModal, IconButton, Chip, Typography, Button } from "@mui/material";
import { useState, useEffect, useContext, FunctionComponent } from "react";
import { ReactComponent as FeatureBackground } from "./FeatureBackground.svg";
import theme from "lib/theme";
import { Link } from "react-router-dom";
import useSubscriptions from "hooks/companySettings/useSubscriptions";
import { OrganizationConfigurationContext } from "hooks/useOrganizationConfiguration";
import { RequestSentConfirmationModal } from "components/RequestSentConfirmationModal";
import { useAlert } from "hooks/alerts/useAlert";
import Cookies from "js-cookie";
import useFeatureFlag from "hooks/useFeatureFlag";

type Feature = {
  title: string;
  description: string[];
};

interface FeatureContentProps {
  featureContent: {
    title: string;
    header: string;
    features: Feature[];
    buttonText: string;
    buttonLink: string;
    cookieName: string;
    salesRequest: string;
    featureFlagName: string;
  };
  SvgElement: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export function FeatureModal({ featureContent, SvgElement }: FeatureContentProps) {
  const { requestPlanUpgrade } = useSubscriptions();
  const organizationConfiguration = useContext(OrganizationConfigurationContext);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { alertError } = useAlert();
  const { enabled: enabledFeature } = useFeatureFlag(featureContent.featureFlagName);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const cookie = Cookies.get(featureContent.cookieName);
    if ((!!enabledFeature || !featureContent.featureFlagName) && !cookie) {
      setShowModal(true);
    }
  }, [enabledFeature]);

  const dismissModal = () => {
    Cookies.set(featureContent.cookieName, "true");
    setShowModal(false);
  };

  const handleContactSales = () => {
    if (organizationConfiguration?.organizationPlan?.plan) {
      requestPlanUpgrade(
        String(organizationConfiguration.organizationPlan.subscription.id),
        featureContent.salesRequest
      )
        .then(() => {
          setOpenConfirmation(true);
          setShowModal(false);
        })
        .catch(() => {
          alertError("Something went wrong, please try again.");
        });
    }
  };
  return (
    <>
      <MUIModal open={showModal} onClose={dismissModal} disableAutoFocus={true}>
        <Box
          sx={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "816px",
            height: "437px",
            bgcolor: "background.paper",
            boxShadow: 24,
            padding: 3,
          }}
        >
          <SvgElement style={{ position: "absolute", top: 0, right: 0, width: "48%", height: "100%" }} />
          <IconButton
            onClick={dismissModal}
            sx={{ position: "absolute", top: "26px", right: "26px", color: "primary" }}
          >
            <Close />
          </IconButton>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: 2,
            }}
          >
            <Box p={2}>
              <Chip
                label="NEW FEATURE"
                sx={{
                  backgroundColor: theme.palette.lightBlue.main,
                  color: theme.palette.primary.main,
                  fontWeight: 700,
                }}
              />
              <Typography fontSize={"24px"} fontWeight={600} sx={{ mt: 2 }}>
                {featureContent.title}
              </Typography>
              <Typography variant="body1" fontWeight={600} sx={{ mt: 1 }}>
                {featureContent.header}
              </Typography>
              {featureContent.features.map((feature, featureIndex) => (
                <Box key={featureIndex}>
                  <Typography variant="body1" fontWeight={600} sx={{ mt: 1 }}>
                    {feature.title}
                  </Typography>
                  {feature.description.map((description, descriptionIndex) => (
                    <Typography variant="body2" key={descriptionIndex} sx={{ mt: 1 }}>
                      &bull; {description}
                    </Typography>
                  ))}
                </Box>
              ))}
              <Box mt={2}>
                <Link to={featureContent.buttonLink}>
                  <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={dismissModal}>
                    {featureContent.buttonText}
                  </Button>
                </Link>
                <Button variant="outlined" color="primary" onClick={handleContactSales} sx={{ mt: 2, ml: 2 }}>
                  learn more
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </MUIModal>
      <RequestSentConfirmationModal open={openConfirmation} setOpen={setOpenConfirmation} dismissModal={dismissModal} />
    </>
  );
}
