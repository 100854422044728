import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import theme from "lib/theme";
import ActivityRow from "./ActivityRow";

function ActivityTable(transactions: any) {
  return (
    <div>
      <TableContainer
        sx={{
          mt: 2,
        }}
      >
        <Table sx={{ ".MuiTableRow-root:last-child td, .MuiTableRow-root:last-child th": { border: 0 } }}>
          <TableHead sx={{ ".MuiTableCell-root": { color: theme.palette.text.disabled, borderBottom: 0 } }}>
            <TableRow>
              <TableCell>DESCRIPTION</TableCell>
              <TableCell>AMOUNT</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions &&
              transactions.transactions.map((transaction: any) => {
                return <ActivityRow transaction={transaction} />;
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ActivityTable;
