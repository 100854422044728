import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { Box, Typography, IconButton, FormControl, debounce } from "@mui/material";
import { InputBase, InputLabel } from "components/common/Input";
import {
  startOfMonth,
  startOfWeek,
  addMonths,
  isSunday,
  isSaturday,
  format,
  isSameMonth,
  addDays,
  differenceInDays,
  endOfMonth,
  startOfDay,
} from "date-fns";
import usePickup from "hooks/pickups/usePickup";
import useUpdatePickup from "hooks/pickups/useUpdatePickup";
import theme from "lib/theme";
import { useState, useMemo, useEffect, useCallback } from "react";

export default function PickupDate() {
  return (
    <Box>
      <Typography variant="h6" fontSize="18px" fontWeight="bold">
        7. When would you like us to pick up the items?
      </Typography>
      <Typography mt={0.5} variant="body2">
        Request a day and time for us to come! If you’re trying to request a date within the next 2 weeks, we’ll do our
        best to accommodate you. While we can’t guarantee spots, we make every effort and will contact you to confirm.
      </Typography>

      <Box mt={3}>
        <Calendar />
      </Box>
    </Box>
  );
}

interface CalendarDay {
  date: Date;
  day: string;
  week: string;
  type: "out" | "used" | "free";
}

const DaysToNotBeAvailable = 7;

function Calendar() {
  const { updatePickup } = useUpdatePickup();
  const { pickup } = usePickup();

  let shouldBeFreeAfter = useMemo(() => {
    let today = startOfDay(new Date());
    return addDays(today, DaysToNotBeAvailable);
  }, []);

  let isFreeAfter = useCallback(
    (day: Date) => {
      return differenceInDays(day, shouldBeFreeAfter) >= 0;
    },
    [shouldBeFreeAfter]
  );

  let [month, setMonth] = useState(() => {
    let monthStart = startOfMonth(new Date());
    while (true) {
      let lastDayOfMonth = startOfDay(endOfMonth(monthStart));
      if (isFreeAfter(lastDayOfMonth)) {
        return monthStart;
      }
      monthStart = addMonths(monthStart, 1);
    }
  });

  let [time, setTime] = useState<{ date?: Date; requestedTime?: string } | null>(null);

  const [comments, setComments] = useState("");

  useEffect(() => {
    if (pickup?.scheduledAt) {
      var parsed = new Date(pickup?.scheduledAt);

      var utcDate = new Date(parsed.getUTCFullYear(), parsed.getUTCMonth(), parsed.getUTCDate());

      setTime({
        date: utcDate,
        requestedTime: pickup?.requestedTime,
      });

      setMonth(startOfMonth(utcDate));
    }
  }, [pickup]);

  let isBackDisable = useMemo(() => {
    let endOfLastMonth = startOfDay(endOfMonth(addMonths(month, -1)));
    return !isFreeAfter(endOfLastMonth);
  }, [isFreeAfter, month]);

  let monthDays = useMemo(() => {
    let firstDayOfTheWeek = startOfWeek(month);
    let nextMonth = addMonths(month, 1);

    let days: CalendarDay[] = [];
    let p = firstDayOfTheWeek;
    do {
      if (isSunday(p) || isSaturday(p)) {
        continue;
      }

      let day = format(p, "d");
      let week = format(p, "EEEEEE");

      days.push({
        date: p,
        day,
        week,
        type: isSameMonth(p, month) ? (isFreeAfter(p) ? "free" : "used") : "out",
      });
    } while ((p = addDays(p, 1)) && (isSameMonth(p, nextMonth) ? days.length % 5 !== 0 : true));

    // check first row if empty we should delete it
    let isFirstRowShouldBeRemoved = days[4].type === "out";
    if (isFirstRowShouldBeRemoved) {
      days.splice(0, 5);
    }

    return days;
  }, [isFreeAfter, month]);

  function nextMonth() {
    setMonth(startOfMonth(addMonths(month, 1)));
  }

  function prevMonth() {
    if (!isBackDisable) {
      setMonth(startOfMonth(addMonths(month, -1)));
    }
  }

  const formattedDate = (date?: Date) => {
    if (!date) return;
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  const handleChange = useCallback(
    debounce((comments: string) => {
      setComments(comments);
      updatePickup({ comments: comments });
    }, 200),
    [pickup]
  );

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Typography fontSize={"20px"} fontWeight="600" width="170px">
            {format(month, "MMMM yyyy")}
          </Typography>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            <IconButton disabled={isBackDisable} size="small" sx={{ svg: { fontSize: "18px" } }} onClick={prevMonth}>
              <ArrowBackIosNew />
            </IconButton>
            <IconButton size="small" sx={{ svg: { fontSize: "18px" } }} onClick={nextMonth}>
              <ArrowForwardIos />
            </IconButton>
          </Box>
        </Box>

        <Box mt={1} px={{ xs: 0, md: 2 }}>
          <Box sx={{ mb: 2, display: "flex", gap: 1, borderBottom: `1px solid ${theme.palette.divider}` }}>
            {["M", "T", "W", "TH", "F"].map((day) => (
              <Box
                key={day}
                sx={{ flex: 1, py: 2, fontSize: "20px", color: theme.palette.text.secondary, textAlign: "center" }}
              >
                {day}
              </Box>
            ))}
          </Box>
          <Box sx={{ display: "grid", gridTemplateColumns: "repeat(5, 1fr)", gap: 1 }}>
            {monthDays.map((item) => {
              let isUsed = item.type === "used";
              let isFree = item.type === "free";
              let isSelected = formattedDate(time?.date) === formattedDate(item.date);

              return (
                <Box
                  key={item.date.toISOString()}
                  disabled={isUsed}
                  component="button"
                  onClick={() => {
                    if (isFree) {
                      setTime(isSelected ? null : { date: item.date });

                      const dateString = `${item.date.getFullYear()}-${
                        item.date && item.date.getMonth() + 1
                      }-${item.date.getDate()} ${time?.requestedTime || "9:00 AM"}`;

                      updatePickup({ scheduledAt: dateString, requestedTime: time?.requestedTime });
                    }
                  }}
                  sx={[
                    {
                      cursor: "pointer",
                      background: "white",
                      py: 2.5,
                      fontSize: "20px",
                      fontFamily: "Catamaran",
                      textAlign: "center",
                      borderRadius: "4px",
                      border: `1px solid white`,
                    },
                    isUsed && { cursor: "not-allowed", background: "rgba(0, 0, 0, 0.08);" },
                    isFree ? { border: `1px solid ${theme.palette.primary.main}` } : { cursor: "not-allowed" },
                    isSelected && { background: theme.palette.primary.main, color: "white" },
                  ]}
                >
                  {item.day}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      {time && (
        <>
          <Box mt={5}>
            <Typography fontSize={"20px"} fontWeight="600">
              {time.date && format(time.date, "EEEE, MMMM d")}
            </Typography>

            <Box mt={3} sx={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: 3 }}>
              {[
                { time: "9:00 AM", type: "free" },
                { time: "1:00 PM", type: "free" },
              ].map((item) => {
                let isUsed = item.type === "used";
                let isFree = item.type === "free";
                let isSelected = time?.requestedTime === item.time;
                return (
                  <Box
                    key={item.time}
                    disabled={isUsed}
                    component="button"
                    onClick={() => {
                      if (isFree) {
                        setTime({ date: time?.date, requestedTime: item.time });

                        if (time?.date) {
                          const dateString = `${time?.date.getFullYear()}-${
                            time?.date && time?.date.getMonth() + 1
                          }-${time.date.getDate()} ${item.time}`;

                          updatePickup({ scheduledAt: dateString, requestedTime: item.time });
                        }
                      }
                    }}
                    sx={[
                      {
                        cursor: "pointer",
                        background: "white",
                        py: 1,
                        fontSize: "14px",
                        color: theme.palette.primary.main,
                        fontFamily: "Catamaran",
                        textAlign: "center",
                        borderRadius: "4px",
                        border: `1px solid ${theme.palette.primary.main}`,
                      },
                      isUsed && {
                        cursor: "not-allowed",
                        opacity: 0.5,
                        color: theme.palette.text.secondary,
                        borderColor: theme.palette.text.secondary,
                      },
                      isSelected && { background: "#DDF3FC" },
                    ]}
                  >
                    {item.time}
                  </Box>
                );
              })}
            </Box>
          </Box>

          <Box mt={5}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel shrink htmlFor="comments">
                Tell us other possible dates and information
              </InputLabel>
              <InputBase
                placeholder={
                  "example: Tuesday's and Thursday's are best for us because we are at the office, we usually prefer the afternoon if possible."
                }
                multiline
                defaultValue={pickup?.comments}
                rows={2}
                id="comments"
                onChange={(e) => handleChange(e.target.value)}
              />
            </FormControl>
          </Box>
        </>
      )}
    </>
  );
}
