import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Collapse,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import { useEffect, useRef, useState } from "react";
import theme from "lib/theme";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { formatDate } from "utils/helpers";
import { Pickup } from "types/pickup";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { PickupStatus } from "./PickupStatus";
import { TableLoadingSkeleton } from "components/common/Skeletons";
import { PendingConfirmationDrawer } from "./PickupRowStatuses/PendingConfirmationDrawer";
import { MissingInfoDrawer } from "./PickupRowStatuses/MissingInfoDrawer";
import { ProcessingDrawer } from "./PickupRowStatuses/ProcessingDrawer";
import { InitialReportReadyDrawer } from "./PickupRowStatuses/InitialReportReadyDrawer";
import { OnHoldDrawer } from "./PickupRowStatuses/OnHoldDrawer";
import { VerifyingDrawer } from "./PickupRowStatuses/VerifyingDrawer";
import { CompletedDrawer } from "./PickupRowStatuses/CompletedDrawer";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { ScheduledDrawer } from "./PickupRowStatuses/ScheduledDrawer";
import { CanceledDrawer } from "./PickupRowStatuses/CanceledDrawer";

interface PickupTableProps {
  pickups: Pickup[];
  errorMessage: null;
  isLoading: boolean;
  getPickups: () => void;
  hasNextPage: boolean;
  deletePickup: (pickupUuid: string) => Promise<void>;
  markPickupUnenrolled: (pickupUuid: string) => void;
  incrementCurrentPage: () => void;
}

export default function PickupTable({
  pickups,
  errorMessage,
  isLoading,
  hasNextPage,
  deletePickup,
  markPickupUnenrolled,
  incrementCurrentPage,
}: PickupTableProps) {
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: incrementCurrentPage,
    disabled: !!errorMessage,
    rootMargin: "0px 0px 400px 0px",
  });

  if (errorMessage) {
    return (
      <Box
        sx={{
          mt: 6,
          border: "1px solid",
          borderColor: theme.palette.divider,
          background: "white",
          minHeight: "500px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage || "There was a problem loading your pickups."}
        </Alert>
      </Box>
    );
  }

  let isEmpty = pickups?.length === 0;

  if (!isLoading && isEmpty) {
    return (
      <Box
        sx={{
          mt: 6,
          border: "1px solid",
          borderColor: theme.palette.divider,
          background: "white",
          borderRadius: "4px",
          px: 2,
          py: 12,
        }}
      >
        <Box display={"flex"} flexDirection="column" alignItems={"center"}>
          <Box>
            <LocalShippingOutlinedIcon sx={{ fontSize: "64px", color: "text.disabled" }} />
          </Box>

          <Typography variant="h6" fontWeight={"bold"}>
            You don’t have any pickups yet!
          </Typography>
          <Typography mt={1} maxWidth={"420px"} textAlign="center">
            Begin scheduling your first pickup by clicking below.
          </Typography>
          <Button component={RouterLink} variant="outlined" sx={{ mt: 2 }} to="/pickups/schedule-pickup">
            Start your first pickup
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <TableContainer
      sx={{
        mt: 6,
        border: "1px solid",
        borderColor: theme.palette.divider,
        background: "white",
        borderRadius: "4px",
      }}
      component={Box}
    >
      <Table sx={{ minWidth: 850 }} size={isTablet ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>PICKUP DATE</TableCell>
            <TableCell>PICKUP ID</TableCell>
            <TableCell>TYPE</TableCell>
            <TableCell>LOCATION</TableCell>
            <TableCell>PICKUP ADDRESS</TableCell>
            <TableCell>STATUS</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pickups.map((pickup) => (
            <PickupRow
              key={pickup.id}
              id={pickup.uuid}
              pickup={pickup}
              deletePickup={deletePickup}
              markPickupUnenrolled={markPickupUnenrolled}
            />
          ))}
          {(isLoading || hasNextPage) && (
            <>
              <TableRow ref={sentryRef}>
                <TableCell colSpan={6}>
                  <Skeleton variant="text" height={41} />
                </TableCell>
              </TableRow>
              <TableLoadingSkeleton cellSpan={6} cellHeight={41} />
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const PickupType = ({ pickup }: { pickup: Pickup }) => {
  return (
    <Chip
      label={pickup.virtualPickup ? "Remote Retrieval" : "Onsite Pickup"}
      sx={[
        pickup.virtualPickup
          ? { backgroundColor: "#E0E0E0", color: "#212121" }
          : { backgroundColor: "#F5F5F5", color: "#212121" },
      ]}
    />
  );
};

function PickupRow({
  pickup,
  deletePickup,
  markPickupUnenrolled,
  id,
}: {
  pickup: Pickup;
  deletePickup: Function;
  markPickupUnenrolled: Function;
  id: string;
}) {
  let [open, setOpen] = useState(false);
  const pickupRow = useRef<any>(null);
  let location = useLocation();

  useEffect(() => {
    if (location.hash.split("#")[1] === id) {
      pickupRow?.current?.scrollIntoView({ behavior: "smooth" });
      setOpen(true);
    }
  }, []);

  return (
    <>
      <TableRow
        id={id}
        key={pickup.id}
        sx={[{ "&:last-child td, &:last-child th": { border: 0 } }, open && { border: 0 }]}
        ref={pickupRow}
      >
        <TableCell sx={[open && { border: 0 }]}>{!pickup.virtualPickup && formatDate(pickup.scheduledAt)}</TableCell>
        <TableCell sx={[open && { border: 0 }]}>{pickup.orderNumber}</TableCell>
        <TableCell sx={[open && { border: 0 }]}>
          <PickupType pickup={pickup} />
        </TableCell>
        <TableCell sx={[open && { border: 0 }]}>
          {pickup.virtualPickup ? "Direct To Revivn" : pickup.location && pickup.location.name}
        </TableCell>
        <TableCell sx={[open && { border: 0 }]}>
          {pickup.virtualPickup ? "-" : pickup.location && pickup.location.address}
        </TableCell>
        <TableCell sx={[open && { border: 0 }]}>
          <PickupStatus pickup={pickup} />
        </TableCell>
        <TableCell sx={[open && { border: 0 }]}>
          <IconButton onClick={() => setOpen((f) => !f)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: open ? undefined : 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ my: 1 }}>
              {pickup.state === "missing_info" && <MissingInfoDrawer pickup={pickup} deletePickup={deletePickup} />}
              {pickup.state === "pending_confirmation" && <PendingConfirmationDrawer pickup={pickup} />}
              {pickup.state === "scheduled" && <ScheduledDrawer pickup={pickup} />}
              {pickup.state === "canceled" && <CanceledDrawer pickup={pickup} />}
              {pickup.state === "processing" && <ProcessingDrawer pickup={pickup} />}
              {pickup.state === "initial_report_ready" && <InitialReportReadyDrawer pickup={pickup} />}
              {pickup.state === "on_hold" && (
                <OnHoldDrawer pickup={pickup} markPickupUnenrolled={markPickupUnenrolled} />
              )}
              {pickup.state === "verifying" && <VerifyingDrawer pickup={pickup} />}
              {pickup.state === "complete" && <CompletedDrawer pickup={pickup} />}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
