import { alpha, createTheme } from "@mui/material";

export default createTheme({
  typography: {
    fontFamily: ["'Catamaran'", "Roboto", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#0089BD",
    },
    primaryPro: {
      main: "#F9A62B",
      light: "#FFF7EB",
      contrastText: "#FFFFFF",
    },
    lightBlue: {
      main: "#EAF3F8",
    },
    secondary: {
      main: "#F9A62B",
      contrastText: "#FFFFFF",
    },
    neutral: {
      main: "#323232",
      light: "#323232",
    },
    error: {
      main: "#b71c1c",
    },
    placed: {
      main: "#F5F5F5",
      contrastText: "#202020",
      light: "#F5F5F5",
      dark: "",
    },
    accepted: {
      main: "#DDF3FC",
      contrastText: "#074E68",
      light: alpha("#DDF3FC", 0.5),
      dark: alpha("#DDF3FC", 0.9),
    },
    outToDestination1: {
      main: "#FFF7EB",
      contrastText: "#ED6C02",
      light: alpha("#FFF7EB", 0.5),
      dark: alpha("#FFF7EB", 0.9),
    },
    outToDestination2: {
      main: "#EFEDFF",
      contrastText: "#3E2968",
      light: alpha("#EFEDFF", 0.5),
      dark: alpha("#EFEDFF", 0.9),
    },
    complete: {
      main: "#EDF7ED",
      contrastText: "#395D3B",
      light: alpha("#EDF7ED", 0.5),
      dark: alpha("#EDF7ED", 0.9),
    },
    canceled: {
      main: "#FFF2F1",
      contrastText: "#E31B0C",
      light: alpha("#FFF2F1", 0.5),
      dark: alpha("#FFF2F1", 0.9),
    },
  },
});

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
    primaryPro: Palette["primary"];
    lightBlue: Palette["primary"];
    placed: Palette["primary"];
    accepted: Palette["primary"];
    outToDestination1: Palette["primary"];
    outToDestination2: Palette["primary"];
    complete: Palette["primary"];
    canceled: Palette["primary"];
  }
  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
    primaryPro?: PaletteOptions["primary"];
    lightBlue?: PaletteOptions["primary"];
    placed?: Palette["primary"];
    accepted?: Palette["primary"];
    outToDestination1?: Palette["primary"];
    outToDestination2?: Palette["primary"];
    complete?: Palette["primary"];
    canceled?: Palette["primary"];
  }
}
declare module "@mui/material/Alert" {
  interface AlertPropsColorOverrides {
    neutral: true;
    primary: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    placed: true;
    accepted: true;
    outToDestination1: true;
    outToDestination2: true;
    complete: true;
    canceled: true;
  }
}
declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides {
    primaryPro: true;
  }
}
declare module "@mui/material/Switch" {
  interface SwitchPropsColorOverrides {
    primaryPro: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
    primaryPro: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    neutral: true;
  }
}

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    primaryPro: true;
  }
}
