import { Box, Typography } from "@mui/material";
import { ReactComponent as EmptyGraphIcon } from "./images/empty-graph.svg";

export default function EmptyDonationHistoryGraph() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        px: 1,
      }}
    >
      <Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Typography fontSize="15px" textAlign="center">
            Don’t worry, you’ll see more data appear once you donate over a period of time. Schedule a pickup with us to
            increase earnings and donate!
          </Typography>
          <Box
            sx={{
              width: "200px",
              height: "200px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <EmptyGraphIcon style={{ width: "140px", height: "140px" }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
