import Pages from "pages";
import { useGlobalLoader } from "hooks/useGlobalLoader";
import { Box, CircularProgress } from "@mui/material";

export default function App() {
  const { loading } = useGlobalLoader();
  return loading ? (
    <Box width="100%" height="100vh" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <img src={require("assets/images/logo.png")} width="120px" />
      <CircularProgress color="primaryPro" sx={{ marginTop: 3 }} />
    </Box>
  ) : (
    <Pages />
  );
}
