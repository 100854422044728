import { ArrowForward, InfoOutlined } from "@mui/icons-material";
import { Box, Typography, FormControl, Button, Tooltip, Alert, AlertTitle, MenuItem } from "@mui/material";
import { Blob } from "activestorage";
import { AppSelect, InputBase, InputLabel } from "components/common/Input";
import FileDropSection from "components/FileDropSection";
import { timezones } from "constants/timezones";
import { FormikContextType } from "formik";
import useDirectUpload from "hooks/useDirectUpload";
import theme from "lib/theme";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ActiveStorageAttachment } from "types/activeStorageAttachment";
import { Location } from "types/location";
import LocationContact from "./LocationContact";

type LocationFormFirstStepProps = {
  formik: FormikContextType<Location>;
  nextAsSubmit?: boolean;
  originalPath: string;
};

interface BLobWithUrl extends Blob {
  url: string;
}

export default function LocationFormFirstStep({
  originalPath,
  formik,
  nextAsSubmit = false,
}: LocationFormFirstStepProps) {
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const { upload, blob } = useDirectUpload({ keyPrefix: "/locations/images/", service: "amazon_public" });
  const [currentFile, setCurrentFile] = useState<File | undefined>();
  const [newBlobs, setNewBlobs] = useState<BLobWithUrl[]>([]);
  const [oldAttachments, setOldAttachments] = useState<ActiveStorageAttachment[]>(formik.values.locationImages || []);

  const documents = useMemo(
    () => [
      ...newBlobs.map((blob) => ({
        name: blob?.filename,
        url: blob.url,
        onDelete: () => {
          setNewBlobs((blobs) => blobs.filter(({ signed_id }) => signed_id !== blob.signed_id));
        },
      })),
      ...oldAttachments.map((att) => ({
        name: att.filename,
        url: att.url,
        onDelete: () => {
          setOldAttachments((attechments) => attechments.filter(({ id }) => id !== att.id));
        },
      })),
    ],
    [newBlobs, oldAttachments]
  );

  useEffect(() => {
    if (blob && currentFile) {
      const reader = new FileReader();

      reader.readAsDataURL(currentFile);
      reader.onload = function (event) {
        if (event.target?.result) {
          const result = event.target.result as string;
          setNewBlobs((blobs) => [...blobs, { ...blob, url: result }]);
          setCurrentFile(undefined);
        }
      };
    }
  }, [blob]);

  useEffect(() => {
    formik.setFieldValue("locationImages", [
      ...oldAttachments.map(({ id, url, filename }) => ({ id, url, filename })),
      ...newBlobs.map(({ signed_id, url, filename }) => ({ signed_id, url, filename })),
    ]);
  }, [oldAttachments, newBlobs]);

  return (
    <div>
      <Box
        sx={{
          mt: 3,
          p: 4,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography variant="h6" fontWeight={"600"} sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <ArrowForward />
          Pickup address
        </Typography>

        <Box sx={{ mt: 4, display: "flex", flexDirection: "column", gap: 3 }}>
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink htmlFor="location-address">
              Address Line 1
            </InputLabel>
            <InputBase
              id="location-address"
              placeholder="Street address or P.O. Box"
              {...formik.getFieldProps("address")}
            />
          </FormControl>

          <FormControl variant="standard" fullWidth>
            <InputLabel shrink htmlFor="location-address2">
              Address Line 2
            </InputLabel>
            <InputBase
              id="location-address2"
              placeholder="Apt, suite, unit, building, floor, etc."
              {...formik.getFieldProps("address2")}
            />
          </FormControl>

          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="location-city">
                City
              </InputLabel>
              <InputBase id="location-city" placeholder="City" {...formik.getFieldProps("city")} />
            </FormControl>

            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="location-state">
                State
              </InputLabel>
              <InputBase id="location-state" placeholder="State" {...formik.getFieldProps("state")} />
            </FormControl>
          </Box>

          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="location-zipcode">
                Zipcode
              </InputLabel>
              <InputBase id="location-zipcode" placeholder="Zipcode" {...formik.getFieldProps("zipcode")} />
            </FormControl>

            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="location-country" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                Country
                <Tooltip
                  title="Revivn supports some international shipping; our sales team will review and approve any pickup requests made outside of the US!"
                  placement="right"
                >
                  <InfoOutlined color="primary" />
                </Tooltip>
              </InputLabel>

              <InputBase id="location-country" placeholder="Country" {...formik.getFieldProps("country")} />
            </FormControl>
          </Box>
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="location-name">
                (Optional) Location Nickname
              </InputLabel>
              <InputBase
                type="text"
                id="location-name"
                placeholder="Name of location"
                {...formik.getFieldProps("name")}
              />
            </FormControl>

            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="users-count">
                Approximately how many end users does this location have?
              </InputLabel>
              <InputBase
                type="number"
                id="users-count"
                {...formik.getFieldProps("approximatePeople")}
                value={formik.values.approximatePeople ?? ""}
                onChange={(e) => {
                  e.preventDefault();
                  const { value } = e.target;
                  if (+value < 0) {
                    return;
                  }
                  formik.setFieldValue("approximatePeople", value);
                }}
              />
            </FormControl>
          </Box>
        </Box>
      </Box>

      {/* <Box mt={4} sx={{ p: 4, background: "white", borderRadius: 1, border: `1px solid ${theme.palette.divider}` }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <ArrowForward sx={{ mt: "4px", flexShrink: 0 }} />
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" fontWeight={"bold"}>
              (Optional) Upload a picture of your site
            </Typography>

            <Box mt={2}>
              <Alert severity="info">
                <AlertTitle>Why are we asking for a picture?</AlertTitle>
                It’s especially helpful for those hidden gems - knowing what your site looks like will help our crew
                find it with no difficulties!
              </Alert>
            </Box>

            <Box mt={2}>
              <FileDropSection
                acceptHint={"jpg, jpeg, png"}
                accept={{
                  "image/png": ["png"],
                  "image/jpeg": ["jpg", "jpeg"],
                }}
                documents={documents.length > 0 ? documents : undefined}
                onUpload={([file]) => {
                  setCurrentFile(file);
                  upload(file);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box> */}

      <Box mt={4} sx={{ p: 4, background: "white", borderRadius: 1, border: `1px solid ${theme.palette.divider}` }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <ArrowForward sx={{ mt: "4px", flexShrink: 0 }} />
          <Box sx={{ flex: 1 }}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <label htmlFor="access-instructions">
                <Typography variant="h6" fontWeight={"bold"}>
                  Access Instructions
                </Typography>
                <Typography>
                  Are there any instructions our crew needs to follow to access your building and the space to pickup
                  your assets?
                </Typography>
              </label>
              <InputBase
                multiline
                rows={3}
                id="access-instructions"
                sx={{ mt: "12px !important" }}
                {...formik.getFieldProps("accessInstructions")}
              />
            </FormControl>
          </Box>
        </Box>
      </Box>

      <Box mt={4} sx={{ p: 4, background: "white", borderRadius: 1, border: `1px solid ${theme.palette.divider}` }}>
        <LocationContact />
      </Box>

      <Box mt={4} sx={{ display: "flex", flexDirection: "column", alignItems: "start", gap: 2 }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="outlined"
            onClick={() => {
              navigate(originalPath);
            }}
          >
            Back
          </Button>

          {nextAsSubmit ? (
            <Button variant="contained" type="submit">
              Next
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                setSearchParams({ step: "2" });
              }}
            >
              Next
            </Button>
          )}
        </Box>
      </Box>
    </div>
  );
}
