import { useEffect, useState } from "react";
import { api } from "services/api.service";
import { AssetEvent } from "types/assetEvent";

export default function useAssetEvents({ uuid }: { uuid: string | undefined }) {
  const [isLoading, setIsloading] = useState(false);
  const [assetEvents, setAssetEvents] = useState<AssetEvent[]>([]);

  useEffect(() => {
    setIsloading(true);
    api
      .get<{ assets: AssetEvent[] }>(`/assets/${uuid}/asset_events`)
      .then(({ data: { assets } }) => setAssetEvents(assets))
      .finally(() => {
        setIsloading(false);
      });
  }, [uuid]);

  return {
    assetEvents,
    isLoading,
  };
}
