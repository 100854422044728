import { Box, Button, Grid, Typography } from "@mui/material";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import theme from "lib/theme";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

type AnnouncemntBannerProps = {
  title: string;
  description: string;
};

export default function AnnouncementBanner({ title, description }: AnnouncemntBannerProps) {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const cookie = Cookies.get(`${title} Dismissed`);
    if (!cookie) {
      setShowBanner(true);
    }
  }, []);

  const dismissBanner = () => {
    Cookies.set(`${title} Dismissed`, "true");
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <Box
      sx={{
        pl: 3,
        pr: 5,
        py: 3,
        background: "white",
        borderRadius: 1,
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item width={"70px"}>
          <Box
            sx={{
              backgroundColor: "#EAF3F8",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 49,
              height: 49,
            }}
          >
            <CampaignOutlinedIcon fontSize="large" color="primary" sx={{ verticalAlign: "middle" }} />
          </Box>
        </Grid>
        <Grid item width={"calc(100% - 150px)"}>
          <Typography sx={{ fontWeight: "bold" }} variant="h6">
            {title}
          </Typography>

          <Typography variant="body1" sx={{ mt: 1, fontSize: 14 }}>
            {description}
          </Typography>
        </Grid>
        <Grid item width={"80px"} alignItems={"right"}>
          <Button variant="outlined" onClick={() => dismissBanner()}>
            DISMISS
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
