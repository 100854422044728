import { Box } from "@mui/material";
import theme from "lib/theme";
import Breadcrumbs from "components/common/Breadcrumbs";
import CompanySettingNavbar from "./CompanySettingNavbar";
import { Outlet, useLocation } from "react-router-dom";
import { OnboardingProvider } from "hooks/companySettings/useOnboarding";
import { useEffect, useState } from "react";
import useAnalytics from "hooks/analytics/useAnalytics";

function CompanySettings() {
  let location = useLocation();
  let [, tabValue] = location.pathname.split("/").filter(Boolean);
  const { createAnalyticsEvent } = useAnalytics();
  const [eventTracked, setEventTracked] = useState(false);

  useEffect(() => {
    if (eventTracked) return;
    setEventTracked(true);
    createAnalyticsEvent("company_settings_page_visit");
  }, [eventTracked]);

  return (
    <div>
      <OnboardingProvider>
        <Box sx={{ background: "white", borderBottom: `1px solid ${theme.palette.divider}`, p: 6, pb: 4 }}>
          <Breadcrumbs
            data={[
              { label: "Dashboard", to: "/" },
              { label: "Company Settings", to: `/company-settings/${tabValue}` },
              {
                label: {
                  overview: "Overview",
                  locations: "Locations",
                  users: "Users",
                  "payment-info": "Payment info",
                  authentication: "Authentication",
                }[tabValue],
              },
            ]}
          />

          <Box mt={3}>
            <CompanySettingNavbar />
          </Box>
        </Box>
        <Box sx={{ p: 6, pt: 4 }}>
          <Outlet />
        </Box>
      </OnboardingProvider>
    </div>
  );
}

export default CompanySettings;
