import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Typography,
  Skeleton,
} from "@mui/material";
import useOauthApplications from "hooks/api/useOauthApplications";
import theme from "lib/theme";
import { Link as RouterLink } from "react-router-dom";
import { OauthApplication } from "types/oauthApplication";
import { useState } from "react";
import { Code, NoiseControlOff, Visibility, VisibilityOff } from "@mui/icons-material";
import PlanFeatureWrapper from "components/PlanFeatureWrapper";
import PaywallModal from "components/PaywallModal";
import { formatDate } from "utils/helpers";

function ApiTable() {
  const { oauthApplications, isLoading } = useOauthApplications();
  return (
    <div>
      {isLoading ? (
        <>
          <Skeleton variant="text" height={75} />
          <Skeleton variant="text" height={75} />
          <Skeleton variant="text" height={75} />
          <Skeleton variant="text" height={75} />
          <Skeleton variant="text" height={75} />
        </>
      ) : oauthApplications && oauthApplications.length > 0 ? (
        <ApiDataTable oauthApplications={oauthApplications} />
      ) : (
        <EmptyTable />
      )}
    </div>
  );
}

function CreateAppButton() {
  return (
    <PlanFeatureWrapper
      feature="api"
      enabledElement={
        <Button component={RouterLink} variant="contained" size="large" to="/api/credentials/new" disableElevation>
          Create Application
        </Button>
      }
      disabledElement={
        <>
          <PaywallModal
            feature={"apiTable"}
            trigger={(props) => (
              <Button variant="contained" size="large" {...props} disableElevation>
                Create Application
              </Button>
            )}
          />
        </>
      }
      grandfatheredElement={
        <Button component={RouterLink} variant="contained" size="large" to="/api/credentials/new" disableElevation>
          Create Application
        </Button>
      }
    />
  );
}

function EmptyTable() {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height={400}>
      <Box maxWidth={390} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <Code sx={{ fontSize: 70, opacity: 0.25 }} />
        <Typography variant="h5">You don’t have any applications yet!</Typography>
        <Typography mt={2} mb={3} textAlign="center">
          Integrate with Revivn's API to seamlessly manage your pickups, assets, and access your data.
        </Typography>
        <CreateAppButton />
      </Box>
    </Box>
  );
}

function ApiDataTable({ oauthApplications }: { oauthApplications: OauthApplication[] }) {
  return (
    <>
      <TableContainer
        sx={{
          mt: 2,
        }}
      >
        <Table sx={{ ".MuiTableRow-root:last-child td, .MuiTableRow-root:last-child th": { border: 0 } }}>
          <TableHead sx={{ ".MuiTableCell-root": { color: theme.palette.text.disabled, borderBottom: 0 } }}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Secret</TableCell>
              <TableCell>Created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {oauthApplications?.map((oauthApplication) => {
              return <ApiTableRow key={oauthApplication.id} oauthApplication={oauthApplication} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateAppButton />
    </>
  );
}

function ApiTableRow({ oauthApplication }: { oauthApplication: OauthApplication }) {
  let [showSecret, setShowSecret] = useState(false);

  return (
    <TableRow>
      <TableCell>{oauthApplication.name}</TableCell>
      <TableCell>{oauthApplication.uid}</TableCell>
      <TableCell width={"510px"}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px", justifyContent: "space-between" }}>
          {showSecret ? (
            oauthApplication.secret
          ) : (
            <Typography sx={{ fontSize: "8px" }}>
              {Array.from({ length: 10 }, (_, i) => (
                <NoiseControlOff key={i} fontSize="inherit" />
              ))}
            </Typography>
          )}
          <IconButton
            size="small"
            onClick={() => {
              setShowSecret((f) => !f);
            }}
          >
            {showSecret ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Box>
      </TableCell>
      <TableCell>{formatDate(oauthApplication.createdAt)}</TableCell>
    </TableRow>
  );
}

export default ApiTable;
