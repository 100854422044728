import { Box } from "@mui/material";
import theme from "lib/theme";
import { useCallback, useState } from "react";
import { useFormikContext } from "formik";
import { AssetRetrievalBulkOrderDetails } from "types/assetRetrieval";
import BulkAssetCart from "./BulkAssetCart";
import { useValidateAddress } from "hooks/useValidateAddress";
import { useAlert } from "hooks/alerts/useAlert";
import { AssetRetrieval } from "hooks/assetRetrievals/useAssetRetrieval";
import ReturnAddressRadioGroup from "./ReturnAddressRadioGroup";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface ReturnDetailsProps {
  goToNextStep: () => void;
}

export default function ReturnDetails({ goToNextStep }: ReturnDetailsProps) {
  const { alertError } = useAlert();
  const { values, errors, setFieldError } = useFormikContext<AssetRetrievalBulkOrderDetails | AssetRetrieval>();
  const [customReturnLabel, setCustomReturnLabel] = useState(false);

  const [isValidating, setIsValidation] = useState(false);
  const { validateAssetRetrievalReturnAddress } = useValidateAddress();

  const validateAddressAndGoToNextStep = useCallback(() => {
    setIsValidation(true);
    if (customReturnLabel) {
      validateAssetRetrievalReturnAddress(values)
        .then(() => {
          goToNextStep();
        })
        .catch((error) => {
          setFieldError("returnRecipientAddress", "Invalid Address");
          setFieldError("returnRecipientAddressTwo", "Invalid Address");
          setFieldError("returnRecipientAddressCity", "Invalid Address");
          setFieldError("returnRecipientAddressState", "Invalid Address");
          setFieldError("returnRecipientAddressZipCode", "Invalid Address");
          alertError(error.response.data.errors[0].message);
        })
        .finally(() => {
          setIsValidation(false);
        });
    } else {
      goToNextStep();
    }
  }, [values]);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "2fr 1fr",
        gap: 4,
        alignItems: "start",
        [theme.breakpoints.down("md")]: { gridTemplateColumns: "1fr" },
      }}
    >
      <Box
        sx={{
          py: 3,
          px: 4,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
          [theme.breakpoints.down("lg")]: { px: 2, py: 2 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          <ArrowForwardIcon />
          Where the box is being returned to
        </Box>
        <ReturnAddressRadioGroup setCustomReturnLabel={setCustomReturnLabel} customReturnLabel={customReturnLabel} />
      </Box>
      <BulkAssetCart
        onContinue={validateAddressAndGoToNextStep}
        disableContinue={
          Boolean(
            errors.returnRecipientFirstName ||
              errors.returnRecipientLastName ||
              errors.returnRecipientAddress ||
              errors.returnRecipientAddressCity ||
              errors.returnRecipientAddressState ||
              errors.returnRecipientAddressZipCode ||
              errors.returnRecipientAddressCountry ||
              isValidating
          ) || Boolean(errors.locationId || isValidating)
        }
      />
    </Box>
  );
}
