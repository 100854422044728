import useAuth from "hooks/useAuth";
import { useCallback, useEffect, useState } from "react";
import { api } from "services/api.service";
import { User } from "types/user";

export default function useCurrentUser() {
  const [isLoading, setIsLoading] = useState(false);
  const { loggedIn } = useAuth();
  const [currentUser, setCurrentUser] = useState<User>({
    firstName: "",
    lastName: "",
    email: "",
    uuid: "",
    phoneNumber: "",
    roles: [{ uuid: "", name: "" }],
  });

  const updateCurrentUser = useCallback(
    ({ firstName, lastName, email, phoneNumber }: User) =>
      api
        .put<{ user: User }>("/profile", { user: { firstName, lastName, email, phoneNumber } })
        .then(({ data: { user } }) => {
          setCurrentUser(user);
        }),
    []
  );

  const updateCurrentUserPassword = useCallback(
    ({
      currentPassword,
      password,
      passwordConfirmation,
    }: {
      currentPassword: string;
      password: string;
      passwordConfirmation: string;
    }) =>
      api.put<{ user: User }>("/profile/password", { profile: { currentPassword, password, passwordConfirmation } }),
    []
  );

  useEffect(() => {
    if (!isLoading && loggedIn) {
      setIsLoading(true);
      api
        .get(`/profile`)
        .then((res) => {
          setCurrentUser(res.data.user);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  return {
    currentUser,
    updateCurrentUser,
    updateCurrentUserPassword,
  };
}
