import { Box, Button, IconButton, Link, Typography } from "@mui/material";
import theme from "lib/theme";
import { DrawerProps } from "types/pickup";
import { BarChart, CheckCircleOutline, Download } from "@mui/icons-material";
import { ReactComponent as FileCSV } from "./images/file-csv.svg";
import { Link as RouterLink } from "react-router-dom";
import {
  address,
  contact,
  orderNumber,
  PickupAgentDetails,
  pickupDate,
  PickupDetailsRow,
  PickupDetailsRows,
  PickupDetailsTitle,
} from "./shared";
import useCompletedPickup from "hooks/pickups/useCompletedPickup";

export function CompletedDrawer(props: DrawerProps) {
  const { pickup } = props;
  const { completedPickupInfo } = useCompletedPickup(pickup.uuid);

  const downloadCod = () => {
    fetch(completedPickupInfo.codUrl).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "pickup_certificate_of_destruction.pdf";
        a.click();
      });
    });
  };

  const downloadTrp = () => {
    fetch(completedPickupInfo.trpUrl).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "pickup_transparency_report.pdf";
        a.click();
      });
    });
  };

  const downloadImpactReport = () => {
    fetch(completedPickupInfo.impactReportUrl).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "pickup_impact_report.pdf";
        a.click();
      });
    });
  };

  return (
    <Box mb={5} mx={2}>
      <Box
        sx={{
          py: 3,
          px: 4,
          background: "#FAFAFA",
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
        }}
      >
        <CheckCircleOutline />
        <Typography variant="h6" fontWeight="bold">
          Your pickup is complete!
        </Typography>
        <Typography>
          {pickup.virtualPickup ? (
            <>
              {completedPickupInfo.waivedFees > 0 &&
                `You’ve saved $${completedPickupInfo.waivedFees} in pickup fees by sending your laptops back direct to Revivn.`}
              Take a look at the impact below and see how you can put your Revivn earnings towards nonprofits in need.
            </>
          ) : (
            "Thanks again for scheduling a pickup with Revivn! By working with us to repurpose your old hardware, you're " +
            "keeping machines out of landfills and giving back to communities in need."
          )}
        </Typography>

        {(completedPickupInfo.trpUrl || completedPickupInfo.codUrl) && (
          <Box mt={3} mx={2}>
            <Box
              sx={{
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontSize: "14px",
                color: "text.secondary",
                textTransform: "uppercase",
                pb: 1,
              }}
            >
              <BarChart sx={{ fontSize: "20px" }} /> REPORTS & CERTIFICATES
            </Box>
            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}>
              {completedPickupInfo.trpUrl && (
                <Box
                  sx={{
                    p: 3,
                    background: "white",
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <FileCSV />
                  <Box>
                    <Typography fontWeight="bold" fontSize="14px">
                      Transparency Report
                    </Typography>
                  </Box>
                  <Box ml="auto">
                    <IconButton
                      sx={{ mr: 1 }}
                      onClick={() => {
                        downloadTrp();
                      }}
                    >
                      <Download color="primary" />
                    </IconButton>
                  </Box>
                </Box>
              )}
              {completedPickupInfo.impactReportUrl && (
                <Box
                  sx={{
                    p: 3,
                    background: "white",
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <FileCSV />
                  <Box>
                    <Typography fontWeight="bold" fontSize="14px">
                      Impact Report
                    </Typography>
                  </Box>
                  <Box ml="auto">
                    <IconButton
                      sx={{ mr: 1 }}
                      onClick={() => {
                        downloadImpactReport();
                      }}
                    >
                      <Download color="primary" />
                    </IconButton>
                  </Box>
                </Box>
              )}
              {completedPickupInfo.codUrl && (
                <Box
                  sx={{
                    p: 3,
                    background: "white",
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <FileCSV />
                  <Box>
                    <Typography fontWeight="bold" fontSize="14px">
                      Certificate of Destruction
                    </Typography>
                  </Box>
                  <Box ml="auto">
                    <IconButton
                      sx={{ mr: 1 }}
                      onClick={() => {
                        downloadCod();
                      }}
                    >
                      <Download color="primary" />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )}

        <Box mt={2}>
          <PickupDetailsTitle />

          <PickupDetailsRows>
            <PickupDetailsRow
              columnCount={4}
              items={
                pickup.virtualPickup
                  ? [
                      {
                        title: "PICKUP ID",
                        list: orderNumber(pickup),
                      },
                      {
                        title: "ASSETS RETRIEVED",
                        list: [
                          pickup.inventoryCount ? (
                            pickup.inventoryCount > 0 ? (
                              <Link
                                href={`/assets?page[number]=1&page[size]=25&filter[]=state%2Cis_any_of%2Cretired&filter[]=pickup_id%2Cis_any_of%2C${pickup.id}`}
                              >
                                {pickup.inventoryCount}
                              </Link>
                            ) : (
                              "0"
                            )
                          ) : (
                            "0"
                          ),
                        ],
                      },
                      {
                        title: "ENROLLED ASSETS",
                        list: [0],
                      },
                    ]
                  : [
                      {
                        title: "PICKUP ID",
                        list: orderNumber(pickup),
                      },
                      {
                        title: "PICKUP DATE",
                        list: pickupDate(pickup),
                      },
                      {
                        title: "CONTACT",
                        list: contact(pickup),
                      },
                      {
                        title: "ADDRESS",
                        list: address(pickup),
                      },
                    ]
              }
            />
            {!pickup.virtualPickup && (
              <PickupDetailsRow
                columnCount={4}
                items={[
                  <PickupAgentDetails pickup={pickup} />,
                  {
                    title: "EARNINGS STATUS",
                    list: [
                      (completedPickupInfo && completedPickupInfo.earnings > 0 && (
                        <Link
                          sx={{ my: 2 }}
                          href={`/donations-and-earnings/earnings?filter%5B%5D=pickup_id%2Cis_any_of%2C${pickup.id}`}
                        >
                          {completedPickupInfo.earningsCredit}
                        </Link>
                      )) ||
                        (completedPickupInfo && completedPickupInfo.earningsCredit),
                    ],
                  },
                ]}
              />
            )}
          </PickupDetailsRows>
        </Box>
      </Box>

      <Box
        mt={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          gap: 3,
        }}
      >
        <Button
          variant="contained"
          size="small"
          component={RouterLink}
          to={`/assets?filter[]=state,is,retired&filter[]=pickup_id,is_any_of,${pickup?.id}`}
        >
          See retired assets
        </Button>
      </Box>
    </Box>
  );
}
