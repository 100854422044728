import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { ReactComponent as LogoSVG } from "images/logo.svg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import useAuth from "hooks/useAuth";
import { Menu } from "components/common/Menu";
import Popover from "components/common/Popover";
import { Alert, Button, FormControl, Link, useMediaQuery } from "@mui/material";
import theme from "lib/theme";
import { Link as RouterLink } from "react-router-dom";
import {
  ArrowForward,
  ArrowForwardIos,
  Attachment,
  AttachMoney,
  Devices,
  ErrorOutlineRounded,
  LocalShippingOutlined,
  Mail,
} from "@mui/icons-material";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import React, { useRef, useState, useContext } from "react";
import { InputBase, InputLabel } from "components/common/Input";
import FileDropSection from "components/FileDropSection";
import TokenService from "services/token.service";
import MenuIcon from "@mui/icons-material/Menu";
import { useFormik } from "formik";
import useSupport from "hooks/support/useSupport";
import useDirectUpload from "hooks/useDirectUpload";
import { OrganizationConfigurationContext } from "hooks/useOrganizationConfiguration";

interface NavProps {
  open: boolean;
  handleToggle(): void;
}

export const navActions = { openContactConcierge() {} };

function Nav({ open, handleToggle }: NavProps) {
  const isTabletView = useMediaQuery(theme.breakpoints.down("lg"));
  const { loggedIn } = useAuth();
  const { organizationPlan } = useContext(OrganizationConfigurationContext);

  const buttonRef = useRef<HTMLButtonElement>(null);

  navActions.openContactConcierge = () => {
    buttonRef.current?.click();
  };

  return (
    <Box sx={{ flexGrow: 1, position: "sticky", top: 0, zIndex: 10 }}>
      <AppBar variant="outlined" elevation={0} sx={{ bgcolor: "white", color: "#000" }}>
        <Toolbar>
          {isTabletView && loggedIn && (
            <IconButton
              color="primary"
              onClick={handleToggle}
              aria-label={open ? "Collapse Sidebar" : "Expand Sidebar"}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Typography variant="h6" noWrap component="div" sx={{ display: "flex", alignItems: "center" }}>
            <LogoSVG />
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {loggedIn && (
            <Box sx={{ display: "flex" }}>
              <Menu
                button={(props) => (
                  <IconButton aria-label="Profile" size="large" color="primary" {...props}>
                    <AccountCircleIcon />
                  </IconButton>
                )}
                actions={[
                  {
                    label: "Profile",
                    to: "/profile",
                  },
                  {
                    label: "Support Center",
                    href: "https://revivn.zendesk.com/hc/en-us",
                    props: { target: "_blank" },
                  },
                  {
                    label: "Log Out",
                    onClick: () => {
                      TokenService.remove();
                      window.location.href = "/login";
                    },
                  },
                ]}
              ></Menu>
              {/* <Popover
                button={(props) => (
                  <IconButton aria-label="Notifications" size="large" color="primary" {...props}>
                    <NotificationsIcon />
                  </IconButton>
                )}
              >
                <NotificationsPanel />
              </Popover> */}
              <Popover
                button={(props) => (
                  <IconButton aria-label="Email" size="large" color="primary" ref={buttonRef} {...props}>
                    <EmailIcon />
                  </IconButton>
                )}
              >
                <ContactConcierge />
              </Popover>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      {!!localStorage.getItem("sandbox") && (
        <Alert
          severity="info"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 8,
            position: "absolute",
            width: "100%",
            zIndex: 1,
          }}
        >
          You are currently in test mode
        </Alert>
      )}
    </Box>
  );
}

function NotificationsPanel() {
  let [isSeeAll, setIsSeeAll] = React.useState(false);

  return (
    <Box
      sx={{
        width: "300px",
      }}
    >
      <Box
        sx={{
          height: "81px",
          px: 2.5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          Notifications
        </Typography>
        <Link sx={{ fontSize: "10px", textDecoration: "none" }} component={"button"}>
          MARK ALL AS READ
        </Link>
      </Box>

      <Box sx={{ maxHeight: "718px", overflowY: "auto" }}>
        <Box
          sx={{
            "> a:not(:last-child)": {
              borderBottom: `1px solid ${theme.palette.divider}`,
            },
          }}
        >
          {Array.from({ length: isSeeAll ? 10 : 1 }).map((_, i) => (
            <React.Fragment key={i}>
              <NotificationLink
                active
                icon={<ErrorOutlineRounded color="primary" sx={{ fontSize: "25px" }} />}
                text="Fill out your company info to ensure a smooth pickup."
              />
              <NotificationLink
                active
                icon={<LocalShippingOutlined color="primary" sx={{ fontSize: "25px" }} />}
                text="Pickup at Adobe - NYC office on 4/27 was scheduled."
              />
              <NotificationLink
                active
                icon={<Devices color="primary" sx={{ fontSize: "25px" }} />}
                text="257 assets are ready to retire soon."
              />
              <NotificationLink
                active
                icon={<VolunteerActivismIcon color="primary" sx={{ fontSize: "25px" }} />}
                text="Your donation to Digital Girls was processed. View your..."
              />
              <NotificationLink
                icon={<AttachMoney color="primary" sx={{ fontSize: "25px" }} />}
                text="$10,067 was just posted to your earnings account."
              />
              <NotificationLink
                icon={<ErrorOutlineRounded color="primary" sx={{ fontSize: "25px" }} />}
                text="Please update your COI for Adobe - NYC."
              />
            </React.Fragment>
          ))}
        </Box>

        {!isSeeAll && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              borderTop: `1px solid ${theme.palette.divider}`,
              height: "41px",
              px: 2.5,
            }}
          >
            <Link
              sx={{ fontSize: "10px", textDecoration: "none" }}
              component={"button"}
              onClick={() => setIsSeeAll(true)}
            >
              SEE ALL
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  );
}

interface NotificationLinkProps {
  icon: React.ReactElement;
  text: string;
  active?: boolean;
}

function NotificationLink({ icon, text, active = false }: NotificationLinkProps) {
  return (
    <Link
      component={RouterLink}
      to="/"
      sx={[
        { px: 3, py: 2, display: "flex", alignItems: "center", gap: 2, textDecoration: "none" },
        active && { background: "rgba(0, 137, 189, 0.05)" },
      ]}
    >
      <Box sx={{ p: 1, display: "flex" }}>{icon}</Box>
      <Box color="text.primary" fontSize="14px" minHeight={"80px"} display="flex" alignItems={"center"}>
        {text}
      </Box>
      <Box sx={{ p: 1, display: "flex" }} color="action.active">
        <ArrowForwardIos sx={{ fontSize: "25px" }} />
      </Box>
    </Link>
  );
}

function ContactConcierge() {
  const { contactConcierge } = useSupport();
  const { upload, blob, reset } = useDirectUpload({
    keyPrefix: "/support/contact_concierge/",
    service: "amazon_public",
  });

  const [submitted, setSubmitted] = useState(false);
  const initialValues = {
    email: "",
    phone: "",
    comments: "",
  };
  const { getFieldProps, handleSubmit, resetForm, isSubmitting, setValues, setSubmitting, setTouched } = useFormik({
    initialValues,
    onSubmit: (values) => {
      contactConcierge({ ...values, blobId: blob?.signed_id }).finally(() => {
        setSubmitted(true);
        resetForm();
      });
    },
  });

  return (
    <Box
      sx={{
        width: "435px",
        p: 4,
      }}
    >
      <form onSubmit={handleSubmit}>
        <Box sx={{ textAlign: "center" }}>
          <Mail color="primary" />
          <Typography variant="h6" fontWeight="bold">
            Contact Concierge
          </Typography>
          <Typography mt={1}>
            Have questions or need additional help? Send us a message and we’ll get back to you as soon as we can.
          </Typography>
        </Box>

        <Box mt={3} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="email">
              Email Address
            </InputLabel>
            <InputBase type="email" id="email" {...getFieldProps("email")} />
          </FormControl>

          <FormControl variant="standard">
            <InputLabel shrink htmlFor="phone">
              Phone Number
            </InputLabel>
            <InputBase type="text" id="phone" {...getFieldProps("phone")} />
          </FormControl>

          <FormControl variant="standard">
            <InputLabel shrink htmlFor="comments">
              Comments
            </InputLabel>
            <InputBase multiline rows={2} type="text" id="comments" {...getFieldProps("comments")} />
          </FormControl>

          {blob ? (
            <Box padding={theme.spacing(2)}>
              <Typography color="text.secondary" fontWeight="700">
                Attached:
              </Typography>
              <Button variant="outlined">
                <Attachment />
                {blob.filename}
              </Button>
            </Box>
          ) : (
            <FileDropSection
              accept={{
                "text/csv": [],
                "text/xls": ["xls", "xlxs"],
                "application/msword": ["doc"],
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ["docx"],
                "image/*": [],
                "application/pdf": [],
              }}
              onUpload={([file]) => {
                upload(file);
              }}
            />
          )}

          <Box sx={{ textAlign: "right" }}>
            <Button size="small" variant="contained" type="submit" disabled={isSubmitting}>
              send message
            </Button>
          </Box>
        </Box>
      </form>

      {submitted && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "white",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex", flexFlow: "column", py: 15, px: 4, textAlign: "center", maxWidth: "30em" }}>
            <Typography variant="h6" fontWeight="bold">
              Thank you for contacting us!
            </Typography>
            <Typography mt={1} variant="body2">
              We’ll get in touch with you as soon as we can. In the meantime, visit our support center for additional
              help and frequently asked questions.
            </Typography>
            <Button
              variant="text"
              sx={{ mt: 2, alignSelf: "center" }}
              endIcon={<ArrowForward />}
              onClick={() => {
                setSubmitted(false);
                setSubmitting(false);
                setValues({ ...initialValues });
                setTouched({ email: false, phone: false, comments: false });
              }}
            >
              send another message
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Nav;
