import { useQuery } from "react-query";
import { api } from "services/api.service";
import { OauthApplication } from "types/oauthApplication";

export default function useOauthApplications() {
  const { data, isLoading, isError, refetch } = useQuery("oauth_applications", () =>
    api.get<{ oauthApplications: OauthApplication[] }>("/oauth_applications").then((res) => ({ data: res.data }))
  );

  return {
    oauthApplications: data?.data.oauthApplications,
    isLoading,
    isError,
    refetch,
  };
}
