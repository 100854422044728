import { Box } from "@mui/material";
import { Bar } from "react-chartjs-2";

export default function AssetsReadyForRetirementBarChart(homeInfo: any) {
  return (
    <Box sx={{ minHeight: "230px", height: "100%" }}>
      <Bar
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: { display: false },
            },
            y: {
              grid: { display: false },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
        data={{
          labels:
            homeInfo.homeInfo &&
            homeInfo.homeInfo.homeInfo &&
            homeInfo.homeInfo.homeInfo.retiredAssetsInfo &&
            homeInfo.homeInfo.homeInfo.retiredAssetsInfo.deviceTypes.map(({ deviceType }: any) => deviceType),
          datasets: [
            {
              label: "Dataset 2",
              data:
                homeInfo.homeInfo &&
                homeInfo.homeInfo.homeInfo &&
                homeInfo.homeInfo.homeInfo.retiredAssetsInfo &&
                homeInfo.homeInfo.homeInfo.retiredAssetsInfo.deviceTypes.map(({ count }: any) => count),
              borderColor: "#0089BD",
              borderRadius: Number.MAX_VALUE,
              backgroundColor: "#0089BD",
              barPercentage: 0.3,
            },
          ],
        }}
      />
    </Box>
  );
}
