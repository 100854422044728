import useBreadcrumbs from "use-react-router-breadcrumbs";
import Breadcrumbs from "./Breadcrumbs";

export default function RouterBreadcrumbs({ routes }: { routes: any }) {
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <Breadcrumbs
      data={breadcrumbs.map(({ match, breadcrumb }) => ({
        label: breadcrumb,
        to: match.pathname,
      }))}
    />
  );
}
