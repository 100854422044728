import { ArrowForward, InfoOutlined } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button, FormControl, Tooltip, Typography, MenuItem } from "@mui/material";
import { AppSelect, InputBase, InputLabel } from "components/common/Input";
import FileDropSection from "components/FileDropSection";
import theme from "lib/theme";
import React from "react";

function CreateLocation() {
  return (
    <div>
      <Box mt={6}>
        <Typography variant="h4" fontWeight="bold">
          Pickup Location Details
        </Typography>
      </Box>

      <Box mt={4} sx={{ p: 4, background: "white", borderRadius: 1, border: `1px solid ${theme.palette.divider}` }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <ArrowForward sx={{ mt: "4px", flexShrink: 0 }} />
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" fontWeight={"bold"}>
              Pickup addressPickup address
            </Typography>

            <Box mt={4}>
              <PickupAddressForm />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mt={4} sx={{ p: 4, background: "white", borderRadius: 1, border: `1px solid ${theme.palette.divider}` }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <ArrowForward sx={{ mt: "4px", flexShrink: 0 }} />
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" fontWeight={"bold"}>
              (Optional) Upload a picture of your site
            </Typography>

            <Box mt={2}>
              <Alert severity="info">
                <AlertTitle>Why are we asking for a picture?</AlertTitle>
                It’s especially helpful for those hidden gems - knowing what your site looks like will help our crew
                find it with no difficulties!
              </Alert>
            </Box>

            <Box mt={2}>
              <FileDropSection
                acceptHint={"csv, xls, xlxs, ppt, pptx, doc, docx, pdf, jpg, jpeg, png"}
                accept={{
                  "text/csv": [],
                  "text/xls": ["xls", "xlxs"],
                  "application/msword": ["doc"],
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ["docx"],
                  "image/png": ["png"],
                  "image/jpeg": ["jpg", "jpeg"],
                  "application/pdf": ["pdf"],
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mt={4} sx={{ p: 4, background: "white", borderRadius: 1, border: `1px solid ${theme.palette.divider}` }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <ArrowForward sx={{ mt: "4px", flexShrink: 0 }} />
          <Box sx={{ flex: 1 }}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <label htmlFor="access-instructions">
                <Typography variant="h6" fontWeight={"bold"}>
                  Access Instructions
                </Typography>
                <Typography>
                  Are there any instructions our crew needs to follow to access your building and the space to pickup
                  your assets?
                </Typography>
              </label>
              <InputBase multiline rows="4" id="access-instructions" sx={{ mt: "12px !important" }} />
            </FormControl>
          </Box>
        </Box>
      </Box>

      <Box mt={4} sx={{ display: "flex", flexDirection: "column", alignItems: "start", gap: 2 }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="outlined"
            onClick={() => {
              // navigate("/user-setup/company-info");
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              // navigate("/user-setup/connect-assets");
            }}
          >
            Next
          </Button>
        </Box>
      </Box>
    </div>
  );
}

function PickupAddressForm() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <FormControl variant="standard">
        <InputLabel shrink htmlFor="address-line-1">
          Address Line 1
        </InputLabel>
        <InputBase type="text" id="address-line-1" placeholder="Street address or P.O. Box" />
      </FormControl>

      <FormControl variant="standard">
        <InputLabel shrink htmlFor="address-line-2">
          Address Line 2
        </InputLabel>
        <InputBase type="text" id="address-line-2" placeholder="Apt, suite, unit, building, floor, etc." />
      </FormControl>

      <FormControl variant="standard">
        <InputLabel shrink htmlFor="city">
          City
        </InputLabel>
        <InputBase type="text" id="city" placeholder="Address" />
      </FormControl>

      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}>
        <FormControl variant="standard">
          <InputLabel shrink htmlFor="state">
            State
          </InputLabel>
          <MenuItem id="state">
            <MenuItem value="">--</MenuItem>
            <MenuItem value="Alex">Alex</MenuItem>
            <MenuItem value="Cairo">Cairo</MenuItem>
          </MenuItem>
        </FormControl>

        <FormControl variant="standard">
          <InputLabel shrink htmlFor="country" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            Country
            <Tooltip
              title="Revivn supports some international shipping; our sales team will review and approve any pickup requests made outside of the US!"
              placement="right"
            >
              <InfoOutlined color="primary" />
            </Tooltip>
          </InputLabel>
          <AppSelect id="country">
            <MenuItem value="">--</MenuItem>
            <MenuItem value="Egypt">Egypt</MenuItem>
            <MenuItem value="US">US</MenuItem>
          </AppSelect>
        </FormControl>
      </Box>

      <FormControl variant="standard">
        <InputLabel shrink htmlFor="zip-code">
          Zip code
        </InputLabel>
        <InputBase type="text" id="zip-code" />
      </FormControl>

      <FormControl variant="standard">
        <InputLabel shrink htmlFor="user-number">
          Approximately how many end users do you support at this location?
        </InputLabel>
        <InputBase type="number" id="user-number" />
      </FormControl>
    </Box>
  );
}

export default CreateLocation;
