import { useContext, useEffect, useState } from "react";
import { OrganizationConfigurationContext } from "hooks/useOrganizationConfiguration";
import { api } from "services/api.service";
import PaymentStatus from "pages/CompanySettings/PaymentInfo/paymentStatus";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Modal } from "../../components/common/Modal";
import { useLocation } from "react-router";

export const useStripePaymentMethod = () => {
  const { stripePromise } = useContext(OrganizationConfigurationContext);
  const [stripeIntentSecret, setStripeIntentSecret] = useState<string>();

  useEffect(() => {
    api.get("/payment_methods/intent_secret").then(({ data: { intentSecret } }) => {
      setStripeIntentSecret(intentSecret);
    });
  }, []);

  return {
    stripePromise,
    stripeIntentSecret,
  };
};

export const StripePaymentMethodProvider = ({ children }: { children: React.ReactNode }) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [openPaymentStatus, setOpentPaymentStatus] = useState(Boolean(query.get("paymentCallback")));

  return (
    <>
      {children}
      <Modal open={openPaymentStatus} setOpen={setOpentPaymentStatus} hideCloseButton={true}>
        <Box sx={{ px: 3 }}>
          <Box width="100%" height="" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="h5" sx={{ marginTop: 3 }}>
              Processing credit card please wait...
            </Typography>
            <CircularProgress color="primaryPro" sx={{ marginTop: 3 }} />
            <PaymentStatus callback={() => setOpentPaymentStatus(false)} />
          </Box>
        </Box>
      </Modal>
    </>
  );
};
