import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Attachment } from "@mui/icons-material";
import FileDropSection from "components/FileDropSection";
import theme from "lib/theme";
import useDirectUpload from "hooks/useDirectUpload";

type UploadAssetsProps = {
  fileUploaded: (key: string) => void;
};

function UploadAssets({ fileUploaded }: UploadAssetsProps) {
  const { blob, upload } = useDirectUpload({ keyPrefix: "/assets/file/" });

  useEffect(() => {
    if (blob) fileUploaded(blob.signed_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blob]);

  return blob ? (
    <Box padding={theme.spacing(2)}>
      <Typography color="text.secondary" fontWeight="700">
        Attached:
      </Typography>
      <Button variant="outlined">
        <Attachment />
        {blob.filename}
      </Button>
    </Box>
  ) : (
    <FileDropSection
      acceptHint={"csv"}
      accept={{
        "text/csv": [],
      }}
      onUpload={(files) => {
        upload(files[0]);
      }}
    />
  );
}

export default UploadAssets;
