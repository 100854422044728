import {
  TableContainer,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
} from "@mui/material";
import theme from "lib/theme";
import { Menu } from "components/common/Menu";
import { MoreVert } from "@mui/icons-material";
import useUsers from "hooks/users/useUsers";
import { User } from "types/user";
import { userRoles } from "constants/userRoles";
import useCurrentUserPermission from "hooks/useCurrentUserPermission";

function UserManagementTable() {
  const { users, deleteUser } = useUsers();
  return (
    <TableContainer
      sx={{
        mt: 6,
        border: "1px solid",
        borderColor: theme.palette.divider,
        background: "white",
        borderRadius: "4px",
      }}
      component={Box}
    >
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>First name</TableCell>
            <TableCell>Last name</TableCell>
            <TableCell>Email Address</TableCell>
            <TableCell>Role</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => {
            return <UserManagementTableRow key={user.uuid} user={user} deleteUser={deleteUser} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

type UserProps = {
  user: User;
  deleteUser: (uuid: string) => any;
};

const roleDisplayName = (role: string) => {
  const foundRole = userRoles.find((userRole) => userRole[0] == role);

  if (foundRole) {
    return foundRole[1];
  } else {
    return "";
  }
};

function UserManagementTableRow({ user, deleteUser }: UserProps) {
  const { hasPermission } = useCurrentUserPermission();

  return (
    <TableRow>
      <TableCell>{user.firstName}</TableCell>
      <TableCell>{user.lastName}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user?.roles && roleDisplayName(user?.roles[0]?.name)}</TableCell>
      <TableCell align="right">
        <Menu
          button={(props: any) => (
            <Tooltip
              title={
                hasPermission("userManagement")
                  ? ""
                  : "You do not have permissions for this action, please contact your administrator for permission."
              }
            >
              <span>
                <IconButton {...props} disabled={!hasPermission("userManagement")}>
                  <MoreVert />
                </IconButton>
              </span>
            </Tooltip>
          )}
          actions={[
            {
              label: "Edit User",
              to: `${user.uuid}/edit`,
            },
            {
              label: "Delete User",
              onClick: () => {
                deleteUser(user.uuid);
              },
            },
          ]}
        />
      </TableCell>
    </TableRow>
  );
}

export default UserManagementTable;
