import { Box, Button, Divider, FormControl, Link, Typography } from "@mui/material";
import { InputBase, InputLabel } from "components/common/Input";
import { Formik } from "formik";
import theme from "lib/theme";
import LoginCoverImg from "images/login-cover.png";
import { useLocation } from "react-router-dom";
import { api } from "services/api.service";
import TokenService from "services/token.service";
import { useEffect, useState } from "react";

export default function UserAcceptInvitation() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const invitationToken = queryParams.get("token");
  const [tokenError, setTokenError] = useState(false);

  useEffect(() => {
    if (TokenService.get()) {
      TokenService.remove();
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  });

  return (
    <div>
      <img
        src={LoginCoverImg}
        alt="login cover"
        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
      ></img>

      <Typography
        variant="h4"
        sx={{ textAlign: "center", mt: 6, fontWeight: "bold", color: theme.palette.text.primary }}
      >
        Welcome! Please set your password
      </Typography>

      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          color: theme.palette.text.secondary,
          mt: 3,
        }}
      >
        Manage your hardware lifecycle and repurpose your tech for a greater cause all in one place
      </Typography>

      <Box sx={{ maxWidth: "660px", mx: "auto" }}>
        <Divider sx={{ mt: 3, mx: 3 }}>Set your password and begin the onboarding process:</Divider>

        <Box mt={4}>
          <Formik
            initialValues={{ password: "", invitationToken: invitationToken }}
            onSubmit={(values) => {
              return api
                .post("/users/accept_invitation", values)
                .then((res: any) => {
                  TokenService.set(res.data.jwt);
                  window.location.href = "/";
                })
                .catch(() => {
                  setTokenError(true);
                });
            }}
          >
            {({ getFieldProps, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="column">
                  <FormControl variant="standard">
                    <InputLabel shrink htmlFor="password">
                      Password
                    </InputLabel>
                    <InputBase type="password" {...getFieldProps("password")} id="password" />
                  </FormControl>

                  <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    disabled={isSubmitting}
                    sx={{ textTransform: "uppercase", mt: 4, alignSelf: "start" }}
                  >
                    Set Password
                  </Button>
                  {tokenError && (
                    <Typography paddingTop={2} color="red">
                      We're sorry! This invite link is no longer active. Please check with the person who shared it with
                      you to request a new invite. If you're having trouble, please contact your Revivn account manager
                      or email{" "}
                      <Link sx={{ my: 2 }} href={`mailto:support@revivn.com`}>
                        support@revivn.com
                      </Link>
                      .
                    </Typography>
                  )}
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </div>
  );
}
