import { ArrowForward } from "@mui/icons-material";
import { Box, Button, FormControl, Typography } from "@mui/material";
import { InputBase, InputLabel } from "components/common/Input";
import { useFormik } from "formik";
import useBenefitingOrganizations from "hooks/donations/useBenefitingOrganization";
import theme from "lib/theme";
import { defaultBenefitingOrganization } from "types/benefitingOrganization";
import * as Yup from "yup";

function ReferOrganization() {
  const { createBenefitingOrganization, submitted, setSubmitted } = useBenefitingOrganizations();

  const { getFieldProps, handleSubmit, isSubmitting, setValues, setSubmitting, touched, errors, setTouched } =
    useFormik({
      initialValues: defaultBenefitingOrganization,
      validationSchema: Yup.object({
        websiteUrl: Yup.string().required("Website is required for us to reach to the organization"),
        name: Yup.string().required("Name is required"),
      }),
      onSubmit: (values, { setFieldError }) => {
        createBenefitingOrganization(values).catch(({ response: { data } }) => {
          data.errors.name && setFieldError("name", "This benefiting organization has been referred before");
        });
      },
    });

  return (
    <Box
      sx={{
        p: 2,
        background: "white",
        borderRadius: 1,
        border: `1px solid ${theme.palette.divider}`,
        alignSelf: "start",
        width: "100%",
        position: "relative",
      }}
    >
      <Typography variant="h6" fontWeight="bold">
        Refer an Organization
      </Typography>

      <Typography variant="body2" mt={1}>
        We would love to donate to more organizations! Fill out the fields below and we’ll get in touch with them asap!
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" gap={2} mt={2} mb={1}>
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="name">
              Organization name
            </InputLabel>
            <InputBase
              type="text"
              id="name"
              {...getFieldProps("name")}
              error={touched.name && !!errors.name}
              helperText={touched.name && errors.name}
            />
          </FormControl>

          <FormControl variant="standard">
            <InputLabel shrink htmlFor="websiteUrl">
              Website
            </InputLabel>
            <InputBase
              type="text"
              id="websiteUrl"
              {...getFieldProps("websiteUrl")}
              error={touched.websiteUrl && !!errors.websiteUrl}
              helperText={touched.websiteUrl && errors.websiteUrl}
            />
          </FormControl>

          <FormControl variant="standard">
            <InputLabel shrink htmlFor="mainContactName">
              Contact name
            </InputLabel>
            <InputBase type="text" id="mainContactName" {...getFieldProps("mainContactName")} />
          </FormControl>

          <FormControl variant="standard">
            <InputLabel shrink htmlFor="mainContactEmail">
              Contact email
            </InputLabel>
            <InputBase type="email" id="mainContactEmail" {...getFieldProps("mainContactEmail")} />
          </FormControl>

          <FormControl variant="standard">
            <InputLabel shrink htmlFor="mainContactPhoneNumber">
              Contact phone number
            </InputLabel>
            <InputBase type="text" id="mainContactPhoneNumber" {...getFieldProps("mainContactPhoneNumber")} />
          </FormControl>

          <Button
            variant="contained"
            size="small"
            type="submit"
            sx={{ alignSelf: "end" }}
            endIcon={<ArrowForward />}
            disabled={isSubmitting}
          >
            submit
          </Button>
        </Box>
      </form>
      {/* submit screen */}
      {submitted && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "white",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex", flexFlow: "column", py: 15, px: 2, textAlign: "center", maxWidth: "30em" }}>
            <Typography variant="h6" fontWeight="bold">
              Thank you for your referral!
            </Typography>
            <Typography mt={1} variant="body2">
              We’ll get in touch with them as soon as we can. In the meantime, browse our other organizations that are
              in need of donations or submit another referral.
            </Typography>
            <Button
              variant="text"
              sx={{ mt: 2, alignSelf: "center" }}
              endIcon={<ArrowForward />}
              onClick={() => {
                setSubmitted(false);
                setSubmitting(false);
                setValues({ ...defaultBenefitingOrganization });
                setTouched({ name: false, websiteUrl: false });
              }}
            >
              submit another referral
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default ReferOrganization;
