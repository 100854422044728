import { Box, Typography, Autocomplete, TextField, MenuItem } from "@mui/material";
import { useFormikContext } from "formik";
import { AppSelect, InputBase } from "components/common/Input";
import theme from "lib/theme";
import { Asset } from "types/asset";
import useLocations from "hooks/locations/useLocations";
import { useCallback } from "react";
import { deviceTypes } from "constants/deviceTypes";

export default function AssetForm() {
  const { locations } = useLocations();
  const { getFieldProps, setFieldValue, values } = useFormikContext<Asset>();

  const accessoriesInputOnChange = useCallback(
    (e: any) => {
      if (e.key === "Enter") {
        e.preventDefault();
        setFieldValue("accessories", [...values.accessories, e.target.value]);
        e.target.value = "";
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values]
  );

  return (
    <Box mt={7} sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 5 }}>
      <Box sx={{ "> div:not(:last-child)": { borderBottom: `1px solid ${theme.palette.divider}` } }}>
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
          <Typography>Device Type</Typography>
          <AppSelect {...getFieldProps("deviceType")}>
            {deviceTypes.map((device) => {
              return (
                <MenuItem key={device} value={device}>
                  {device}
                </MenuItem>
              );
            })}
          </AppSelect>
        </Box>
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
          <Typography>Manufacturer</Typography>
          <AppSelect {...getFieldProps("manufacturer")}>
            <MenuItem value="Apple">Apple</MenuItem>
            <MenuItem value="Dell">Dell</MenuItem>
            <MenuItem value="Dell">HP</MenuItem>
          </AppSelect>
        </Box>
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
          <Typography>Serial Number</Typography>
          <InputBase {...getFieldProps("serialNumber")} />
        </Box>
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
          <Typography>Asset Tag</Typography>
          <InputBase {...getFieldProps("assetTag")} />
        </Box>
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
          <Typography>Model</Typography>
          <InputBase {...getFieldProps("model")} />
        </Box>
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
          <Typography>Vendor</Typography>
          <InputBase {...getFieldProps("vendor")} />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        <Box sx={{ "> div:not(:last-child)": { borderBottom: `1px solid ${theme.palette.divider}` } }}>
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
            <Typography>Date Purchased</Typography>
            <InputBase {...getFieldProps("purchaseDate")} type="date" />
          </Box>
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
            <Typography>Warranty Expiration</Typography>
            <InputBase {...getFieldProps("expirationDate")} type="date" />
          </Box>
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
            <Typography>End of Life</Typography>
            <InputBase {...getFieldProps("endOfLifeDate")} type="date" />
          </Box>
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
            <Typography>Accessories</Typography>
            <Autocomplete
              multiple
              autoComplete={false}
              open={false}
              openOnFocus={false}
              options={[] as string[]}
              value={values.accessories}
              onChange={(_, options) => setFieldValue("accessories", options)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  onKeyPress={accessoriesInputOnChange}
                  placeholder="Press enter to add"
                />
              )}
            />
          </Box>
          {locations && locations[0] && locations[0].id && (
            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
              <Typography>Location</Typography>
              <AppSelect {...getFieldProps("locationId")}>
                {locations.map((location) => (
                  <MenuItem key={location.id} value={location.id}>
                    {location.name}
                  </MenuItem>
                ))}
              </AppSelect>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
