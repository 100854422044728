import AppLayout from "components/AppLayout";
import { Navigate, Route, Routes } from "react-router-dom";
import Assets from "./Assets";
import Login from "./Login";
import Pickups from "./Pickups";
import DonationsAndEarnings from "./DonationsAndEarnings";
import Donations from "./DonationsAndEarnings/Donations";
import Earnings from "./DonationsAndEarnings/Earnings";
import DonationDetails from "./DonationsAndEarnings/DonationRequestDetails";
import CompanySettings from "./CompanySettings";
import Overview from "./CompanySettings/Overview";
import Locations from "./CompanySettings/Locations";
import LocationsList from "./CompanySettings/Locations/List";
import LocationNew from "./CompanySettings/Locations/New";
import LocationEdit from "./CompanySettings/Locations/Edit";
import UserManagement from "./CompanySettings/Users/UserManagement";
import PaymentInfo from "./CompanySettings/PaymentInfo";
import StandardReports from "./ReportingAndDocuments/StandardReports";
import ReportingAndDocuments from "./ReportingAndDocuments/index";
import Documents from "./ReportingAndDocuments/Documents";
import Api from "./Api";
import ApiCredentials from "./Api/Credentials/ApiCredentials";
import Webhooks from "./Api/Webhooks/Webhooks";
import SignUp from "./SignUp";
import CompanyInfo from "./UserSetup/CompanyInfo";
import UserSetup from "./UserSetup";
import AddLocations from "./UserSetup/AddLocations";
import ConnectAssets from "./UserSetup/ConnectAssets";
import CreateLocation from "./UserSetup/CreateLocation";
import SchedulePickup from "./Pickups/SchedulePickup/SchedulePickup";
import AssetsDetails from "./Assets/AssetsDetails";
import AssetHistory from "./Assets/AssetsDetails/AssetHistory";
import AssetCreate from "./Assets/AssetCreate";
import UploadCSV from "./Assets/UploadCSV";
import Summary from "./Pickups/SchedulePickup/Summary";
import Home from "./Home";
import AssetsReturn from "./Assets/AssetsReturn";
import { Authentication } from "./CompanySettings/Authentication/Authentication";
import { SelectProvider } from "./CompanySettings/Authentication/SelectProvider";
import { ConfigureProvider } from "./CompanySettings/Authentication/ConfigureProvider";
import ACHInformation from "./CompanySettings/PaymentInfo/ACHInformation";
import NewUser from "./CompanySettings/Users/New";
import Users from "./CompanySettings/Users";
import UserAcceptInvitation from "./UserAcceptInvitation";
import EditUser from "./CompanySettings/Users/Edit";
import Profile from "./Profile";
import ResetPassword from "./Login/resetPassword";
import CreateResetPassword from "./Login/createResetPassword";
import FindOrganization from "./Login/findOrganization";
import NoConnectionToBackend from "./Login/noConnectionToBackend";
import OauthApplicationForm from "./Api/Credentials/OauthApplicationForm";
import WebhookForm from "./Api/Webhooks/WebhookForm";
import SendBoxes from "./ShippingAndRetrieval/SendBoxes";
import NewAssetRetrieval from "./ShippingAndRetrieval/NewAssetRetrieval";
import BulkOrder from "./ShippingAndRetrieval/NewAssetRetrieval/BulkOrder";

export default function Pages() {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <AppLayout>
            <Login />
          </AppLayout>
        }
      />

      <Route
        path="/create-reset-password"
        element={
          <AppLayout>
            <CreateResetPassword />
          </AppLayout>
        }
      />

      <Route
        path="/find-organization"
        element={
          <AppLayout>
            <FindOrganization />
          </AppLayout>
        }
      />

      <Route
        path="/no-backend-connection"
        element={
          <AppLayout>
            <NoConnectionToBackend />
          </AppLayout>
        }
      />

      <Route
        path="/reset-password"
        element={
          <AppLayout>
            <ResetPassword />
          </AppLayout>
        }
      />

      <Route
        path="/signup"
        element={
          <AppLayout>
            <SignUp />
          </AppLayout>
        }
      />

      <Route
        path="/accept-invitation"
        element={
          <AppLayout>
            <UserAcceptInvitation />
          </AppLayout>
        }
      />

      <Route
        path="user-setup"
        element={
          <AppLayout>
            <UserSetup />
          </AppLayout>
        }
      >
        <Route index element={<Navigate to="/signup" replace />} />
        <Route path="company-info" element={<CompanyInfo />} />
        <Route path="add-locations" element={<AddLocations />} />
        <Route path="add-locations/create" element={<CreateLocation />} />
        <Route path="connect-assets" element={<ConnectAssets />} />
      </Route>

      <Route
        path="/profile"
        element={
          <AppLayout>
            <Profile />
          </AppLayout>
        }
      />

      <Route
        path="/pickups"
        element={
          <AppLayout>
            <Pickups />
          </AppLayout>
        }
      />
      <Route
        path="/pickups/:pickupUuid"
        element={
          <AppLayout container>
            <SchedulePickup />
          </AppLayout>
        }
      />

      <Route
        path="/pickups/:pickupUuid/summary"
        element={
          <AppLayout container>
            <Summary />
          </AppLayout>
        }
      />

      <Route
        path="/assets"
        element={
          <AppLayout>
            <Assets />
          </AppLayout>
        }
      />
      <Route
        path="/assets/new"
        element={
          <AppLayout>
            <AssetCreate />
          </AppLayout>
        }
      />
      <Route
        path="/assets/upload-csv"
        element={
          <AppLayout>
            <UploadCSV />
          </AppLayout>
        }
      />
      <Route
        path="/assets/:assetUuid"
        element={
          <AppLayout>
            <AssetsDetails />
          </AppLayout>
        }
      >
        <Route index element={<Navigate to="/history" replace />} />
        {/* <Route path="details" element={<AssetDetails />} /> */}
        <Route path="history" element={<AssetHistory />} />
        {/* <Route path="checkout" element={<AssetCheckout />} /> */}
      </Route>

      <Route
        path="/assets/:assetUuid/retrieve"
        element={
          <AppLayout>
            <AssetsReturn />
          </AppLayout>
        }
      ></Route>

      <Route
        path="/retrievals/send-boxes"
        element={
          <AppLayout>
            <SendBoxes />
          </AppLayout>
        }
      ></Route>

      <Route
        path="/retrievals/send-boxes/new"
        element={
          <AppLayout>
            <NewAssetRetrieval />
          </AppLayout>
        }
      ></Route>
      <Route
        path="/retrievals/send-boxes/bulk-new"
        element={
          <AppLayout>
            <BulkOrder />
          </AppLayout>
        }
      ></Route>

      <Route
        path="/donations-and-earnings"
        element={
          <AppLayout>
            <DonationsAndEarnings />
          </AppLayout>
        }
      >
        <Route index element={<Navigate to="/donations-and-earnings/donations" replace />} />
        <Route path="donations" element={<Donations />} />
        <Route path="donations/:donationRequestUuid" element={<DonationDetails />} />
        <Route path="earnings" element={<Earnings />} />
      </Route>

      <Route
        path="/reporting-and-documents"
        element={
          <AppLayout>
            <ReportingAndDocuments />
          </AppLayout>
        }
      >
        <Route index element={<Navigate to="/reporting-and-documents/reporting/reports" replace />} />
        <Route path="reporting" element={<Navigate to="/reporting-and-documents/reporting/reports" replace />} />
        <Route path="reporting/reports" element={<StandardReports />} />
        {/* <Route path="reporting/custom-reports" element={<CustomReports />} />
        <Route path="reporting/custom-reports/create-report" element={<CreateReport />} /> */}
        <Route path="documents" element={<Documents />} />
      </Route>

      <Route
        path="/api"
        element={
          <AppLayout>
            <Api />
          </AppLayout>
        }
      >
        <Route index element={<Navigate to="/api/credentials" replace />} />
        <Route path="credentials" element={<ApiCredentials />} />
        <Route path="credentials/new" element={<OauthApplicationForm />} />
        <Route path="webhooks" element={<Webhooks />} />
        <Route path="webhooks/new" element={<WebhookForm />} />
      </Route>

      <Route
        path="/company-settings"
        element={
          <AppLayout noPadding>
            <CompanySettings />
          </AppLayout>
        }
      >
        <Route index element={<Navigate to="/company-settings/overview" replace />} />
        <Route path="overview" element={<Overview />} />
        <Route path="locations" element={<Locations />}>
          <Route index element={<LocationsList />} />
          <Route path="new" element={<LocationNew />} />
          <Route path=":uuid/edit" element={<LocationEdit />} />
        </Route>
        <Route index element={<Navigate to="/company-settings/overview" replace />} />

        <Route path="users" element={<Users />}>
          <Route index element={<UserManagement />} />
          <Route path="new" element={<NewUser />} />
          <Route path=":uuid/edit" element={<EditUser />} />
        </Route>
        <Route path="payment-info" element={<PaymentInfo />} />
        <Route path="payment-info/ach-information" element={<ACHInformation />} />
        <Route path="authentication" element={<Authentication />} />
        <Route path="authentication/provider" element={<SelectProvider />} />
        <Route path="authentication/provider/:provider" element={<ConfigureProvider />} />
        {/* <Route path="integrations" element={<Integrations />} /> */}
      </Route>

      <Route
        path="/*"
        element={
          <AppLayout>
            <Home />
          </AppLayout>
        }
      ></Route>
    </Routes>
  );
}
