import { Box, Tab, Tabs } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import RouterBreadcrumbs from "components/common/RouterBreadcrumbs";

function Api() {
  const navigate = useNavigate();
  const location = useLocation();
  let [, tabValue] = location.pathname.split("/").filter(Boolean);

  return (
    <Box>
      <RouterBreadcrumbs
        routes={[
          { path: "/", breadcrumb: "Dashboard" },
          { path: "/api", breadcrumb: "API" },
          { path: "/api/credentials", breadcrumb: "Credentials" },
          { path: "/api/webhooks", breadcrumb: "Webhooks" },
        ]}
      />

      <Box mt={4}>
        <Tabs
          value={tabValue}
          onChange={(e, value) => {
            navigate(`/api/${value}`);
          }}
        >
          <Tab label="API Credentials" value="credentials" />
          <Tab label="Webhooks" value="webhooks" />
        </Tabs>
      </Box>

      <Box sx={{ padding: "24px 0" }}>
        <Outlet />
      </Box>
    </Box>
  );
}

export default Api;
