import { useCallback } from "react";
import { api } from "services/api.service";

export default function useAnalytics() {
  const createAnalyticsEvent = useCallback((eventType: string) => {
    api.post("/analytics_events", { analyticsEvent: { eventType } });
  }, []);

  return {
    createAnalyticsEvent,
  };
}
