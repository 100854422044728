import { Box, Button, Divider, FormControl, Typography } from "@mui/material";
import { InputBase, InputLabel } from "components/common/Input";
import { Formik } from "formik";
import theme from "lib/theme";
import LoginCoverImg from "images/login-cover.png";
import { useNavigate } from "react-router-dom";

export default function SignUp() {
  let navigate = useNavigate();

  return (
    <div>
      <img
        src={LoginCoverImg}
        alt="login cover"
        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
      ></img>

      <Typography
        variant="h4"
        sx={{ textAlign: "center", mt: 6, fontWeight: "bold", color: theme.palette.text.primary }}
      >
        You’re invited to sign up for the Revivn platform
      </Typography>

      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          color: theme.palette.text.secondary,
          mt: 3,
        }}
      >
        Manage your hardware lifecycle and repurpose your tech for a greater cause all in one place
      </Typography>

      <Box sx={{ maxWidth: "660px", mx: "auto" }}>
        <Divider sx={{ mt: 3, mx: 3 }}>Set your password and begin the onboarding process:</Divider>

        <Box mt={4}>
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={(values) => {
              navigate("/user-setup/company-info");
              // return login(values);
            }}
          >
            {({ getFieldProps, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="column">
                  <FormControl variant="standard">
                    <InputLabel shrink htmlFor="password">
                      Password
                    </InputLabel>
                    <InputBase type="password" {...getFieldProps("password")} id="password" />
                  </FormControl>

                  <FormControl variant="standard" sx={{ mt: 2 }}>
                    <InputLabel shrink htmlFor="confirm-password">
                      Confirm your password
                    </InputLabel>
                    <InputBase type="password" {...getFieldProps("confirm-password")} id="confirm-password" />
                  </FormControl>

                  <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    disabled={isSubmitting}
                    sx={{ textTransform: "uppercase", mt: 4, alignSelf: "start" }}
                  >
                    Sign Up
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </div>
  );
}
