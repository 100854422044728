import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import { Box, Skeleton } from "@mui/material";
import useOnboarding from "hooks/companySettings/useOnboarding";
import theme from "lib/theme";
import { Link, useLocation } from "react-router-dom";

function CompanySettingNavbar() {
  const { onboarding } = useOnboarding();
  let location = useLocation();
  let [, tabValue] = location.pathname.split("/").filter(Boolean);

  const navItems =
    onboarding.length === 0
      ? []
      : [
          { label: "Overview", to: "/overview", checked: onboarding[0].completed },
          { label: "Locations", to: "/locations", checked: onboarding[1].completed },
          { label: "Users", to: "/users", checked: onboarding[2].completed },
          { label: "Plans & Payment", to: "/payment-info", checked: onboarding[3].completed },
          { label: "Authentication", to: "/authentication", checked: onboarding[4].completed },
        ];

  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      {!navItems[0] && <Skeleton height={60} width={700} />}
      {navItems.map((item) => {
        let isActive = item.to.replace(/^\//, "") === tabValue;

        return (
          <Box
            key={item.label}
            component={Link}
            to={`/company-settings${item.to}`}
            sx={[
              {
                textTransform: "uppercase",
                display: "inline-flex",
                alignItems: "center",
                gap: 1,
                fontSize: "14px",
                color: "text.secondary",
                px: "18px",
                py: "11px",
                borderRadius: "4px",
                border: `1px solid ${theme.palette.divider}`,
                textDecoration: "none",
                ":hover": { border: `1px solid ${theme.palette.primary.main}`, color: theme.palette.primary.main },
              },
              isActive && {
                border: `1px solid ${theme.palette.primary.main}`,
                color: theme.palette.primary.main,
                backgroundColor: "#EDF5FA",
              },
            ]}
          >
            {item.label}
            {item.checked ? <CheckCircle /> : <RadioButtonUnchecked />}
          </Box>
        );
      })}
    </Box>
  );
}

export default CompanySettingNavbar;
