import { Snackbar, Alert, AlertColor, Box, SnackbarOrigin } from "@mui/material";
import { createContext, useCallback, useContext, useEffect, useState } from "react";

type AlertMessage = {
  body: string;
  type: AlertColor;
  color?: AlertColor;
};

type AlertContextValue = {
  alertError: (s: string, origin?: SnackbarOrigin) => void;
  alertSuccess: (s: string, origin?: SnackbarOrigin) => void;
  alertInfo: (s: string, origin?: SnackbarOrigin) => void;
  alertWarning: (s: string, origin?: SnackbarOrigin) => void;
  alertNeutral: (s: string, origin?: SnackbarOrigin, color?: AlertColor) => void;
};

const AlertContext = createContext<AlertContextValue>({
  alertError: (_) => {},
  alertSuccess: (_) => {},
  alertInfo: (_) => {},
  alertWarning: (_) => {},
  alertNeutral: (_) => {},
});

export const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const [alerts, setAlerts] = useState<AlertMessage[]>([]);
  const [origin, setOrigin] = useState<SnackbarOrigin>();
  const [timeouts, setTimeouts] = useState<NodeJS.Timeout[]>([]);
  const removeAlert = useCallback(() => {
    const timeout = setTimeout(() => {
      setAlerts(([_toBeRemoved, ...alerts]) => alerts);
      setOrigin(undefined);
    }, 5000);
    setTimeouts((oldTimeouts) => [timeout, ...oldTimeouts]);
  }, [setAlerts, setTimeouts]);

  const alertError = useCallback(
    (body: string, origin?: SnackbarOrigin) => {
      setAlerts(() => [{ body, type: "error" }]);
      setOrigin(origin);
      removeAlert();
    },
    [setAlerts]
  );

  const alertSuccess = useCallback(
    (body: string, origin?: SnackbarOrigin) => {
      setAlerts(() => [{ body, type: "success" }]);
      setOrigin(origin);
      removeAlert();
    },
    [setAlerts, alerts]
  );

  const alertInfo = useCallback(
    (body: string, origin?: SnackbarOrigin) => {
      setAlerts(() => [{ body, type: "info" }]);
      setOrigin(origin);
      removeAlert();
    },
    [setAlerts]
  );

  const alertWarning = useCallback(
    (body: string, origin?: SnackbarOrigin) => {
      setAlerts(() => [{ body, type: "warning" }]);
      setOrigin(origin);
      removeAlert();
    },
    [setAlerts]
  );

  const alertNeutral = useCallback(
    (body: string, origin?: SnackbarOrigin) => {
      setAlerts(() => [{ body, type: "success", color: "neutral" as AlertColor }]);
      setOrigin(origin);
      removeAlert();
    },
    [setAlerts]
  );

  useEffect(() => {
    return () => {
      timeouts.forEach((timeout) => {
        clearTimeout(timeout);
      });
    };
  }, [timeouts]);

  const value = {
    alertError,
    alertSuccess,
    alertInfo,
    alertWarning,
    alertNeutral,
  };
  return (
    <AlertContext.Provider value={value}>
      {children}
      <Snackbar
        open={true}
        anchorOrigin={
          origin || {
            vertical: "top",
            horizontal: "center",
          }
        }
      >
        <Box data-testid="alerts-container">
          {alerts.map((alert, index) => (
            <Alert key={index} severity={alert.type} color={alert.color} data-testid={`alerts-message-${alert.type}`}>
              {alert.body}
            </Alert>
          ))}
        </Box>
      </Snackbar>
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const { alertError, alertInfo, alertSuccess, alertWarning, alertNeutral } = useContext(AlertContext);

  return {
    alertError,
    alertInfo,
    alertSuccess,
    alertWarning,
    alertNeutral,
  };
};
