import {
  Box,
  Button,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import theme from "lib/theme";
import { ArrowForward } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { DonationRequest } from "types/donationRequest";
import { useEffect, useState } from "react";
import { formatNumberToCurrency } from "utils/helpers";

export interface MakeDonationProps {
  donationRequests: DonationRequest[];
  replace: (field: string, value: string) => void;
  meta: {
    count: number;
    totalPages: number;
  };
}

function MakeDonation({ donationRequests, meta, replace }: MakeDonationProps) {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    replace("page[number]", pageNumber.toString());
  }, [pageNumber, replace]);

  useEffect(() => {
    replace("page[size]", pageSize.toString());
  }, [pageSize, replace]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  return (
    <>
      <Typography variant="h6" fontWeight="bold">
        Make a Donation
      </Typography>

      <TableContainer
        sx={{
          mt: 2,
        }}
      >
        <Table sx={{ ".MuiTableRow-root:last-child td, .MuiTableRow-root:last-child th": { border: 0 } }}>
          <TableHead sx={{ ".MuiTableCell-root": { color: theme.palette.text.disabled, borderBottom: 0 } }}>
            <TableRow>
              <TableCell>ORGANIZATION</TableCell>
              <TableCell>DESCRIPTION</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {donationRequests.map((donationRequest, i) => {
              return (
                <>
                  <TableRow>
                    <TableCell>
                      <Box sx={{ display: "flex", gap: 3, alignItems: "center", height: 4 }}>
                        <img
                          src={donationRequest.benefitingOrganization.logoUrl}
                          alt=""
                          style={{
                            width: 38,
                            height: 38,
                            objectFit: "cover",
                            objectPosition: "center",
                          }}
                        />
                        <Typography fontSize={13} fontWeight={600}>
                          {donationRequest.benefitingOrganization?.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography fontSize={14} fontWeight={700}>
                          Help this organization reach:{" "}
                          {formatNumberToCurrency(donationRequest.totalRequestedAmountInDollars)}
                        </Typography>
                        <Typography fontSize={13} fontWeight={400}>
                          {donationRequest.goalDescription.slice(0, 350)}
                          {donationRequest.goalDescription.length > 350 ? "...." : null}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        component={Link}
                        to={`/donations-and-earnings/donations/${donationRequest.uuid}`}
                        variant="text"
                        size="small"
                        endIcon={<ArrowForward />}
                      >
                        view details & donate
                      </Button>
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
        {meta.totalPages > 1 ? (
          <Box sx={{ display: "flex", justifyContent: "end", mt: 1, px: 2 }}>
            <Pagination count={meta.totalPages} color="primary" onChange={handlePageChange} />
          </Box>
        ) : null}
      </TableContainer>
    </>
  );
}

export default MakeDonation;
