import { Typography } from "@mui/material";
import { Outlet } from "react-router-dom";

function Users() {
  return (
    <div>
      <Typography variant="h4" fontWeight="bold">
        Users
      </Typography>

      <Outlet />
    </div>
  );
}

export default Users;
