import { Box, Typography, Button } from "@mui/material";
import { Computer } from "@mui/icons-material";
import { Modal } from "components/common/Modal";
import useUpdateAsset from "hooks/assets/useUpdateAsset";

interface RetiredAssetModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  refetch: () => void;
  uuid?: string;
  bulkPendingPickup?: () => void;
}

export function RetiredAssetModal({ open, setOpen, refetch, uuid, bulkPendingPickup }: RetiredAssetModalProps) {
  const { pendingPickup } = useUpdateAsset({ refetch });

  const handleClick = () => {
    if (uuid) {
      pendingPickup(uuid);
    } else if (bulkPendingPickup) {
      bulkPendingPickup();
      setOpen(false);
    }
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Box>
        <Box sx={{ textAlign: "center" }}>
          <Computer color="primary" sx={{ fontSize: "60px" }} />
          <Typography mt={1} variant="h5" component="h2" fontWeight={800}>
            Are you sure you want to retire this asset?
          </Typography>
          <Typography mt={0.5} color="text.secondary">
            We’ll change the status of the asset to ‘Pending Pickup’ if you choose to proceed! Once the asset is picked
            up and processed by us, it’ll be moved to your list of retired assets.
          </Typography>

          <Box mt={3} sx={{ display: "flex", gap: 1, alignItems: "center", justifyContent: "center" }}>
            <Button
              variant="outlined"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={() => handleClick()}>
              proceed
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
