import { Box, Button, FormControl, Typography } from "@mui/material";
import { InputBase, InputLabel } from "components/common/Input";
import { Modal } from "components/common/Modal";
import { useFormik } from "formik";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  updatePassword: (payload: {
    password: string;
    passwordConfirmation: string;
    currentPassword: string;
  }) => Promise<any>;
};

export default function UpdatePassword({ open, setOpen, updatePassword }: Props) {
  const { handleSubmit, getFieldProps, errors } = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
      currentPassword: "",
    },
    onSubmit: (values, { setFieldError, setErrors }) => {
      setErrors({});
      updatePassword(values)
        .then(() => {
          setOpen(false);
        })
        .catch(
          ({
            response: {
              data: { errors },
            },
          }) => {
            if (errors.current_password) setFieldError("currentPassword", "Incorrect Current Passowrd");
            if (errors.password) setFieldError("password", "Invalid or blank new password");
            if (errors.password_confirmation)
              setFieldError("passwordConfirmation", "Password confirmation is blank or doesn't match");
          }
        );
    },
  });
  return (
    <Modal open={open} setOpen={setOpen}>
      <Box>
        <form onSubmit={handleSubmit}>
          <Box>
            <Box sx={{ mt: 4, display: "flex", flexDirection: "column", gap: 3 }}>
              <Box sx={{ display: "grid", gridTemplateColumns: "1fr", gap: 3 }}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink htmlFor="currentPassword">
                    Current Password
                  </InputLabel>
                  <InputBase
                    id="password-confirmation"
                    placeholder="Current password"
                    {...getFieldProps("currentPassword")}
                  />
                  {errors.currentPassword}
                </FormControl>
              </Box>

              <Box sx={{ display: "grid", gridTemplateColumns: "1fr", gap: 3 }}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink htmlFor="password">
                    New Password
                  </InputLabel>
                  <InputBase id="password" placeholder="Password" {...getFieldProps("password")} />
                  {errors.password}
                </FormControl>
              </Box>

              <Box sx={{ display: "grid", gridTemplateColumns: "1fr", gap: 3 }}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink htmlFor="password-confirmation">
                    New Password Confirmation
                  </InputLabel>
                  <InputBase
                    id="password-confirmation"
                    placeholder="Password Confirmation"
                    {...getFieldProps("passwordConfirmation")}
                  />
                  {errors.passwordConfirmation}
                </FormControl>
              </Box>
            </Box>

            <Box mt={3} sx={{ display: "flex", gap: 1, alignItems: "center", justifyContent: "center" }}>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                Update
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Modal>
  );
}
