import { Box, Button, FormControl, Typography } from "@mui/material";
import useCancelPickup from "hooks/pickups/useCancelPickup";
import { useState } from "react";
import { Pickup } from "types/pickup";
import { InputBase, InputLabel, RequiredSymbol } from "components/common/Input";
import { DescriptionOutlined } from "@mui/icons-material";
import theme from "lib/theme";
import React, { Fragment } from "react";
import _ from "lodash";
import { differenceInHours } from "date-fns";
import { formatDate } from "utils/helpers";

export const mainContact = (pickup: Pickup) => {
  return pickup.pickupContacts.find((contact) => contact.pointOfContactType === "main");
};

export const orderNumber = (pickup: Pickup) => {
  return [pickup.orderNumber];
};

export const pickupDate = (pickup: Pickup) => {
  return [
    formatDate(pickup?.scheduledAt) ?? "Please select a date",
    pickup?.confirmedTime || pickup?.requestedTime || "",
  ];
};

export const contact = (pickup: Pickup) => {
  if (mainContact(pickup)) {
    return [
      mainContact(pickup) ? `${mainContact(pickup)?.firstName} ${mainContact(pickup)?.lastName}` : "",
      mainContact(pickup)?.email,
      mainContact(pickup)?.phoneNumber,
    ];
  } else if (pickup.state == "missing_info" || pickup.state == "scheduled") {
    return ["Please select a main point of contact"];
  } else {
    return [""];
  }
};

export const address = (pickup: Pickup) => {
  if (pickup?.location) {
    return [
      pickup.location.address,
      pickup.location.address2,
      `${pickup.location.city}, ${pickup.location.state} ${pickup.location.zipcode}`,
    ];
  } else {
    return ["Please select a location"];
  }
};

export const assets = (pickup: Pickup) => {
  if (pickup.pickupEstimatedItems[0]) {
    return pickup.pickupEstimatedItems.map((estimatedAsset) => {
      return `${estimatedAsset.quantity} ${estimatedAsset.name}`;
    });
  } else {
    return ["Please add the items that will be in your pickup"];
  }
};

export function CancelConfirmation({ close, pickup }: { close: () => void; pickup: Pickup }) {
  let [value, setValue] = useState("");
  const { cancelPickup } = useCancelPickup();

  const handleCancel = (value: string, pickupUuid: string) => {
    cancelPickup(value, pickupUuid).then(() => {
      window.location.href = "/pickups";
    });
  };

  return (
    <Box>
      <FormControl variant="standard" sx={{ width: "100%" }}>
        <InputLabel shrink htmlFor="pickup-notes">
          Are you sure you want to cancel your pickup? Please note your reason for your cancellation below.{" "}
          <RequiredSymbol />
        </InputLabel>
        <InputBase multiline rows={2} id="pickup-notes" value={value} onChange={(e) => setValue(e.target.value)} />
      </FormControl>
      <Box mt={3} sx={{ display: "flex", alignItems: "center", justifyContent: "end", gap: 3 }}>
        <Button size="small" variant="contained" onClick={close}>
          go back
        </Button>
        <Button size="small" variant="contained" disabled={!value} onClick={(e) => handleCancel(value, pickup.uuid)}>
          yes, cancel pickup
        </Button>
      </Box>
    </Box>
  );
}

export function PickupDetailsTitle() {
  return (
    <Box
      sx={{
        fontWeight: "600",
        display: "flex",
        alignItems: "center",
        gap: 1,
        fontSize: "14px",
        color: "text.secondary",
        textTransform: "uppercase",
        borderBottom: `1px solid ${theme.palette.divider}`,
        pb: 1,
      }}
    >
      <DescriptionOutlined sx={{ fontSize: "20px" }} />
      PICKUP DETAILS
    </Box>
  );
}

export function PickupDetailsRows({ children }: React.PropsWithChildren<{}>) {
  return (
    <Box
      sx={{
        "> div": {},
        "> div:not(:last-child)": { borderBottom: `1px solid ${theme.palette.divider}`, py: 2 },
        "> div:last-child": { pt: 2 },
      }}
    >
      {children}
    </Box>
  );
}

interface RowItem {
  title: React.ReactNode;
  list: React.ReactNode[];
}

interface PickupDetailsRowProps {
  items?: (RowItem | React.ReactNode)[];
  customItems?: React.ReactNode[];
  columnCount?: number;
}

export function PickupDetailsRow({ items, customItems, columnCount = 3 }: PickupDetailsRowProps) {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
        // gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
        gap: 3,
      }}
    >
      {items?.map((item, i) => {
        if (item != null && typeof item === "object" && "list" in item) {
          return (
            <Box key={i}>
              <Typography color="text.secondary" fontSize="12px">
                {item.title}
              </Typography>
              <Box mt={0.5}>
                {item.list.map((listItem, i) => (
                  <Typography key={i} fontSize="14px" fontWeight="600">
                    {listItem}
                  </Typography>
                ))}
              </Box>
            </Box>
          );
        } else {
          return <Fragment key={i}>{item}</Fragment>;
        }
      })}
    </Box>
  );
}

interface PickupAssetsDetailsProps {
  items?: React.ReactNode[];
  title?: React.ReactNode;
}

export function PickupAssetsDetails({ title = "ASSETS", items }: PickupAssetsDetailsProps) {
  let renderItems = _.chunk(items, 2);
  return (
    <Box>
      <Typography fontSize="12px" color="text.secondary">
        {title}
      </Typography>
      <Box sx={{ mt: 0.5, display: "flex", flexWrap: "wrap", gap: 4 }}>
        {renderItems.map((items, i) => (
          <Box key={i}>
            {items.map((item, i) => (
              <Typography key={i} fontSize="14px" fontWeight="600">
                {item}
              </Typography>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export function shouldDisableEditPickup(pickup: Pickup) {
  if (pickup.scheduledAt == null) {
    return false;
  }
  let hoursDiff = differenceInHours(new Date(pickup.scheduledAt), new Date());
  let disableEditPickup = hoursDiff <= 72;
  return disableEditPickup;
}
interface PickupAgentDetailsProps {
  pickup: Pickup;
}

export function PickupAgentDetails({ pickup }: PickupAgentDetailsProps) {
  return (
    <Box>
      {pickup?.subAgent?.name ? (
        <>
          <Typography fontSize="12px" color="text.secondary">
            ASSIGNED PICKUP TEAM
          </Typography>
          <Box mt={0.5}>
            <Typography fontSize="14px" fontWeight="600">
              {pickup?.subAgent?.name}
            </Typography>
          </Box>
        </>
      ) : (
        pickup?.agent?.name && (
          <>
            <Typography fontSize="12px" color="text.secondary">
              ASSIGNED PICKUP TEAM
            </Typography>
            <Box mt={0.5}>
              <Typography fontSize="14px" fontWeight="600">
                {pickup?.agent?.name}
              </Typography>
            </Box>
          </>
        )
      )}
    </Box>
  );
}
