import { Box, Radio, Slider, Typography } from "@mui/material";
import theme from "lib/theme";
import React, { useState } from "react";
import { numberWithCommas } from "utils/helpers";

function EarningSlider() {
  let [value, setValue] = useState(1000);
  let [confirming, setConfirming] = useState(false);

  return (
    <Box>
      <Box mt={8}>
        <Slider
          valueLabelDisplay="on"
          size="small"
          value={value}
          onChange={(e, v) => !Array.isArray(v) && setValue(v)}
          max={14700}
          aria-label="Small"
          valueLabelFormat={(v) => `$${numberWithCommas(v)}`}
        />
        <Box display="flex" justifyContent={"end"}>
          <Typography
            variant="body2"
            fontWeight="600"
            color="text.secondary"
            sx={{ position: "relative", top: "-12px" }}
          >
            Current Earnings Balance: $14,700
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 4,
          p: 3,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
          display: "grid",
          gridTemplateColumns: `auto 1fr`,
          alignItems: "center",
          gap: 1,
          cursor: "pointer",
          userSelect: "none",
          boxSizing: "border-box",
        }}
        role="button"
        zIndex={0}
        onClick={(e) => setConfirming((f) => !f)}
      >
        <Radio
          checked={confirming}
          // @ts-ignore
          type="checkbox"
          size="medium"
          name="radio-buttons"
          inputProps={{ "aria-label": "A" }}
        />
        <Typography fontSize={"20px"} color="text.secondary" fontWeight={600}>
          Use ${numberWithCommas(value)} of your earnings
        </Typography>
      </Box>
    </Box>
  );
}

export default EarningSlider;
