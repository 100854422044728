import { format } from "date-fns";
import { Location } from "types/location";
interface AutocompleteOption {
  label: string;
  id: string | number | undefined;
  address?: string;
  zipcode?: string;
}

export function formatDate(dateString: string) {
  if (!dateString) return;
  var date = new Date(dateString.substring(0, 10)).toISOString().slice(0, -1);

  return format(new Date(date), "MMMM dd, yyyy"); // ex: April 27, 2022;
}

export function numberWithCommas(x: number | string) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatNumberToCurrency(number: number) {
  return number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

export const formatLocations = (locations: Location[], retrievalPage = false) => {
  let formattedLocations: AutocompleteOption[] = [{ label: "None", id: "" }];
  if (locations) {
    locations.forEach((location) => {
      if (
        !retrievalPage ||
        (location.country && ["United States", "USA", "US", "united states"].includes(location.country.trim()))
      ) {
        formattedLocations.push({
          label: `${location.name || ""} — ${location.address || ""} ${location.address2 || ""} — ${
            location.city || ""
          }, ${location.state || ""}`,
          id: retrievalPage ? location.id : location.uuid,
        });
      }
    });
  }
  return formattedLocations;
};
