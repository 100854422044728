import { api } from "services/api.service";
import useAuth from "./useAuth";
import { createContext, useContext, useEffect, useState } from "react";
import { Role } from "types/role";

type CurrentUserContextValue = {
  role?: Role;
  receivedWelcomeModal?: boolean;
};

export const CurrentUserContext = createContext<CurrentUserContextValue>({
  role: undefined,
  receivedWelcomeModal: true,
});

export const CurrentUserProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentUserRole, setCurrentUserRole] = useState<any>();
  const [receivedWelcomeModal, setReceivedWelcomeModal] = useState<any>();
  const { loggedIn } = useAuth();

  useEffect(() => {
    if (!currentUserRole && loggedIn) {
      getCurrentUser();
    }
  }, [currentUserRole, loggedIn]);

  const getCurrentUser = () => {
    api.get("/current_user").then(({ data: { role, receivedWelcomeModal } }) => {
      setCurrentUserRole(role);
      setReceivedWelcomeModal(receivedWelcomeModal);
    });
  };

  return (
    <CurrentUserContext.Provider value={{ role: currentUserRole, receivedWelcomeModal }}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export default function useCurrentUserPermission() {
  const { role } = useContext(CurrentUserContext);
  const restrictedFeatures: any = {
    admin: [],
    regular: ["userManagement", "donations", "sso", "earnings"],
    read_only: ["userManagement", "donations", "sso", "pickups", "assets", "locations", "companyInfo", "earnings"],
  };

  const hasPermission = (feature: string) => {
    if (!restrictedFeatures[role?.name ?? ""]) return false;
    return !restrictedFeatures[role?.name ?? ""].includes(feature);
  };

  return {
    hasPermission,
  };
}
