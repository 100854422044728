import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Donation } from "types/donation";
import { formatDate, formatNumberToCurrency } from "utils/helpers";
import theme from "lib/theme";

type DonationTableProps = {
  donations: Donation[];
};
export default function DonationsTable({ donations }: DonationTableProps) {
  return (
    <Box>
      <TableContainer
        sx={{
          mt: 2,
        }}
      >
        <Table sx={{ ".MuiTableRow-root:last-child td, .MuiTableRow-root:last-child th": { border: 0 } }}>
          <TableHead sx={{ ".MuiTableCell-root": { color: theme.palette.text.disabled, borderBottom: 0 } }}>
            <TableRow>
              <TableCell>DATE POSTED</TableCell>
              <TableCell>ORGANIZATION</TableCell>
              <TableCell>AMOUNT</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {donations.map((donation, i) => {
              return (
                <>
                  <TableRow>
                    <TableCell>{formatDate(donation.createdAt)}</TableCell>
                    <TableCell>
                      <Typography variant="body2">{donation.donationRequest.benefitingOrganization.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" color="success.main">
                        {formatNumberToCurrency(donation.totalDollarsAmount)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
