import useOnboarding from "hooks/companySettings/useOnboarding";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "services/api.service";
import { Organization } from "types/organization";

export default function useOrganization() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { fetchOnboarding } = useOnboarding();
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>();

  const [attemptedOrganizationConfiguration, setAttemptedOrganizationConfiguration] = useState(false);

  const [organization, setOrganization] = useState<Organization>({
    uuid: "",
    name: "",
    industry: "",
    refreshCycle: "one_year",
  });

  const [organizationConfiguration, setOrganizationConfiguration] = useState({ ssoActive: false, ssoProvider: "" });

  const updateOrganization = useCallback((organization: Organization) => {
    return api
      .put<Organization>("/organizations/current", { organization })
      .then(({ data }) => {
        setOrganization(data);
      })
      .finally(() => {
        fetchOnboarding && fetchOnboarding();
      });
  }, []);

  const getOrganizationConfiguration = () => {
    setAttemptedOrganizationConfiguration(true);
    api
      .get("/organization_configuration")
      .then(({ data }) => {
        setOrganizationConfiguration(data);
        setLoadingConfig(false);
      })
      .catch(({ response }) => {
        setLoadingConfig(false);
        if (response.status === 404) {
          navigate("/find-organization");
        } else if (response.status === 403 || response.status === 401) {
          setErrorMessage(
            "It looks like your company is using a Web Gateway that is blocking requests to our api. " +
              "Please have your security team allow requests to https://api.revivn.dev and refresh the page. Thank you!"
          );
        } else if (response.status === 423) {
          setErrorMessage(
            "Your account has been deactivated. Please contact concierge@revivn.com to reactivate your account."
          );
        } else {
          setErrorMessage("There was a problem loading your page, please try again shortly.");
        }
      });
  };

  const getOrganization = () => {
    api.get<Organization>("/organizations/current").then(({ data }) => {
      setOrganization(data);
    });
  };

  useEffect(() => {
    if (pathname.includes("company-settings")) {
      getOrganization();
    }

    if (pathname.includes("login") && !attemptedOrganizationConfiguration) {
      setAttemptedOrganizationConfiguration(true);
      getOrganizationConfiguration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const updateTermsOfServiceTimestamp = useCallback(() => {
    return api.put("/asset_retrieval_terms_of_service");
  }, []);

  return {
    organization,
    organizationConfiguration,
    updateOrganization,
    loadingConfig,
    errorMessage,
    updateTermsOfServiceTimestamp,
  };
}
