import { Typography, Box } from "@mui/material";
import theme from "lib/theme";
import { DrawerProps } from "types/pickup";
import { CancelPresentation } from "@mui/icons-material";
import {
  address,
  contact,
  orderNumber,
  pickupDate,
  PickupDetailsRow,
  PickupDetailsRows,
  PickupDetailsTitle,
} from "./shared";

export function CanceledDrawer(props: DrawerProps) {
  const { pickup } = props;

  return (
    <Box mb={5} mx={2}>
      <Box
        sx={{
          py: 3,
          px: 4,
          background: "#FAFAFA",
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
        }}
      >
        <CancelPresentation />
        <Typography variant="h6" fontWeight="bold">
          Your pickup was canceled!
        </Typography>
        <Typography>
          Your pickup was canceled! If you have any questions or need help scheduling a new pickup, please reach out to
          us.
        </Typography>

        <Box mt={2}>
          <PickupDetailsTitle />

          <PickupDetailsRows>
            <PickupDetailsRow
              columnCount={4}
              items={[
                {
                  title: "PICKUP ID",
                  list: orderNumber(pickup),
                },
                {
                  title: "PICKUP DATE",
                  list: pickupDate(pickup),
                },
                {
                  title: "CONTACT",
                  list: contact(pickup),
                },
                {
                  title: "ADDRESS",
                  list: address(pickup),
                },
              ]}
            />
            <PickupDetailsRow
              columnCount={4}
              items={[
                {
                  title: "CANCELED BY",
                  list: [
                    `${pickup.canceledBy?.firstName || ""} ${pickup.canceledBy?.lastName || ""}`,
                    pickup.canceledBy?.email || "",
                  ],
                },
                {
                  title: "CANCELLATION REASON",
                  list: [pickup.cancellationReason || ""],
                },
              ]}
            />
          </PickupDetailsRows>
        </Box>
      </Box>
    </Box>
  );
}
