import { LocationOn, LocalShipping, DateRange } from "@mui/icons-material";
import { Box, Typography, Divider } from "@mui/material";
import usePickup from "hooks/pickups/usePickup";
import theme from "lib/theme";
import { mainContact, otherContacts } from "utils/pickup";

export default function SummaryInfo() {
  const { pickup } = usePickup();
  const { firstName, lastName, phoneNumber, email } = mainContact(pickup);

  return (
    <Box sx={{ p: 4, background: "white", borderRadius: 1, border: `1px solid ${theme.palette.divider}` }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "200px 1fr",
          gap: 1,
          [theme.breakpoints.down("md")]: {
            gridTemplateColumns: "1fr",
            gap: 2,
          },
        }}
      >
        <Box>
          <Typography variant="h6" fontWeight="bold" sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <LocationOn color="primary" />
            Pickup location
          </Typography>
        </Box>
        <Box>
          <Typography fontWeight={600}>{pickup?.location?.name}</Typography>
          <Typography mt={0.5}>
            {pickup?.location?.address} <br /> {pickup?.location?.address2} <br /> {pickup?.location?.city},{" "}
            {pickup?.location?.state} {pickup?.location?.zipcode}
          </Typography>
        </Box>
      </Box>

      <Divider sx={{ my: 4 }} />

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "200px 1fr",
          gap: 1,
          [theme.breakpoints.down("md")]: {
            gridTemplateColumns: "1fr",
            gap: 2,
          },
        }}
      >
        <Box>
          <Typography variant="h6" fontWeight="bold" sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <LocalShipping color="primary" />
            Pickup info
          </Typography>
        </Box>
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 1 }}>
          <Box>
            <Typography fontWeight={600}>Pickup details:</Typography>

            {pickup?.pickupEstimatedItems.map((item) => {
              return (
                <Typography mt={0.5} key={item.name}>
                  {item.quantity} {item.name}
                </Typography>
              );
            })}
          </Box>
          <Box>
            <Typography fontWeight={600}>Reason for pickup: </Typography>
            <Typography mt={0.5}>{pickup?.reason}</Typography>

            <Typography mt={2} fontWeight={600}>
              Point of contact:
            </Typography>
            <Typography mt={0.5}>
              {firstName} {lastName} <br />
              {phoneNumber} <br />
              {email}
            </Typography>
          </Box>
          {otherContacts(pickup) && otherContacts(pickup)[0] && (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Box>
                <Typography fontWeight={600}>Notify:</Typography>

                {otherContacts(pickup).map((contact) => {
                  return (
                    <Typography mt={0.5} key={contact.email}>
                      {contact.email}
                    </Typography>
                  );
                })}
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <Divider sx={{ my: 4 }} />

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "200px 1fr",
          gap: 1,
          [theme.breakpoints.down("md")]: {
            gridTemplateColumns: "1fr",
            gap: 2,
          },
        }}
      >
        <Box>
          <Typography variant="h6" fontWeight="bold" sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <DateRange color="primary" />
            Pickup date
          </Typography>
        </Box>
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", gap: 1 }}>
          <Box>
            <Typography fontWeight={600}>{new Date(pickup?.scheduledAt || "").toDateString()}</Typography>
            <Typography mt={0.5}>{pickup?.requestedTime || ""}</Typography>
          </Box>
          {pickup?.comments && (
            <Box>
              <Typography fontWeight={600}>Comments</Typography>
              <Typography mt={0.5}>{pickup?.comments || ""}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
