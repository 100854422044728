import { Box, Button, FormControl, Typography } from "@mui/material";
import { InputBase, InputLabel } from "components/common/Input";
import { Formik } from "formik";
import useAuth from "hooks/useAuth";
import theme from "lib/theme";
import LoginCoverImg from "images/login-cover.png";
import * as yup from "yup";

export default function ResetPassword() {
  const { resetPassword } = useAuth();

  return (
    <Box sx={{ paddingBottom: "100px" }}>
      <img
        src={LoginCoverImg}
        alt="login cover"
        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
      ></img>

      <Typography
        variant="h4"
        sx={{ textAlign: "center", mt: 6, fontWeight: "bold", color: theme.palette.text.primary }}
      >
        Reset Password
      </Typography>

      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          color: theme.palette.text.secondary,
          mt: 3,
        }}
      >
        Manage your hardware lifecycle and repurpose your unused tech for a greater cause all in one place{" "}
      </Typography>
      <Box sx={{ maxWidth: "660px", mx: "auto" }}>
        <Box mt={4}>
          <Formik
            initialValues={{ passwordConfirmation: "", password: "" }}
            onSubmit={async (values) => {
              try {
                return await resetPassword(values);
              } finally {
              }
            }}
            validationSchema={yup.object({
              password: yup.string().required("required"),
              passwordConfirmation: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
            })}
          >
            {({ getFieldProps, errors, handleSubmit, isSubmitting, touched, isValid }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="column">
                  <FormControl variant="standard" sx={{ mt: 2 }}>
                    <InputLabel shrink htmlFor="password">
                      Password
                    </InputLabel>
                    <InputBase
                      type="password"
                      {...getFieldProps("password")}
                      id="password"
                      helperText={touched.password && errors.password}
                      error={touched.password && !!errors.password}
                    />
                  </FormControl>

                  <FormControl variant="standard" sx={{ mt: 2 }}>
                    <InputLabel shrink htmlFor="password">
                      Password Confirmation
                    </InputLabel>
                    <InputBase
                      type="password"
                      {...getFieldProps("passwordConfirmation")}
                      id="passwordConfirmation"
                      helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                      error={touched.passwordConfirmation && !!errors.passwordConfirmation}
                    />
                  </FormControl>

                  <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    disabled={isSubmitting || !isValid}
                    sx={{ textTransform: "uppercase", mt: 4, alignSelf: "start" }}
                  >
                    Reset Password
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}
