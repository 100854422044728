import React from "react";
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export interface BreadcrumbItem {
  label: React.ReactNode;
  to?: string;
}

export interface BreadcrumbsProps {
  data: BreadcrumbItem[];
}

function Breadcrumbs({ data }: BreadcrumbsProps) {
  return (
    <div>
      <MuiBreadcrumbs aria-label="breadcrumb">
        {data.map((item, i) => {
          let isLast = i === data.length - 1;
          if (!isLast) {
            if (item.to) {
              return (
                <Link key={i} fontSize={"14px"} component={RouterLink} underline="hover" color="inherit" to={item.to}>
                  {item.label}
                </Link>
              );
            } else {
              return (
                <Typography key={i} fontSize={"14px"} color="inherit">
                  {item.label}
                </Typography>
              );
            }
          }
          return (
            <Typography key={i} fontSize={"14px"} color="text.primary" sx={{ fontWeight: "bold" }}>
              {item.label}
            </Typography>
          );
        })}
      </MuiBreadcrumbs>
    </div>
  );
}

export default Breadcrumbs;
