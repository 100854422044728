import { Alert, Box, Collapse, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Breadcrumbs from "components/common/Breadcrumbs";
import { useCallback, useState } from "react";
import theme from "lib/theme";
import { Formik } from "formik";
import * as yup from "yup";
import useAnalytics from "hooks/analytics/useAnalytics";
import BulkOrderDetails from "./BulkOrderDetails";
import ReturnAddressDetails from "./ReturnAddressDetails";
import { AssetRetrievalBulkOrderDetails } from "types/assetRetrieval";
import BulkPlaceOrder from "./BulkPlaceOrder";
import useAssetRetrievalBulkUpload from "hooks/assetRetrievals/useAssetRetrievalBulkUpload";

const initialValues: AssetRetrievalBulkOrderDetails = {
  fileSignedId: "",
  locationId: "",
  totalRows: 0,
  insuranceOption: "0",
  paymentType: "earnings",
  returnRecipientFirstName: "",
  returnRecipientLastName: "",
  returnRecipientEmail: "",
  returnRecipientCompany: "",
  returnRecipientAddress: "",
  returnRecipientAddressTwo: "",
  returnRecipientAddressCity: "",
  returnRecipientAddressState: "",
  returnRecipientAddressZipCode: "",
  returnRecipientAddressCountry: "United States",
  isCustomAddress: false,
  virtualPickup: false,
};

const validationSchema = yup.object().shape({
  fileSignedId: yup.string().required("Please upload a file"),
  isCustomAddress: yup.boolean(),
  locationId: yup.string().when(["isCustomAddress", "virtualPickup"], {
    is: (isCustomAddress: boolean, virtualPickup: boolean) => {
      return !isCustomAddress && !virtualPickup;
    },
    then: yup.string().required("Please select a location"),
  }),
  insuranceOption: yup.string().required("Please select an insurance option"),
  returnRecipientFirstName: yup.string().when(["isCustomAddress", "virtualPickup"], {
    is: (isCustomAddress: boolean, virtualPickup: boolean) => {
      return isCustomAddress && !virtualPickup;
    },
    then: yup.string().required("Please enter a first name"),
  }),
  returnRecipientLastName: yup.string().when(["isCustomAddress", "virtualPickup"], {
    is: (isCustomAddress: boolean, virtualPickup: boolean) => {
      return isCustomAddress && !virtualPickup;
    },
    then: yup.string().required("Please enter a last name"),
  }),
  returnRecipientAddress: yup.string().when(["isCustomAddress", "virtualPickup"], {
    is: (isCustomAddress: boolean, virtualPickup: boolean) => {
      return isCustomAddress && !virtualPickup;
    },
    then: yup.string().required("Please enter an address"),
  }),
  returnRecipientAddressCity: yup.string().when(["isCustomAddress", "virtualPickup"], {
    is: (isCustomAddress: boolean, virtualPickup: boolean) => {
      return isCustomAddress && !virtualPickup;
    },
    then: yup.string().required("Please enter a city"),
  }),
  returnRecipientAddressState: yup.string().when(["isCustomAddress", "virtualPickup"], {
    is: (isCustomAddress: boolean, virtualPickup: boolean) => {
      return isCustomAddress && !virtualPickup;
    },
    then: yup.string().required("Please enter a state"),
  }),
  returnRecipientAddressZipCode: yup.string().when(["isCustomAddress", "virtualPickup"], {
    is: (isCustomAddress: boolean, virtualPickup: boolean) => {
      return isCustomAddress && !virtualPickup;
    },
    then: yup.string().required("Please enter a zip code"),
  }),
});
export default function BulkOrder() {
  let [searchParams, setSearchParams] = useSearchParams();
  const { createAnalyticsEvent } = useAnalytics();
  const { create } = useAssetRetrievalBulkUpload();
  const [open, setOpen] = useState(true);
  let step = parseInt(searchParams.get("step") ?? "0");

  let goToNextStep = useCallback(() => {
    setSearchParams({ step: String(step + 1) });
    createAnalyticsEvent(`asset_retrieval_form_step_${step + 1}`);
  }, [setSearchParams, step]);

  return (
    <>
      <Box>
        <Breadcrumbs
          data={[
            { label: "Dashboard", to: "/" },
            { label: "Retrievals", to: "/retrievals/send-boxes" },
            { label: "Bulk Order", to: "/retrievals/send-boxes/bulk-new" },
          ]}
        ></Breadcrumbs>
      </Box>
      <Box mt={4}>
        <Typography variant="h4" fontWeight="bold">
          Place a Bulk Order
        </Typography>
        <Typography variant="body1">Send a box to an employee to retrieve their asset. </Typography>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Stepper
            alternativeLabel
            activeStep={step}
            sx={{
              width: "100%",
              maxWidth: "900px",
              mt: 5,
              ".MuiStepLabel-root": {
                [theme.breakpoints.down("sm")]: {
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 0.5,
                },
              },
            }}
          >
            <Step
              onClick={() => setSearchParams({ step: "0" })}
              sx={{
                cursor: "pointer",
              }}
            >
              <StepLabel>Order Details</StepLabel>
            </Step>
            <Step>
              <StepLabel>Return Address</StepLabel>
            </Step>
            <Step>
              <StepLabel>Review & Pay</StepLabel>
            </Step>
          </Stepper>
        </Box>
        <Box my={3}>
          <Collapse in={open}>
            <Alert
              variant="filled"
              color="primary"
              severity="info"
              onClose={() => {
                setOpen(false);
              }}
            >
              Reminder: Before placing orders, make sure you have the latest addresses for your employees! If there's a
              pending move, check out our express shipping options for quicker delivery. Alternatively, consider waiting
              until their new address is available before placing the order.{" "}
            </Alert>
          </Collapse>
        </Box>
      </Box>
      <Formik
        validateOnMount
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values) => {
          create(values);
        }}
      >
        <Box sx={{ mt: 4 }}>
          {step === 0 ? (
            <BulkOrderDetails goToNextStep={goToNextStep} />
          ) : step === 1 ? (
            <ReturnAddressDetails goToNextStep={goToNextStep} />
          ) : step === 2 ? (
            <BulkPlaceOrder goToNextStep={goToNextStep} />
          ) : null}
        </Box>
      </Formik>
    </>
  );
}
