import { Sidetab } from "@typeform/embed-react";
import useAnalytics from "hooks/analytics/useAnalytics";

export default function TypeformFeedbackButton() {
  const { createAnalyticsEvent } = useAnalytics();
  const handleFeedBackEvent = () => {
    createAnalyticsEvent("triggered_typeform_feedback");
  };

  return (
    <button onClick={handleFeedBackEvent}>
      <Sidetab id="l5vtmg9b" buttonText="Feedback" buttonColor="#0089BD" onClose={handleFeedBackEvent} autoClose />
    </button>
  );
}
