import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { AppSelect, InputBase, InputLabel } from "components/common/Input";
import theme from "lib/theme";
import { useFormik } from "formik";
import useCreateWebhookSubscription from "hooks/api/useCreateWebhookSubscription";
import slackLogo from "images/slack_icon.png";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  eventName: Yup.string().required("Event name is required"),
  endpoint: Yup.string()
    .required("Endpoint is required")
    .when("channel", {
      is: "slack",
      then: Yup.string().test("slack-endpoint", "Endpoint must be a valid slack webhook", (value) => {
        // valid slack webhook url: https://hooks.slack.com/services/T00000000/B00000000/XXXXXXXXXXXXXXXXXXXXXXXX
        const regex = /^https:\/\/hooks\.slack\.com\/services\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+$/;
        return value ? regex.test(value) : false;
      }),
    }),
  requestType: Yup.string()
    .required("Request type is required")
    .when("channel", {
      is: "slack",
      then: Yup.string().oneOf(["post"], "Request type must be post for slack webhooks"),
    }),
  headerName1: Yup.string(),
  headerValue1: Yup.string(),
  headerName2: Yup.string(),
  headerValue2: Yup.string(),
});

function WebhookForm() {
  const { create } = useCreateWebhookSubscription();
  const formik = useFormik({
    initialValues: {
      eventName: "",
      endpoint: undefined,
      requestType: "post",
      customHeaders: undefined,
      channel: "rest_api",
      headerName1: "",
      headerValue1: "",
      headerName2: "",
      headerValue2: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { eventName, endpoint, requestType, channel } = values;
      const payload = {
        eventName,
        endpoint,
        requestType,
        channel,
        customHeaders: JSON.stringify({
          [values.headerName1]: values.headerValue1,
          [values.headerName2]: values.headerValue2,
        }),
      };
      create(payload);
    },
  });

  return (
    <div>
      <Typography variant="h4" fontWeight="bold">
        Subscribe to a webhook event
      </Typography>

      <Box
        sx={{
          mt: 3,
          px: 5,
          py: 3,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mt: 4, display: "grid", gap: 3 }}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel shrink htmlFor="endpoint">
                Event Name
              </InputLabel>
              <AppSelect placeholder="Select Event" {...formik.getFieldProps("eventName")}>
                {[
                  "Pickup Scheduled",
                  "Pickup Initial Report Ready",
                  "Pickup On Hold",
                  "Pickup Complete",
                  "Donation Made",
                ].map((eventName) => (
                  <MenuItem key={eventName} value={eventName}>
                    {eventName}
                  </MenuItem>
                ))}
              </AppSelect>
            </FormControl>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="endpoint">
                Channel
              </InputLabel>
              <RadioGroup row {...formik.getFieldProps("channel")}>
                <FormControlLabel value="rest_api" control={<Radio />} label="Rest API" />
                <FormControlLabel
                  value="slack"
                  control={<Radio />}
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={slackLogo} alt=" Slack" style={{ width: "18px", height: "18px", marginRight: "8px" }} />{" "}
                      Slack
                    </div>
                  }
                />
              </RadioGroup>
            </FormControl>
            <FormControl variant="standard">
              <Grid container>
                <Grid container item xs={1} direction="row">
                  <InputLabel shrink htmlFor="endpoint">
                    Request
                  </InputLabel>
                </Grid>
                <Grid container item xs={9} direction="row">
                  <InputLabel shrink htmlFor="endpoint">
                    Endpoint
                  </InputLabel>
                </Grid>
              </Grid>

              <Paper
                component="form"
                elevation={0}
                variant="outlined"
                sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
              >
                <Select
                  sx={{ width: "90px", textTransform: "uppercase" }}
                  variant="standard"
                  defaultValue={"post"}
                  disableUnderline
                  {...formik.getFieldProps("requestType")}
                >
                  {["post", "put"].map((type) => (
                    <MenuItem sx={{ textTransform: "uppercase" }} key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  type="text"
                  id="endpoint"
                  placeholder="https://..."
                  {...formik.getFieldProps("endpoint")}
                />
              </Paper>
              <FormHelperText error={formik.touched.requestType && Boolean(formik.errors.requestType)}>
                {formik.touched.requestType && formik.errors.requestType}
              </FormHelperText>
              <FormHelperText error={formik.touched.endpoint && Boolean(formik.errors.endpoint)}>
                {formik.touched.endpoint && formik.errors.endpoint}
              </FormHelperText>
            </FormControl>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="endpoint">
                Custom Headers
              </InputLabel>
              <Grid container spacing={2} paddingTop={2}>
                <Grid container item xs={6} direction="column">
                  <InputBase
                    type="text"
                    id="headerName1"
                    placeholder="X-API-KEY"
                    {...formik.getFieldProps("headerName1")}
                  />
                </Grid>
                <Grid container item xs={6} direction="column">
                  <InputBase
                    type="text"
                    id="headerValue1"
                    placeholder="ABCD123"
                    {...formik.getFieldProps("headerValue1")}
                  />
                </Grid>
                <Grid container item xs={6} direction="column">
                  <InputBase
                    type="text"
                    id="headerName2"
                    placeholder="X-API-KEY"
                    {...formik.getFieldProps("headerName2")}
                  />
                </Grid>
                <Grid container item xs={6} direction="column">
                  <InputBase
                    type="text"
                    id="headerValue2"
                    placeholder="ABCD123"
                    {...formik.getFieldProps("headerValue2")}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>

          <Box textAlign="right" mt={3}>
            <Button type="submit" variant="contained" size="large">
              Create Subscription
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  );
}

export default WebhookForm;
