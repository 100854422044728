import { VpnKey } from "@mui/icons-material";
import { Typography, Box, Button, Skeleton, Tooltip } from "@mui/material";
import PaywallModal from "components/PaywallModal";
import PlanFeatureWrapper from "components/PlanFeatureWrapper";
import useSaml from "hooks/saml/useSaml";
import useCurrentUserPermission from "hooks/useCurrentUserPermission";
import theme from "lib/theme";
import { Link as RouterLink } from "react-router-dom";

export function Authentication() {
  const { isLoading, samlSetting, deactivateSaml } = useSaml();
  const { hasPermission } = useCurrentUserPermission();

  const SsoButton = () => {
    return (
      <Box display="flex" alignItems="center" gap={1}>
        <img src={require("assets/images/ProLogo.png")} alt="" style={{ width: "55px", height: "55px" }}></img>
        <Button
          component={RouterLink}
          variant="contained"
          color="secondary"
          to="/company-settings/authentication/provider"
          disabled={!hasPermission("sso")}
        >
          {samlSetting ? "edit your Saml configuration" : "configure"}
        </Button>
      </Box>
    );
  };

  return (
    <div>
      <Typography variant="h4" fontWeight="bold">
        Authentication
      </Typography>
      {isLoading && <Skeleton />}

      {!isLoading && (
        <>
          <Typography mt={1}>
            {samlSetting && "Edit your Saml configuration below."}
            {!samlSetting &&
              "Revivn supports a number of single sign-on (SSO) services. Get started with setting up your company’s SSO below."}
          </Typography>

          <Box
            mt={4}
            sx={{
              px: 5,
              py: 3,
              background: "white",
              borderRadius: 1,
              border: `1px solid ${theme.palette.divider}`,
            }}
          >
            {!samlSetting && (
              <Typography variant="h6" fontWeight="600">
                Configure SSO with Saml 2.0
              </Typography>
            )}

            <Box mt={3} sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
              <Box sx={{ display: "grid", gridTemplateColumns: "auto 1fr auto", gap: 2, alignItems: "center" }}>
                <Box sx={{ color: theme.palette.primary.main, display: "grid", placeItems: "center" }}>
                  <VpnKey />
                </Box>
                {!samlSetting && (
                  <Box>
                    <Typography fontWeight="600">SAML authentication</Typography>
                    <Typography>Get set up with Okta, OneLogin, or your custom SAML 2.0 solution</Typography>
                  </Box>
                )}
                {samlSetting && (
                  <Box>
                    <Typography fontWeight="600">{`Your Saml configuration is active.`}</Typography>
                  </Box>
                )}

                <Box>
                  <Tooltip
                    title={
                      hasPermission("sso")
                        ? ""
                        : "You do not have permissions for this action, please contact your administrator for permission."
                    }
                  >
                    <span>
                      <PlanFeatureWrapper
                        feature="sso"
                        enabledElement={
                          <Button
                            component={RouterLink}
                            variant="contained"
                            to="/company-settings/authentication/provider"
                            disabled={!hasPermission("sso")}
                          >
                            {samlSetting ? "edit your Saml configuration" : "configure"}
                          </Button>
                        }
                        disabledElement={
                          <>
                            <Box display="flex" alignItems="center" gap={1}>
                              <PaywallModal
                                feature={"sso"}
                                trigger={(props) => (
                                  <Button variant="contained" {...props} disabled={!hasPermission("sso")}>
                                    configure
                                  </Button>
                                )}
                              />
                            </Box>
                          </>
                        }
                        grandfatheredElement={
                          <>
                            <SsoButton />
                          </>
                        }
                      />
                    </span>
                  </Tooltip>
                  <Box sx={{ margin: "10px 0 0 0" }}>
                    {samlSetting && (
                      <Button
                        color="error"
                        variant="contained"
                        disabled={!hasPermission("sso")}
                        onClick={(e) => {
                          deactivateSaml();
                        }}
                      >
                        Deactivate Saml
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </div>
  );
}
