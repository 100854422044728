import { Box, Button, FormControl, Link, MenuItem, Pagination, Typography } from "@mui/material";
import { AppSelect, InputBase, InputLabel } from "components/common/Input";
import theme from "lib/theme";
import { useState } from "react";
import { AccountBalance, ArrowForward, CreditCard } from "@mui/icons-material";
import { useNavigate, Link as RouterLink } from "react-router-dom";

function CompanyInfo() {
  let [activeStep, setActiveStep] = useState(1);
  let navigate = useNavigate();

  return (
    <div>
      <Box mt={6} sx={{ display: "flex", justifyContent: "space-between", gap: 8 }}>
        {activeStep !== 3 ? (
          <Box>
            <Typography variant="h4" fontWeight="bold">
              Create company profile
            </Typography>
            <Typography variant="body1" mt={1}>
              Don’t worry, you can change or set up your profile later
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography variant="h4" fontWeight="bold">
              Payment information
            </Typography>
            <Typography variant="body1" mt={1}>
              We collect your ACH info for payment relating to earnings and allow you to save a credit card in your
              account if you choose to purchase additional features within the app (but don’t worry, we won’t charge you
              without notifying you first)!
            </Typography>
          </Box>
        )}

        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "end", flexShrink: 0 }}>
          <Typography variant="body1">Step number:</Typography>
          <Pagination
            sx={{ mt: 0.5, ".MuiButtonBase-root": { height: "40px", width: "40px", borderRadius: "4px" } }}
            page={activeStep}
            onChange={(event, value) => {
              setActiveStep(value);
            }}
            count={3}
            hideNextButton
            hidePrevButton
            color="primary"
          />
        </Box>
      </Box>

      <Box mt={4}>
        {activeStep === 1 && <Step1 />}
        {activeStep === 2 && <Step2 />}
        {activeStep === 3 && <Step3 />}
      </Box>

      <Box mt={4} sx={{ display: "flex", flexDirection: "column", alignItems: "start", gap: 2 }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          {activeStep > 1 && (
            <Button
              variant="outlined"
              onClick={() => {
                setActiveStep(activeStep - 1);
              }}
            >
              Back
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() => {
              if (activeStep < 3) {
                setActiveStep(activeStep + 1);
              } else {
                navigate("/user-setup/add-locations");
              }
            }}
          >
            Next
          </Button>
        </Box>
        <Link
          component="button"
          underline="hover"
          variant="button"
          onClick={() => {
            navigate("/user-setup/add-locations");
          }}
        >
          Skip
        </Link>
      </Box>
    </div>
  );
}

function Step1() {
  return (
    <Box
      sx={{
        p: 4,
        background: "white",
        borderRadius: 1,
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <FormControl variant="standard">
          <InputLabel shrink htmlFor="company-name">
            Company name
          </InputLabel>
          <InputBase id="company-name" />
        </FormControl>

        <FormControl variant="standard">
          <InputLabel shrink htmlFor="industry">
            What industry are you in?
          </InputLabel>
          <InputBase placeholder="Search industries" id="industry" />
        </FormControl>

        <FormControl variant="standard">
          <InputLabel shrink htmlFor="job-role">
            What’s your job role?
          </InputLabel>
          <InputBase placeholder="Search job roles" id="job-role" />
        </FormControl>
      </Box>
    </Box>
  );
}

function Step3() {
  return (
    <Box
      sx={{
        p: 4,
        background: "white",
        borderRadius: 1,
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Typography variant="h6" fontWeight={"bold"}>
        Complete one or both of the following:
      </Typography>
      <Typography color="text.secondary">
        Note: You won’t be able to schedule a pickup until we receive your signed ACH authorization form.
      </Typography>

      <Box mt={3} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box
          component={RouterLink}
          to="/company-settings/payment-info/ach-information"
          sx={{
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.primary.main}`,

            display: "grid",
            gridTemplateColumns: "auto 1fr auto",
            gap: 1,
            alignItems: "center",
            minHeight: "72px",
            maxWidth: "570px",
          }}
        >
          <Box sx={{ px: 2.5, display: "grid", placeItems: "center" }}>
            <AccountBalance sx={{ color: theme.palette.primary.main }} />
          </Box>
          <Typography fontSize="18px" fontWeight="600" sx={{ py: 1 }}>
            ACH Authorization Form (Domestic & International)
          </Typography>
          {/* <Box sx={{ color: theme.palette.primary.main, px: 2.5, display: "grid", placeItems: "center" }}>
            <CheckCircle />
          </Box> */}
        </Box>

        <Box
          sx={{
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.primary.main}`,

            display: "grid",
            gridTemplateColumns: "auto 1fr auto",
            gap: 1,
            alignItems: "center",
            minHeight: "72px",
            maxWidth: "570px",
          }}
        >
          <Box sx={{ px: 2.5, display: "grid", placeItems: "center" }}>
            <CreditCard sx={{ color: theme.palette.primary.main }} />
          </Box>
          <Typography fontSize="18px" fontWeight="600" sx={{ py: 1 }}>
            Add new credit card
          </Typography>
          {/* <Box sx={{ color: theme.palette.primary.main, px: 2.5, display: "grid", placeItems: "center" }}>
            <CheckCircle />
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
}

function Step2() {
  let [selectedUserNumbers, setSelectedUserNumbers] = useState("");

  return (
    <Box
      sx={{
        p: 4,
        background: "white",
        borderRadius: 1,
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box>
        <Typography variant="h6" fontWeight={"bold"} sx={{ display: "flex", gap: 1 }}>
          <ArrowForward sx={{ mt: "4px" }} />
          How many end users do you support?
        </Typography>

        <Box mt={3} sx={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(210px, 1fr))", gap: 2 }}>
          {["1-10", "11-50", "51-100", "101-500", "501-1000", "1000+", "5000+", "10000+"].map((item, index) => {
            let isActive = selectedUserNumbers === item;
            return (
              <Box
                component="button"
                key={index}
                sx={[
                  {
                    cursor: "pointer",
                    height: "74px",
                    background: "white",
                    borderRadius: 1,
                    border: `1px solid ${theme.palette.divider}`,
                    display: "grid",
                    placeItems: "center",
                  },
                  !isActive && {
                    ":hover": {
                      border: `1px solid ${theme.palette.primary.main}`,
                      color: theme.palette.primary.main,
                    },
                  },
                  isActive && {
                    border: `1px solid ${theme.palette.primary.main}`,
                    textDecoration: "underline",
                    backgroundColor: "#EDF5FA",
                  },
                ]}
                onClick={() => setSelectedUserNumbers(item)}
              >
                {item}
              </Box>
            );
          })}
        </Box>
      </Box>

      <Box mt={4}>
        <Typography variant="h6" fontWeight={"bold"} sx={{ display: "flex", gap: 1 }}>
          <ArrowForward sx={{ mt: "4px" }} />
          <div>
            Does your company currently have a refresh cycle?
            <Typography mt={0.5}>Are you on a refresh cycle? If so, please select your cycle below.</Typography>
          </div>
        </Typography>

        <Box
          mt={3}
          sx={{
            p: 4,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box sx={{ width: "100%", maxWidth: "450px" }}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel shrink htmlFor="cycle-length">
                Cycle Length
              </InputLabel>
              <AppSelect id="cycle-length">
                <MenuItem value="2000">2000</MenuItem>
                <MenuItem value="2001">2001</MenuItem>
                <MenuItem value="2002">2002</MenuItem>
                <MenuItem value="2003">2003</MenuItem>
                <MenuItem value="2004">2004</MenuItem>
                <MenuItem value="2005">2005</MenuItem>
              </AppSelect>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default CompanyInfo;
