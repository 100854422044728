import { useCallback, useEffect, useState } from "react";
import { api } from "services/api.service";

export type AssetRetrieval = {
  orderNumber: string;
  employeeFirstName: string;
  employeeLastName: string;
  employeeEmail: string;
  employeePhone: string;
  employeeAddress: string;
  employeeAddressTwo: string;
  employeeAddressCity: string;
  employeeAddressState: string;
  employeeAddressZipCode: string;
  employeeAddressCountry: string;
  manufacturer: string;
  model: string;
  serialNumber: string;
  insuranceOption: string;
  locationId: string;
  accountingPocEmail: string;
  invoiced?: boolean;
  virtualPickup?: boolean;
  paymentType: "earnings" | "payment";
  isCustomAddress?: boolean;
  returnRecipientName: string;
  returnRecipientFirstName: string;
  returnRecipientLastName: string;
  returnRecipientEmail: string;
  returnRecipientCompany: string;
  returnRecipientAddress: string;
  returnRecipientAddressTwo: string;
  returnRecipientAddressCity: string;
  returnRecipientAddressState: string;
  returnRecipientAddressZipCode: string;
  returnRecipientAddressCountry: string;
};

export type AssetRetrievalPricing = {
  basePriceCents: number;
  basePriceCurrency: string;
  discountPercentage: number;
  offPriceCents: number;
  offPriceCurrency: string;
};

const dummyAssetRetrieval: AssetRetrieval = {
  orderNumber: "",
  employeeFirstName: "",
  employeeLastName: "",
  employeeEmail: "",
  employeePhone: "",
  employeeAddress: "",
  employeeAddressTwo: "",
  employeeAddressCity: "",
  employeeAddressState: "",
  employeeAddressZipCode: "",
  employeeAddressCountry: "United States",
  manufacturer: "",
  model: "",
  serialNumber: "",
  insuranceOption: "0",
  locationId: "",
  accountingPocEmail: "",
  virtualPickup: false,
  paymentType: "earnings",
  isCustomAddress: false,
  returnRecipientName: "",
  returnRecipientFirstName: "",
  returnRecipientLastName: "",
  returnRecipientEmail: "",
  returnRecipientCompany: "",
  returnRecipientAddress: "",
  returnRecipientAddressTwo: "",
  returnRecipientAddressCity: "",
  returnRecipientAddressState: "",
  returnRecipientAddressZipCode: "",
  returnRecipientAddressCountry: "United States",
};

export default function useAssetRetrieval() {
  const [assetRetrieval, setAssetRetrieval] = useState(dummyAssetRetrieval);
  const [loading, setLoading] = useState(true);
  const [pricing, setPricing] = useState<AssetRetrievalPricing>();

  useEffect(() => {
    setLoading(true);
    api.get("/asset_retrievals/pricing").then((response) => {
      setPricing(response.data.pricing);
      setLoading(false);
    });
  }, []);

  const create = useCallback(
    ({
      employeeFirstName,
      employeeLastName,
      employeeEmail,
      employeePhone,
      employeeAddress,
      employeeAddressTwo,
      employeeAddressCity,
      employeeAddressState,
      employeeAddressZipCode,
      employeeAddressCountry,
      manufacturer,
      model,
      serialNumber,
      locationId,
      insuranceOption,
      invoiced,
      paymentType,
      virtualPickup,
      returnRecipientFirstName,
      returnRecipientLastName,
      returnRecipientEmail,
      returnRecipientCompany,
      returnRecipientAddress,
      returnRecipientAddressTwo,
      returnRecipientAddressCity,
      returnRecipientAddressState,
      returnRecipientAddressZipCode,
      returnRecipientAddressCountry,
    }: AssetRetrieval) => {
      return api.post("/asset_retrievals", {
        asset_retrieval: {
          employeeFirstName,
          employeeLastName,
          employeeEmail,
          employeePhone,
          employeeAddress,
          employeeAddressTwo,
          employeeAddressCity,
          employeeAddressState,
          employeeAddressZipCode,
          employeeAddressCountry,
          manufacturer,
          model,
          serialNumber,
          locationId,
          insuranceOption,
          invoiced,
          paymentType,
          virtualPickup,
          returnRecipientFirstName,
          returnRecipientLastName,
          returnRecipientEmail,
          returnRecipientCompany,
          returnRecipientAddress,
          returnRecipientAddressTwo,
          returnRecipientAddressCity,
          returnRecipientAddressState,
          returnRecipientAddressZipCode,
          returnRecipientAddressCountry,
        },
      });
    },
    []
  );

  return { assetRetrieval, loading, create, pricing };
}
