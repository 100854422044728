import { Box, Popover as MUIPopover, PopoverOrigin } from "@mui/material";
import React, { useId, useState } from "react";

interface PopoverProps {
  button: (props: any) => React.ReactNode;
  origin?: PopoverOrigin;
  offset?: number;
}

function Popover({ button, children, offset }: React.PropsWithChildren<PopoverProps>) {
  const id = useId();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      {button({
        "aria-describedby": id,
        onClick: handleClick,
      })}

      <MUIPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ ".MuiPaper-root": { mt: offset } }}
      >
        {children}
      </MUIPopover>
    </Box>
  );
}

export default Popover;
