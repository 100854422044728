import { useCallback } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { api } from "services/api.service";
import { Asset } from "types/asset";
import { Person } from "types/person";

export default function useAsset({ uuid }: { uuid: string | undefined }) {
  const navigate = useNavigate();
  const { data, isLoading, isError, refetch } = useQuery(`assets/${uuid}`, () =>
    api.get<Asset>(`/assets/${uuid}`).then((res) => ({ data: res.data }))
  );

  const checkout = useCallback(
    (person: Person) =>
      api.post(`/assets/${uuid}/checkout`, { person }).then(() => {
        navigate(`/assets/${uuid}/details`);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    asset: data?.data,
    isLoading,
    isError,
    refetch,
    checkout,
  };
}
