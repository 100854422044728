import { useCallback, useEffect, useState } from "react";
import { api } from "services/api.service";
import { Location } from "types/location";

export default function useLocations() {
  const [locations, setLocations] = useState<Location[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const refetch = useCallback(() => {
    setIsLoading(true);
    return api
      .get<{ locations: Location[] }>("/locations")
      .then(({ data: { locations } }) => {
        setLocations(locations);
        setIsLoading(false);
      })
      .catch(() => {
        setIsError(true);
      });
  }, []);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    locations,
    isLoading,
    isError,
    refetch,
  };
}
