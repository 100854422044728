import { useEffect, useState } from "react";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { useStripePaymentMethod } from "hooks/paymentMethods/useStripePaymentMethod";
import { usePaymentMethod } from "hooks/paymentMethods/usePaymentMethod";

const PaymentStatusComp = ({ callback }: { callback: () => void }) => {
  const stripe = useStripe();
  const { createPaymentMethod } = usePaymentMethod();
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get("setup_intent_client_secret");

    if (!clientSecret) {
      return;
    }

    stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
      if (!setupIntent) {
        return;
      }
      switch (setupIntent.status) {
        case "succeeded":
          setMessage("Success! Your payment method has been saved.");
          createPaymentMethod(setupIntent, callback);
          break;
        case "processing":
          setMessage("Processing payment details. We'll update you when processing is complete.");
          break;

        case "requires_payment_method":
          setMessage("Failed to process payment details. Please try another payment method.");
          break;
      }
    });
  }, [stripe]);

  return <div>{message}</div>;
};

export default function PaymentStatus({ callback }: { callback: () => void }) {
  const { stripePromise, stripeIntentSecret } = useStripePaymentMethod();

  if (!stripeIntentSecret) return null;

  return (
    <div>
      <Elements stripe={stripePromise} options={{ clientSecret: stripeIntentSecret }}>
        <PaymentStatusComp callback={callback} />
      </Elements>
    </div>
  );
}
