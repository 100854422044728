import { Box, Button, Divider, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import useAssetRetrieval, { AssetRetrieval } from "hooks/assetRetrievals/useAssetRetrieval";
import { OrganizationConfigurationContext } from "hooks/useOrganizationConfiguration";
import useEarnings from "hooks/earnings/useEarnings";
import theme from "lib/theme";
import { useContext, useMemo, useState } from "react";
import { ServiceTermsModal } from "./ServiceTermsModal";
import { orderDiscountAmount, orderTotal } from "utils/assetRetrieval";

interface AssetCartProps {
  onContinue: () => void;
  continueLabel?: string;
  disableContinue?: boolean;
}

function AssetCart({ onContinue, continueLabel = "Continue", disableContinue }: AssetCartProps) {
  const { values } = useFormikContext<AssetRetrieval>();
  const { pricing } = useAssetRetrieval();
  const { earningsBalance } = useEarnings();
  const total = orderTotal(values.insuranceOption, pricing, values);
  const discountAmount = orderDiscountAmount(values.insuranceOption, pricing, values);
  const insufficientFunds = total > earningsBalance;
  const { assetRetrievalTermsSigned } = useContext(OrganizationConfigurationContext);
  const [openTerms, setOpenTerms] = useState(false);

  const shouldOpenTerms = useMemo(() => {
    return continueLabel === "place order" && !assetRetrievalTermsSigned;
  }, [continueLabel, assetRetrievalTermsSigned]);

  return (
    <div style={{ position: "sticky", top: "95px" }}>
      <Box
        sx={{
          py: 3,
          px: 4,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
          [theme.breakpoints.down("lg")]: { px: 2, py: 2 },
        }}
      >
        <Typography fontWeight="bold" variant="h6">
          Order Summary
        </Typography>

        <Box sx={{ py: 2, display: "flex", flexDirection: "column", gap: 0.5 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", color: "text.secondary" }}>
            <Box>Laptop Box & Shipping Flat Fee</Box>
            <Box>${pricing?.basePriceCents ? pricing?.basePriceCents / 100 : "70"}</Box>
          </Box>
          {Number(values.insuranceOption) > 0 && (
            <Box sx={{ display: "flex", justifyContent: "space-between", color: "text.secondary" }}>
              <Box>Insurance</Box>
              <Box>${values.insuranceOption}</Box>
            </Box>
          )}
          <Box sx={{ display: "flex", justifyContent: "space-between", color: "text.secondary" }}>
            <Box>Processing Fees</Box>
            <Box>$10</Box>
          </Box>
          {values.virtualPickup ? (
            <Box sx={{ display: "flex", justifyContent: "space-between", color: "text.secondary" }}>
              <Box>Data Wipe & Audit Fee</Box>
              <Box>$10</Box>
            </Box>
          ) : null}
          {!values.virtualPickup && pricing?.discountPercentage && pricing.discountPercentage > 0 ? (
            <Box sx={{ display: "flex", justifyContent: "space-between", color: theme.palette.primaryPro.main }}>
              <Box>Beta Discount ({pricing?.discountPercentage}%)</Box>
              <Box>-${discountAmount}</Box>
            </Box>
          ) : (
            ""
          )}
          {values.virtualPickup && (
            <Box sx={{ display: "flex", justifyContent: "space-between", color: theme.palette.primaryPro.main }}>
              <Box>Waived Fees</Box>
              <Box>-${discountAmount}</Box>
            </Box>
          )}
        </Box>
        <Divider />
        <Box mt={2} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography fontSize={"20px"} fontWeight="bold" color="primary">
            Total
          </Typography>
          <Typography fontSize={"20px"} fontWeight="bold" color="primary">
            ${total}
          </Typography>
        </Box>
        <Box mt={1} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", color: "text.secondary" }}>
            New Earnings Balance: ${insufficientFunds ? 0 : earningsBalance - total}
          </Box>
        </Box>
        <Box mt={5}>
          <span>
            <Button
              fullWidth
              variant="contained"
              onClick={shouldOpenTerms ? () => setOpenTerms(true) : onContinue}
              disabled={disableContinue}
            >
              {continueLabel}
            </Button>
          </span>
        </Box>
        <ServiceTermsModal open={openTerms} setOpen={setOpenTerms} submit={onContinue} />
      </Box>
    </div>
  );
}

export default AssetCart;
