import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Typography, Button } from "@mui/material";
import { Modal } from "components/common/Modal";

interface ConfirmationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  dismissModal?: () => void;
}

export function RequestSentConfirmationModal({ open, setOpen, dismissModal }: ConfirmationModalProps) {
  return (
    <Modal open={open} setOpen={setOpen} width={"550px"}>
      <Box>
        <Box sx={{ textAlign: "center" }}>
          <CheckCircleIcon color="primary" sx={{ fontSize: "60px" }} />
          <Typography mt={1} variant="h5" component="h2" fontWeight={800}>
            We’ll be in touch!
          </Typography>
          <Typography mt={0.5} color="text.secondary">
            Thanks for your interest. Our team will reach out to <br /> tell you about our new features.
          </Typography>

          <Box mt={3} sx={{ display: "flex", gap: 1, alignItems: "center", justifyContent: "center" }}>
            <Button
              variant="contained"
              onClick={() => {
                if (dismissModal) {
                  dismissModal();
                }
                setOpen(false);
              }}
            >
              all set
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
