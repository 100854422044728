import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { api } from "services/api.service";
import { DonationRequest } from "types/donationRequest";

export default function useDonationRequest() {
  const params = useParams();
  const [donationRequest, setDonationRequest] = useState<DonationRequest>();
  const [donationRequests, setDonationRequests] = useState<DonationRequest[]>([]);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [meta, setMeta] = useState<{ totalPages: number; count: number }>({ totalPages: 0, count: 0 });
  const [totalDonated, setTotalDonated] = useState(0);

  useEffect(() => {
    if (params.donationRequestUuid) {
      setLoading(true);
      getDonationRequest();
    }
  }, [params.donationRequestUuid]);

  const getDonationRequest = () => {
    api
      .get<DonationRequest>(`/donation_requests/${params.donationRequestUuid}`)
      .then((res) => {
        setDonationRequest(res.data);
        setTotalDonated(res.data.totalDollarsDonated);
      })
      .then(() => setLoading(false))
      .catch(() => setError(true));
  };

  useEffect(() => {
    if (isLoading) return;
    setLoading(true);
    const args = searchParams.toString() ? searchParams.toString() : "page%5Bnumber%5D=1&page%5Bsize%5D=5";
    api
      .get<{ donationRequests: DonationRequest[]; meta: { totalPages: number; count: number } }>(
        `/donation_requests?${args.toString()}`
      )
      .then((res) => {
        setDonationRequests(res.data.donationRequests);
        setMeta(res.data.meta);
      })
      .then(() => setLoading(false))
      .catch(() => setError(true));
  }, [searchParams]);

  return {
    donationRequest,
    donationRequests,
    isError,
    isLoading,
    meta,
    totalDonated,
    getDonationRequest,
  };
}
