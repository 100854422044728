import { Form, FormikProvider, useFormik } from "formik";
import { useSearchParams } from "react-router-dom";
import { Location } from "types/location";
import LocationFormFirstStep from "./LocationFormFirstStep";
import LocationFormSecondStep from "./LocationFormSecondStep";
import { useEffect } from "react";
import { useAlert } from "hooks/alerts/useAlert";
import { Button, Box, Typography } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";

type LocationFormProps = {
  location: Location;
  onSubmit: (location: Location) => any;
  originalPath: string;
};

export default function LocationForm({ location, onSubmit, originalPath }: LocationFormProps) {
  const [searchParams] = useSearchParams();
  const { alertNeutral, alertError } = useAlert();

  const step = searchParams.get("step") ?? "1";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const formik = useFormik({
    initialValues: location,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const handleCopyToClipboard = (uuid: any) => {
    navigator.clipboard
      .writeText(uuid)
      .then(() => {
        alertNeutral("Copied to clipboard");
      })
      .catch(() => {
        alertError("Failed to copy serial numbers. Please try again");
      });
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" fontWeight="bold">
            Locations
          </Typography>
          {location.uuid ? (
            <Button
              size="small"
              onClick={() => {
                handleCopyToClipboard(location.uuid);
              }}
              startIcon={<ContentCopy />}
            >
              copy location id
            </Button>
          ) : null}
        </Box>

        {step === "1" ? (
          <LocationFormFirstStep originalPath={originalPath} formik={formik} />
        ) : step === "2" ? (
          <LocationFormSecondStep formik={formik} />
        ) : null}
      </Form>
    </FormikProvider>
  );
}
