import { DonationRequest } from "./donationRequest";

export interface BenefitingOrganization {
  name: string;
  logoUrl: string;
  websiteUrl: string;
  mainContactName: string;
  mainContactEmail: string;
  mainContactPhoneNumber: string;
  donationRequest?: DonationRequest[];
}

export const defaultBenefitingOrganization = {
  name: "",
  logoUrl: "",
  websiteUrl: "",
  mainContactName: "",
  mainContactEmail: "",
  mainContactPhoneNumber: "",
};
