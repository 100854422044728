import { Attachment } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { InputBase, InputLabel } from "components/common/Input";
import FileDropSection from "components/FileDropSection";
import { FormikContextType } from "formik";
import { Blob } from "activestorage";
import useDirectUpload from "hooks/useDirectUpload";
import theme from "lib/theme";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Location } from "types/location";
import { ActiveStorageAttachment } from "types/activeStorageAttachment";

interface LocationFormSecondStepProps {
  formik: FormikContextType<Location>;
}

interface BLobWithUrl extends Blob {
  url: string;
}

function LocationFormSecondStep({ formik }: LocationFormSecondStepProps) {
  let [showAlert, setShowAlert] = useState(true);
  const [, setSearchParams] = useSearchParams();
  const { upload, blob } = useDirectUpload({ keyPrefix: "/location/insurance_certificates/" });
  const [currentFile, setCurrentFile] = useState<File | undefined>();
  const [newBlob, setNewBlob] = useState<BLobWithUrl>();
  const [oldAttachments, setOldAttachments] = useState<ActiveStorageAttachment | undefined>(
    formik.values.certificateOfInsuranceFile
  );

  useEffect(() => {
    if (blob && currentFile) {
      const reader = new FileReader();

      reader.readAsDataURL(currentFile);
      reader.onload = function (event) {
        if (event.target?.result) {
          const result = event.target.result as string;
          setNewBlob({ ...blob, url: result });
          setCurrentFile(undefined);
        }
      };
    }
  }, [blob]);

  useEffect(() => {
    if (newBlob) formik.setFieldValue("certificateOfInsuranceFile", newBlob.signed_id);
  }, [newBlob]);

  return (
    <div>
      <Box
        sx={{
          mt: 3,
          p: 4,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography variant="h5">1. Location details</Typography>

        <Box mt={2} sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
          <Box>
            <Typography fontWeight={"bold"}>Name:</Typography>
            <Typography mt={0.5}>
              {formik.values.name} <br />
              {formik.values.approximatePeople && `${formik.values.approximatePeople} End Users`}
            </Typography>
          </Box>
          <Box>
            <Typography fontWeight={"bold"}>Address:</Typography>
            <Typography mt={0.5}>
              {formik.values.address}, {formik.values.address2} <br />
              {formik.values.city}, {formik.values.state} {formik.values.zipcode} <br />
              {formik.values.country}
            </Typography>
          </Box>
          <Box>
            <Typography fontWeight={"bold"}>Point of contact:</Typography>
            {formik.values.locationContacts && formik.values.locationContacts[0] && (
              <Typography mt={0.5}>
                {`${formik.values.locationContacts[0].firstName} ${formik.values.locationContacts[0].lastName}`} <br />
                {formik.values.locationContacts[0].email} <br />
                {formik.values.locationContacts[0].phoneNumber}
              </Typography>
            )}
          </Box>
          {/* <Box>
            <strong>Pictures:</strong> <br />
            {formik.values.locationImages?.map(({ url, filename }) => (
              <Tooltip
                title={
                  <>
                    <img src={url} alt={filename} loading="lazy" width={280} />
                  </>
                }
              >
                <Box
                  component="a"
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    color: theme.palette.primary.main,
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    fontFamily: "Catamaran",
                  }}
                >
                  <Attachment />
                  {filename}
                </Box>
              </Tooltip>
            ))}
          </Box> */}
        </Box>

        <Box mt={2}>
          <Typography fontWeight={700}>Access Instructions:</Typography>
          <Typography mt={0.5}>{formik.values.accessInstructions}</Typography>
        </Box>
      </Box>

      {/* <Box mt={4} sx={{ p: 4, background: "white", borderRadius: 1, border: `1px solid ${theme.palette.divider}` }}>
        <Typography variant="h5">2. Certificate of Insurance</Typography>

        <Box mt={3}>
          <Typography fontWeight={700}>Certificate of Insurance </Typography>
          <Typography mt={0.5}>
            Prior to pickups, Revivn wants to ensure our insurance coverage meets your property’s requirements. Please
            provide us with the following details so that our insurer can generate a certificate of insurance for your
            building to approve, certifying our coverage.
          </Typography>
        </Box>

        {showAlert && (
          <Box mt={2}>
            <Alert
              severity="info"
              onClose={() => {
                setShowAlert(false);
              }}
            >
              <AlertTitle>
                Have questions about your building’s Certificate of Insurance? We’re here to help.
              </AlertTitle>
              If you’re having trouble getting a sample Certificate of Insurance, no problem. Just shoot us an email at
              or give us a call.
            </Alert>
          </Box>
        )}

        <Box mt={3} sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink htmlFor="name-of-cert">
              Name of Certificate Owner
            </InputLabel>
            <InputBase id="name-of-cert" placeholder="Name" {...formik.getFieldProps("nameOfCertificateOwner")} />
          </FormControl>

          <FormControl variant="standard" fullWidth>
            <InputLabel shrink htmlFor="address-of-cert">
              Address of Certificate Owner
            </InputLabel>
            <InputBase
              id="address-of-cert"
              placeholder="Enter address"
              {...formik.getFieldProps("addressOfCertificateOwner")}
            />
          </FormControl>

          <FormControl variant="standard" fullWidth>
            <InputLabel shrink htmlFor="additional-insured">
              Additional insured
            </InputLabel>
            <InputBase id="additional-insured" placeholder="Name(s)" {...formik.getFieldProps("additionalInsured")} />
          </FormControl>

          <Box mt={2}>
            <FileDropSection
              acceptHint={"csv, xls, xlxs, ppt, pptx, doc, docx, pdf, jpg, jpeg, png"}
              accept={{
                "text/csv": [],
                "text/xls": ["xls", "xlxs"],
                "application/msword": ["doc"],
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ["docx"],
                "image/png": ["png"],
                "image/jpeg": ["jpg", "jpeg"],
                "application/pdf": ["pdf"],
              }}
              documents={
                newBlob || oldAttachments
                  ? newBlob
                    ? [
                        {
                          name: newBlob.filename,
                          url: newBlob.url,
                          onDelete: () => {
                            setNewBlob(undefined);
                          },
                        },
                      ]
                    : oldAttachments && oldAttachments.url
                    ? [
                        {
                          name: oldAttachments.filename,
                          url: oldAttachments.url,
                          onDelete: () => {
                            setOldAttachments(undefined);
                          },
                        },
                      ]
                    : undefined
                  : undefined
              }
              onUpload={([file]) => {
                setOldAttachments(undefined);
                setCurrentFile(file);
                upload(file);
              }}
            />
          </Box>
        </Box>
      </Box>

      <Box mt={3}>
        <Typography fontWeight="700">No Certificate of Insurance?</Typography>
        <FormControlLabel
          control={<Checkbox />}
          label="Check this box if this location does not need a Certificate of Insurance."
        />
      </Box> */}

      <Box mt={4} sx={{ display: "flex", flexDirection: "column", alignItems: "start", gap: 2 }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="outlined"
            onClick={() => {
              setSearchParams({ step: "1" });
            }}
          >
            Back
          </Button>

          <Button variant="contained" type="submit">
            Save
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default LocationFormSecondStep;
