import { useFormik } from "formik";
import * as yup from "yup";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { Box, Typography, Link, MenuItem, FormControl, Button } from "@mui/material";
import usePickup from "hooks/pickups/usePickup";
import useUpdatePickup from "hooks/pickups/useUpdatePickup";
import theme from "lib/theme";
import { useCallback, useEffect, useState } from "react";
import { PickupContact } from "types/pickupContact";
import { AppSelect, InputBase, InputLabel } from "../../../components/common/Input";
import useContacts from "hooks/useContacts";
import { useAlert } from "hooks/alerts/useAlert";

interface PickupMainContactProps {
  goToNextStep: () => void;
}

const NewContactForm = ({ addNewContact }: { addNewContact: (c: PickupContact) => void }) => {
  const { createContact } = useContacts();
  const { alertSuccess, alertError } = useAlert();
  const { handleSubmit, getFieldProps, touched, errors, isValid, isSubmitting, setFieldError, setSubmitting } =
    useFormik({
      initialValues: {
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
      },
      validationSchema: yup.object({
        email: yup.string().email("Invalid Email").required("Required"),
        firstName: yup.string().required("Required"),
        lastName: yup.string().required("Required"),
        phoneNumber: yup.string().required("Required"),
      }),
      onSubmit: (values, {}) => {
        createContact({ ...values, pointOfContactType: "main" })
          .then(() => {
            addNewContact({ ...values, pointOfContactType: "main" });
            alertSuccess("Successfully created a contact");
          })
          .catch(({ response: { data } }) => {
            data.errors.email && setFieldError("email", "A contact with this email already exists");
            alertError("There was an error, please try again.");
            setSubmitting(false);
          });
      },
      validateOnMount: true,
    });

  return (
    <Box mt={2}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 2,
          [theme.breakpoints.down("sm")]: { gridTemplateColumns: "1fr" },
        }}
      >
        <FormControl variant="standard">
          <InputLabel shrink htmlFor="first-name">
            First name
          </InputLabel>
          <InputBase
            type="text"
            id="first-name"
            {...getFieldProps("firstName")}
            helperText={touched.firstName && errors.firstName}
            error={touched.firstName && !!errors.firstName}
          />
        </FormControl>
        <FormControl variant="standard">
          <InputLabel shrink htmlFor="last-name">
            Last name
          </InputLabel>
          <InputBase
            type="text"
            id="last-name"
            {...getFieldProps("lastName")}
            helperText={touched.lastName && errors.lastName}
            error={touched.lastName && !!errors.lastName}
          />
        </FormControl>
        <FormControl variant="standard">
          <InputLabel shrink htmlFor="phone">
            Phone
          </InputLabel>
          <InputBase
            type="text"
            id="phone"
            {...getFieldProps("phoneNumber")}
            helperText={touched.phoneNumber && errors.phoneNumber}
            error={touched.phoneNumber && !!errors.phoneNumber}
          />
        </FormControl>
        <FormControl variant="standard">
          <InputLabel shrink htmlFor="email">
            Email
          </InputLabel>
          <InputBase
            type="email"
            id="email"
            {...getFieldProps("email")}
            helperText={touched.email && errors.email}
            error={touched.email && !!errors.email}
          />
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
        <Button onClick={() => handleSubmit()} disabled={isSubmitting || !isValid}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default function PickupMainContact({ goToNextStep }: PickupMainContactProps) {
  const [showNewContact, setShowNewContact] = useState(false);
  const { contacts } = useContacts();
  const { updatePickup } = useUpdatePickup();
  const { pickup } = usePickup();
  const [mainContact, setMainContact] = useState("");
  const [newContact, setNewContact] = useState<PickupContact>();

  const handleChange = (event: any) => {
    let contact: { email: string; firstName: string; lastName: string; phoneNumber: string } | undefined =
      contacts?.find((u) => u.email === event.target.value);
    contact ||= pickup?.pickupContacts.find((c) => c.email === event.target.value);
    contact ||= newContact?.email === event.target.value ? newContact : undefined;
    if (!contact) return;
    const { email, firstName, lastName, phoneNumber } = contact;

    updatePickup({ contacts: [{ pointOfContactType: "main", email, firstName, lastName, phoneNumber }] });
    setMainContact(email);
    goToNextStep();
  };

  const addNewContact = useCallback(
    (contact: PickupContact) => {
      setNewContact(contact);
      updatePickup({ contacts: [contact] });
      setMainContact(contact.email);
      setShowNewContact(false);
      goToNextStep();
    },
    [updatePickup]
  );

  useEffect(() => {
    const currentMainContact = pickup?.pickupContacts.find((contact) => contact.pointOfContactType === "main");
    if (!currentMainContact) return;
    setMainContact(currentMainContact.email);
  }, [pickup]);

  return (
    <Box>
      <Typography variant="h6" fontSize="18px" fontWeight="bold">
        5. Choose an on-site point of contact for this pickup
      </Typography>
      <Typography mt={0.5} variant="body2">
        On the day of pickup, this person should be available to communicate with our team and receive any pickup
        related notifications.
      </Typography>

      <Box mt={3}>
        <Typography fontWeight={600}>Select one:</Typography>

        <FormControl variant="standard" sx={{ mt: 0.5, width: "100%" }}>
          <AppSelect fullWidth onChange={(e) => handleChange(e)} value={mainContact}>
            {contacts?.map((user) => {
              return (
                <MenuItem value={user.email} key={user.uuid}>
                  <strong>{`${user.firstName} ${user.lastName}`}</strong>{" "}
                  <Box component={"span"} sx={{ padding: "0 6px" }}>
                    {user.phoneNumber}
                  </Box>
                  {user.email}
                </MenuItem>
              );
            })}
            {pickup?.pickupContacts &&
              pickup.pickupContacts
                .filter(({ email }) => !contacts?.find((u) => u.email === email))
                .map((contact) => (
                  <MenuItem key={contact.email} value={contact.email}>
                    <strong>{`${contact.firstName} ${contact.lastName}`}</strong>,{" "}
                    <Box component={"span"} sx={{ padding: "0 6px" }}>
                      {contact.phoneNumber}
                    </Box>
                    {contact.email}
                  </MenuItem>
                ))}
            {newContact && (
              <MenuItem value={newContact.email}>
                <strong>{`${newContact.firstName} ${newContact.lastName}`}</strong>,{" "}
                <Box component={"span"} sx={{ padding: "0 6px" }}>
                  {newContact.phoneNumber}
                </Box>
                {newContact.email}
              </MenuItem>
            )}
          </AppSelect>
        </FormControl>
      </Box>

      <Link
        component="button"
        onClick={() => {
          setShowNewContact(!showNewContact);
        }}
        sx={{
          mt: 2,
          display: "inline-flex",
          alignItems: "center",
          gap: 1,
          fontWeight: 600,
          textDecoration: "none",
          fontSize: "16px",
          fontFamily: "Catamaran",
          ":hover": {
            textDecoration: "underline",
          },
        }}
      >
        {showNewContact ? (
          <RemoveCircleOutline sx={{ width: "20px", height: "20px" }} />
        ) : (
          <AddCircleOutline sx={{ width: "20px", height: "20px" }} />
        )}
        Add a new contact
      </Link>

      {showNewContact && <NewContactForm addNewContact={addNewContact} />}
    </Box>
  );
}
