import { Box, Button, FormControl, Typography } from "@mui/material";
import { InputBase, InputLabel } from "components/common/Input";
import theme from "lib/theme";
import { useFormik } from "formik";
import useCreateOauthApplication from "hooks/api/useCreateOauthApplication";

function OauthApplicationForm() {
  const { create } = useCreateOauthApplication();
  const formik = useFormik({
    initialValues: { name: "" },
    enableReinitialize: true,
    onSubmit: (values) => values && create(values),
  });

  return (
    <div>
      <Typography variant="h4" fontWeight="bold">
        Create Oauth Application
      </Typography>

      <Box
        sx={{
          mt: 3,
          px: 5,
          py: 3,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mt: 4, display: "grid", gridTemplateColumns: `1fr 1fr`, gap: 3 }}>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="company-name">
                Name
              </InputLabel>
              <InputBase
                type="text"
                id="oauth-application"
                placeholder="Name of your application.."
                {...formik.getFieldProps("name")}
              />
            </FormControl>
          </Box>

          <Box textAlign="right" mt={3}>
            <Button type="submit" variant="contained" size="large">
              Create
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  );
}

export default OauthApplicationForm;
