import { useState } from "react";
import { api } from "services/api.service";
import { BenefitingOrganization } from "types/benefitingOrganization";

export default function useBenefitingOrganizations() {
  let [submitted, setSubmitted] = useState(false);

  const createBenefitingOrganization = (benefiting_org: BenefitingOrganization) => {
    const { name, websiteUrl, mainContactName, mainContactEmail, mainContactPhoneNumber } = benefiting_org;

    return api
      .post("/benefiting_organizations", {
        benefitingOrganization: { name, websiteUrl, mainContactName, mainContactEmail, mainContactPhoneNumber },
      })
      .then(() => {
        setSubmitted(true);
      });
  };

  return {
    createBenefitingOrganization,
    submitted,
    setSubmitted,
  };
}
