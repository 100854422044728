import { api } from "services/api.service";
import { useAlert } from "hooks/alerts/useAlert";

export default function useSubscriptions() {
  const { alertError, alertSuccess } = useAlert();

  type SubscriptionIdProps = string;

  const requestPlanCancellation = (subscriptionId: SubscriptionIdProps) => {
    api
      .post(`/subscriptions/${subscriptionId}/cancel_plan`, {})
      .then(() => {
        alertSuccess(
          "We've received your request for cancellation. You will continue to have access to pro features until your subscription expires."
        );
      })
      .catch(() => {
        alertError("Something went wrong.");
      });
  };
  const requestPlanUpgrade = (subscriptionId: string, feature: string) => {
    return api.post(`/subscriptions/${subscriptionId}/upgrade_plan`, { requested_feature: feature });
  };

  return {
    requestPlanCancellation,
    requestPlanUpgrade,
  };
}
