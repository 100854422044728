/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, debounce, Tooltip } from "@mui/material";
import { SearchInput } from "components/common/Input";
import useSearchParamsOperations from "hooks/useSearchParamsOperations";
import { useCallback } from "react";
import UserManagementTable from "./UserManagementTable";
import { Link as RouterLink } from "react-router-dom";
import useCurrentUserPermission from "hooks/useCurrentUserPermission";

function UserManagement() {
  const { replace } = useSearchParamsOperations();
  const { hasPermission } = useCurrentUserPermission();

  const handleSearchChange = useCallback(
    debounce((replaceParamsFn: (f: string, v: string) => void, search: string) => {
      replaceParamsFn("q", search);
    }, 200),
    []
  );

  return (
    <div>
      <Box mt={4} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <SearchInput placeholder="Search users" onChange={(e) => handleSearchChange(replace, e.target.value)} />

        <Tooltip
          title={
            hasPermission("userManagement")
              ? ""
              : "You do not have permissions for this action, please contact your administrator for permission."
          }
        >
          <span>
            <Button
              component={RouterLink}
              variant="contained"
              to={"/company-settings/users/new"}
              disabled={!hasPermission("userManagement")}
            >
              invite new user
            </Button>
          </span>
        </Tooltip>
      </Box>
      <Box mt={4}>
        <UserManagementTable />
      </Box>
    </div>
  );
}

export default UserManagement;
