import { Box, Typography } from "@mui/material";
import { ReactComponent as EmptyDonationIcon } from "./images/empty-donation.svg";

export default function EmptyDonationTable() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        px: 1,
      }}
    >
      <Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Typography fontSize="15px" textAlign="center">
            Looks like you haven’t made a donation yet - we can help with that! Select an organization above to donate
            to today.
          </Typography>
          <Box
            sx={{
              width: "200px",
              height: "200px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <EmptyDonationIcon style={{ width: "200px", height: "200px" }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
