import { ArrowBack } from "@mui/icons-material";
import { Box, Typography, Button } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import { Form, Formik } from "formik";
import useCreateAsset from "hooks/assets/useCreateAsset";
import theme from "lib/theme";
import { Link } from "react-router-dom";
import { Asset, defaultAsset } from "types/asset";
import AssetForm from "./AssetsDetails/AssetForm";

export default function AssetCreate() {
  const { create: createAsset } = useCreateAsset();

  return (
    <div>
      <Box>
        <Breadcrumbs
          data={[{ label: "Dashboard", to: "/" }, { label: "Assets", to: "/assets" }, { label: "Add Asset" }]}
        ></Breadcrumbs>
      </Box>

      <Box mt={4} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h4" mt={3} fontWeight="bold">
            Add Multiple Assets
          </Typography>

          <Button sx={{ mt: 4.5 }} startIcon={<ArrowBack />} component={Link} to="/assets">
            back to assets
          </Button>
        </Box>

        <img src={require("assets/images/monitor.png")} alt=""></img>
      </Box>

      <Box
        sx={{
          mt: 3,
          px: 5,
          py: 3,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Formik
          initialValues={defaultAsset}
          onSubmit={(values) => {
            createAsset(values);
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Box textAlign="right">
                <Button variant="contained" type="submit" disabled={isSubmitting}>
                  save asset
                </Button>
              </Box>
              <AssetForm />
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
}
