import { Box, Button, IconButton, Link, Typography } from "@mui/material";
import theme from "lib/theme";
import { DrawerProps } from "types/pickup";
import { Download, Equalizer } from "@mui/icons-material";
import {
  address,
  contact,
  orderNumber,
  pickupDate,
  PickupDetailsRow,
  PickupDetailsRows,
  PickupDetailsTitle,
} from "./shared";
import usePickupReports from "hooks/pickups/usePickupReports";
import { navActions } from "components/AppLayout/Nav";
import useAnalytics from "hooks/analytics/useAnalytics";

export function InitialReportReadyDrawer(props: DrawerProps) {
  const { pickup } = props;
  const { getInitialReportData, initialReportData, setDownloadInitialReport, downloadInitialReport } =
    usePickupReports();
  const { createAnalyticsEvent } = useAnalytics();

  if (downloadInitialReport) {
    setDownloadInitialReport(false);
    const blob = new Blob([initialReportData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `pickup_${pickup.orderNumber}_initial_report.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      createAnalyticsEvent("initial_report_downloaded");
    }
  }

  return (
    <Box mb={5} mx={2}>
      <Box
        sx={{
          py: 3,
          px: 4,
          background: "#FAFAFA",
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
        }}
      >
        <Equalizer />
        <Typography variant="h6" fontWeight="bold">
          Your initial pickup report is ready!
        </Typography>
        <Typography>
          Your initial pickup report is ready to be viewed! This is a preliminary (but incomplete) report of computers
          we’ve collected. We will continue to process your assets and you can expect your final report within 30
          business days (or for international pickups, in 30-60 business days). Please double check your assets have
          been removed from your DEP to prevent any delays.
        </Typography>

        <Box
          sx={{
            mt: 3,
            py: 2,
            px: 3,
            background: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 4,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
          }}
        >
          <Box sx={{ display: "flex", gap: 4, alignItems: "center" }}>
            <Equalizer color="primary" sx={{ fontSize: "25px" }} />
            <Box>
              <Typography fontSize="18px" fontWeight="bold">
                Initial Report
              </Typography>
              <Typography mt={0.5}>Download your preliminary list of assets</Typography>
            </Box>
          </Box>
          <IconButton
            size="small"
            onClick={() => {
              getInitialReportData(pickup.uuid);
            }}
          >
            <Download color="primary" sx={{ fontSize: "25px" }} />{" "}
          </IconButton>
        </Box>

        <Box mt={2}>
          <PickupDetailsTitle />

          <PickupDetailsRows>
            <PickupDetailsRow
              columnCount={4}
              items={
                pickup.virtualPickup
                  ? [
                      {
                        title: "PICKUP ID",
                        list: orderNumber(pickup),
                      },
                      {
                        title: "ASSETS RETRIEVED",
                        list: [
                          pickup.inventoryCount ? (
                            pickup.inventoryCount > 0 ? (
                              <Link
                                href={`/assets?page[number]=1&page[size]=25&filter[]=state%2Cis_any_of%2Cretired&filter[]=pickup_id%2Cis_any_of%2C${pickup.id}`}
                              >
                                {pickup.inventoryCount}
                              </Link>
                            ) : (
                              "0"
                            )
                          ) : (
                            "0"
                          ),
                        ],
                      },
                      {
                        title: "Enrolled Assets",
                        list: [
                          pickup.enrolledCount ? (
                            pickup.enrolledCount > 0 ? (
                              <Link
                                href={`/assets?page[number]=1&page[size]=25&filter[]=state%2Cis_any_of%2Cretired&filter[]=pickup_id%2Cis_any_of%2C${pickup.id}&filter[]=status%2Cis%2CEnrolled`}
                              >
                                {pickup.enrolledCount}
                              </Link>
                            ) : (
                              "0"
                            )
                          ) : (
                            "0"
                          ),
                        ],
                      },
                    ]
                  : [
                      {
                        title: "PICKUP ID",
                        list: orderNumber(pickup),
                      },
                      {
                        title: "PICKUP DATE",
                        list: pickupDate(pickup),
                      },
                      {
                        title: "CONTACT",
                        list: contact(pickup),
                      },
                      {
                        title: "ADDRESS",
                        list: address(pickup),
                      },
                    ]
              }
            />
          </PickupDetailsRows>
        </Box>
      </Box>

      <Box
        mt={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          gap: 3,
        }}
      >
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            navActions.openContactConcierge();
          }}
        >
          questions? contact us
        </Button>
      </Box>
    </Box>
  );
}
