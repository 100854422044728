import { Box, Typography, Link } from "@mui/material";
import theme from "lib/theme";
import { Link as RouterLink } from "react-router-dom";
import AssetsReadyForRetirementBarChart from "./AssetsReadyForRetirement";
import QuickStatsPickUpTable from "./QuickStatsPickupTable";
import RetiredAssetDoughnutChart from "./RetiredAssetDoughnutChart";

export default function QuickStats(homeInfo: any) {
  return (
    <Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "5fr 3fr",
          gap: 4,
          [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr",
            gap: 2,
          },
        }}
      >
        <Box
          sx={{
            minWidth: 0,
            p: 3,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
            [theme.breakpoints.down("md")]: {
              p: 2,
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography variant="h6" fontWeight={"bold"}>
              Latest pickups
            </Typography>
            <Link component={RouterLink} to={"/pickups"} underline="hover" textTransform={"uppercase"} fontSize="14px">
              See all
            </Link>
          </Box>
          <Box mt={1.5}>
            <QuickStatsPickUpTable />
          </Box>
        </Box>

        <Box
          sx={{
            minWidth: 0,
            p: 3,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
            [theme.breakpoints.down("md")]: {
              p: 2,
            },
          }}
        >
          <Box>
            <Typography variant="h6" fontWeight={"bold"}>
              Retired Assets by Manufacturer
            </Typography>
          </Box>
          <Box mt={1.5}>
            <RetiredAssetDoughnutChart homeInfo={homeInfo} />
          </Box>
        </Box>
      </Box>

      <Box
        mt={4}
        sx={{
          mt: 4,
          display: "grid",
          gridTemplateColumns: "5fr 4fr",
          gap: 4,
          [theme.breakpoints.down("sm")]: {
            mt: 2,
            gridTemplateColumns: "1fr",
            gap: 2,
          },
        }}
      >
        <Box
          sx={{
            minWidth: 0,
            p: 3,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
            [theme.breakpoints.down("md")]: {
              p: 2,
            },
          }}
        >
          <Box>
            <Typography variant="h6" fontWeight={"bold"}>
              Retired Assets by Type
            </Typography>
          </Box>
          <Box mt={1.5}>
            <AssetsReadyForRetirementBarChart homeInfo={homeInfo} />
          </Box>
        </Box>

        {/* <Box
          sx={{
            minWidth: 0,
            p: 3,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
            [theme.breakpoints.down("md")]: {
              p: 2,
            },
          }}
        >
          <Box>
            <Typography variant="h6" fontWeight={"bold"}>
              Overall e-waste generated
            </Typography>
          </Box>
          <Box mt={1.5}>
            <EWasteGeneratedLineChart />
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
}
