import { createContext, useEffect, useState } from "react";
import { useGlobalLoader } from "./useGlobalLoader";
import { api } from "services/api.service";
import { OrganizationFeature, OrganizationPlan } from "types/plan";
import { loadStripe, Stripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "");

type OrganizationConfigurationType = {
  stripePromise: Promise<Stripe | null>;
  ssoActive: boolean;
  ssoProvider: string;
  organizationPlan?: OrganizationPlan;
  planFeatures: OrganizationFeature[];
  assetRetrievalTermsSigned: boolean;
};

export const OrganizationConfigurationContext = createContext<OrganizationConfigurationType>({
  stripePromise: stripePromise,
  ssoActive: false,
  ssoProvider: "",
  planFeatures: [],
  assetRetrievalTermsSigned: false,
});

export const OrganizationConfigurationProvider = ({ children }: { children: React.ReactNode }) => {
  const [organizationConfiguration, setOrganizationConfiguration] = useState({
    stripePromise: stripePromise,
    ssoActive: false,
    ssoProvider: "",
    planFeatures: [],
    assetRetrievalTermsSigned: false,
  });
  const { fireApiCall } = useGlobalLoader();

  const getOrganizationConfiguration = () => {
    fireApiCall(
      api.get("/organization_configuration").then(({ data }) => {
        const organizationPlan = data.organizationPlan as OrganizationPlan;
        setOrganizationConfiguration({
          ...data,
          stripePromise,
          planFeatures: organizationPlan.organizationPlanFeatures.map(({ feature: { name }, grandfathered }) => ({
            name,
            grandfathered,
          })),
        });
      })
    );
  };

  useEffect(() => {
    getOrganizationConfiguration();
  }, []);

  return (
    <OrganizationConfigurationContext.Provider value={organizationConfiguration}>
      {children}
    </OrganizationConfigurationContext.Provider>
  );
};
