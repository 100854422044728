import { useState } from "react";
import { Box, Button, FormControl, Link, Typography } from "@mui/material";
import { InputBase, InputLabel } from "components/common/Input";
import useAuth from "hooks/useAuth";
import theme from "lib/theme";
import LoginCoverImg from "images/login-cover.png";
import * as yup from "yup";

export default function NoConnectionToBackend() {
  return (
    <Box sx={{ paddingBottom: "100px" }}>
      <img
        src={LoginCoverImg}
        alt="login cover"
        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
      ></img>

      <Box sx={{ textAlign: "center" }} mt={3}>
        <Typography
          variant="h6"
          sx={{ textAlign: "center", mt: 6, fontWeight: "bold", color: theme.palette.text.primary }}
        >
          There is a network problem connecting to our backend.
        </Typography>
        <Box sx={{ marginTop: 4 }}>
          Reach out to us at{" "}
          <Link sx={{ my: 2 }} href={`mailto:support@revivn.com`}>
            support@revivn.com
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
