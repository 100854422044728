import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { api } from "services/api.service";

export default function useConfirmPickup() {
  const params = useParams();

  const confirmPickup = useCallback(
    () => api.put(`/pickups/${params.pickupUuid}/confirm_scheduled_pickup`).then(() => {}),
    [params.pickupUuid]
  );

  return {
    confirmPickup,
  };
}
