import {
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  IconButton,
} from "@mui/material";
import { Attachment } from "@mui/icons-material";
import theme from "lib/theme";
import { SearchInput } from "components/common/Input";
import useAssetEvents from "hooks/assets/useAssetEvents";
import { useParams } from "react-router-dom";
import { formatDate } from "utils/helpers";
import useAsset from "hooks/assets/useAsset";

function AssetHistory() {
  const { assetUuid } = useParams();
  let { asset } = useAsset({ uuid: assetUuid });
  const { assetEvents } = useAssetEvents({ uuid: assetUuid });

  return (
    <Box mt={1}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h6" fontWeight="bold">
          History Log {asset?.serialNumber && `- ${asset.serialNumber}`}
        </Typography>
      </Box>

      <TableContainer
        sx={{
          mt: 2,
        }}
      >
        <Table sx={{ ".MuiTableRow-root:last-child td, .MuiTableRow-root:last-child th": { border: 0 } }}>
          <TableHead sx={{ ".MuiTableCell-root": { color: theme.palette.text.disabled, borderBottom: 0 } }}>
            <TableRow>
              <TableCell>DATE</TableCell>
              <TableCell>DESCRIPTION</TableCell>
              <TableCell>ACTION TAKEN BY</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assetEvents.map((assetEvent) => (
              <TableRow key={assetEvent.uuid}>
                <TableCell>{formatDate(assetEvent.createdAt)}</TableCell>
                <TableCell>{assetEvent.description}</TableCell>
                <TableCell>
                  {assetEvent.user && (
                    <Avatar sx={{ backgroundColor: theme.palette.primary.main }}>
                      {assetEvent.user.firstName[0]}
                      {assetEvent.user.lastName[0]}
                    </Avatar>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default AssetHistory;
