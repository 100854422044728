import { Box, Link, Typography, Button } from "@mui/material";
import theme from "lib/theme";
import { ReactComponent as DonationsHeroIcon } from "./images/donations-hero.svg";
import MakeDonation from "./MakeDonation";
import useDonations from "hooks/donations/useDonations";
import { useEffect, useState } from "react";
import DonationsTable from "./DonationsTable";
import EarningsCard from "./EarningsCard";
import EmptyDonationTable from "./EmptyDonationsTable";
import EmptyDonationHistoryGraph from "./EmptyDonationHistoryGraph";
import useDonationRequest from "hooks/donations/useDonationRequest";
import useSearchParamsOperations from "hooks/useSearchParamsOperations";
import ReferAnOrganization from "./ReferAnOrganization";

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: { display: false },
    },
    y: {
      grid: { display: false },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

function Donations() {
  let [seeMore, setSeeMore] = useState(false);
  const { donations } = useDonations();
  const { donationRequests, meta } = useDonationRequest();
  const { replace } = useSearchParamsOperations();
  const [isTableExpanded, setExpandTable] = useState(false);

  useEffect(() => {}, [isTableExpanded, donations]);

  function moreItem({ number, text }: { number: number; text: string }) {
    return (
      <Box sx={{ display: "flex", gap: 1, alignItems: "baseline" }}>
        <Box
          sx={{
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            border: `1px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
            display: "grid",
            placeItems: "center",
            flexShrink: 0,
          }}
        >
          <Box sx={{ fontSize: "14px", lineHeight: "14px" }}>{number}</Box>
        </Box>
        <Typography variant="body1">{text}</Typography>
      </Box>
    );
  }

  return (
    <div>
      <Typography variant="h4" fontWeight="bold">
        Donations
      </Typography>

      <Box
        sx={{
          mt: 3,
          p: 2,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
          display: "grid",
          gridTemplateColumns: "1fr auto",
          gap: 3,
        }}
      >
        <Box sx={{ pt: 3 }}>
          <Typography variant="h6" fontWeight="600">
            Repurpose your tech or donate for a greater cause.
          </Typography>
          <Typography variant="body1" mt={1} sx={{ maxWidth: `60em` }}>
            Revivn is on a mission to repurpose retired computer hardware and give it to people who need it. We also
            donate a portion of total eligible assets collected to organizations in need. For more information on
            organizations we partner with, check out our{" "}
            <Link href="https://www.revivn.com/tech-access" target="_blank" rel="noreferrer">
              tech access page
            </Link>
            .
          </Typography>

          {seeMore && (
            <Box sx={{ pl: 4, display: "flex", flexDirection: "column", gap: 2, pt: 1 }}>
              {moreItem({
                number: 1,
                text: "After we collect your devices, our Tech Ops team processes them at one of our highly secure hubs to wipe all devices.",
              })}
              {moreItem({
                number: 2,
                text: "We prep the now-sanitized devices, package them safely, and send them to organizations in need.",
              })}
              {moreItem({
                number: 3,
                text: "Once received, our Tech Access team continues to work with the organization to gather and share the impact YOU made.",
              })}
            </Box>
          )}

          <Box mt={1}>
            <Link component={"button"} underline="hover" variant="button" onClick={() => setSeeMore((f) => !f)}>
              {seeMore ? "See less" : "See more"}
            </Link>
          </Box>
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <DonationsHeroIcon style={{ width: "180px", height: "180px" }} />
        </Box>
      </Box>

      <Box
        sx={{
          mt: 3,
          p: 2,
          backgroundColor: "#0089BD",
          opacity: "80%",
          borderRadius: "4px 4px 0px 0px",
          border: `1px solid ${theme.palette.divider}`,
          display: "grid",
          gridTemplateColumns: "1fr auto",
        }}
      >
        <EarningsCard />
      </Box>

      <Box sx={{ display: "grid", gridTemplateColumns: "1fr auto" }}>
        <Box
          sx={{
            p: 2,
            background: "white",
            borderRadius: "0px 0px 4px 4px",
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <MakeDonation donationRequests={donationRequests} meta={meta} replace={replace} />
        </Box>
      </Box>
      <Box sx={{ mt: 2, display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}>
        <Box
          sx={{
            px: 3,
            py: 2,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,

            display: "grid",
            gridTemplateRows: "auto 1fr",
            gap: 1,
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Your Donation History
          </Typography>
          <EmptyDonationHistoryGraph />
        </Box>

        <Box
          sx={{
            px: 3,
            py: 2,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,

            display: "grid",
            gridTemplateRows: "auto 1fr",
            gap: 1,
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Your Donation Transactions
          </Typography>
          {donations.length > 0 ? (
            <Box>
              <DonationsTable donations={isTableExpanded ? donations : donations.slice(0, 2)} />
              {donations.length > 2 ? (
                <Button variant="text" onClick={() => setExpandTable(!isTableExpanded)}>
                  {isTableExpanded ? "SEE LESS" : "SEE MORE"}
                </Button>
              ) : null}
            </Box>
          ) : (
            <EmptyDonationTable />
          )}
        </Box>
      </Box>

      <ReferAnOrganization />
    </div>
  );
}

export default Donations;
