import { Button as MuiButton, styled } from "@mui/material";

export const SecondaryButton = styled(MuiButton)(({ theme }) => ({
  textTransform: "uppercase",
  paddingLeft: 40,
  paddingRight: 40,
  color: theme.palette.text.secondary,
  borderColor: theme.palette.text.secondary,
  ":hover": {
    borderColor: theme.palette.text.primary,
    color: theme.palette.text.primary,
    backgroundColor: "transparent",
  },
}));
