import { Box, Button, Divider, Tooltip, Typography, FormControl, Autocomplete } from "@mui/material";
import { ReactComponent as ShippingAndRetrievalIcon } from "assets/icons/ShippingAndRetrievalInvoice.svg";
import { InputBase } from "components/common/Input";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { OrganizationConfigurationContext } from "hooks/useOrganizationConfiguration";
import useEarnings from "hooks/earnings/useEarnings";
import theme from "lib/theme";
import { useContext, useEffect, useMemo, useState } from "react";
import { ServiceTermsModal } from "./ServiceTermsModal";
import useFeatureFlag from "hooks/useFeatureFlag";
import { useFormikContext } from "formik";
import { AssetRetrieval, AssetRetrievalBulkOrderDetails } from "types/assetRetrieval";
import useCurrentUser from "hooks/users/useCurrentUser";
import useUsers from "hooks/users/useUsers";

interface AssetCartProps {
  onContinue: (i?: boolean) => void;
  continueLabel?: string;
  disableContinue?: boolean;
  paymentType: string;
  total: number;
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function AssetCartCheckout({
  onContinue,
  continueLabel = "Continue",
  disableContinue,
  paymentType,
  total,
}: AssetCartProps) {
  const { earningsBalance, isLoading: isLoadingEarnings } = useEarnings();
  const insufficientFunds = total > earningsBalance;
  const isPaymentOrder = paymentType === "payment";
  const canPlaceOrder = !insufficientFunds || isPaymentOrder;
  const { assetRetrievalTermsSigned } = useContext(OrganizationConfigurationContext);
  const [openTermsEarnings, setOpenTermsEarnings] = useState(false);
  const [openTermsInvoice, setOpenTermsInvoice] = useState(false);
  const { enabled: showPayWithInvoiceEnabled } = useFeatureFlag("show_pay_with_invoice");
  const { getFieldProps, setFieldValue, values } = useFormikContext<AssetRetrievalBulkOrderDetails | AssetRetrieval>();
  const { currentUser } = useCurrentUser();
  const { users } = useUsers();

  const userEmails = useMemo(() => {
    return users.map((user) => user.email);
  }, [users]);

  const shouldOpenTerms = useMemo(() => {
    return continueLabel === "place order" && !assetRetrievalTermsSigned;
  }, [continueLabel, assetRetrievalTermsSigned]);

  useEffect(() => {
    setFieldValue("returnRecipientEmail", currentUser.email);
  }, [currentUser]);

  return (
    <>
      <Box
        sx={{
          py: 3,
          px: 4,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
          [theme.breakpoints.down("lg")]: { px: 2, py: 2 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          <ArrowForwardIcon />
          Order Summary
        </Box>
        <Typography>
          We'll deduct this amount from your total earnings! You'll see this transaction posted to your earnings page
          shortly.
        </Typography>
        <Box sx={{ py: 2, display: "flex", flexDirection: "column", gap: 0.5 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", color: "text.secondary" }}>
            <Box>Order Total</Box>
            <Box>${total}</Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", color: "text.secondary" }}>
            <Box>Earnings Used</Box>
            <Box>-${insufficientFunds ? 0 : total}</Box>
          </Box>
        </Box>
        <Divider />
        <Box mt={2} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography fontSize={"20px"} fontWeight="bold" color="primary">
            Total
          </Typography>
          <Typography fontSize={"20px"} fontWeight="bold" color="primary">
            ${total}
          </Typography>
        </Box>
        <Box my={1} mb={2} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", color: "text.secondary" }}>
            New Earnings Balance: ${insufficientFunds ? 0 : earningsBalance - total}
          </Box>
        </Box>
        <Divider />
        <Box mt={2}>
          <FormControl variant="standard" fullWidth>
            <Box sx={{ display: "flex", justifyContent: "space-between", color: "text.secondary" }}>
              <Box>CC Email</Box>
            </Box>
            <Autocomplete
              multiple
              freeSolo
              options={userEmails}
              renderInput={(params) => (
                <InputBase
                  {...params}
                  helperText={"Use this field for visibility into the email reminder correspondence"}
                />
              )}
              {...getFieldProps("returnRecipientEmail")}
              value={values.returnRecipientEmail ? values.returnRecipientEmail.split(",") : []}
              onChange={(event: any, newValue: any) => {
                if (newValue.every((email: string) => emailRegex.test(email))) {
                  setFieldValue("returnRecipientEmail", newValue.join(","));
                }
              }}
            />
          </FormControl>
        </Box>
        <Box mt={5}>
          <Tooltip
            title={
              insufficientFunds && !isPaymentOrder
                ? "Sorry, looks like you don't have enough earnings for this order!"
                : ""
            }
          >
            <span>
              <Button
                fullWidth
                variant="contained"
                onClick={shouldOpenTerms ? () => setOpenTermsEarnings(true) : () => onContinue(false)}
                disabled={disableContinue === undefined ? !canPlaceOrder : disableContinue}
              >
                {continueLabel}
              </Button>
            </span>
          </Tooltip>
        </Box>
        <ServiceTermsModal open={openTermsEarnings} setOpen={setOpenTermsEarnings} submit={() => onContinue(false)} />
      </Box>

      {showPayWithInvoiceEnabled && insufficientFunds && !isPaymentOrder && !isLoadingEarnings && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "3fr 1fr",
            mt: 4,
            gap: 4,
            alignItems: "start",
            [theme.breakpoints.down("md")]: { gridTemplateColumns: "1fr" },
          }}
        >
          <Box
            sx={{
              py: 3,
              px: 4,
              background: "white",
              borderRadius: 1,
              border: `1px solid ${theme.palette.divider}`,
              [theme.breakpoints.down("lg")]: { px: 2, py: 2 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              Don't have enough earnings?
            </Box>

            <Typography>
              We can invoice you for this order instead. Just place your order below and confirm the amount we’ll be
              invoicing you for shortly!
            </Typography>

            <Box mt={2} sx={{ display: "flex", gap: 10 }}>
              <Typography fontSize={"20px"} fontWeight="bold" color="primary">
                Order Total
              </Typography>
              <Typography fontSize={"20px"} fontWeight="bold" color="primary">
                ${total}
              </Typography>
            </Box>
            <Box mt={5} display="flex" justifyContent="flex-end">
              <span>
                <Button onClick={shouldOpenTerms ? () => setOpenTermsInvoice(true) : () => onContinue(true)}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <div>place order & request invoice</div>
                    <ArrowForwardIcon />
                  </Box>
                </Button>
              </span>
            </Box>
            <ServiceTermsModal open={openTermsInvoice} setOpen={setOpenTermsInvoice} submit={() => onContinue(true)} />
          </Box>
          <ShippingAndRetrievalIcon />
        </Box>
      )}
    </>
  );
}

export default AssetCartCheckout;
