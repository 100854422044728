import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { api } from "services/api.service";
import { Asset } from "types/asset";

export default function useAssetExport() {
  const [isError, setIsError] = useState(false);
  const [searchParams] = useSearchParams();
  const [downloadAssetsExport, setDownloadAssetsExport] = useState<Boolean>(false);
  const [exportedAssets, setExportedAssets] = useState<any>("");

  const exportAssets = () => {
    const args = searchParams.toString();
    api
      .get<{ assets: Asset[] }>(`/assets_export.csv?${args.toString()}`)
      .then(({ data }) => {
        setExportedAssets(data);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setDownloadAssetsExport(true);
      });
  };

  return {
    isError,
    exportedAssets,
    downloadAssetsExport,
    setDownloadAssetsExport,
    exportAssets,
  };
}
