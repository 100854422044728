import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "services/api.service";

interface webhookProps {
  endpoint?: string;
  eventName?: string;
  customHeaders?: any;
}

export default function useCreateWebhookSubscription() {
  const navigate = useNavigate();

  const create = useCallback((webhookSubscription: webhookProps) => {
    api.post("/webhook_subscriptions", { webhookSubscription }).then(() => {
      navigate("/api/webhooks");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    create,
  };
}
