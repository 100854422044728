import { Box, Button, FormControl, MenuItem, TablePagination, Tooltip, Typography } from "@mui/material";
import { AppSelect } from "components/common/Input";
import theme from "lib/theme";
import { useCallback, useEffect, useState } from "react";
import LogoIcon from "images/logo-icon.svg";
import { InfoOutlined, Send } from "@mui/icons-material";
import ActivityTable from "./ActivityTable";
import usePickups from "hooks/pickups/usePickups";
import useEarnings from "hooks/earnings/useEarnings";
import { formatDate, formatNumberToCurrency } from "utils/helpers";
import useSearchParamsOperations from "hooks/useSearchParamsOperations";
import useOnboarding, { OnboardingProvider } from "hooks/companySettings/useOnboarding";
import { useSearchParams } from "react-router-dom";
import { Modal } from "components/common/Modal";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { MenuProps } from "constants/filterMenuProps";
import { Line } from "react-chartjs-2";
import useEarningsReport from "hooks/earnings/useEarningsReport";
import useCurrentUserPermission from "hooks/useCurrentUserPermission";

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: { display: false },
    },
    y: {
      grid: { display: false },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 2",
      data: labels.map(() => Math.floor(Math.random() * 5000)),
      borderColor: "rgb(0, 137, 189)",
      backgroundColor: "rgba(0, 137, 189, 0.5)",
    },
  ],
};

const ConfirmWithdrawalModal = ({
  open,
  setOpen,
  requestWithdrawal,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  requestWithdrawal: () => void;
}) => {
  const withdraw = () => {
    requestWithdrawal();
    setOpen(false);
  };

  return (
    <Modal open={open} setOpen={setOpen} width={540}>
      <Box>
        <Box display="flex" flexDirection="column" justifyItems="center" alignItems="center">
          <Send
            sx={{
              color: "#0089BD",
              fontSize: 50,
            }}
          />
          <Typography fontSize="24px" fontWeight="800">
            Transfer Earnings
          </Typography>
          <Typography align="center">
            Are you sure you want to initiate a transfer of your total earnings to your company’s bank account? You
            should receive your funds in about 3-4 business days.
          </Typography>
          <Box sx={{ mt: 2, display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}>
            <Button variant="contained" size="small" disableElevation onClick={withdraw}>
              Yes, Transfer
            </Button>
            <Button variant="contained" size="small" disableElevation onClick={() => setOpen(false)}>
              No, Go back
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

const WithdrawalButton = ({ requestWithdrawal }: { requestWithdrawal: () => void }) => {
  const { onboarding } = useOnboarding();
  const { hasPermission } = useCurrentUserPermission();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  return onboarding.length === 0 ? null : (
    <>
      <Tooltip
        title={
          onboarding[3].completed
            ? ""
            : "Please fill out the payment info section in your company settings to be onboarded onto our payment platform and receive any funds."
        }
      >
        <span>
          <Tooltip
            title={
              hasPermission("earnings")
                ? ""
                : "You do not have permissions for this action, please contact your administrator for permission."
            }
          >
            <span>
              <Button
                variant="contained"
                size="small"
                fullWidth
                disableElevation
                disabled={!hasPermission("earnings") || !onboarding[3].completed}
                onClick={() => {
                  setShowConfirmModal(true);
                }}
              >
                transfer to bank account
              </Button>
            </span>
          </Tooltip>
        </span>
      </Tooltip>
      <ConfirmWithdrawalModal
        requestWithdrawal={requestWithdrawal}
        open={showConfirmModal}
        setOpen={(open: boolean) => {
          setShowConfirmModal(open);
        }}
      />
    </>
  );
};

function Earnings() {
  const { pickups, errorMessage, isLoading, hasNextPage, incrementCurrentPage } = usePickups(true);
  const { earningsBalance, earningsTransactions, meta, requestWithdrawal } = useEarnings();
  const { earningsReport, isLoading: loadingReport } = useEarningsReport();
  const [searchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const { replace, replaceInArrayFieldUsingPrefix, deleteFieldInArrayUsingPrefix } = useSearchParamsOperations();

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: incrementCurrentPage,
    disabled: !!errorMessage,
    rootMargin: "0px 0px 400px 0px",
  });

  const [pickupIds, setPickupIds] = useState(
    searchParams
      .getAll("filter[]")
      .find((p) => p.includes("pickup_id,is_any_of"))
      ?.split("pickup_id,is_any_of,")[1]
      ?.split(",") || []
  );

  useEffect(() => {
    replace("page[number]", (pageNumber + 1).toString());
  }, [pageNumber, replace]);

  useEffect(() => {
    replace("page[size]", pageSize.toString());
  }, [pageSize, replace]);

  useEffect(() => {
    if (pickupIds && pickupIds.length > 0) {
      replaceInArrayFieldUsingPrefix("filter[]", "pickup_id,is_any_of", `pickup_id,is_any_of,${pickupIds.join(",")}`);
    } else {
      deleteFieldInArrayUsingPrefix("filter[]", "pickup_id,is");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupIds]);

  const cleanFilters = useCallback(() => {
    setPickupIds([]);
  }, []);

  return (
    <Box>
      <Typography variant="h4" fontWeight="bold">
        Earnings
      </Typography>
      <Typography mt={1}>View your earnings history</Typography>

      <Box sx={{ mt: 2, display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}>
        <Box
          sx={{
            px: 3,
            py: 2,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box sx={{ position: "absolute", color: theme.palette.primary.main }}>
            <Tooltip title="Repurpose more and grow your earnings balance to perform donations!" placement="top">
              <InfoOutlined />
            </Tooltip>
          </Box>
          <div>
            <Box sx={{ display: "flex", alignItems: "center", height: "100%", width: "100%" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 5, padding: "10px" }}>
                <Box sx={{ marginLeft: "40px", width: "152px", height: "88px" }}>
                  <Box sx={{ width: "100%", height: "100%" }}>
                    <img
                      src={LogoIcon}
                      alt=""
                      style={{ height: "100%", width: "100%", objectFit: "cover", objectPosition: "left" }}
                    />
                  </Box>
                </Box>
                <Box textAlign="center">
                  <Typography variant={"h3"} fontWeight={600} component="div">
                    {formatNumberToCurrency(earningsBalance)}
                  </Typography>
                  <Typography fontWeight={600}>Current Earnings Balance</Typography>
                </Box>
              </Box>
            </Box>
            <OnboardingProvider>
              <WithdrawalButton requestWithdrawal={requestWithdrawal} />
            </OnboardingProvider>
          </div>
        </Box>
        {!loadingReport && (
          <>
            <Box
              sx={{
                px: 3,
                py: 2,
                background: "white",
                borderRadius: 1,
                border: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Typography variant="h6" fontWeight="bold">
                Waived fees
              </Typography>
              <Box>
                <Line
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      x: {
                        grid: { display: false },
                      },
                      y: {
                        grid: { display: false },
                        ticks: {
                          callback: (value) =>
                            value.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            }),
                        },
                      },
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        callbacks: {
                          label: (item) => `${item.dataset.label}: $${item.formattedValue}`,
                        },
                      },
                    },
                    elements: {
                      line: {
                        tension: 0.5,
                      },
                    },
                  }}
                  data={{
                    labels: earningsReport.map((v) => v[0]),
                    datasets: [
                      {
                        label: "Waived Fees",
                        data: earningsReport.map((v) => v[1] / 100.0),
                        borderColor: "#0089BD",
                        backgroundColor: "#0089BD",
                      },
                    ],
                  }}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>

      <Box mt={4}>
        <Box
          display="grid"
          gridTemplateColumns="auto 1fr 1fr 1fr 1fr 1fr auto"
          columnGap="1em"
          marginY={2}
          alignItems="center"
        >
          <Typography width="100%">Quick Filters:</Typography>
          <FormControl variant="standard" size="small" sx={{ width: "100%" }}>
            <AppSelect
              multiple
              MenuProps={MenuProps}
              value={pickupIds}
              onChange={(e) => setPickupIds(e.target.value as string[])}
              renderValue={(values) =>
                values.length === 0
                  ? "Pickup Date"
                  : values.length === 1
                  ? formatDate(pickups.find(({ id }) => id.toString() === values[0].toString())?.scheduledAt || "")
                  : `${values.length} pickups selected`
              }
              displayEmpty
            >
              {pickups
                .filter(({ scheduledAt }) => scheduledAt)
                .map((pickup) => (
                  <MenuItem key={pickup.id} value={pickup.id} ref={sentryRef}>
                    {formatDate(pickup.scheduledAt)} - {pickup.orderNumber}
                  </MenuItem>
                ))}
            </AppSelect>
          </FormControl>
          <Button variant={"outlined"} onClick={cleanFilters}>
            Clear All
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          mt: 2,
          px: 3,
          py: 2,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          Recent Activity
        </Typography>

        <Box sx={{ mt: 4 }}>
          <ActivityTable transactions={earningsTransactions} />
        </Box>
      </Box>
      <Box mt={8} sx={{ display: "flex", justifyContent: "end" }}>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={meta.count || 1}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={(_, page) => {
            setPageNumber(page);
          }}
          onRowsPerPageChange={(e) => {
            setPageSize(parseInt(e.target.value));
          }}
        />
      </Box>
    </Box>
  );
}

export default Earnings;
