import { Box } from "@mui/material";
import theme from "lib/theme";
import React, { useRef } from "react";
import { useEffect } from "react";

interface StepCardProps {
  step: number;
  activeStep: number;
  disable?: boolean;
}

function StepCard({ step, activeStep, disable = activeStep < step, children }: React.PropsWithChildren<StepCardProps>) {
  let boxRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (activeStep > 1 && activeStep === step) {
      let elementPositionTop = (boxRef.current?.getBoundingClientRect().top ?? 0) + window.scrollY;
      window.scrollTo({ top: elementPositionTop - 80, behavior: "smooth" });
    }
  }, [activeStep, step]);

  return (
    <Box
      ref={boxRef}
      sx={{
        p: 4,
        background: "white",
        borderRadius: 1,
        border: `1px solid ${theme.palette.divider}`,
        position: "relative",
        overflow: "hidden",
        [theme.breakpoints.down("md")]: {
          p: 2,
        },
      }}
    >
      {disable && (
        <Box
          sx={[
            {
              zIndex: 1000,
              position: "absolute",
              background: "white",
              opacity: 0.8,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            },
          ]}
        ></Box>
      )}

      {children}
    </Box>
  );
}

export default StepCard;
