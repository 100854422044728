export const deviceTypes = [
  "A/V Equipment",
  "AIO",
  "Chromebook",
  "Chromebox",
  "Computer Accessory",
  "Data Cartridge Tape",
  "Desktop",
  "Hard Drive",
  "Keyboard",
  "Laptop",
  "Mini Desktop",
  "Mobile Phone",
  "Monitor",
  "Mouse",
  "Networking Equipment",
  "Office Equipment",
  "Office Phone",
  "Other",
  "Power Equipment",
  "Printer",
  "TV",
  "Tablet",
];
