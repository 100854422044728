import { Box, Button } from "@mui/material";
import theme from "lib/theme";
import { ReactComponent as Document } from "assets/icons/Document.svg";
import { ReactComponent as BannerGradient } from "assets/icons/BannerGradient.svg";
import useSubscriptions from "hooks/companySettings/useSubscriptions";
import { OrganizationConfigurationContext } from "hooks/useOrganizationConfiguration";
import { ArrowForward } from "@mui/icons-material";
import { useContext, useState } from "react";
import { RequestSentConfirmationModal } from "components/RequestSentConfirmationModal";
import { useAlert } from "hooks/alerts/useAlert";

export default function IndividualCodBanner() {
  const [open, setOpen] = useState(false);
  const { requestPlanUpgrade } = useSubscriptions();
  const organizationConfiguration = useContext(OrganizationConfigurationContext);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { alertError } = useAlert();

  const handleContactSales = () => {
    if (organizationConfiguration?.organizationPlan?.plan) {
      requestPlanUpgrade(String(organizationConfiguration.organizationPlan.subscription.id), "individual_cod's")
        .then(() => {
          setOpenConfirmation(true);
          setOpen(false);
        })
        .catch(() => {
          alertError("Something went wrong, please try again.");
        });
    }
    setOpen(false);
  };
  return (
    <>
      <Box
        sx={{
          display: "grid",
          position: "relative",
          overflow: "hidden",
          background: "white",
          borderRadius: 1,
          p: 2,
          mt: 2,
          border: `1px solid ${theme.palette.divider}`,
          gridTemplateColumns: "3fr 1fr",
          gap: 2,
          [theme.breakpoints.down("md")]: {
            gridTemplateColumns: "1fr 1fr",
          },
          [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr",
          },
        }}
      >
        <BannerGradient
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            height: "100%",
            width: "auto",
          }}
        />
        <Box>
          <Box component={"span"} sx={{ fontSize: 26, fontWeight: 700 }}>
            Looking for individual CODs?
          </Box>
          <Box>
            Simply send a request to our sales team and get individualized certificates of destruction for future
            pickups.
          </Box>

          <Button
            size="small"
            variant="contained"
            sx={{ mt: "20px" }}
            onClick={() => handleContactSales()}
            endIcon={<ArrowForward />}
          >
            send request
          </Button>
        </Box>

        <Box>
          <Document
            style={{
              position: "absolute",
              top: 5,
              right: 40,
            }}
          />
        </Box>
      </Box>
      <RequestSentConfirmationModal open={openConfirmation} setOpen={setOpenConfirmation} />
    </>
  );
}
