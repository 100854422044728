import { Box, Typography, Button, Tabs, Tab } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import { ArrowBack } from "@mui/icons-material";
import theme from "lib/theme";
import { Link as RouterLink, Outlet, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { Menu } from "components/common/Menu";
import useAsset from "hooks/assets/useAsset";
import { RetiredAssetModal } from "../RetiredAssetModal";

function AssetsDetails() {
  let { assetUuid } = useParams();
  // let { asset, refetch } = useAsset({ uuid: assetUuid });
  let location = useLocation();
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  let [, uuid, path] = location.pathname.split("/").filter(Boolean);
  let [retiredAssetOpen, setRetiredAssetOpen] = useState(false);

  let showTabs = path === "details" || path === "history";

  return (
    <div>
      <Box>
        <Breadcrumbs
          data={[
            { label: "Dashboard", to: "/" },
            { label: "Assets", to: "/assets" },
            // { label: "Asset Details" },
            {
              label: {
                // details: "Details",
                history: "History",
                retrieve: "Remote Asset Return",
                checkout: "Check Out Asset",
              }[path],
            },
          ]}
        ></Breadcrumbs>
      </Box>

      <Box mt={4} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h4" fontWeight="bold">
            Asset History
          </Typography>

          {path === "checkout" ? (
            <Button sx={{ mt: 4.5 }} startIcon={<ArrowBack />} component={RouterLink} to={`/assets/${uuid}/details`}>
              back to Asset details
            </Button>
          ) : (
            <Button sx={{ mt: 4.5 }} startIcon={<ArrowBack />} onClick={() => navigate(-1)}>
              back to assets
            </Button>
          )}
        </Box>

        <Box
          sx={{
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          }}
        >
          {" "}
        </Box>
      </Box>

      <Box
        mt={2}
        sx={{
          p: 4,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
          [theme.breakpoints.down("md")]: {
            p: 2,
          },
        }}
      >
        {/* {showTabs && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column-reverse",
                gap: 1,
                alignItems: "stretch",
              },
            }}
          >
            <Tabs
              value={path}
              sx={{
                "&.MuiTabs-root": {
                  [theme.breakpoints.down("sm")]: {
                    width: "100% !important",
                  },
                },
                button: {
                  width: "224px",
                  [theme.breakpoints.down("md")]: {
                    width: "154px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    width: "50%",
                  },
                },
              }}
              onChange={(e, value) => {
                navigate(`/assets/${uuid}/${value}`);
              }}
            >
              <Tab label="details" value="details" />
              <Tab label="History" value="history" />
            </Tabs>

            <Box>
              <Menu
                button={(props) => (
                  <Button
                    variant="contained"
                    size="large"
                    {...props}
                    sx={{ [theme.breakpoints.down("sm")]: { width: "100%" } }}
                  >
                    Actions
                  </Button>
                )}
                actions={[
                  {
                    label: "Check Out Asset",
                    to: `/assets/${uuid}/checkout`,
                  },

                  {
                    label: searchParams.get("editing") ? "Cancel Editing Asset" : "Edit Asset Details",
                    onClick: () =>
                      setSearchParams(searchParams.get("editing") ? {} : { editing: "true" }, { replace: true }),
                  },
                ]}
              />

              <RetiredAssetModal open={retiredAssetOpen} setOpen={setRetiredAssetOpen} uuid={uuid} refetch={refetch} />
            </Box>
          </Box>
        )} */}

        <Outlet />
      </Box>
    </div>
  );
}

export default AssetsDetails;
