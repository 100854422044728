import { useCallback, useState } from "react";
import { api } from "services/api.service";
import { AssetRetrievalBulkOrderDetails } from "types/assetRetrieval";

export default function useAssetRetrievalBulkUpload() {
  const [key, setKey] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);

  const create = useCallback(
    (assetRetrievalBulkUpload: AssetRetrievalBulkOrderDetails) => {
      setSubmitting(true);
      const { isCustomAddress, ...payload } = assetRetrievalBulkUpload;
      api.post("/asset_retrieval_bulk_uploads", { assetRetrievalBulkUpload: payload }).finally(() => {
        setSubmitting(false);
      });
    },
    [key]
  );

  return {
    setKey,
    key,
    submitting,
    create,
  };
}
