import { useAlert } from "hooks/alerts/useAlert";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "services/api.service";

export default function useDeleteWebhookSubscription() {
  const { alertSuccess } = useAlert();
  const navigate = useNavigate();

  const deleteWebhookSubscription = useCallback((uuid: string, setReload: any) => {
    api.delete(`/webhook_subscriptions/${uuid}`).then(() => {
      setReload(true);
      navigate("/api/webhooks");
      alertSuccess("Webhooks subscription successfully deleted");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    deleteWebhookSubscription,
  };
}
