import { Box, Typography, Button, Modal, Alert, AlertTitle } from "@mui/material";
import { useState } from "react";
import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import SummaryInfo from "./SummaryInfo";
import useConfirmPickup from "hooks/pickups/useConfirmPickup";
import { useNavigate } from "react-router-dom";
import theme from "lib/theme";
import useAnalytics from "hooks/analytics/useAnalytics";

export default function Summary() {
  let [open, setOpen] = useState(false);
  const { confirmPickup } = useConfirmPickup();
  const { createAnalyticsEvent } = useAnalytics();
  const navigate = useNavigate();

  return (
    <Box mt={3}>
      <Typography variant="h4" fontWeight="bold">
        Pickup Request Summary
      </Typography>
      <Typography mt={1}>Here’s a summary of your pickup request! Review and submit your request below.</Typography>

      <Box mt={6}>
        <SummaryInfo />
      </Box>

      <Box mt={6} sx={{ display: "flex", gap: 2 }}>
        <Button size="large" variant="outlined" onClick={() => navigate(-1)}>
          back
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={() => {
            setOpen(true);
            confirmPickup();
            createAnalyticsEvent("finish_schedule_pickup");
          }}
        >
          request pickup
        </Button>
      </Box>

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          navigate("/pickups");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ mx: 2 }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "742px",
            bgcolor: "background.paper",
            boxShadow: 24,
            pt: 4.5,
            px: 12,
            pb: 6,
            [theme.breakpoints.down("md")]: {
              px: 3,
            },
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <CheckCircleOutlineOutlined color="primary" sx={{ fontSize: "60px" }} />
            <Typography mt={1} variant="h5" component="h2" fontWeight={800}>
              Our team is reviewing your pickup date request!
            </Typography>
            <Typography mt={0.5} fontSize="20px">
              Your date isn’t confirmed yet - our team is reviewing the size of your pickup and our availability. Reach
              out if you have any questions in the meantime!
            </Typography>
          </Box>

          <Box mt={2}>
            <Alert severity="info">
              <AlertTitle>Keep your pickup details up to date!</AlertTitle>
              Thanks for helping us keep old devices in circulation and preventing unnecessary e-waste! Make sure your
              pickup details are accurate up to 48 hours prior to the pickup date.
            </Alert>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
