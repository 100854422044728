import { useAlert } from "hooks/alerts/useAlert";
import useOnboarding from "hooks/companySettings/useOnboarding";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "services/api.service";
import { Location } from "types/location";

export default function useCreateLocation(originalPath: string) {
  const { fetchOnboarding } = useOnboarding();
  const { alertError } = useAlert();

  const navigate = useNavigate();
  const createLocation = useCallback(
    (location: Location) =>
      api
        .post<{ uuid: string }>("/locations", { location })
        .then((res) => {
          if (originalPath) {
            navigate(originalPath, { state: { newLocationUuid: res.data.uuid } });
          } else {
            navigate("/company-settings/locations");
          }
        })
        .catch(({ response: { data } }) => {
          if (data.errors.contact[0].email) {
            alertError(data.errors.contact[0].email);
          } else {
            alertError(data.errors.toString());
          }
        })
        .finally(() => {
          fetchOnboarding && fetchOnboarding();
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    createLocation,
  };
}
