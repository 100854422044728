import { Alert, AlertTitle, Box, Button, Grid, Link, styled } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ContentCopy } from "@mui/icons-material";
import useAssets from "hooks/assets/useAssets";
import useAnalytics from "hooks/analytics/useAnalytics";

const StyledAlert = styled(Alert)({
  "& .MuiAlert-message": {
    width: "100%",
  },
  "& .MuiAlert-icon": {
    alignItems: "center",
    paddingLeft: "20px",
    color: "#0089BD",
    transform: "scale(1.2)",
  },
});

export default function EnrolledAssets({ enrolledAssetsCount }: { enrolledAssetsCount: number }) {
  const { getAllSerialsNumbers } = useAssets();
  const { createAnalyticsEvent } = useAnalytics();

  const handleCopyToClipboard = () => {
    getAllSerialsNumbers(undefined, true);
    createAnalyticsEvent("copied_enrolled_asset_serials");
  };
  return (
    <Box>
      <StyledAlert
        color="primary"
        sx={{
          width: "100%",
          verticalAlign: "middle",
          py: "10px",
        }}
        severity="warning"
      >
        <Box
          sx={{
            width: "100%",
            pr: "8px",
            pl: "24px",
          }}
        >
          <AlertTitle sx={{ mb: 0, fontWeight: "bold" }}>
            You have {enrolledAssetsCount} enrolled{" "}
            {enrolledAssetsCount > 1 ? "devices that require" : "device that requires"} action
          </AlertTitle>
          <Grid
            container
            sx={{
              display: "grid",
              gridTemplateColumns: "2fr 1fr",
              alignItems: "center",
              justifyContent: "space-between",
              direction: "row",
            }}
          >
            <Box sx={{ width: "100%" }}>
              Release your {enrolledAssetsCount > 1 ? "devices" : "device"} from MDM and/or{" "}
              <Link
                href="https://support.apple.com/guide/apple-business-manager/release-devices-axmec4d28461/web"
                target="_blank"
                color="inherit"
              >
                ABM
              </Link>{" "}
              to optimize your processing times for pickups.
            </Box>
            <Box display="flex" justifyContent="end" alignItems="center" position="relative" top="-10px;">
              <Button
                variant="outlined"
                sx={{ mr: "16px" }}
                onClick={handleCopyToClipboard}
                startIcon={<ContentCopy />}
              >
                Copy Serials
              </Button>
              <Button
                variant="contained"
                component={RouterLink}
                to="/assets?page%5Bnumber%5D=1&page%5Bsize%5D=25&filter%5B%5D=status%2Cis%2CEnrolled&filter%5B%5D=state%2Cis_any_of%2Cretired"
              >
                View assets
              </Button>
            </Box>
          </Grid>
        </Box>
      </StyledAlert>
    </Box>
  );
}
