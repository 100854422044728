import { InfoOutlined } from "@mui/icons-material";
import { Box, FormControl, MenuItem, Tooltip, Typography } from "@mui/material";
import { AppSelect, InputBase, InputLabel, RequiredSymbol } from "components/common/Input";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import theme from "lib/theme";
import AssetCart from "./AssetCart";
import { useFormikContext } from "formik";
import { AssetRetrieval } from "hooks/assetRetrievals/useAssetRetrieval";
import HowItWorks from "./HowItWorks";

interface StartOrderProps {
  goToNextStep: () => void;
}

function StartOrder({ goToNextStep }: StartOrderProps) {
  const { getFieldProps, errors, touched } = useFormikContext<AssetRetrieval>();

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "2fr 1fr",
        gap: 4,
        alignItems: "start",
        [theme.breakpoints.down("md")]: { gridTemplateColumns: "1fr" },
      }}
    >
      <Box
        sx={{
          py: 3,
          px: 4,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
          [theme.breakpoints.down("lg")]: { px: 2, py: 2 },
        }}
      >
        <Typography variant="h5" fontWeight="bold">
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            <ArrowForwardIcon />
            What's being sent back
          </Box>
        </Typography>
        <Typography variant="body1">
          Do you have any details of the laptop you're retrieving? This will help ensure we send the appropriate box
          size.
        </Typography>

        <Box mt={2} sx={{ display: "grid", gridTemplateColumns: "2fr 2fr", gap: 4 }}>
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="employee-name">
              Manufacturer
            </InputLabel>
            <InputBase
              type="text"
              {...getFieldProps<AssetRetrieval>("manufacturer")}
              error={touched.manufacturer && !!errors.manufacturer}
              helperText={touched.manufacturer && errors.manufacturer}
              placeholder={"N/A"}
            />
          </FormControl>
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="employee-name">
              Model
            </InputLabel>
            <InputBase
              type="text"
              {...getFieldProps<AssetRetrieval>("model")}
              error={touched.model && !!errors.model}
              helperText={touched.model && errors.model}
              placeholder={"N/A"}
            />
          </FormControl>
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="employee-name">
              Serial No
            </InputLabel>
            <InputBase
              type="text"
              {...getFieldProps<AssetRetrieval>("serialNumber")}
              error={touched.serialNumber && !!errors.serialNumber}
              helperText={touched.serialNumber && errors.serialNumber}
              placeholder={"N/A"}
            />
          </FormControl>
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="employee-name" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              Insurance
              <Tooltip title="UPS provides liability coverage for shipments up to $100. Purchase additional coverage below.">
                <InfoOutlined color="primary" sx={{ fontSize: "15px" }} />
              </Tooltip>
            </InputLabel>
            <AppSelect {...getFieldProps<AssetRetrieval>("insuranceOption")}>
              <MenuItem value="0">No Insurance</MenuItem>
              <MenuItem value="10">Up to $1,000 (+$10)</MenuItem>
              <MenuItem value="20">Up to $2,000 (+$20)</MenuItem>
              <MenuItem value="30">Up to $3,000 (+$30)</MenuItem>
              <MenuItem value="40">Up to $4,000 (+$40)</MenuItem>
              <MenuItem value="50">Up to $5,000 (+$50)</MenuItem>
            </AppSelect>
          </FormControl>
        </Box>
        <HowItWorks />
      </Box>

      <AssetCart
        onContinue={goToNextStep}
        disableContinue={Boolean(errors.manufacturer || errors.model || errors.serialNumber)}
      />
    </Box>
  );
}

export default StartOrder;
